import React, { Component } from "react";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import axios from "axios";
class AdminVlengthListSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      response: {},
      flagLoading: false,
    };
  }
  handleEditChapter = (chapter) => {
    this.props.onEditChapter(chapter);
  };

  handleChapterSelection = (event) => {
    this.props.onChapterSelection(event);
  };
  handleChange = (event) => {
    // let userInput = event.target.checked;
    // console.log("Tai " + userInput);
    // this.setState({
    //     chapter: { ...this.state.chapter, [name]: userInput },
    //   });
    // let { chapter } = this.props;
    // this.props.onInStockCheckBoxChange(chapter, userInput);
  };
  render() {
    let { error } = this.state;
    let { chapter } = this.props;
    let { flagLoading } = this.state;
    // ={this.state.languageChoice}
    let content = (
      <React.Fragment>
        <tr className="myborder-top">
          <td>{chapter.chapterNumber}</td>
          <td>{chapter.name}</td>
          <td>
            <div className="row justify-content-around">
              <div className="col-4">
                <input
                  type="radio"
                  name="something"
                  value={chapter.chapterId}
                  onClick={(event) => this.handleChapterSelection(event)}
                  onChange={(event) => this.handleChapterSelection(event)}
                />
              </div>

              {flagLoading && (
                <div className="col-4">
                  <BeatLoader size={8} color={"blue"} flagLoading />
                </div>
              )}
            </div>
          </td>
        </tr>
      </React.Fragment>
    );
    if (error) {
      return <div>Error retriving Chapters </div>;
    } else {
      return content;
    } //else
  }
}
export default AdminVlengthListSingle;
