export class PaymentDB {
  constructor(payments) {
    this.payments = payments;
  }
  allPayments() {
    return this.payments;
  }
  searchByPaymentId(id) {
    for (let i = 0; i < this.payments.length; i++) {
      if (this.payments[i].paymentId == id) {
        return this.payments[i];
      }
    }
    return null; // not found
  }
  filterByName(query) {
    let filteredPayments = [];
    for (let i = 0; i < this.payments.length; i++) {
      if (this.payments[i].name.toLowerCase().includes(query.toLowerCase())) {
        filteredPayments.push(this.payments[i]);
      }
    } //for
    return filteredPayments;
  }
}
export default PaymentDB;
