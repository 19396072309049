import React from "react";
import { Link } from "react-router-dom";
import AdminWorkshopListSingle from "./admin-workshop-list-single";

function AdminWorkshopList(props) {
  function handleEditWorkshop(workshop) {
    props.onEditWorkshop(workshop);
  }
  function handleShowChapters(workshop) {
    props.onShowChapters(workshop);
  }
  function handleCrudAction(message, action) {
    props.onCrudAction(message, action);
  }
  function handleDeleteWorkshop(workshop) {
    props.onDeleteWorkshop(workshop);
  }
  function handleSearchTextBoxKeyUp(event) {
    props.onSearchTextBoxKeyUp(event);
  }
  function handleSearchTextChange(event) {
    props.onSearchTextChange(event);
  }
  function handleHeaderClick(event) {
    props.onHeaderClick(event);
  }
  function handleSectionNumberClick(event) {
    props.onSectionNumberClick(event);
  }
  let { filteredWorkshops } = props;
  let { searchText } = props;
  let { currentSize } = props;
  let { accessLevel } = props;
  let { adminType } = props;

  let contentNoworkshops = <div className="text-center">No workshops !</div>;
  return (
    <>
      {filteredWorkshops.length == 0 && (
        <div className="text-center">No workshops !</div>
      )}
      <div className="form-heading  col-12">
        LIST OF workshops ({filteredWorkshops.length})
      </div>
      {/* row starts */}
      {filteredWorkshops.length != 0 && (
        <>
          <div className="row justify-content-center myborder">
            <div className="col-6 my-2 ">
              <input
                type="search"
                className="container-fluid form-control"
                value={searchText}
                onKeyUp={handleSearchTextBoxKeyUp}
                onChange={handleSearchTextChange} // This is contolled by parent
                placeholder="Search workshops"
                id=""
              />
            </div>
          </div>
          {/* row ends */}
          <div className="row p-0 justify-content-center ">
            <div className="col-2 col-md-1"></div>
            <div className="col-8 col-md-4">
              <Link to="#" onClick={handleHeaderClick} id="name">
                Name
              </Link>
            </div>
            <div className="col-2">&nbsp;</div>
          </div>
          {filteredWorkshops.map((workshop, index) => (
            <AdminWorkshopListSingle
              key={index}
              index={index}
              workshop={workshop}
              onDeleteWorkshop={handleDeleteWorkshop}
              onEditWorkshop={handleEditWorkshop}
              onCrudAction={handleCrudAction}
              currentSize={currentSize}
              accessLevel={accessLevel}
              adminType={adminType}
            />
          ))}
        </>
      )}
    </>
  );
}
export default AdminWorkshopList;
