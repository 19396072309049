export class CourseDB {
  constructor(subjects) {
    this.subjects = subjects;
  }
  allCourses() {
    return this.subjects;
  }
  searchByCourseId(id) {
    for (let i = 0; i < this.subjects.length; i++) {
      if (this.subjects[i].subjectId == id) {
        return this.subjects[i];
      }
    }
    return null; // not found
  }
  filterByName(query) {
    let filteredCourses = [];
    for (let i = 0; i < this.subjects.length; i++) {
      if (this.subjects[i].name.toLowerCase().includes(query.toLowerCase())) {
        filteredCourses.push(this.subjects[i]);
      }
    } //for
    return filteredCourses;
  }
}
export default CourseDB;
