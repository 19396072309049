import React, { Component } from "react";
import { BeatLoader } from "react-spinners";
import { Link } from "react-router-dom";
import axios from "axios";
class AdminReportLog extends Component {
  grandTotal = 0;
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      response: {},
      filteredYaadies: [],
      yaadidb: {},
      flagLoading: false,
      date: "",
      log: "",
      type: "",
    };
  }
  pad = (num, size) => {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  };

  handleRefreshClick = () => {
    // get data of items for today from yaadies
    this.getDataFromDatabase(this.state.whichDate);
  };

  handleTypeSelection = (value) => {
    this.setState({
      type: value,
    });
  };
  handleShowHereClick = () => {
    let { type } = this.state;
    this.getLastDate(type); // value is whichDate (today/yesterday)
  };
  handleShowLogClick = () => {
    let { type } = this.state;
    this.setState({
      flagLoading: true,
    });
    let requestUrl = "/files/downloadLogFile/" + type + "_rjac.log";
    axios
      .get(window.routerPrefix + requestUrl)
      .then((res) => {
        this.setState({
          log: res.data,
        });
        this.setState({
          flagLoading: false,
          message: "",
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          error: err,
          flagLoadingActualContent: false,
          flagLoading: false,
        }); // error is set
      });
  };
  handleDownloadClick = () => {
    let { type } = this.state;
    let requestUrl = "/files/downloadLogFile/" + type + "_rjac.log";
    axios
      .get(window.routerPrefix + requestUrl)
      .then((res) => {
        this.setState({
          code: res.data,
        });
        this.setState({
          flagAccess: 2, // downloaded successfully
          flagLoading: false,
          message: "",
        });
        let fileName = res.headers["content-disposition"].split('"')[1];
        let type = "text/plain";
        const url = window.URL.createObjectURL(
          new Blob([this.state.code], {
            type: type,
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove(); // you need to remove that elelment which is created before.
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          error: err,
          flagLoadingActualContent: false,
          flagLoading: false,
        }); // error is set
      });
  };
  getLastDate = (type) => {
    this.setState({
      flagLoading: true,
    });
    axios
      .get(window.routerPrefix + "/general/logDate/" + type)
      .then((res) => {
        let date = res.data;
        date = new Date(date);
        this.setState({
          date: date,
          flagLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  };

  render() {
    let { flagLoading } = this.state;
    let { error } = this.state;
    let { type } = this.state;
    let { date } = this.state;
    let { log } = this.state;
    let content = (
      <React.Fragment>
        {/* row starts */}
        <div className="column col-12 mt-5">
          <div className="form-heading">Log Files</div>
        </div>
        {/* row starts */}
        <div className="row">
          <div className="col-12 my-2 text-center ">
            <input
              type="radio"
              className="form-control-inline"
              name="type"
              value="info"
              onClick={() => this.handleTypeSelection("info")}
              onChange={() => this.handleTypeSelection("info")}
              checked={type === "info"}
            />
            &nbsp;Info
            <div className="col-12 my-2">
              <input
                type="radio"
                className="form-control-inline"
                name="type"
                value="Error"
                onClick={() => this.handleTypeSelection("error")}
                onChange={() => this.handleTypeSelection("error")}
              />
              &nbsp;Error
            </div>
          </div>
        </div>

        {flagLoading && (
          <div className="text-center mt-5">
            <BeatLoader size={16} color={"blue"} flagLoading />
          </div>
        )}
        <div className="col-12 text-center">
          <Link className="" to="#" onClick={this.handleShowHereClick}>
            last Date and Time
          </Link>
        </div>
        {date && (
          <div className="col-12 text-center">{date.toLocaleString()}</div>
        )}
        <div className="col-12 text-center">
          <button
            className="bg-darkcolor text-lightcolor "
            onClick={this.handleDownloadClick}
            disabled={type == ""}
          >
            &nbsp; Download &nbsp;
          </button>
          &nbsp; &nbsp;
          <button
            className="bg-darkcolor text-lightcolor "
            onClick={this.handleShowLogClick}
            disabled={type == ""}
          >
            &nbsp; Refresh content&nbsp;
          </button>
        </div>

        <div className="col-12 text-center"></div>
        {log && (
          <div className="col-12 " style={{ whiteSpace: "pre-wrap" }}>
            {log}
          </div>
        )}
        {/* {message && selectedMenu && (
          <div className="text-center mt-2" style={{ whiteSpace: "pre" }}>
            {message}
          </div>
        )} */}
      </React.Fragment>
    );
    if (error) {
      return <div>Error retriving logs </div>;
    } else {
      return (
        // <div className="container-fluid bg-blue">
        // <div className="row">
        <div className="container-fluid container-content-page p-0">
          {content}
        </div>
        // </div>
        // </div>
      );
    } //else
  }
}
export default AdminReportLog;
