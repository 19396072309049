import React, { Component } from "react";
import NavBarChapters from "./navbar-chapters";
import OfflineActualContent from "./offline-actual-content";
import axios from "axios";
import { BeatLoader } from "react-spinners";

class OfflineContentPage extends Component {
  constructor(props) {
    super(props);
    let menuList = [
      
      {
        name: "program",
        display: "Programs",
        isDisabled: false,
        isReady: true,
        flagActive: false,
        showLength: false,
        shortName: "p",
        availableForGuest: true,
        category: "category1",
      },
      // req. mapping url is exercise
      {
        name: "exercise",
        display: "Exercises",
        isDisabled: false,
        isReady: true,
        flagActive: false,
        showLength: false,
        shortName: "e",
        availableForGuest: true,
        category: "category1",
      },
      // req. mapping url is mprogram
      {
        name: "mprogram",
        display: "M.Programs",
        isDisabled: false,
        isReady: true,
        flagActive: false,
        showLength: false,
        shortName: "m",
        availableForGuest: true,
        category: "category1",
      },
    ];
    this.state = {
      menuList: menuList,
      studentPortalClicked: false,
      message: "",
      subjectList: [],
      programList: [],
      testList: [],
      chapterList: [],
      student: null,
      student1: null,
      student2: null,
      // info whether admitted for subject or Demo or not at all
      // added on 11.01.2023
      /*
       visitorInfo: {
          ...this.state.visitorInfo,
          admissionStatus: sessionInfo.admissionStatus[i],
          admissionType: sessionInfo.admissionType[i],
          chapterLimit: sessionInfo.chapterLimit[i],
        },
      */ 
      visitorInfo: {
        status: "Guest",
        admissionStatus: "Guest",
        chapterLimit: 0,
      },
      selectedChapterId: -1,
      selectedChapterNumber: 0,
      selectedChapterName: "",
      selectedMenu: "",
      flagLoggedIn: false,
      flagLoadingChapters: false,
      maxStudentLimit: 1,
      type: "",
      loggedinStudents: 0,
      loggedIn: "",
      emailId1: "",
      emailId2: "",
      studentNumber: 0,
      videoDuration: 0,
      isSelectedChapterReady: true,
      windowWidth: "", // Trying to make the website responsive 14012023
      currentPage: "homePage",
    };
  }

  sh_ActualContent = () => {
    let { selectedChapterId } = this.state;
    if (selectedChapterId != -1) return true;
    return false;
  };
  handleCancelClick = () => {
    this.setState({
      studentPortalClicked: false,
      message: "",
    });
  };
  handleStudentPortalClick = () => {
    this.setState({
      studentPortalClicked: true,
      message: "",
    });
  };
  handleStudent1InfoClick = () => {
    this.setState({
      clicked: "student1InfoClicked",
      message: "",
      studentNumber: 1,
    });
  };
  handleStudent2InfoClick = () => {
    this.setState({
      clicked: "student2InfoClicked",
      message: "",
      studentNumber: 2,
    });
  };

  hideMessage = () => {
    window.setTimeout(() => {
      this.setState({
        message: "",
      });
    }, 3000);
  };
  updateDimensions = () => {
    let windowWidth = "";
    if (window.innerWidth > 768) {
      windowWidth = "desktop";
    } else {
      windowWidth = "mobile";
    }
    this.setState({
      windowWidth: windowWidth,
    });
  };
  componentDidMount = () => {
    this.setState({
      flagLoadingChapters: true,
    });
    let { selectedSubjectIncludesAssignments } = this.props;
    let menuList = [...this.state.menuList];
    for (let i = 0; i < menuList.length; i++) {
      if (selectedSubjectIncludesAssignments == 1) {
        if (
          menuList[i].name == "exercise" ||
          menuList[i].name == "mprogram" ||
          menuList[i].name == "note" ||
          menuList[i].name == "questionanswer" ||
          menuList[i].name == "test" ||
          menuList[i].name == "download"
        ) {
          menuList[i].show = "no";
        } else {
          menuList[i].show = "yes";
        }
        if (menuList[i].name == "program") {
          menuList[i].display = "Assignments";
        }
      } else {
        menuList[i].show = "yes";
      }
    } //for
    this.setState({
      menuList: menuList,
    });
    this.getChaptersFromDatabase(this.props.selectedSubjectId);
  };
  getChaptersFromDatabase = (subjectId) => {
    axios
      .get(window.routerPrefix + "/chapter/chapters/" + subjectId)
      .then((res) => {
        let chapterList = res.data;
        chapterList.sort((a, b) =>
          a.chapterNumber > b.chapterNumber ? 1 : -1
        );
        if (!chapterList) {
          this.setState({
            chapterList: [],
            message: "Something went wrong, Reload",
            flagLoadingChapters: false,
          });
          this.hideMessage();
        } else {
          // Add FlagActive to each chapter
          for (let i = 0; i < chapterList.length; i++) {
            chapterList[i].flagActive = false;
          } //for
          this.setState({
            chapterList: chapterList,
            flagLoadingChapters: false,
          });
        } //else
        if (this.props.flagStartLearning) {
          this.handleChapterClick(chapterList[0]);
        }
      })
      .catch((err) => {
        this.setState({
          error: err,
          message: "Something went wrong",
          flagLoadingChapters: false,
        }); // error is set
      });
    this.hideMessage();
  };
  unselectMenu = () => {
    // unselect selected menu
    let menuList = [...this.state.menuList];
    for (let i = 0; i < menuList.length; i++) {
      menuList[i].flagActive = false;
    } //for
    this.setState({
      selectedMenu: "",
      menuList: menuList,
    });
  };
  // This gives info about access alloted to a student
  handleChapterHeaderClick = () => {
    if (this.props.selectedSubjectId == -1) {
      this.setState({
        message: "Select a subject first",
        message: "Which subject you want to explore?",
      });
      this.hideMessage();
    } else {
      this.setState({
        message: "",
      });
    }
  };
  handleChapterClick = (chapter) => {
    // if (chapter.chapterId == this.state.selectedChapterId) {
    //   return;
    // }
    this.setState({
      selectedChapterId: chapter.chapterId,
      selectedChapterNumber: chapter.chapterNumber,
      selectedChapterName: chapter.name,
      isSelectedChapterReady: Boolean(chapter.isReady),
      clicked: "chapterClicked",
      videoDuration: 0,
    });
    // Active button color should be changed 28.10.2022
    let chapterList = [...this.state.chapterList];
    for (let i = 0; i < chapterList.length; i++) {
      if (chapter.flagActive == false) {
        chapterList[i].flagActive = false;
        if (chapterList[i].chapterId === chapter.chapterId) {
          chapterList[i].flagActive = true;
        }
      } //if
      else {
        chapterList[i].flagActive = false;
      }
    } //for
    this.setState({
      chapterList: chapterList,
    });
    if (this.state.selectedMenu) {
      this.unselectMenu();
    } else if (this.props.flagStartLearning) {
      this.handleMenuClick(this.state.menuList[0], "");
    }
  };
  handleDownloadNoClick = () => {
    this.unselectMenu();
  };
  handleDownloadYesClick = () => {
    // pdf download
    let requestUrl =
      "/general/codePdf/" +
      this.props.selectedSubjectFilePrefix +
      this.state.selectedChapterNumber;
    this.setState({
      programList: [], // programlist should be empty
    });
    this.downloadPdfFile(requestUrl);
  };
  handleMenuClick = (menu, message) => {
    if (this.state.selectedChapterId == -1) {
      // No chapter selected
      return;
    }
    if (menu.flagActive) {
      // Do not click again and again
      return;
    }
    this.setState({
      selectedMenu: menu,
    });

    // Active button color should be changed 28.10.2022
    let menuList = [...this.state.menuList];
    for (let i = 0; i < menuList.length; i++) {
      if (menuList[i].flagActive == false) {
        menuList[i].flagActive = false;
        if (menuList[i].name === menu.name) {
          menuList[i].flagActive = true;
        }
      } //if
      else {
        menuList[i].flagActive = false;
      }
    } //for
    this.setState({
      menuList: menuList,
      programList: [],
    });
    let prefix = menu.shortName; //name.charAt(0);
    let filePrefixWOExtension =
      this.props.selectedSubjectFilePrefix +
      this.state.selectedChapterNumber +
      prefix;
    this.setState({
      filePrefixWOExtension: filePrefixWOExtension,
    });
    // if (menu.availableForDemo && !message)
    if (!message)
      this.getContentFromDatabase(this.state.selectedChapterId, menu);
  };
  getContentFromDatabase = (chapterId, menu) => {
    let programType = menu.name;
    if (!this.state.isSelectedChapterReady || !menu.isReady) return; // work in progress

    if (menu.name == "download") return; // For pdf download , don't go ahead

    let requestUrl;
    if (menu.category == "category1") {
      requestUrl =
        "/" + programType + "/" + programType + "s" + "/" + chapterId;
    } else if (menu.category == "category2" && menu.name == "questionanswer") {
      // interview question
      requestUrl =
        "/general/interviewQuestions/" +
        this.props.selectedSubjectFilePrefix +
        this.state.selectedChapterNumber;
    } else if (menu.category == "category2" && menu.name == "test") {
      // Tests
      requestUrl =
        "/general/tests/" +
        this.props.selectedSubjectFilePrefix +
        this.state.selectedChapterNumber;
    }
    this.setState({
      flagLoadingChapterContent: true,
    });
    // Only for programs/videos/interview questions (Category 1,2)
    axios
      .get(window.routerPrefix + requestUrl)
      .then((res) => {
        if (
          menu.category == "category1" ||
          (menu.category == "category2" && menu.name == "questionanswer")
        ) {
          let programList = res.data;
          if (menu.category == "category1") {
            programList.sort((a, b) =>
              a.programNumber > b.programNumber ? 1 : -1
            );
          }
          let sum = 0;
          for (let i = 0; i < programList.length; i++) {
            programList[i].flagActive = false;
            if (menu.showLength) {
              sum += programList[i].length; //video duration
            }
          } //for
          this.setState({
            programList: programList,
            videoDuration: sum,
            flagLoadingChapterContent: false,
          });
          // First video should be displayed
          if (this.props.flagStartLearning) {
            this.handlePlayVideoClick(programList[0], programList[0].link);
            this.props.resetFlagStartLearning();
          }
        } else if (menu.category == "category2" && menu.name == "test") {
          let testCount = res.data;
          // this.setState({
          //   testCount: testCount,
          // });
          // get all data of tests of this student
          // Query parameters used here
          let { student } = this.props;
          let { selectedChapterId } = this.state;
          axios
            .get(
              window.routerPrefix +
                "/testResult/testResults/" +
                student.studentId +
                "?chapterId=" +
                selectedChapterId
            )
            .then((res) => {
              let testList = res.data;
              this.setState({
                testList: testList,
                testCount: testCount,
                flagLoadingChapterContent: false,
              });
            })
            .catch((err) => {
              console.log(err);
              this.setState({ error: err, flagLoadingChapterContent: false }); // error is set
            });
        }
      })
      .catch((err) => {
        this.setState({
          error: err,
          message: "Something went wrong",
          flagLoadingChapterContent: false,
        }); // error is set
        this.hideMessage();
      });
  };
  downloadPdfFile = (requestUrl) => {
    this.setState({
      flagLoadingChapterContent: true,
    });
    axios
      .get(window.routerPrefix + requestUrl, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/pdf",
        },
      })
      .then((res1) => {
        let type = "application/pdf";
        const url = window.URL.createObjectURL(
          new Blob([res1.data], {
            type: type,
          })
        );
        let fileName = res1.headers["content-disposition"].split('"')[1];
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.setState({
          flagLoadingChapterContent: false,
        });
        this.setState({
          message: "pdf file downloaded successfully!",
        });
        this.hideMessage();
        this.unselectMenu();
      })
      .catch((err) => {
        this.setState({
          error: err,
          message: "Something went wrong",
          flagLoadingChapterContent: false,
        }); // error is set
        this.hideMessage();
      });
  };
  handleShowAnswerClick = (program) => {
    let programList = [...this.state.programList];
    for (let i = 0; i < programList.length; i++) {
      if (program.flagActive == false) {
        programList[i].flagActive = false;
        if (programList[i].questionNumber === program.questionNumber) {
          programList[i].flagActive = true;
        }
      } //if
      else {
        programList[i].flagActive = false;
      }
    } //for
    this.setState({
      programList: programList,
    });
  };
  handleShowCodeClick = (program) => {
    let programList = [...this.state.programList];
    for (let i = 0; i < programList.length; i++) {
      if (program.flagActive == false) {
        programList[i].flagActive = false;
        if (programList[i].programId === program.programId) {
          programList[i].flagActive = true;
        }
      } //if
      else {
        programList[i].flagActive = false;
      }
    } //for
    this.setState({
      programList: programList,
    });
  };
  handleOtherCoursesClick = () => {
    this.props.onOtherCoursesClick();
  };
  render() {
    let { chapterList } = this.state;
    let { programList } = this.state;
    let { testList } = this.state;
    let { message } = this.state;
    let { selectedSubjectName } = this.props;
    let { selectedSubjectFees } = this.props;

    let { selectedSubjectAvailableFor } = this.props;
    let { selectedSubjectIncludesAssignments } = this.props;

    let { selectedSubjectFilePrefix } = this.props;
    let { student1 } = this.props;
    let { windowWidth } = this.props;
    let { visitorInfo } = this.props;
    let { isSelectedChapterReady } = this.state;
    let { filePrefixWOExtension } = this.state;
    let { videoDuration } = this.state;
    let { flagLoadingChapters } = this.state;
    let { flagLoadingChapterContent } = this.state;
    let { selectedChapterNumber } = this.state;
    let { selectedChapterName } = this.state;
    let { selectedChapterId } = this.state;
    let { selectedMenu } = this.state;
    let { menuList } = this.state;
    let { testCount } = this.state;
    return (
      <React.Fragment>
        <div className="container  container-content-page ">
          <div className="row ">
            {/* ~~~~~~~~~~~~~~ Selected Subject Name (on see course details - click)  ~~~~~~~~~~~~~~*/}
            {selectedSubjectName && (
              <h5 className="col-12    text-center">{selectedSubjectName}</h5>
            )}
          </div>
          {/* ~~~~~~~~~~~~~~ Chapter Nav Bar ~~~~~~~~~~~~~~*/}
          {flagLoadingChapters && (
            <div className="text-center mt-5">
              <BeatLoader size={16} color={"blue"} flagLoadingChapters />
            </div>
          )}
          <div className="row justify-content-center text-center ">
            {!flagLoadingChapters && (
              <div className="col-10 col-md-6 container-chapters ">
                <NavBarChapters
                  windowWidth={windowWidth}
                  chapterList={chapterList}
                  selectedChapterId={selectedChapterId}
                  selectedChapterName={selectedChapterName}
                  selectedChapterNumber={selectedChapterNumber}
                  flagLoadingChapters={flagLoadingChapters}
                  onChapterClick={this.handleChapterClick}
                  onChapterHeaderClick={this.handleChapterHeaderClick}
                  message={message}
                />
              </div>
            )}
            {message && (
              <div className="col-12 mt-2 text-left text-message text-center">
                {message}
              </div>
            )}
            <div className="container-actual-content">
              {this.sh_ActualContent() && (
                <OfflineActualContent
                  menuList={menuList}
                  programList={programList}
                  testList={testList}
                  isSelectedChapterReady={isSelectedChapterReady}
                  selectedSubjectFilePrefix={selectedSubjectFilePrefix}
                  selectedSubjectName={selectedSubjectName}
                  selectedSubjectFees={selectedSubjectFees}
                  selectedSubjectAvailableFor={selectedSubjectAvailableFor}
                  selectedSubjectIncludesAssignments={
                    selectedSubjectIncludesAssignments
                  }
                  filePrefixWOExtension={filePrefixWOExtension}
                  selectedChapterNumber={selectedChapterNumber}
                  selectedMenu={selectedMenu}
                  selectedChapterId={selectedChapterId}
                  flagLoadingChapterContent={flagLoadingChapterContent}
                  flagLoadingChapters={flagLoadingChapters}
                  testCount={testCount}
                  onShowCodeClick={this.handleShowCodeClick}
                  onShowAnswerClick={this.handleShowAnswerClick}
                  onMenuClick={this.handleMenuClick}
                  onDownloadNoClick={this.handleDownloadNoClick}
                  onDownloadYesClick={this.handleDownloadYesClick}
                  getContentFromDatabase={this.getContentFromDatabase}
                  videoDuration={videoDuration}
                  student={student1}
                  visitorInfo={visitorInfo}
                />
              )}
            </div>
          </div>
          {/* row ends */}
        </div>
        {/* ~~~~~~~~~~~~~~ Container below Navbar Ends ~~~~~~~~~~~~~~*/}
      </React.Fragment>
      // { content; }
    );
  }
}
export default OfflineContentPage;
