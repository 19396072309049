import React, { Component } from "react";
import axios from "axios";
import "./css/login.css";
import AdminLoginFirstForm from "./admin-login-first-form";
import { BeatLoader } from "react-spinners";
class AdminLoginFirst extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flagLoading: false,
      flagLoginSuccess: false,
    };
  }
  handleLoginFirstFormSubmit = (data) => {
    // data contains password_old, password_new, password_new1
    this.setState({
      flagLoading: true,
    });
    axios
      .post("/admins/checkFirstLogin", data, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          // "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        },
      })
      .then((res) => {
        if (res.data.status == true) {
        } else {
          // first time login failed
        }
        this.setState({
          response: res.data.status,
          flagLoading: false,
        });
        this.props.onLoginFirstFormSubmit(res.data.status);
      })
      .catch((err) => {
        console.log("error...");
        this.setState({
          flagLoading: false,
        });
      });
  };
  render() {
    let { flagLoading } = this.state;
    let content;
    if (flagLoading) {
      content = (
        <div className="text-center">
          <BeatLoader size={16} color={"blue"} flagLoading />
        </div>
      );
    } else {
      content = (
        <AdminLoginFirstForm
          onLoginFirstFormSubmit={this.handleLoginFirstFormSubmit}
        />
      );
    }
    return <React.Fragment>{content}</React.Fragment>;
  }
}
export default AdminLoginFirst;
