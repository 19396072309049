import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import axios from "axios";
function AdminSubjectListSingle(props) {
  let [flagLoading, setFlagLoading] = useState(false);
  let [error, setError] = useState("");

  function handleEditSubject(subject) {
    props.onEditSubject(subject);
  }
  function handleDeleteSubject(subject) {
    // Now call for delete api
    setFlagLoading(true);
    axios
      .delete(window.routerPrefix + "/subject/delete/" + subject.subjectId)
      .then((res) => {
        setFlagLoading(false);
        if (res.data) {
          props.onDeleteSubject(subject);
        } else {
          //Error, Something went wrong
        }
      })
      .catch((err) => {
        setFlagLoading(false);
        setError(err);
      });
  }
  let { subject } = props;
  let { index } = props;
  let { accessLevel } = props;
  let { adminType } = props;
  return (
    <div className="row myborder-top justify-content-center mb-2">
      <div className="col-2 col-md-1">{index + 1}.</div>
      <div className="col-6 col-md-4 ">{subject.name}</div>
      <div className="col-2 col-md-1">
        {adminType >= accessLevel && (
          <Link to="#" onClick={() => handleEditSubject(subject)}>
            <i className="fas fa-edit admin-icons"></i>
          </Link>
        )}{" "}
      </div>
      <div className="col-2 col-md-1">
        {!flagLoading && adminType >= accessLevel && (
          <Link
            to="#"
            onClick={() => {
              if (
                window.confirm(
                  "You really want to delete Subject - " + subject.name + " ?"
                )
              )
                handleDeleteSubject(subject);
            }}
          >
            <i className="far fa-trash-alt admin-icons"></i>
          </Link>
        )}
        {flagLoading && <BeatLoader size={8} color={"blue"} flagLoading />}
      </div>
    </div>
  );
}
export default AdminSubjectListSingle;
