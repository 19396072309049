import React, { Component } from "react";
import FacebookLogin from "react-facebook-login";
import "./css/login.css";
import { BeatLoader } from "react-spinners";
class LoginPage extends Component {
  errorStudent = {
    emailId: { message: "" },
    password: { message: "", onlyDigits: true },
  };
  constructor(props) {
    super(props);
    this.state = {
      student: {
        emailId: "",
        password: "",
      },
      errorStudent: this.errorStudent,
      flagFormInvalid: false,
      flagWhatsappChecked: false,
    };
  }
  handleLoginCancelClick = () => {
    this.props.onLoginCancelClick();
  };
  handleLoginFormSubmit = (event) => {
    event.preventDefault();
    let data = this.state.student; //this.state
    // this.props.onFormSubmit(data);

    this.props.onLoginFormSubmit(data);
  };
  handleChange = (event) => {
    let { errorStudent } = this.state;
    let name = event.target.name;
    let message;
    let value = event.target.value;
    let mxLen = errorStudent[`${name}`].mxLen;
    let onlyDigits = errorStudent[`${name}`].onlyDigits;
    if (value.length > mxLen) {
      value = value.slice(0, mxLen);
      if (!onlyDigits) message = "Maximum " + mxLen + " characters allowed";
      else message = "Maximum " + mxLen + " digits allowed";
    } //if...
    else {
      message = "";
    }
    errorStudent[`${name}`].message = message;
    this.setState({
      errorStudent: errorStudent,
      student: { ...this.state.student, [name]: value },
    });
    this.checkAllErrors(errorStudent);
  };
  handleBlur = (event) => {
    let name,
      value,
      requiredMessage = " is required",
      type,
      message = "",
      mnLen,
      onlyDigits;
    let { errorStudent } = this.state;
    name = event.target.name;
    type = event.target.type;
    if (type === "text" || type === "textarea") {
      // check if 'required', also check min no. of characters
      value = event.target.value.trim();
      mnLen = errorStudent[`${name}`].mnLen;
      onlyDigits = errorStudent[`${name}`].onlyDigits;
      if (value.length === 0) {
        message = name + requiredMessage;
      } else if (value.length < mnLen) {
        if (!onlyDigits) message = "Min " + mnLen + " characters required";
        else message = "Min " + mnLen + " digits required";
      } //else
      else if (onlyDigits) {
        for (let i = value.length - 1; i >= 0; i--) {
          const d = value.charCodeAt(i);
          if (d < 48 || d > 57) {
            message = "Enter only digits";
            break;
          } //if
        } //for
      } //else if... onlyDigits
      else if (event.target.type === "email") {
        value = event.target.value.trim();
        if (/\S+@\S+\.\S+/.test(value) == 0) {
          message = name + requiredMessage;
        } else {
          message = "";
        }
      } //else if
      else {
        message = "";
      }
    } //if... text || textarea
    errorStudent[`${name}`].message = message;
    this.setState({ errorStudent: errorStudent });
    this.checkAllErrors(errorStudent);
  };
  handleFocus = (event) => {
    let name = event.target.name;
    let errorStudent = this.state.errorStudent;
    errorStudent[`${name}`].message = "";
    this.setState({ errorStudent: errorStudent });
    this.checkAllErrors(errorStudent);
  };
  checkAllErrors = (errorStudent) => {
    // let { errorProduct } = this.state;
    let flagFormInvalid = false;
    for (let field in errorStudent) {
      if (errorStudent[field].message !== "") {
        // error is there
        flagFormInvalid = true;
        break;
      } //if
    } //for
    this.setState({ flagFormInvalid: flagFormInvalid });
  };
  handleUserLogIn = () => {
    this.props.onUserLogIn();
  };
  handleStudentLogIn = () => {
    this.props.onStudentLogIn();
    // change content in the menubars in the navbar
  };
  handleFormSubmit = (event) => {
    event.preventDefault();
    let data = this.state.student; //this.state
    this.props.onFormSubmit(data);
  };
  responseFacebook = (response) => {
    console.log(response);
    this.props.onFacebookLoginSubmit(response.email);
  };
  handleFacebookLoginClick = (data) => {
    console.log(data);
  };
  render() {
    let { errorStudent } = this.state;
    let { student } = this.state;
    let { flagFormInvalid } = this.state;
    let { maxStudentLimit } = this.props;
    let { loggedinStudents } = this.props;
    let { flagLoadingLogin } = this.props;

    let headingMessage;
    if (maxStudentLimit == 2 && maxStudentLimit == 0) {
      headingMessage = " (First Student)";
    } else if (maxStudentLimit == 2 && loggedinStudents == 1) {
      headingMessage = " (Second Student)";
    }
    if (flagLoadingLogin) {
      return (
        <div className="text-center mt-5">
          <BeatLoader size={16} color={"blue"} flagLoadingLogin />
        </div>
      );
    }
    return (
      <React.Fragment>
        <div className="container container-student-login-form">
          {/* // row starts */}
          {/* <div className="form-heading  text-center ">OR</div> */}
          {/* <div className="form-heading  text-center "></div> */}
          <div className="text-center mb-4">
            <h5
              className="text-primarycolor text-uppercase mb-3"
              style={{ letterSpacing: "5px" }}
            >
              Login using email-id
            </h5>
          </div>
          {/* // row starts */}
          <div className="form-student-login">
            <form className="text-dark " onSubmit={this.handleLoginFormSubmit}>
              <div className="text-white bg-darkcolor my-2 px-3 py-3">
                <div className="form-group row column align-items-center px-1">
                  {/* row starts */}
                  <div className="col-4 text-right">
                    <label>Email-id</label>
                  </div>
                  <div className="col-8  column px-0">
                    <input
                      type="email"
                      className="form-control"
                      name="emailId"
                      value={student.emailId}
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                      onFocus={this.handleFocus}
                      placeholder="Enter email-id"
                      required
                    />
                  </div>
                  <div className="offset-5">
                    {errorStudent.emailId.message ? (
                      <span className="error-text">
                        {this.errorStudent.emailId.message}
                      </span>
                    ) : null}
                  </div>
                </div>
                {/* row ends */}
                {/* row starts */}
                <div className="form-group row column align-items-center">
                  <div className="col-4 text-right">
                    <label>Password</label>
                  </div>
                  <div className="col-8 px-0">
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      value={student.password}
                      onChange={this.handleChange}
                      placeholder="Enter Password"
                    />
                  </div>
                </div>
                {/* row ends */}
                {/* row starts */}
                <div className="form-group row offset-5 text-center mb-0">
                  <button
                    className="bg-lightcolor text-dark-color no-border p-2"
                    type="submit"
                    disabled={flagFormInvalid}
                  >
                    Submit
                  </button>
                  &nbsp;&nbsp;&nbsp;
                  <button
                    className="bg-lightcolor text-dark-color no-border p-2"
                    type="cancel"
                    onClick={this.handleLoginCancelClick}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default LoginPage;
