import React, { Component } from "react";
import { Link } from "react-router-dom";
import AdminAccessListSingle from "./admin-access-list-single";
import Select from "react-select";

class AdminAccessList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      selectedStudent: "",
      response: {},
    };
  }
  handleEditAccess = (access) => {
    this.props.onEditAccess(access);
  };

  handleCrudAction = (message, action) => {
    this.props.onCrudAction(message, action);
  };
  handleDuplicateAccess = (access, selection) => {
    this.props.onDuplicateAccess(access, selection);
  };
  handleDeleteAccess = (access) => {
    this.props.onDeleteAccess(access);
  };
  handleSearchTextBoxKeyUp = (event) => {
    this.props.onSearchTextBoxKeyUp(event);
  };
  handleSearchTextChange = (event) => {
    this.props.onSearchTextChange(event);
  };
  handleHeaderClick = (event) => {
    this.props.onHeaderClick(event);
  };
  handleSectionNumberClick = (event) => {
    this.props.onSectionNumberClick(event);
  };
  handleSelectStudentChange = (event) => {
    let index = event.target.selectedIndex; // get selected index, instad of selected value
    var optionElement = event.target.childNodes[index];
    var selectedStudentId = optionElement.getAttribute("id");
    let selectedStudent = event.target.value;
    this.props.onSelectStudentChange(selectedStudent, selectedStudentId);
  };
  handleSelectStudentChangeNew = (value) => {
    let selectedStudent = value; //event.target.value;
    let selectedStudentId = value.studentId;
    this.props.onSelectStudentChange(selectedStudent, selectedStudentId);
  };
  render() {
    let { error } = this.state;
    let { selectedStudent } = this.props;
    let { filteredAccesss } = this.props;
    let { studentList } = this.props;
    let { searchText } = this.props;
    let { currentSize } = this.props;
    let { accessLevel } = this.props;
    let { adminType } = this.props;
    let contentNoaccesss = <div className="text-center">No accesss !</div>;
    // prepare options
    let optionsStudent = studentList.map((student, index) => (
      <option value={student.fullName} key={index} id={student.studentId}>
        {student.fullName}
      </option>
    ));
    let optionsStudentNew = [...studentList];
    studentList.map((student, index) => {
      // optionsStudentNew[index].value = student.fullName;
      optionsStudentNew[index].label = student.fullName;
    }); //map

    let content = (
      <React.Fragment>
        {/* First select the student  */}
        <div className="row justify-content-center">
          <div className="col-6 ">
            <Select
              className="form-control"
              name="selectedStudent"
              value={selectedStudent.fullName}
              onChange={this.handleSelectStudentChangeNew}
              options={optionsStudentNew}
            />
          </div>
        </div>
        {selectedStudent && currentSize != 0 && (
          <div className="form-heading column col-12">
            LIST OF accesss ({filteredAccesss.length})
          </div>
        )}
        {selectedStudent && currentSize == 0 && (
          <div className="form-heading column col-12 thick-red-text">
            No Accesss
          </div>
        )}
        {/* row starts */}
        {selectedStudent && currentSize != 0 && (
          <div className="row justify-content-center column">
            <div className="col-6 my-2 column">
              <input
                type="search"
                className="container-fluid form-control"
                value={searchText}
                onKeyUp={this.handleSearchTextBoxKeyUp}
                onChange={this.handleSearchTextChange} // This is contolled by parent
                placeholder="Search accesss"
                id=""
              />
            </div>
          </div>
        )}
        {/* row ends */}
        {selectedStudent && filteredAccesss.length != 0 && (
          <div className="row p-0 ">
            <div className="col-12 justify-content-center">
              <div className="table-responsive">
                <table className="table mx-auto w-auto table-sm table-hover table-borderless bigger-text">
                  <thead>
                    <tr>
                      <th>
                        {" "}
                        <Link
                          to="#"
                          onClick={this.handleHeaderClick}
                          id="accessNumber"
                        >
                          Subject
                        </Link>
                      </th>
                      <th>
                        {" "}
                        <Link
                          to="#"
                          onClick={this.handleHeaderClick}
                          id="admissionType"
                        >
                          Type
                        </Link>
                      </th>
                      <th>
                        <Link to="#" onClick={this.handleHeaderClick} id="name">
                          Chapter Limit
                        </Link>
                      </th>
                    </tr>
                  </thead>
                  {filteredAccesss.map((access, index) => (
                    <tbody key={index}>
                      <AdminAccessListSingle
                        access={access}
                        index={index}
                        onDeleteAccess={this.handleDeleteAccess}
                        onDuplicateAccess={this.handleDuplicateAccess}
                        onEditAccess={this.handleEditAccess}
                        onCrudAction={this.handleCrudAction}
                        currentSize={currentSize}
                        accessLevel={accessLevel}
                        adminType={adminType}
                      />
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
    if (error) {
      return <div>Error retriving accesss </div>;
    } else if (filteredAccesss.length >= 0) {
      return (
        // <div className="container-fluid bg-blue">
        // <div className="row">
        <div className="">{content}</div>
        // </div>
        // </div>
      );
    } //else
    else if (filteredAccesss.length == 0) {
      return contentNoaccesss;
    } //else
  }
}
export default AdminAccessList;
