import React, { Component } from "react";
import TestPage from "./test-page";
import NavBtnQuestion from "./nav-btn-question";
import axios from "axios";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { FaStar } from "react-icons/fa";

class Tests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      testStatus: "notStarted",
      flagLoadingTestQuestions: false,
      testQuestionList: [],
      currentTestNumber: "",
      selectedQuestionNumber: -1,
      selectedAnswer: "",
      counterSubmittedAnswers: 0,
      flagResultShown: false,
      flagTestSubmit: false,
      marksObtained: 0,
    };
  }
  handleCancelTestClick = () => {
    let { counterSubmittedAnswers } = this.state;
    let { testQuestionList } = this.state;

    //counterSubmittedAnswers == testQuestionList.length
    if (counterSubmittedAnswers < testQuestionList.length) {
      // Its "Cancel Test" clicked
      this.setState({
        testStatus: "notStarted",
        counterSubmittedAnswers: 0,
        selectedQuestionNumber: -1,
      });
    } else {
      // Its "Try another Test " clicked
      // This score is submitted, now update test_list
      this.props.getContentFromDatabase();
    }
  };
  handleQuestionClick = (testQuestion) => {
    let selectedQuestionNumber = testQuestion.questionNumber;
    let { testQuestionList } = this.state;
    let { testStatus } = this.state;
    let selectedAnswer, currentStatus, selectedQuestionState;
    if (testStatus == "reviewTest") {
      // selectedAnswer = testQuestionList[selectedQuestionNumber].selectedAnswer;
    } //end of "reviewTest"
    else if (testStatus == "started") {
      for (let i = 0; i < testQuestionList.length; i++) {
        if (testQuestionList[i].questionNumber == selectedQuestionNumber) {
          if (testQuestionList[i].questionState != "answered") {
            testQuestionList[i].questionState = "clicked";
            selectedQuestionState = "clicked";
            selectedAnswer = "";
            currentStatus = "Select answer of Q." + selectedQuestionNumber;
          } else {
            // answered question clicked again
            selectedAnswer = testQuestionList[i].selectedAnswer;
            currentStatus =
              "For Q" +
              selectedQuestionNumber +
              ", you answered - " +
              selectedAnswer;
            selectedQuestionState = "answered";
          }
        } //end of selected question
        else if (testQuestionList[i].questionState == "clicked") {
          testQuestionList[i].questionState = "unClicked";
          selectedQuestionState = "unClicked";
        }
      } //for
    } //end of "started"
    this.setState({
      testQuestionList: testQuestionList,
      selectedQuestion: testQuestion, //testQuestionList[selectedQuestionNumber - 1],
      selectedQuestionNumber: selectedQuestionNumber,
      currentStatus: currentStatus,
      selectedQuestionState: selectedQuestionState,
      selectedAnswer: selectedAnswer,
    });
  };
  handleSubmitAnswerClick = () => {
    let { selectedQuestionNumber } = this.state;
    let { testQuestionList } = this.state;
    let { counterSubmittedAnswers } = this.state;
    let currentStatus;
    for (let i = 0; i < testQuestionList.length; i++) {
      if (testQuestionList[i].questionNumber == selectedQuestionNumber) {
        testQuestionList[i].selectedAnswer = this.state.selectedAnswer;
        testQuestionList[i].questionState = "answered";
      }
    } //for
    counterSubmittedAnswers++;

    if (counterSubmittedAnswers == testQuestionList.length) {
      // exam over
      // submit answers to server
      let submittedAnswers = testQuestionList.map((e) => e.selectedAnswer);
      // Add chapterId also in the body,
      // It is required to be stored in TestResult Table
      submittedAnswers[testQuestionList.length] = this.props.selectedChapterId;
      // Add flagFirstAttempt also in the body,
      // It tells whether it is first attempt or not
      submittedAnswers[testQuestionList.length] = this.props.selectedChapterId;
      submittedAnswers[testQuestionList.length + 1] =
        this.state.flagFirstAttempt;
      let requestUrl =
        "/general/testQuestions/" +
        this.props.selectedSubjectFilePrefix +
        this.props.selectedChapterNumber +
        "test" +
        this.state.currentTestNumber;
      this.setState({
        currentStatus: "Submitting the test...",
        flagTestSubmit: true,
      });
      axios
        .post(window.routerPrefix + requestUrl, submittedAnswers, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
          },
        })
        .then((res) => {
          let testResult = res.data;
          let marksObtained = 0;
          for (let i = 0; i < testResult.length; i++) {
            testQuestionList[i].answer = testResult[i].answer;
            if (
              testQuestionList[i].answer == testQuestionList[i].selectedAnswer
            ) {
              marksObtained++;
              testQuestionList[i].questionState = "answeredCorrect";
            } else {
              testQuestionList[i].questionState = "answeredWrong";
            }
            testQuestionList[i].explanation = testResult[i].explanation;
          } //for
          currentStatus = "Test is submitted";
          selectedQuestionNumber = -1;
          this.setState({
            flagLoading: false,
            testQuestionList: testQuestionList,
            currentStatus: currentStatus,
            counterSubmittedAnswers: counterSubmittedAnswers,
            selectedQuestionNumber: selectedQuestionNumber,
            marksObtained: marksObtained,
            testStatus: "resultAwaited",
            flagTestSubmit: false,
          });
          this.setState({
            flagLoading: false,
          });
        })
        .catch((err) => {
          console.log("error!");
          this.setState({
            flagTestSubmit: false,
            currentStatus: "Something went wrong",
          });
        });
    } //if... last answer submitted
    else {
      if (counterSubmittedAnswers == testQuestionList.length - 1) {
        currentStatus =
          "Answer of Q." +
          selectedQuestionNumber +
          " submitted. Try last question now.";
      } else {
        currentStatus =
          "Answer of Q." +
          selectedQuestionNumber +
          " submitted. Try other question now.";
      }
      // currentStatus = "Answer of Q." + selectedQuestionNumber + " submitted";
      selectedQuestionNumber = this.state.selectedQuestionNumber;
      this.setState({
        testQuestionList: testQuestionList,
        currentStatus: currentStatus,
        counterSubmittedAnswers: counterSubmittedAnswers,
        selectedQuestionNumber: selectedQuestionNumber,
      });
    }
  };
  getImageLink = () => {
    let { selectedQuestionNumber } = this.state;
    let { currentTestNumber } = this.state;

    //pr1testImage1_1
    let serverResponse =
      window.routerPrefix +
      "/files/downloadTestQuestionImageFile/" +
      (this.props.selectedSubjectFilePrefix +
        this.props.selectedChapterNumber +
        "testImage" +
        currentTestNumber +
        "_" +
        selectedQuestionNumber);
    return serverResponse;
  };
  handleAnswerSelection = (selectedAnswer) => {
    let { selectedQuestionNumber } = this.state;
    this.setState({
      selectedAnswer: selectedAnswer,
      currentStatus: "Submit Answer of Q." + selectedQuestionNumber + ".",
    });
  };
  handleTryTestClick = (testNumber, flagFirstAttempt) => {
    this.setState({
      testStatus: "started",
      flagLoadingTestQuestions: true,
      currentTestNumber: testNumber,
      flagFirstAttempt: flagFirstAttempt,
    });
    // get test details (Questions, options) from server
    let requestUrl =
      "/general/testQuestions/" +
      this.props.selectedSubjectFilePrefix +
      this.props.selectedChapterNumber +
      "test" +
      testNumber;
    // console.log("send this to server --> " + requestUrl);
    axios
      .get(window.routerPrefix + requestUrl)
      .then((res) => {
        let testQuestionList = res.data;
        //add questionState parameter
        //add selectedAnswer  parameter
        let updatedTestQuestionList = [...testQuestionList];
        for (let i = 0; i < testQuestionList.length; i++) {
          updatedTestQuestionList[i].questionState = "unClicked";
          updatedTestQuestionList[i].selectedAnswer = "";
        } //for
        this.setState({
          testQuestionList: updatedTestQuestionList,
        });
        this.setState({
          testQuestionList: testQuestionList,
          flagLoadingTestQuestions: false,
          currentStatus:
            "Select any of the " + testQuestionList.length + " questions",
        });
      })
      .catch((err) => {
        this.setState({
          error: err,
          message: "Something went wrong",
          flagLoadingSubjects: false,
        }); // error is set
        // this.hideMessage();
      });
  };
  getDateAppeared = (test) => {
    let dateAppeared = test.appearedDate;
    let date = new Date(dateAppeared);
    dateAppeared =
      date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear();
    return dateAppeared;
  };
  handleSeeResultClick = () => {
    this.setState({
      flagResultShown: true,
    });
  };
  handleReviewTestClick = () => {
    this.setState({
      testStatus: "reviewTest",
      currentStatus:
        "Click a question to see the correct answer with explanation",
    });
  };
  getResultMessage = () => {
    let { marksObtained } = this.state;
    let { testQuestionList } = this.state;
    let percentage = (marksObtained / testQuestionList.length) * 100;
    let extraMessage;
    if (percentage == 100) {
      extraMessage = "Great...100%!";
    } else if (percentage >= 80) {
      extraMessage = "Good...";
    } else if (percentage >= 60) {
      extraMessage = "Not Bad...";
    } else if (percentage >= 40) {
      extraMessage = "Oh No...";
    } else if (percentage >= 20) {
      extraMessage = "Poor show...";
    } else {
      extraMessage = "Very Bad...";
    }
    let message =
      " You scored " +
      marksObtained +
      " out of " +
      testQuestionList.length +
      ".";
    return extraMessage + message;
  };
  getReviewDescription = () => {
    let { selectedQuestionNumber } = this.state;
    let { testQuestionList } = this.state;
    let description;
    if (
      testQuestionList[selectedQuestionNumber - 1].questionState ==
      "answeredCorrect"
    ) {
      description =
        "You answered corretly (" +
        testQuestionList[selectedQuestionNumber - 1].selectedAnswer +
        ").";
    } else {
      description =
        "Correct Answer is " +
        testQuestionList[selectedQuestionNumber - 1].answer +
        ". You answered " +
        testQuestionList[selectedQuestionNumber - 1].selectedAnswer +
        ".";
    }

    return description;
  };
  getTotalScore = () => {
    let { testList } = this.props;
    let marksObtainedTotal = 0,
      outOfMarksTotal = 0;

    for (let i = 0; i < testList.length; i++) {
      marksObtainedTotal += testList[i].marksObtained;
      outOfMarksTotal += testList[i].outOfMarks;
    }
    let percentage = (marksObtainedTotal / outOfMarksTotal) * 100;
    return (
      // "(" + marksObtainedTotal + "/" + outOfMarksTotal + "=" + Math.round(percentage) + "%)"
      "(" + Math.round(percentage) + "%)"
    );
  };
  render() {
    let { testList } = this.props;
    let { testCount } = this.props;
    let { message } = this.state;
    let { testQuestionList } = this.state;
    let { currentTestNumber } = this.state;
    let { selectedQuestionNumber } = this.state;
    let { selectedQuestion } = this.state;
    let { selectedAnswer } = this.state;
    let { counterSubmittedAnswers } = this.state;
    let { testStatus } = this.state;
    let { selectedMenu } = this.props;
    let { currentStatus } = this.state;
    let { flagResultShown } = this.state;
    let { flagTestSubmit } = this.state;

    let { marksObtained } = this.state;

    return (
      <React.Fragment>
        <div className=" text-left ">
          {/* showing attempted test/s */}
          {testStatus == "notStarted" && testList.length != 0 && (
            <React.Fragment>
              <div className="row no-gutters bg-lightcolor mb-2 p-2">
                <div className="col-2">Test No. </div>
                <div className="col-4">Status</div>
                <div className="col-6">Total Score {this.getTotalScore()}</div>
              </div>
              <div>
                {testList.map((test, index) => (
                  <TestPage
                    key={index + 1}
                    test={test}
                    testNumber={test.testNumber}
                    flagFirstAttempt={false}
                    onTryTestClick={this.handleTryTestClick}
                  />
                ))}
              </div>
            </React.Fragment>
          )}
          {/* showing unattempted test */}
          {testStatus == "notStarted" && testList.length < testCount && (
            <TestPage
              test={testList[testCount]}
              testNumber={testList.length + 1}
              flagFirstAttempt={true}
              onTryTestClick={this.handleTryTestClick}
            />
          )}
          {(testStatus == "started" ||
            testStatus == "resultAwaited" ||
            testStatus == "reviewTest") &&
            testQuestionList.length != 0 && (
              <React.Fragment>
                <div className="row no-gutters ">
                  <div className="col-12 text-center bg-lightcolor p-2 m-2">
                    Test No. {currentTestNumber} ({testQuestionList.length}{" "}
                    Questions)
                  </div>
                  {!flagTestSubmit && testStatus != "resultAwaited" && (
                    <div className="col-12 text-center mb-2 ">
                      <button
                        className="btn-darkcolor"
                        onClick={this.handleCancelTestClick}
                      >
                        {counterSubmittedAnswers == testQuestionList.length
                          ? "Try another Test"
                          : "Cancel Test"}
                      </button>
                    </div>
                  )}
                  <div className="col-12 text-center div-testStatus ">
                    {currentStatus}
                  </div>
                </div>
                <div className="row no-gutters mb-2 p-2"></div>
              </React.Fragment>
            )}
          {flagTestSubmit && (
            <div className="text-center mt-5">
              <BeatLoader size={16} color={"blue"} flagTestSubmit />
            </div>
          )}
          {(testStatus == "started" || testStatus == "reviewTest") &&
            !flagTestSubmit && (
              <div className="row no-gutters justify-content-center">
                {testQuestionList.map((testQuestion, index) => (
                  <div className="col-2 mb-2  " key={index}>
                    <NavBtnQuestion
                      index={index}
                      key={index}
                      testQuestion={testQuestion}
                      onQuestionClick={this.handleQuestionClick}
                    />
                  </div>
                ))}
              </div>
            )}
          {testStatus == "resultAwaited" &&
            !flagResultShown &&
            !flagTestSubmit && (
              <div className="text-center">
                And{" "}
                <Link to="#" onClick={this.handleSeeResultClick}>
                  here
                </Link>{" "}
                is the result
              </div>
            )}
          {testStatus == "resultAwaited" &&
            !flagResultShown &&
            flagTestSubmit && (
              <div className="text-center">
                Test is submitted. Wait for the Result...
              </div>
            )}
          {testStatus == "resultAwaited" && flagResultShown && (
            <div className="">
              <div className="text-center">{this.getResultMessage()}</div>
              <div className="text-center">
                {/* let { marksObtained } = this.state; */}
                {/* let { testQuestionList } = this.state; */}
                {[...Array(testQuestionList.length)].map((star, i) => {
                  return (
                    <label key={i + 1}>
                      <input
                        className="form-control-inline star-radio-buttons"
                        type="radio"
                        name="rating"
                        value={i + 1}
                      />
                      <FaStar
                        className="star"
                        size={12}
                        color={i + 1 <= marksObtained ? "green" : "red"}
                      />
                    </label>
                  );
                })}
              </div>
              <div className="text-center">
                Let us have a &nbsp;
                <Link to="#" onClick={this.handleReviewTestClick}>
                  Look
                </Link>{" "}
                at your given Test.
              </div>
            </div>
          )}
        </div>
        <div className="container-question-options  text-left">
          {selectedQuestionNumber != -1 && !flagTestSubmit && (
            <div className="row no-gutters mb-2   ">
              <div
                className=" div-question bg-lightcolor py-2"
                style={{ whiteSpace: "pre-wrap" }}
              >
                {"Q. " + selectedQuestionNumber + "\n"}
                {selectedQuestion.question}
              </div>
              {(testStatus == "started" || testStatus == "reviewTest") &&
                testQuestionList[selectedQuestionNumber - 1].imageInfo && (
                  <div className="col-12 text-center ">
                    <img
                      src={this.getImageLink()}
                      alt="No logo"
                      className="img-Question "
                      // className="img-Question img-fluid "
                      onClick={this.handleLogoClick}
                    />
                  </div>
                )}
              {/* If not answered then only show submit button */}
              {testStatus == "started" &&
                testQuestionList[selectedQuestionNumber - 1].questionState !=
                  "answered" && (
                  <div className="col-12 text-right ">
                    <button
                      className="btn-darkcolor"
                      onClick={this.handleSubmitAnswerClick}
                      disabled={!selectedAnswer}
                    >
                      {counterSubmittedAnswers < testQuestionList.length - 1
                        ? "Submit Answer"
                        : "Last Question, Submit Answer"}
                    </button>
                  </div>
                )}
              {testStatus == "started" &&
                testQuestionList[selectedQuestionNumber - 1].questionState ==
                  "answered" && (
                  <div className="col-12 text-right text-darkcolor ">
                    Answer is submitted
                  </div>
                )}
              <div className="col-2 col-md-1">
                A{" "}
                <input
                  type="radio"
                  name="selectedAnswer"
                  value="A"
                  id="A"
                  onClick={() => this.handleAnswerSelection("A")}
                  onChange={() => this.handleAnswerSelection("A")}
                  checked={selectedAnswer === "A"}
                  disabled={
                    testStatus == "reviewTest" ||
                    testQuestionList[selectedQuestionNumber - 1]
                      .questionState == "answered"
                  }
                />
                {/* {testStatus == "reviewTest" &&(testQuestionList[selectedQuestionNumber - 1].answer =='A' || testQuestionList[selectedQuestionNumber - 1].selectedAnswer =='A' )&&<span>
                  <input
                        className="form-control-inline star-radio-buttons"
                        type="radio"
                      />
                      <FaStar
                        className="star"
                        size={12}
                        color={testQuestionList[selectedQuestionNumber - 1].answer == testQuestionList[selectedQuestionNumber - 1 ].selectedAnswer? "green" : "red"}
                      />
                </span>} */}
              </div>
              <div
                className={
                  "col-10 col-md-11 text-left div-question " +
                  (testQuestionList[selectedQuestionNumber - 1].questionState ==
                    "answered" || testStatus == "reviewTest"
                    ? "text-greycolor"
                    : "text-darkcolor")
                }
              >
                <label htmlFor="A">{selectedQuestion.option1}</label>
              </div>

              <div className="col-2 col-md-1">
                B{" "}
                <input
                  type="radio"
                  name="selectedAnswer"
                  value="B"
                  id="B"
                  onClick={() => this.handleAnswerSelection("B")}
                  onChange={() => this.handleAnswerSelection("B")}
                  checked={selectedAnswer === "B"}
                  disabled={
                    testStatus == "reviewTest" ||
                    testQuestionList[selectedQuestionNumber - 1]
                      .questionState == "answered"
                  }
                />
                {/* {testStatus == "reviewTest" &&(testQuestionList[selectedQuestionNumber - 1].answer =='B' || testQuestionList[selectedQuestionNumber - 1].selectedAnswer =='B' )&&<span>
                  <input
                        className="form-control-inline star-radio-buttons"
                        type="radio"
                      />
                      <FaStar
                        className="star"
                        size={12}
                        color={testQuestionList[selectedQuestionNumber - 1].answer == testQuestionList[selectedQuestionNumber - 1 ].selectedAnswer? "green" : "red"}
                      />
                </span>} */}
              </div>
              <div
                className={
                  "col-10 col-md-11 text-left div-question " +
                  (testQuestionList[selectedQuestionNumber - 1].questionState ==
                    "answered" || testStatus == "reviewTest"
                    ? "text-greycolor"
                    : "text-darkcolor")
                }
              >
                <label htmlFor="B">{selectedQuestion.option2}</label>
              </div>
              <div className="col-2 col-md-1">
                C{" "}
                <input
                  type="radio"
                  name="selectedAnswer"
                  value="C"
                  id="C"
                  onClick={() => this.handleAnswerSelection("C")}
                  onChange={() => this.handleAnswerSelection("C")}
                  checked={selectedAnswer === "C"}
                  disabled={
                    testStatus == "reviewTest" ||
                    testQuestionList[selectedQuestionNumber - 1]
                      .questionState == "answered"
                  }
                />
                {/* {testStatus == "reviewTest" &&(testQuestionList[selectedQuestionNumber - 1].answer =='C' || testQuestionList[selectedQuestionNumber - 1].selectedAnswer =='C' )&&<span>
                  <input
                        className="form-control-inline star-radio-buttons"
                        type="radio"
                      />
                      <FaStar
                        className="star"
                        size={12}
                        color={testQuestionList[selectedQuestionNumber - 1].answer == testQuestionList[selectedQuestionNumber - 1 ].selectedAnswer? "green" : "red"}
                      />
                </span>} */}
              </div>
              <div
                className={
                  "col-10 col-md-11 text-left div-question " +
                  (testQuestionList[selectedQuestionNumber - 1].questionState ==
                    "answered" || testStatus == "reviewTest"
                    ? "text-greycolor"
                    : "text-darkcolor")
                }
              >
                <label htmlFor="C">{selectedQuestion.option3}</label>
              </div>
              <div className="col-2 col-md-1">
                D{" "}
                <input
                  type="radio"
                  name="selectedAnswer"
                  value="D"
                  id="D"
                  onClick={() => this.handleAnswerSelection("D")}
                  onChange={() => this.handleAnswerSelection("D")}
                  checked={selectedAnswer === "D"}
                  disabled={
                    testStatus == "reviewTest" ||
                    testQuestionList[selectedQuestionNumber - 1]
                      .questionState == "answered"
                  }
                />
                {/* {testStatus == "reviewTest" &&(testQuestionList[selectedQuestionNumber - 1].answer =='D' || testQuestionList[selectedQuestionNumber - 1].selectedAnswer =='D' )&&<span>
                  <input
                        className="form-control-inline star-radio-buttons"
                        type="radio"
                      />
                      <FaStar
                        className="star"
                        size={12}
                        color={testQuestionList[selectedQuestionNumber - 1].answer == testQuestionList[selectedQuestionNumber - 1 ].selectedAnswer? "green" : "red"}
                      />
                </span>} */}
              </div>
              <div
                className={
                  "col-10 col-md-11 text-left div-question " +
                  (testQuestionList[selectedQuestionNumber - 1].questionState ==
                    "answered" || testStatus == "reviewTest"
                    ? "text-greycolor"
                    : "text-darkcolor")
                }
              >
                <label htmlFor="D">{selectedQuestion.option4}</label>
              </div>
              {testStatus == "reviewTest" && (
                <div className="col-12 text-center">
                  {" "}
                  {this.getReviewDescription()}
                </div>
              )}
              {testStatus == "reviewTest" && (
                <React.Fragment>
                  <div className="col-12">Explanation:</div>
                  <div
                    className="col-12 p-explanation"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {selectedQuestion.explanation}
                  </div>
                </React.Fragment>
              )}
            </div>
          )}
        </div>
        {/* end of container */}
        {message && selectedMenu && (
          <div className="text-center mt-2" style={{ whiteSpace: "pre" }}>
            {message}
          </div>
        )}
        {/* row-ends */}
      </React.Fragment>
    );
  }
}
export default Tests;
