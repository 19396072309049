import React, { Component } from "react";
import "../App.css";
import axios from "axios";
// import ReactPlayer from "react-player/youtube";
import { Link } from "react-router-dom";

class Program extends Component {
  messageOfOffline = "This lecture is available in offline class-room";
  constructor(props) {
    super(props);
    this.state = {
      flagFileDownload: false,
      fileName: "",
      code: "",
      message: "",
      videoStatus: "",
      programStatus: "",
      flagLoadingVideoLink: false,
      isImage: false,
      isDemoVideo: false,
      isResources: false,
      showWhat: "code",
      cntFiles: 0,
      programExtensions: "",
    };
  }
  hideMessage = () => {
    window.setTimeout(() => {
      this.setState({
        message: "",
      });
    }, 7000);
  };
  getContentToDisplay = () => {
    let { program } = this.props;
    let s = "";
    s = program.programNumber + ". " + program.description + "  ";
    return s;
  };

  handleShowContentClick = () => {
    let { selectedMenu } = this.props;

    if (selectedMenu.name == "video" || selectedMenu.name == "note") {
      this.handlePlayVideoClick();
      return;
    } else {
      this.handleShowProgramCodeClick();
      return;
    }
  };
  // get link for the unlisted videos, it is --- made from server side

  handleShowProgramCodeClick = () => {
    // Check here only, whether  student has taken admission
    //  for this course or not, or is it a Demo
    let { selectedSubjectName } = this.props;
    // let { selectedSubjectAvailableForDemo } = this.props;
    let { selectedSubjectAvailableFor } = this.props;
    let { selectedSubjectFees } = this.props;
    // selectedSubjectAvailableForDemo = Boolean(selectedSubjectAvailableForDemo);
    let message1 =
      "Sorry… This content is available only for registered students. Fees of this course is only Rs. " +
      selectedSubjectFees +
      ". Want to join this course (online/offline)? Contact us at rjacpune@gmail.com or WhatsApp at 9422035020";
    let message2 =
      `Currently, content of following courses can be viewed.
      1. Programming (C, C++, Python)
      2. OOP using C++ and Python
      3. Data Structures
      Want to join the course ` +
      selectedSubjectName +
      `
      ? Contact us at rjacpune@gmail.com or WhatsApp at 9422035020`;
    let message3 =
      "Join this course - " + selectedSubjectName + " (online/offline)";
    let { visitorInfo } = this.props;
    let { selectedChapterNumber } = this.props;
    let { program } = this.props;
    let availableForDemo = Boolean(program.availableForDemo);
    let message;
    let requestUrl;
    // subject available for Demo
    if (selectedSubjectAvailableFor == "All") {
      if (availableForDemo) {
        message = "";
        requestUrl = "/files/downloadDemoFile/";
      } else if (visitorInfo.status == "Guest") {
        // Guest
        message = message1;
      }
      // not guest, so a registered student
      else if (visitorInfo.admissionStatus == "Admitted") {
        // check chapter-limit
        if (selectedChapterNumber > visitorInfo.chapterLimit) {
          message = "Please request Admin to access data of this chapter";
          // message =
          //   "You have not completed chapter no. " +
          //   visitorInfo.chapterLimit +
          //   " yet.";
        } else {
          message = "";
          requestUrl = "/files/downloadFromFile/";
        }
      } else if (
        (visitorInfo.status =
          "Registered" && visitorInfo.admissionStatus == "Guest")
      ) {
        message = message3;
      }
    } //end of "All" (Programming, OOP, DS)
    else {
      // subject NOT available for Demo
      if (visitorInfo.status == "Guest") {
        // Guest
        message = message2;
      } else {
        requestUrl = "/files/downloadFromFile/";
      }
    }
    if (message) {
      this.setState({
        message: message,
      });
      this.hideMessage();
      return;
    }
    this.props.onShowCodeClick(program);
    let { filePrefixWOExtension } = this.props;
    // Date 06.11.2023
    // Get file extension

    let fileName = filePrefixWOExtension + program.programNumber;
    // Program name is not saved
    // It is "filePrefix"+program number
    // E.g. c1p1
    // Content-Type': 'application/pdf'
    //Now check is it demo file
    this.setState({
      flagLoadingActualContent: true,
      flagLoading: true,
    });
    axios
      .get(window.routerPrefix + "/files/programResourcesInfo/" + fileName)
      .then((res) => {
        let isImage, isDemoVideo, isResources;
        let data = res.data;
        let s = data[0];
        isImage = Boolean(s);
        s = data[1];
        isResources = Boolean(s);
        s = data[2];
        isDemoVideo = Boolean(s);
        //Date 06.11.2023, One program, many files (different extensions)
        // Example of content of data[3] --> "c#", "C#cpp#", "c#py#"
        s = data[3];
        let target = "#";
        let pos1;
        let pos;
        let cntFiles = 0;
        // create array programExtensions containing extensions
        let programExtensions = [];
        let from = 0;
        while (true) {
          pos = s.indexOf(target, from);
          if (pos == -1) break;
          programExtensions.push({
            extension: s.substring(from, pos),
            flagActive: false,
          });
          cntFiles++;
          from = pos + 1;
        }
        let demoVideoLink;
        if (isDemoVideo) {
          demoVideoLink = data[2];
        } else {
          demoVideoLink = "---";
        }
        this.setState({
          cntFiles: cntFiles,
          programExtensions: programExtensions,
          isImage: isImage,
          isDemoVideo: isDemoVideo,
          isResources: isResources,
          demoVideoLink: demoVideoLink,
          flagLoadingActualContent: false,
        });

        let extension;
        if (cntFiles == 1) {
          // only one file available
          //this.state.programExtensions contains e.g.   "cpp#"
          extension = programExtensions[0].extension;
        } else {
          // Show file with preferred extension
          let { selectedSubject } = this.props;
          extension = selectedSubject.programExtension;
          programExtensions = programExtensions.map((pe, index) => {
            if (pe.extension == extension) {
              pe.flagActive = true;
            } else {
              pe.flagActive = false;
            }
            return pe;
          });
        }
        // get the code now
        if (programExtensions.length == 0) {
          // no file of code. e.g. web technology
          this.setState({
            flagFileDownload: true, // downloaded successfully
          });
          return;
        }
        let fileName =
          filePrefixWOExtension + program.programNumber + "." + extension;
        axios
          .get(window.routerPrefix + requestUrl + fileName)
          .then((response) => {
            this.setState({
              flagFileDownload: true, // downloaded successfully
            });
            // If no code file is there (e.g. in Web technology), then a file with
            // "nothing" written in it, is stored
            if (response.data != "nothing") {
              this.setState({
                code: response.data,
              });
              programExtensions = programExtensions.map((pe, index) => {
                if (pe.extension == extension) {
                  pe.flagActive = true;
                  pe.code = response.data;
                } else {
                  pe.flagActive = false;
                }
                return pe;
              });
              this.setState({
                programExtensions: programExtensions,
                message: "",
              });
            } else {
              this.setState({
                code: "",
              });
            }
            this.setState({
              message: "",
            });
            // let fileName1 = fileName;
            fileName = response.headers["content-disposition"].split('"')[1];
            this.setState({
              fileName: fileName,
            });
            // Now check whether some image / video is there related with this program
          })
          .catch((err) => {
            this.setState({
              error: err,
              message: "Something went wrong",
              flagLoadingActualContent: false,
            }); // error is set
            this.hideMessage();
          });
      })
      .catch((err) => {
        this.setState({
          error: err,
          flagLoadingActualContent: false,
          message: "Something went wrong",
          flagLoading: false,
        }); // error is set
        this.hideMessage();
      });
  };
  handleCopyToClipboard = () => {
    navigator.clipboard.writeText(this.state.code);
  };
  handleShowImageClick = () => {
    this.setState({
      showWhat: "image",
    });
  };
  handleShowDemoVideoClick = () => {
    this.setState({
      showWhat: "demoVideo",
    });
  };
  handleDownloadFilesClick = () => {
    this.setState({
      showWhat: "resources",
    });
  };
  handleShowCodeClick = (index) => {
    let { programExtensions } = this.state;
    console.log("clicked re baba" + index);
    let extension = programExtensions[index].extension;
    if (!programExtensions[index].code) {
      this.getProgramCode(index);
    } else {
      programExtensions = programExtensions.map((pe, index) => {
        if (pe.extension == extension) {
          pe.flagActive = true;
        } else {
          pe.flagActive = false;
        }
        return pe;
      });
      let { filePrefixWOExtension } = this.props;
      let { program } = this.props;

      let fileName =
        filePrefixWOExtension + program.programNumber + "." + extension;
      this.setState({
        fileName: fileName,
        code: programExtensions[index].code,
        programExtensions: programExtensions,
      });
    }
    this.setState({
      showWhat: "code",
    });
  };

  getProgramCode = (index) => {
    let requestUrl = "/files/downloadFromFile/";
    let { programExtensions } = this.state;
    let extension = programExtensions[index].extension;
    let { filePrefixWOExtension } = this.props;
    let { program } = this.props;
    let fileName =
      filePrefixWOExtension + program.programNumber + "." + extension;
    this.setState({
      flagFileDownload: false,
    });
    axios.get(window.routerPrefix + requestUrl + fileName).then((response) => {
      this.setState({
        flagFileDownload: true, // downloaded successfully
      });

      this.setState({
        code: response.data,
      });
      programExtensions = programExtensions.map((pe, index) => {
        if (pe.extension == extension) {
          pe.flagActive = true;
          pe.code = response.data;
        } else {
          pe.flagActive = false;
        }
        return pe;
      });
      this.setState({
        programExtensions: programExtensions,
        message: "",
      });
      fileName = response.headers["content-disposition"].split('"')[1];
      this.setState({
        fileName: fileName,
      });
    });
  };
  handleDownloadVideoCode = () => {
    let { program } = this.props;
    let requestUrl =
      "/general/videoCode/" +
      this.props.selectedSubjectFilePrefix +
      this.props.selectedChapterNumber +
      "v" +
      program.programNumber;
    this.setState({
      programList: [], // programlist should be empty
    });
    this.downloadPdfFile(requestUrl);
  };
  downloadPdfFile = (requestUrl) => {
    this.setState({
      flagLoadingChapterContent: true,
    });
    axios
      .get(window.routerPrefix + requestUrl, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/pdf",
        },
      })
      .then((res) => {
        let type = "application/pdf";
        const url = window.URL.createObjectURL(
          new Blob([res.data], {
            type: type,
          })
        );
        let fileName = res.headers["content-disposition"].split('"')[1];
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.setState({
          flagLoadingChapterContent: false,
        });
        this.setState({
          message: "pdf file downloaded successfully!",
        });
        this.hideMessage();
        // this.unselectMenu();
      })
      .catch((err) => {
        this.setState({
          error: err,
          message: "Something went wrong",
          flagLoadingChapterContent: false,
        }); // error is set
        this.hideMessage();
      });
  };

  handleCodeDownloadClick = () => {
    let type = "text/plain";
    const url = window.URL.createObjectURL(
      new Blob([this.state.code], {
        type: type,
      })
    );
    let fileName = this.state.fileName;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove(); // you need to remove that elelment which is created before.
  };
  showAvailability = () => {
    let availability = "";
    let { program } = this.props;
    let { visitorInfo } = this.props;
    let availableForDemo = Boolean(program.availableForDemo);
    let { selectedMenu } = this.props;
    if (selectedMenu.name == "video" || selectedMenu.name == "note") {
      if (window.hosting == "offline") {
        if (program.location == "YouTubePublic") {
          availability = "Available on YouTube";
        } else if (program.location == "YouTubeUnlisted") {
        } else if (program.location == "YouTubeUnlisted") {
        } else {
          availability = "";
        }
      } else if (window.hosting == "internet") {
        // video on internet
        if (program.location == "YouTubePublic") {
          if (visitorInfo.admissionStatus == "Guest") {
            availability = "Preview";
          } else if (
            // For Admitted (offline)
            visitorInfo.admissionStatus == "Admitted" &&
            visitorInfo.admissionType == "Offline"
          ) {
            availability = "Available";
          } else if (
            // For Admitted (offline)
            visitorInfo.admissionStatus == "Admitted" &&
            visitorInfo.admissionType == "Online"
          ) {
            availability = "";
          }
        } // end of YouTubePublic
        else if (program.location == "YouTubeUnlisted") {
          if (visitorInfo.admissionStatus == "Guest") {
            availability = "";
          } else if (
            // For Admitted (offline)
            visitorInfo.admissionStatus == "Admitted" &&
            visitorInfo.admissionType == "Offline"
          ) {
            if (selectedMenu.name == "note") {
              //notes
              availability = "Available";
            } else if (selectedMenu.name == "video") {
              // video
              availability = "";
            }
          } else if (
            // For Admitted (online)
            visitorInfo.admissionStatus == "Admitted" &&
            visitorInfo.admissionType == "Online"
          ) {
            availability = "";
          }
        } // end of YouTubeUnlisted
        else if (
          program.location != "YouTubePublic" &&
          program.location != "YouTubeUnlisted"
        ) {
          if (visitorInfo.admissionStatus == "Guest") {
            availability = "";
          } else if (
            // For Admitted (offline)
            visitorInfo.admissionStatus == "Admitted" &&
            visitorInfo.admissionType == "Offline"
          ) {
            availability = "";
          } else if (
            // For Admitted (online)
            visitorInfo.admissionStatus == "Admitted" &&
            visitorInfo.admissionType == "Online"
          ) {
            availability = "";
          }
        } // end of local (not YouTubePublic and not YouTubeUnlisted)
      } // end of video/note
    } else {
      // program, exercise, misc.
      if (window.hosting == "offline") {
        if (availableForDemo && visitorInfo.admissionStatus == "Guest") {
          availability = "Preview";
        } else {
          availability = "";
        }
      } else if (window.hosting == "internet") {
        if (availableForDemo && visitorInfo.admissionStatus == "Guest") {
          availability = "Preview";
        } else {
          availability = "";
        }
      }
    }
    return availability;
  };
  handlePlayVideoClick = () => {
    // Check here only, whether  student has taken admission
    //  for this course or not, or is it a Demo, or Guest
    let { visitorInfo } = this.props;
    let { selectedChapterNumber } = this.props;
    let { selectedSubjectFees } = this.props;

    let { program } = this.props;
    let { selectedMenu } = this.props;
    let message1 =
      "Sorry… This content is available only for registered students. Fees of this course is only Rs. " +
      selectedSubjectFees +
      ". Want to join this course (online/offline)? Contact us at rjacpune@gmail.com or WhatsApp at 9422035020";
    // let availableForDemo = Boolean(program.availableForDemo);
    let message;
    if (window.hosting == "internet") {
      if (program.location == "YouTubePublic") {
        // Available to all : Guest, Registerd, Registerd + Admitted, Guest_to_subject
        message = "";
      } //end of YouTubePublic
      else if (program.location == "YouTubeUnlisted") {
        if (visitorInfo.status == "Guest") {
          // For guest - Join this course message
          message = message1;
        } else if (
          // Registered, but not for this course (So, Guest for this course)
          visitorInfo.admissionStatus == "Guest"
        ) {
          message = "Join this course (Online/Offline) to access the videos";
        } else if (
          // For Admitted (offline)
          visitorInfo.admissionStatus == "Admitted" &&
          visitorInfo.admissionType == "Offline"
        ) {
          if (selectedMenu.name == "note") {
            //notes
            //check chapter-limit
            if (selectedChapterNumber > visitorInfo.chapterLimit) {
              message = "Please request Admin to access data of this chapter";
              // message =
              //   "You have not completed chapter no. " +
              //   visitorInfo.chapterLimit +
              //   " yet.";
            } else {
              message = "";
            }
          } else {
            // video
            message = this.messageOfOffline; //
            //= "This lecture is available in offline class-room";
            //"This lecture is available in offline class-room.";
          }
        } else if (
          // For Admitted (Online)
          visitorInfo.admissionStatus == "Admitted" &&
          visitorInfo.admissionType == "Online"
        ) {
          //check chapter-limit
          if (selectedChapterNumber > visitorInfo.chapterLimit) {
            message = "Please request Admin to access data of this chapter";
            // message =
            //   "You have not completed chapter no. " +
            //   visitorInfo.chapterLimit +
            //   " yet.";
          } else {
            message = "";
          }
        }
      } //end of YouTubeUnlisted
      else if (
        program.location != "YouTubePublic" &&
        program.location != "YouTubeUnlisted"
      ) {
        // Local videos
        if (visitorInfo.status == "Guest") {
          // For guest - Join this course message
          message = "Join the course (Online/Offline) to access the videos";
        } else if (
          // For Admitted (offline)
          visitorInfo.admissionStatus == "Admitted" &&
          visitorInfo.admissionType == "Offline"
        ) {
          message = this.messageOfOffline; //
          // "This lecture is available in offline class-room.";
        } else if (
          // For Admitted (Online)
          visitorInfo.admissionStatus == "Admitted" &&
          visitorInfo.admissionType == "Online"
        ) {
          //check chapter-limit
          if (selectedChapterNumber > visitorInfo.chapterLimit) {
            message = "Please request Admin to access data of this chapter";
            // message =
            //   "You have not completed chapter number " +
            //   visitorInfo.chapterLimit +
            //   " yet.";
          } else {
            // message = "";
            // message = "This lecture is available in offline class-room.";
            message = this.messageOfOffline;
          }
        }
      } //end of Neither "YouTubePublic" nor "YouTubeUnlisted"
    } //end of window.hosting==internet
    else if (window.hosting == "offline") {
      if (program.location == "YouTubePublic") {
        // Available to all : Guest, Registerd, Registerd + Admitted, Guest_to_subject
        if (window.internetInClass == "no") {
          message = "Internet is required";
        } else {
          message = "";
        }
      } //end of YouTubePublic
      else if (program.location == "YouTubeUnlisted") {
        if (visitorInfo.status == "Guest") {
          // For guest - Join this course message
          message = message1;
        } else if (
          // Registered, but not for this course (So, Guest for this course)
          visitorInfo.admissionStatus == "Guest"
        ) {
          message = "Join this course (Online/Offline) to access the videos";
        } else if (
          // For Admitted (offline)
          visitorInfo.admissionStatus == "Admitted" &&
          (visitorInfo.admissionType == "Offline" ||
            visitorInfo.admissionType == "Online")
        ) {
          //check chapter-limit
          if (selectedChapterNumber > visitorInfo.chapterLimit) {
            message = "Please request Admin to access data of this chapter";
            // message =
            //   "You have not completed chapter no. " +
            //   visitorInfo.chapterLimit +
            //   " yet.";
          } else {
            message = "";
          }
        }
      } //end of YouTubeUnlisted
      else if (
        program.location != "YouTubePublic" &&
        program.location != "YouTubeUnlisted"
      ) {
        // Local videos
        if (visitorInfo.status == "Guest") {
          // For guest - Join this course message
          message = "Join the course (Online/Offline) to access the videos";
        } else if (
          // For Admitted (offline)
          visitorInfo.admissionStatus == "Admitted" &&
          (visitorInfo.admissionType == "Offline" ||
            visitorInfo.admissionType == "Online")
        ) {
          //check chapter-limit
          if (selectedChapterNumber > visitorInfo.chapterLimit) {
            message = "Please request Admin to access data of this chapter";
            // message =
            //   "You have not completed chapter number " +
            //   visitorInfo.chapterLimit +
            //   " yet.";
          } else {
            message = "";
          }
        }
      } //end of Neither "YouTubePublic" nor "YouTubeUnlisted"
    } //end of window.hosting=offline
    if (message) {
      this.setState({
        message: message,
      });
      this.hideMessage();
      if (message != this.messageOfOffline) {
        return;
      }
    }
    // Now check whether VideoCode is there related with this Video
    let { filePrefixWOExtension } = this.props;
    let fileName = filePrefixWOExtension + program.programNumber;
    axios
      .get(window.routerPrefix + "/files/videoCodeInfo/" + fileName)
      .then((res) => {
        let isVideoCode;
        let data = res.data;
        let s = data;
        isVideoCode = Boolean(s);
        this.setState({
          isVideoCode: isVideoCode,
          flagLoadingActualContent: false,
        });
      })
      .catch((err) => {
        this.setState({
          error: err,
          message: "Something went wrong",
          flagLoadingActualContent: false,
        }); // error is set
        this.hideMessage();
      });

    // for Unlisted Videos, "---" is returned as link
    if (program.link != "---") {
      this.props.onPlayVideoClick(program, "");
    } else {
      let { program } = this.props;
      this.setState({
        flagLoadingVideoLink: true,
      });
      axios
        .get(window.routerPrefix + "/video/videoById/" + program.programId)
        .then((res) => {
          let data = res.data;
          if (!data) {
            this.setState({
              message: "Timeout... Refresh and Login again",
            }); // error is set
            this.hideMessage();
          }
          this.setState({
            flagLoadingVideoLink: false,
          });
          this.props.onPlayVideoClick(program, data.link);
        })
        .catch((err) => {
          this.setState({
            error: err,
            message: "Something went wrong",
            flagLoadingVideoLink: false,
          }); // error is set
          this.hideMessage();
        });
    }
    // this.props.onShowCodeClick(program);
  };
  getDownloadLink = () => {
    let { filePrefixWOExtension } = this.props;
    let { program } = this.props;
    let serverResponse =
      window.routerPrefix +
      "/files/downloadVideoFromFile/" +
      (filePrefixWOExtension + "" + program.programNumber);
    return serverResponse;
  };
  getDownloadDemoVideoLink = () => {
    let { filePrefixWOExtension } = this.props;
    let { program } = this.props;
    let serverResponse =
      window.routerPrefix +
      "/files/downloadDemoVideoFromFile/" +
      (filePrefixWOExtension + "" + program.programNumber);
    return serverResponse;
  };
  getImageLink = () => {
    let { filePrefixWOExtension } = this.props;
    let { program } = this.props;
    let serverResponse =
      window.routerPrefix +
      "/files/downloadImageFile/" +
      (filePrefixWOExtension + "" + program.programNumber);
    return serverResponse;
  };
  getVideoLength = () => {
    let { program } = this.props;
    let min = 0,
      sec = 0;
    if (program.length) {
      min = Math.floor(program.length / 60);
      sec = program.length % 60;
      if (min < 10 && min > 0) {
        // its single digit
        min = "0" + min;
      }
      if (sec < 10 && sec > 0) {
        // its single digit
        sec = "0" + sec;
      }
    }
    return min + ":" + sec;
  };
  sh_Code = () => {
    let { program } = this.props;
    let { selectedMenu } = this.props;
    let { flagFileDownload } = this.state;
    if (
      program.flagActive &&
      flagFileDownload && // file downloaded successfully
      selectedMenu.name != "video" &&
      selectedMenu != "note"
    )
      return true;
    return false;
  };
  sh_LocalVideo = () => {
    let { program } = this.props;
    let { selectedMenu } = this.props;
    let { message } = this.state;
    let { flagLoadingVideoLink } = this.state;
    if (
      (selectedMenu.name == "video" || selectedMenu.name == "note") &&
      program.flagActive &&
      ((program.location != "YouTubePublic" && window.hosting == "offline") ||
        (program.location == "YouTubePublic" && window.hosting == "offline")) &&
      !message &&
      !flagLoadingVideoLink
    ) {
      return true;
    }
    return false;
  };
  sh_YouTubeUnlistedVideo = () => {
    let { program } = this.props;
    let { selectedMenu } = this.props;
    let { flagLoadingVideoLink } = this.state;
    let { message } = this.state;
    if (
      (selectedMenu.name == "video" || selectedMenu.name == "note") &&
      program.flagActive &&
      program.location == "YouTubeUnlisted" &&
      window.hosting == "internet" &&
      !message &&
      !flagLoadingVideoLink
    ) {
      return true;
    }
    return false;
  };

  sh_YouTubePublicVideo = () => {
    let { program } = this.props;
    let { selectedMenu } = this.props;
    let { message } = this.state;
    let { flagLoadingVideoLink } = this.state;
    if (
      (selectedMenu.name == "video" || selectedMenu.name == "note") &&
      program.flagActive &&
      program.location == "YouTubePublic" &&
      window.hosting == "internet" &&
      // ||  (window.hosting == "offline" &&  window.internetInClass == "yes"))
      !message &&
      !flagLoadingVideoLink
    ) {
      // if (
      //   (selectedMenu.name == "video" || selectedMenu.name == "note") &&
      //   program.flagActive &&
      //   window.hosting == "internet" &&
      //   !message &&
      //   !flagLoadingVideoLink
      // )

      return true;
    }
    return false;
  };
  // handleDownloadResourcesFileClick
  handleDownloadResourcesFileClick = () => {
    // pdf download
    let { filePrefixWOExtension } = this.props;
    let { program } = this.props;
    let fileName = filePrefixWOExtension + program.programNumber;
    let requestUrl = "/files/resources/" + fileName;
    this.setState({
      flagLoadingResourcesFile: true,
    });
    axios
      .get(window.routerPrefix + requestUrl, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/pdf",
        },
      })
      .then((res1) => {
        let type = "application/pdf";
        const url = window.URL.createObjectURL(
          new Blob([res1.data], {
            type: type,
          })
        );
        let fileName = res1.headers["content-disposition"].split('"')[1];
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.setState({
          flagLoadingResourcesFile: false,
        });
        this.setState({
          message: "File downloaded successfully!",
        });
        this.hideMessage();
        this.unselectMenu();
      })
      .catch((err) => {
        this.setState({
          error: err,
          message: "Something went wrong",
          flagLoadingResourcesFile: false,
        }); // error is set
        this.hideMessage();
      });
  };
  render() {
    let { program } = this.props;
    let { flagLoadingActualContent } = this.state;
    let { message } = this.state;
    let { selectedMenu } = this.props;

    let { filePrefixWOExtension } = this.props;
    let { code } = this.state;
    let { programExtensions } = this.state;

    let { isImage } = this.state;
    let { isDemoVideo } = this.state;
    let { isResources } = this.state;
    let { isVideoCode } = this.state;
    let { showWhat } = this.state;
    let { cntFiles } = this.state;
    let content = this.getContentToDisplay();
    return (
      <>
        {/* row starts - Actual content */}
        {content && (
          <div className="row mb-2">
            <button
              to="/"
              className={
                "div-actual-content " +
                (program.flagActive ? " border-mypurple " : " ")
              }
              onClick={this.handleShowContentClick}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {content}
              &nbsp;
              {selectedMenu.showLength && (
                <span className="bg-whitecolor ">{this.getVideoLength()}</span>
              )}
              &nbsp;
              <span className="text-whitecolor  bg-darkcolor span-preview">
                {this.showAvailability()}
              </span>
            </button>
          </div>
        )}
        {/* row ends - Actual content */}
        {/* Code to be displayed after click to program */}
        {/* row starts - program-details */}
        {this.sh_Code() && (
          <div className="program-details ">
            {(isImage || isDemoVideo || isResources || cntFiles>1) && (
              <div className="row div-code-image-video">
                {programExtensions.map((pe, index) => (
                  <div className="col-4 " key={index}>
                    <button
                      onClick={() => {
                        this.handleShowCodeClick(index);
                      }}
                      className={
                        "no-border " +
                        (pe.flagActive == true
                          ? "text-lightcolor bg-darkcolor"
                          : "text-darkcolor bg-lightcolor")
                      }
                      // className={
                      //   "no-border " +
                      //   (showWhat == "code"
                      //     ? "text-lightcolor bg-darkcolor"
                      //     : "text-darkcolor bg-lightcolor")
                      // }
                      // disabled={showWhat == "code"}
                    >
                      &nbsp; {pe.extension} code &nbsp;
                    </button>
                  </div>
                ))}
                {isImage && (
                  <div className="col-4">
                    <button
                      to="#"
                      onClick={this.handleShowImageClick}
                      className={
                        "no-border " +
                        (showWhat == "image"
                          ? "text-lightcolor bg-darkcolor"
                          : "text-darkcolor bg-lightcolor")
                      }
                      disabled={showWhat == "image"}
                    >
                      &nbsp;Image&nbsp;
                    </button>
                  </div>
                )}
                {isDemoVideo && (
                  <div className="col-4 ">
                    <button
                      to="#"
                      onClick={this.handleShowDemoVideoClick}
                      className={
                        "no-border " +
                        (showWhat == "demoVideo"
                          ? "text-lightcolor bg-darkcolor"
                          : "text-darkcolor bg-lightcolor")
                      }
                      disabled={showWhat == "demoVideo"}
                    >
                      &nbsp;Demo Video&nbsp;
                    </button>
                  </div>
                )}
                {isResources && (
                  <div className="col-4 ">
                    <button
                      to="#"
                      onClick={this.handleDownloadFilesClick}
                      className={
                        "no-border " +
                        (showWhat == "resources"
                          ? "text-lightcolor bg-darkcolor"
                          : "text-darkcolor bg-lightcolor")
                      }
                      disabled={showWhat == "resources"}
                    >
                      &nbsp;Resources&nbsp;
                    </button>
                  </div>
                )}
              </div>
            )}
            <div className="row">
              {showWhat == "code" && <div className="col-2"></div>}
              {showWhat == "code" && code && (
                <div className="col-4">
                  <button
                    to="#"
                    onClick={this.handleCopyToClipboard}
                    className="bg-darkcolor text-lightcolor no-border"
                  >
                    &nbsp;Copy&nbsp;
                  </button>
                </div>
              )}
              {showWhat == "code" && code && (
                <div className="col-2 ">
                  <button
                    to="#"
                    onClick={this.handleCodeDownloadClick}
                    className="bg-darkcolor text-lightcolor no-border"
                  >
                    &nbsp;Download&nbsp;
                  </button>
                </div>
              )}
            </div>
            {!flagLoadingActualContent && showWhat == "code" && (
              <div className="program-code" style={{ whiteSpace: "pre-wrap" }}>
                {code}
              </div>
            )}
            {!flagLoadingActualContent && showWhat == "image" && (
              <div className="program-code">
                <img
                  src={this.getImageLink()}
                  alt="No logo"
                  className="img-fluid "
                  onClick={this.handleLogoClick}
                />
              </div>
            )}
            {/* url={this.getDownloadDemoVideoLink()} */}
            {!flagLoadingActualContent && showWhat == "demoVideo" && (
              <iframe
                src={
                  "https://www.youtube.com/embed/" + this.state.demoVideoLink
                }
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            )}
            {!flagLoadingActualContent && showWhat == "resources" && (
              <div className="text-center div-rar">
                <Link
                  to="#"
                  className="col-12 text-center"
                  onClick={this.handleDownloadResourcesFileClick}
                >
                  Download
                </Link>{" "}
                rar file containing required resources.
              </div>
            )}
          </div>
        )}
        {/* row ends  - program-details */}
        {/* Below is for videos */}
        {message && <div className="error-text text-left">{message}</div>}
        {/* {this.sh_YoutubeVideo() && (
          <iframe
            width="560"
            height="315"
            // src={"//www.youtube.com/embed/" + program.link}
            src="//www.youtube.com/embed/jFGKJBPFdUA"
            frameborder="0"
            allowfullscreen
          ></iframe>
        )} */}
        {(this.sh_YouTubePublicVideo() ||
          this.sh_YouTubeUnlistedVideo() ||
          this.sh_LocalVideo() ||
          message == this.messageOfOffline) &&
          isVideoCode && (
            <div className="text-primarycolor">
              <Link to="#" onClick={this.handleDownloadVideoCode}>
                Download{" "}
              </Link>
              code discussed in this video
            </div>
          )}
        {this.sh_YouTubePublicVideo() && (
          <iframe
            src={"https://www.youtube.com/embed/" + program.link}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        )}
        {this.sh_YouTubeUnlistedVideo() && (
          <iframe
            src={"https://www.youtube.com/embed/" + program.link}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        )}
        {/* {this.sh_LocalVideo() && (
          <iframe
            src={this.getDownloadLink()}
            frameBorder="0"
            // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        )} */}
        {this.sh_LocalVideo() && (
          <div className="VideoContainer">
            <video width="320" height="240" controls>
              <source src={this.getDownloadLink()} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        )}
        {/* {this.sh_YoutubeVideo() && (
          <div className="container-video">
            <ReactPlayer
              width="320"
              height="240"
              controls
              // playing="true"
              url={"https://www.youtube.com/watch?v=" + program.link}
            />
          </div>
        )} */}
        {program.instructions && (
          <div className="text-instructions" style={{ whiteSpace: "pre" }}>
            {program.instructions}
          </div>
        )}
      </>
    );
  }
}
export default Program;
