import React, { Component } from "react";
import "../App.css";
import { Link } from "react-router-dom";

class NavBtnQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleQuestionClick = () => {
    let { testQuestion } = this.props;
    this.props.onQuestionClick(testQuestion);
  };
  render() {
    let { testQuestion } = this.props;
    let { index } = this.props;
    let btn_bgcolor;
    if (testQuestion.questionState == "unClicked") {
      btn_bgcolor = " btn-lightcolor";
    } else if (testQuestion.questionState == "clicked") {
      btn_bgcolor = " btn-darkcolor";
    } 
    else if (testQuestion.questionState == "answered") {
      btn_bgcolor = " btn-greycolor";
    }
    else if (testQuestion.questionState == "answeredCorrect") {
      btn_bgcolor = " btn-greencolor";
    }
    else if (testQuestion.questionState == "answeredWrong") {
      btn_bgcolor = " btn-redcolor";
    }
    

    return (
      <div className=" text-center">
        <button
          className={"button-question " + btn_bgcolor}
          to="#"
          onClick={() => this.handleQuestionClick()}
        >
          Q. {index + 1}
        </button>
      </div>
    );
  }
}
export default NavBtnQuestion;
