import React, { Component } from "react";
import AdminStudentList from "./admin-student-list";
import AdminStudentAdd from "./admin-student-add";
import { StudentDB } from "../StudentDB";
import { BeatLoader } from "react-spinners";
import axios from "axios";

class AdminStudent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      response: {},
      student: null,
      filteredStudents: [],
      studentList: [],
      studentdb: {},
      flagLoading: false,
      searchText: "",
      sortedField: "",
      sortedDirection: false,
      sectionNumber: 1,
      entityType: "", // An entity may be repeated
      // so while adding data, data should not get cleared after server
      // informs that data is repeated
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({
      flagLoading: true,
    });
    axios
      .all([axios.get(window.routerPrefix + "/student/students/")])
      .then(
        axios.spread((res1) => {
          let studentList = res1.data;
          let updatedStudents = [...studentList];
          studentList = updatedStudents;
          this.setState({
            studentList: studentList,
            studentdb: new StudentDB(studentList),
            filteredStudents: studentList,
            flagLoading: false,
          });
        })
      )
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  }
  handleSearchTextBoxKeyUp = (event) => {
    let searchText = event.target.value;
    this.setState({ searchText: searchText });
    this.performSearchOperation(searchText);
  };
  handleSearchTextChange = (event) => {
    let searchText = event.target.value;
    this.setState({ searchText: searchText });
    this.performSearchOperation(searchText);
  };
  performSearchOperation = (searchText) => {
    let query = searchText.trim();
    let searchedStudents = [];
    searchedStudents = this.state.studentdb.filterByName(query);
    this.setState({
      filteredStudents: searchedStudents,
    });
  };
  onFormSubmit(data) {
    let { action } = this.props;
    this.setState({
      flagLoading: true,
    });

    if (action === "UPDATE") {
      //EDIT / PUT
      axios
        .put(window.routerPrefix + "/student/update", data)
        .then((res) => {
          this.updateStudentList(data);
          this.setState({
            response: res.data,
            flagLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            flagLoading: false,
          });
          //   this.props.askToLogin();
        });
    } else if (action === "ADD") {
      axios
        .post(window.routerPrefix + "/student/addStudent", data, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
          },
        })
        .then((res) => {
          this.setState({
            flagLoading: false,
            response: res.data,
          });
          if (!res.data) {
            let message = "Something went wrong.. Try again";
            this.setState({
              entityType: "inValid",
              student: data,
            });
            this.props.onCrudAction(message, "ADD");
          } else if (res.data == -1) {
            let message = data.emailId + " is already present";
            this.setState({
              entityType: "inValid",
              student: data,
            });
            this.props.onCrudAction(message, "ADD");
          } else {
            this.setState({
              entityType: "",
            });
            data.studentId = res.data; // id of the added student is returned as response
            this.addToStudentList(data);
          }
        })
        .catch((err) => {
          console.log("error!");
          this.setState({
            flagLoading: false,
          });
        });
    } //else
  }
  handleEditStudent = (student) => {
    this.setState({
      student: student,
      // searchText: "",
    });
    this.props.onEditClick();
  };
  // handleShowChapters = (student) => {
  //   this.props.onShowChapters(student);
  // };
  addToStudentList = (student) => {
    let studentList = [...this.state.studentList];
    studentList.unshift(student);
    let filteredStudents = [...this.state.filteredStudents];
    filteredStudents.unshift(student);
    this.setState({
      filteredStudents: filteredStudents,
      studentList: studentList,
    });
    let message = "The student '" + student.fullName + "' added successfully.";

    this.props.onCrudAction(message, "ADD");
  };
  updateStudentList = (student) => {
    let studentList = [...this.state.studentList];
    for (let i = 0; i < studentList.length; i++) {
      if (studentList[i].studentId == student.studentId) {
        //studentList[i] = student;
        studentList.splice(i, 1); // remove 1 element from position i
        // and insert at the beginning as we want latest modified at the top
        studentList.unshift(student);
      }
    } //for
    // remove from filteredchapters also
    let filteredStudents = [...this.state.filteredStudents];
    for (let i = 0; i < filteredStudents.length; i++) {
      if (filteredStudents[i].studentId == student.studentId) {
        // filteredStudents[i] = student;
        filteredStudents.splice(i, 1); // remove 1 element from position i
        // and insert at the beginning as we want latest modified at the top
        filteredStudents.unshift(student);
      }
    } //for
    //sorting

    this.setState({
      //   chapterdb: new ChapterDB(chapterList),
      filteredStudents: filteredStudents,
      studentList: studentList,
      // searchText: "",
    });
    let message =
      "The student '" + student.fullName + "' updated successfully.";
    this.props.onCrudAction(message, "LIST", "Add a Student");
  };

  handleDeleteStudent = (student) => {
    let studentList = [...this.state.studentList];

    for (let i = 0; i < studentList.length; i++) {
      if (studentList[i].studentId == student.studentId) {
        studentList.splice(i, 1); // remove 1 element from position i
      }
    } //for
    // remove from filteredstudents also
    let filteredStudents = [...this.state.filteredStudents];
    for (let i = 0; i < filteredStudents.length; i++) {
      if (filteredStudents[i].studentId == student.studentId) {
        filteredStudents.splice(i, 1); // remove 1 element from position i
      }
    } //for

    this.setState({
      studentdb: new StudentDB(studentList),
      filteredStudents: filteredStudents,
      studentList: studentList,
    });
    let message = "The student '" + student.name + "' deleted successfully.";
    this.props.onCrudAction(message, "LIST");
  };

  handleHeaderClick = (event) => {
    let field = event.target.id;

    let direction = false;
    if (field === this.state.sortedField) {
      // same button clicked twice
      direction = !this.state.direction;
    } else {
      // different field
      direction = false;
    }
    this.setState({ direction: direction });
    let { filteredStudents } = this.state;
    if (direction == false) {
      //in ascending order
      filteredStudents = filteredStudents.sort((a, b) => {
        if (a[field] > b[field]) {
          return 1;
        }
        if (a[field] < b[field]) {
          return -1;
        }
        return 0;
      });
    } else {
      //in descending order
      filteredStudents = filteredStudents.sort((a, b) => {
        if (a[field] < b[field]) {
          return 1;
        }
        if (a[field] > b[field]) {
          return -1;
        }
        return 0;
      });
    }
    this.setState({ filteredStudents: filteredStudents, sortedField: field });
  };
  handleSectionNumberClick = (event) => {
    this.setState({ sectionNumber: event.target.id });
  };
  render() {
    let { flagLoading } = this.state;
    let { action } = this.props;
    let { student } = this.state;
    let { entityType } = this.state;
    let { accessLevel } = this.props;
    let { adminType } = this.props;

    let content;
    if (flagLoading) {
      content = (
        <div className="text-center">
          <BeatLoader size={16} color={"blue"} flagLoading />
        </div>
      );
    } else if (action === "LIST") {
      // First show list
      content = (
        <AdminStudentList
          onEditStudent={this.handleEditStudent}
          onDeleteStudent={this.handleDeleteStudent}
          filteredStudents={this.state.filteredStudents}
          searchText={this.state.searchText}
          onSearchTextChange={this.handleSearchTextChange}
          onSearchTextBoxKeyUp={this.handleSearchTextBoxKeyUp}
          onHeaderClick={this.handleHeaderClick}
          onSectionNumberClick={this.handleSectionNumberClick}
          sectionNumber={this.state.sectionNumber}
          accessLevel={accessLevel}
          adminType={adminType}
        />
      );
    } else if (action === "ADD" || action === "UPDATE") {
      // After pressing Add Button, show only form and not the list
      content = (
        <div>
          {/* content={" "} */}
          <AdminStudentAdd
            onFormSubmit={this.onFormSubmit}
            student={student}
            action={action}
            entityType={entityType}
          />
        </div>
      );
    } else if (action === "DELETE") {
      content = <div>Delete action in Progress...</div>;
    }
    return (
      <div className="container-fluid container-md container-content-page p-0">
        {content}
      </div>
    );
  }
}
export default AdminStudent;
