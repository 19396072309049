import React, { Component } from "react";
import { BeatLoader } from "react-spinners";
import axios from "axios";
class AdminCertificateData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,      
    };
  }
  componentDidMount() {}
  getCertificateData = () => {
    this.setState({
      flagLoading: true,
    });
    axios
      .all([axios.get(window.routerPrefix + "/general/createCertificateData/")])
      .then(
        axios.spread((res) => {
          this.setState({
            flagLoading: false,
          });
        })
      )
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  };

  render() {
    let { flagLoading } = this.state;
    let { action } = this.props;
    let { chapter } = this.state;
    let { subjectList } = this.state;
    let { selectedSubject } = this.state;
    let { selectedSubjectId } = this.state;
    let content;
    if (flagLoading) {
      content = (
        <div className="text-center">
          <BeatLoader size={16} color={"blue"} flagLoading />
        </div>
      );
    } else {
      content = (
        <div className="text-center">
          <button className="btn-mycyan" onClick={this.getCertificateData}>
            Get Certificate Data
          </button>
        </div>
      );
    }
    return (
      <div className="container-fluid container-md container-content-page p-0">
        {content}
      </div>
    );
  }
}
export default AdminCertificateData;
