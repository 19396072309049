import React, { Component } from "react";
import axios from "axios";
import "./css/login.css";
import AdminLoginForm from "./admin-login-form";
import { BeatLoader } from "react-spinners";
class AdminLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flagLoading: false,
      flagLoginSuccess: false,
    };
  }
  componentDidMount = () => {
    window.scroll(0, 0);
  };
  handleFormSubmit = (data) => {
    this.setState({
      flagLoading: true,
    });
    axios
      .post(window.routerPrefix + "/admin/check", data, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          // "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        },
      })
      .then((res) => {
        this.setState({
          response: res.data,
          flagLoading: false,
          flagLoginSuccess: res.data.validAdmin,
          flagFirstTimeLogin: res.data.flagFirstTimeLogin,
        });
        this.props.onLoginFormSubmit(res.data);
      })
      .catch((err) => {
        console.log("error...");
        this.setState({
          flagLoading: false,
        });
      });
  };
  render() {
    let { flagLoading } = this.state;
    let content;
    if (flagLoading) {
      content = (
        <div className="text-center ">
          <BeatLoader size={16} color={"blue"} flagLoading />
        </div>
      );
    } else {
      content = <AdminLoginForm onFormSubmit={this.handleFormSubmit} />;
    }
    return (
      <React.Fragment>
        <div className="container-fluid container-md p-0">{content}</div>
      </React.Fragment>
    );
  }
}
export default AdminLogin;
