import React, { useEffect, useState } from "react";

function AdminWorkshopAdd(props) {
  let emptyWorkshop;
  let [workshop, setWorkshop] = useState({
    workshopId: "",
    name: "",
    title: "",
    fees: "",
    status: "",
  });
  let [errorWorkshop, setErrorWorkshop] = useState({
    name: { message: "", mxLen: 80, mnLen: 1, onlyDigits: false },
    title: { message: "", mxLen: 100, mnLen: 5,onlyDigits: false },
    fees: { message: "", mnLen: 2, onlyDigits: true },
  });
  let [flagFormInvalid, setFlagFormInvalid] = useState(false);
  useEffect(() => {
    window.scroll(0, 0);
    init();
  }, []);
  function init() {
    emptyWorkshop = workshop;
    let { action } = props;
    if (action === "ADD") {
      setFlagFormInvalid(true);
      setWorkshop(emptyWorkshop);
    } else if (action === "UPDATE") {
      // in edit mode, keep the update button enabled at the beginning
      setFlagFormInvalid(false);
      setWorkshop(props.workshop);
    }
  }
  function handleChange(event) {
    let name = event.target.name;
    let userInput;
    let inputType = event.target.type;
    if (inputType == "checkbox") {
      // for checkbox, no checking of validations.
      userInput = event.target.checked;
      let value;
      if (userInput) {
        value = 1;
      } else {
        value = 0;
      }
      setWorkshop({ ...workshop, [name]: value });
      return;
    }
    setWorkshop({ ...workshop, [name]: event.target.value });
    isValid(event);
  }
  function handleBlur(event) {
    isValid(event);
  }
  function isValid(event) {
    let i,
      flag,
      name,
      value,
      requiredMessage = " is required",
      type,
      message = "",
      mnLen,
      mxLen,
      onlyDigits,
      allDigits,
      noSymbols;
    name = event.target.name;
    type = event.target.type;
    if (type === "text" || type === "textarea" || type === "password") {
      // check if 'required', also check min no. of characters
      value = event.target.value.trim();
      mnLen = errorWorkshop[`${name}`].mnLen;
      mxLen = errorWorkshop[`${name}`].mxLen;
      onlyDigits = errorWorkshop[`${name}`].onlyDigits;
      allDigits = errorWorkshop[`${name}`].allDigits;
      noSymbols = errorWorkshop[`${name}`].noSymbols;
      if (value.length === 0) {
        message = name + requiredMessage;
      } else if (value.length < mnLen) {
        if (!onlyDigits) message = "Min " + mnLen + " characters required";
        else message = "Min " + mnLen + " digits required";
      } //else
      else if (value.length > mxLen) {
        if (!onlyDigits) message = "Maximum " + mxLen + " characters allowed";
        else message = "Maximum " + mxLen + " digits allowed";
      } //else
      else if (onlyDigits) {
        for (let i = value.length - 1; i >= 0; i--) {
          const d = value.charCodeAt(i);
          if (d < 48 || d > 57) {
            message = "Enter only digits";
            break;
          } //if
        } //for
      } //else if... onlyDigits
      else if (noSymbols && !allDigits) {
        let d,
          count = 0;
        for (i = value.length - 1, flag = false; i >= 0; i--) {
          d = value.charCodeAt(i);
          if (d >= 48 && d <= 57) {
            count++; // digits are being counted
          } //if
          if (
            (d >= 97 && d <= 122) ||
            (d >= 65 && d <= 90) ||
            (d >= 48 && d <= 57) ||
            value.charAt(i) == "_"
          ) {
            continue;
          } //if
          else {
            // symbol is there, may be space
            flag = true;
            break;
          }
        } //for
        if (flag == true) {
          let q = String.fromCharCode(d);
          if (d == 32) {
            message = "space is not allowed";
          } else
            message =
              "Symbols other than underscore (_) are not allowed, you have used " +
              q;
        } else {
          if (count == value.length) {
            message = "Only digits are not allowed, use some alphabets also";
          }
        }
      } //else if... noSymbols
      else {
        message = "";
      }
    } //if... text || textarea
    else if (event.target.type === "select-one") {
      value = event.target.selectedIndex;
      if (value == 0) {
        message = name + requiredMessage;
      } else {
        message = "";
      }
    } //else if
    else if (type === "email") {
      value = event.target.value.trim();
      if (/\S+@\S+\.\S+/.test(value) == 0) {
        message = "valid email-id " + requiredMessage;
      } else {
        message = "";
      }
    } //else if
    else {
      message = "";
    }
    let errWorkshop = { ...errorWorkshop };
    errorWorkshop[`${name}`].message = message;
    setErrorWorkshop(errWorkshop);
    checkAllErrors();
  }
  function handleFocus(event) {
    checkAllErrors();
  }
  function checkAllErrors() {
    for (let field in errorWorkshop) {
      if (errorWorkshop[field].message !== "") {
        setFlagFormInvalid(true);
        return;
      } //if
    } //for
    setFlagFormInvalid(false);
  }
  function handleStatusSelection(value) {
    setWorkshop({ ...workshop, status: value });
  }

  function handleSubmit(event) {
    event.preventDefault();
    window.scrollTo(0, 0); // moving scrollbar at the top
    props.onFormSubmit(workshop);
  }
  let { action } = props;
  let heading;
  if (action === "ADD") {
    heading = "Add a Workshop";
  } else if (action === "UPDATE") {
    heading = "Update the Workshop";
  }
  let availableForList = ["All", "Nobody", "Online+Offline"];
  // prepare options
  let optionsAvailableFor = availableForList.map((availableFor, index) => (
    <option value={availableFor} key={index}>
      {availableFor}
    </option>
  ));
  return (
    <>
      <div className="form-heading col-12">{heading}</div>
      <form
        // className="container container-add-form text-dark bg-warning p-4"
        className="text-dark bg-mycyan p-4"
        onSubmit={handleSubmit}
      >
        {/* row starts */}
        <div className="form-group row align-items-center">
          <div className="col-5 text-right">
            <label>Workshop Name</label>
          </div>
          <div className="col-7 px-0">
            <input
              type="text"
              className="form-control"
              name="name"
              value={workshop.name}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholder="Workshop Name"
              required
            />
          </div>
          <div className="offset-5">
            {errorWorkshop.name.message ? (
              <span className="error-text">{errorWorkshop.name.message}</span>
            ) : null}
          </div>
        </div>
        {/* row ends */}
        {/* row starts */}
        <div className="form-group row align-items-center">
          <div className="col-5 text-right">
            <label>Title Name</label>
          </div>
          <div className="col-7 px-0">
            <input
              type="text"
              className="form-control"
              name="title"
              value={workshop.title}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholder="Workshop Title"
              required
            />
          </div>
          <div className="offset-5">
            {errorWorkshop.title.message ? (
              <span className="error-text">{errorWorkshop.title.message}</span>
            ) : null}
          </div>
        </div>
        {/* row ends */}
        {/* row starts */}
        <div className="form-group row align-items-center">
          <div className="col-5 text-right">
            <label>Fees</label>
          </div>
          <div className="col-7 px-0">
            <input
              type="text"
              className="form-control"
              name="fees"
              value={workshop.fees}
              onChange={handleChange}
              placeholder="Enter Fees"
            />
          </div>
          <div className="offset-5">
            {errorWorkshop.fees.message ? (
              <span className="error-text">{errorWorkshop.fees.message}</span>
            ) : null}
          </div>
        </div>
        {/* row ends */}
        {/* row starts */}
        <div className="form-group row  align-items-center">
          <div className="col-5 text-right">
            <label>Status?</label>
          </div>
          <div className="col-7  px-0">
            <input
              type="radio"
              className="form-control-inline"
              name="status"
              value="0"
              onClick={() => handleStatusSelection("0")}
              onChange={() => handleStatusSelection("0")}
              checked={workshop.status == "0"}
            />
            &nbsp;completed &nbsp;&nbsp;&nbsp;
            <input
              type="radio"
              className="form-control-inline"
              name="status"
              value="1"
              onClick={() => handleStatusSelection("1")}
              onChange={() => handleStatusSelection("1")}
              checked={workshop.status == "1"}
            />
            &nbsp;Scheduled&nbsp;&nbsp;
          </div>
        </div>
        {/* row starts */}
        {/* row starts */}
        <div className="form-group row offset-5 text-center mb-0">
          <button
            className="btn-mycyan"
            type="submit"
            disabled={flagFormInvalid}
          >
            {action}
          </button>
        </div>
        <input type="hidden" name="workshopId" value={workshop.workshopId} />
      </form>
    </>
  );
}
export default AdminWorkshopAdd;
