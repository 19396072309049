import React, { Component } from "react";
import { BeatLoader } from "react-spinners";
import "./css/final-yaadi.css";
import { Link } from "react-router-dom";
import axios from "axios";

class Downloadtc extends Component {
  constructor(props) {
    super(props);

    this.state = {
      flagLoading: false,
      flagDownLoadedTcFile: false,
    };
  }
  componentDidMount = () => {
    this.setState({
      flagCheckSession: true,
    });
    axios
      .get(window.routerPrefix + "/general/dsabatchclicked")
      .then((res) => {
        this.setState({
          flagCheckSession: false,
        });
      })
      .catch((err) => {
        this.setState({
          error: err,
          message: "Something went wrong",
          flagCheckSession: false,
        }); // error is set
      });
  };
  hideMessage = () => {
    window.setTimeout(() => {
      this.setState({
        message: "",
      });
    }, 3000);
  };
  getImageLink = () => {
    let serverResponse =
      "/" + window.routerPrefix + "/files/downloadImageFile/advt_download";
    return serverResponse;
  };
  handleDownloadTcCompiler = () => {
    // pdf download
    let requestUrl = "/files/downloadTcCompiler";
    this.downloadTcFile(requestUrl);
  };
  downloadTcFile = (requestUrl) => {
    this.setState({
      flagLoading: true,
    });
    axios
      .get(window.routerPrefix + requestUrl, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/x-msdownload",
          Accept: "application/x-msdownload",
        },
      })
      .then((res1) => {
        let type = "application/x-msdownload";
        const url = window.URL.createObjectURL(
          new Blob([res1.data], {
            type: type,
          })
        );
        let fileName = res1.headers["content-disposition"].split('"')[1];
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.setState({
          flagDownLoadedTcFile: true,
        });
        this.setState({
          message: "TC Compiler file downloaded successfully!",
        });
        this.unselectMenu();
      })
      .catch((err) => {
        this.setState({
          error: err,
          message: "Something went wrong",
          flagLoadingTcFile: false,
        }); // error is set
      });
  };
  render() {
    let { flagCheckSession } = this.state;
    let { flagLinkOk } = this.state;
    let { flagDownLoadedTcFile } = this.state;

    let message;
    if (flagCheckSession) {
      return (
        <div className="text-center mt-5">
          <BeatLoader size={16} color={"blue"} flagCheckSession />
        </div>
      );
    }
    return (
      <>
        <div className="container container-certificate-page">
          <div className="row">
            <div className="col-12 text-center mb-3 ">
              <img
                src="/images/rjac_logo.jpg"
                alt="No logo"
                className="img-fluid "
              />
            </div>
          </div>
          <div className="row div-reference-page text-center">
            <div className="col-12">Welcome to Rajesh Jain Academy.</div>
          </div>
          <div className="row div-reference-page text-center">
            <div className="col-12">
              To know more about us, click &nbsp;&nbsp;
              <Link to={"/"}>here</Link>
            </div>
          </div>

          {flagLinkOk && (
            <div className="row div-reference-page text-center">
              <div className="col-12" style={{ whiteSpace: "pre-wrap" }}>
                {message}
              </div>
            </div>
          )}
          <div className="row justify-content-center my-3 ">
            <div className="col-8 col-lg-6 mb-3 ">
              <img
                src={this.getImageLink()}
                alt="Invalid Link"
                className="img-fluid "
              />
            </div>
            {!flagDownLoadedTcFile && (
              <div className="col-8 mt-3 text-center">
                <button
                  to="#"
                  onClick={this.handleDownloadTcCompiler}
                  className="bg-darkcolor text-lightcolor no-border p-1"
                >
                  Download TC Compiler
                </button>
              </div>
            )}
            {flagDownLoadedTcFile && (
              <div className="col-8 mt-3 text-center text-message">
                TC Compiler Downloaded Successfully!
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}
export default Downloadtc;
