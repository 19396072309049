import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import axios from "axios";
function AdminProgramListSingle(props) {
  let [flagLoading, setFlagLoading] = useState(false);
  let [error, setError] = useState("");
  function handleEditProgram(program) {
    props.onEditProgram(program);
  }
  function handleDeleteProgram(program) {
    // Now call for delete api
    let { programType } = props;
    setFlagLoading(true);
    axios
      .delete(
        window.routerPrefix +
          "/" +
          programType +
          "/deleteFromFile/" +
          program.programId
      )
      .then((res) => {
        setFlagLoading(false);
        props.onDeleteProgram(program);
      })
      .catch((err) => {
        setFlagLoading(false);
        setError(err);
      });
  }
  let { program } = props;
  let { accessLevel } = props;
  let { adminType } = props;
  return (
    <div className="row myborder-top justify-content-center mb-2">
      <div className="col-2 col-md-1">{program.programNumber}.</div>
      <div className="col-6 col-md-4 ">{program.description}</div>
      <div className="col-2 col-md-1">
        {adminType >= accessLevel && (
          <Link to="#" onClick={() => handleEditProgram(program)}>
            {/* Edit */}
            <i className="fas fa-edit admin-icons"></i>
          </Link>
        )}
      </div>
      <div className="col-2 col-md-1">
        {!flagLoading && adminType >= accessLevel && (
          <Link
            to="#"
            onClick={() => {
              if (
                window.confirm(
                  "You really want to delete " + program.programNumber + " ?"
                )
              )
                handleDeleteProgram(program);
            }}
          >
            {/* Delete */}
            <i className="far fa-trash-alt admin-icons"></i>
          </Link>
        )}
        {flagLoading && <BeatLoader size={8} color={"blue"} flagLoading />}
      </div>
    </div>
  );
}
export default AdminProgramListSingle;
