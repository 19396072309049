import React, { Component } from "react";
import { Link } from "react-router-dom";
import AdminCoursebundleListSingle from "./admin-coursebundle-list-single";

class AdminCoursebundleList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      selectedCourse: "",
      response: {},
    };
  }
  handleEditCoursebundle = (coursebundle) => {
    this.props.onEditCoursebundle(coursebundle);
  };
  handleShowPrograms = (coursebundle) => {
    this.props.onShowPrograms(coursebundle);
  };
  handleShowExerPrograms = (coursebundle) => {
    this.props.onShowExerPrograms(coursebundle);
  };
  handleShowMiscPrograms = (coursebundle) => {
    this.props.onShowMiscPrograms(coursebundle);
  };
  handleCrudAction = (message, action) => {
    this.props.onCrudAction(message, action);
  };
  handleDuplicateCoursebundle = (coursebundle, selection) => {
    this.props.onDuplicateCoursebundle(coursebundle, selection);
  };
  handleDeleteCoursebundle = (coursebundle) => {
    this.props.onDeleteCoursebundle(coursebundle);
  };
  handleSearchTextBoxKeyUp = (event) => {
    this.props.onSearchTextBoxKeyUp(event);
  };
  handleSearchTextChange = (event) => {
    this.props.onSearchTextChange(event);
  };
  handleHeaderClick = (event) => {
    this.props.onHeaderClick(event);
  };
  handleSectionNumberClick = (event) => {
    this.props.onSectionNumberClick(event);
  };
  handleSelectCourseChange = (event) => {
    let index = event.target.selectedIndex; // get selected index, instad of selected value
    var optionElement = event.target.childNodes[index];
    var selectedCourseId = optionElement.getAttribute("id");
    let selectedCourse = event.target.value;
    this.props.onSelectCourseChange(selectedCourse, selectedCourseId);
  };
  render() {
    let { error } = this.state;
    let { selectedCourse } = this.props;
    let { filteredCoursebundles } = this.props;
    let { courseList } = this.props;
    let { searchText } = this.props;
    let { currentSize } = this.props;
    let contentNocoursebundles = <div className="text-center">No coursebundles !</div>;
    // prepare options
    let optionsCourse = courseList.map((course, index) => (
      <option value={course.name} key={index} id={course.courseId}>
        {course.name}
      </option>
    ));

    let content = (
      <React.Fragment>
        {/* First select the course  */}
        <div className="row justify-content-center">
          <div className="col-6 ">
            <select
              className="form-control"
              name="selectedCourse"
              value={selectedCourse}
              onChange={this.handleSelectCourseChange}
              onBlur={this.handleBlur}
              onFocus={this.handleFocus}
            >
              <option> Select Course </option>
              {optionsCourse}
            </select>
          </div>
        </div>
        {selectedCourse && currentSize != 0 && (
          <div className="form-heading column col-12">
            LIST OF coursebundles ({filteredCoursebundles.length})
          </div>
        )}
        {selectedCourse && currentSize == 0 && (
          <div className="form-heading column col-12 thick-red-text">
            No Coursebundles
          </div>
        )}
        {/* row starts */}
        {selectedCourse && currentSize != 0 && (
          <div className="row justify-content-center column">
            <div className="col-6 my-2 column">
              <input
                type="search"
                className="container-fluid form-control"
                value={searchText}
                onKeyUp={this.handleSearchTextBoxKeyUp}
                onChange={this.handleSearchTextChange} // This is contolled by parent
                placeholder="Search coursebundles"
                id=""
              />
            </div>
          </div>
        )}
        {/* row ends */}
        {selectedCourse && filteredCoursebundles.length != 0 && (
          <div className="row p-0 ">
            <div className="col-12 justify-content-center">
              <div className="table-responsive">
                <table className="table mx-auto w-auto table-sm table-hover table-borderless bigger-text">
                  <thead>
                    <tr>
                      <th>
                        {" "}
                        <Link
                          to="#"
                          onClick={this.handleHeaderClick}
                          id="coursebundleNumber"
                        >
                          Number
                        </Link>
                      </th>
                      <th>
                        <Link to="#" onClick={this.handleHeaderClick} id="name">
                          Name
                        </Link>
                      </th>
                    </tr>
                  </thead>
                  {filteredCoursebundles.map((coursebundle, index) => (
                    <tbody key={index}>
                      <AdminCoursebundleListSingle
                        coursebundle={coursebundle}
                        index={index}
                        // filteredCoursebundles={filteredCoursebundles}
                        onDeleteCoursebundle={this.handleDeleteCoursebundle}
                        onDuplicateCoursebundle={this.handleDuplicateCoursebundle}
                        onEditCoursebundle={this.handleEditCoursebundle}
                        onShowPrograms={this.handleShowPrograms}
                        onShowExerPrograms={this.handleShowExerPrograms}
                        onShowMiscPrograms={this.handleShowMiscPrograms}
                        onCrudAction={this.handleCrudAction}
                        currentSize={currentSize}
                      />
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
    if (error) {
      return <div>Error retriving coursebundles </div>;
    } else if (filteredCoursebundles.length >= 0) {
      return (
        // <div className="container-fluid bg-blue">
        // <div className="row">
        <div className="">{content}</div>
        // </div>
        // </div>
      );
    } //else
    else if (filteredCoursebundles.length == 0) {
      return contentNocoursebundles;
    } //else
  }
}
export default AdminCoursebundleList;
