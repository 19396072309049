import React, { useEffect, useState } from "react";
import AdminChapterList from "./admin-chapter-list";
import AdminChapterAdd from "./admin-chapter-add";
import { ChapterDB } from "../ChapterDB";
import { BeatLoader } from "react-spinners";
import axios from "axios";
// This is used in AdminActivity
// When user wants to add/delete/edit a chapter
// A subject has got chapters
function AdminChapter(props) {
  let [flagLoading, setFlagLoading] = useState(false);
  let [error, setError] = useState("");
  let [searchText, setSearchText] = useState("");
  let [direction, setDirection] = useState("");
  let [sortedField, setSortedField] = useState("");
  let [sectionNumber, setSectionNumber] = useState(1);
  let [subjectList, setSubjectList] = useState([]);
  let [selectedSubject, setSelectedSubject] = useState("");
  let [selectedSubjectId, setSelectedSubjectId] = useState(-1);
  let [filteredChapters, setFilteredChapters] = useState([]);
  let [chapterList, setChapterList] = useState([]);
  let [chapterdb, setChapterdb] = useState({});
  let [chapter, setChapter] = useState(null);
  let [flagChapterLoading, setFlagChapterLoading] = useState(false);
  let [lastChapterNumber, setLastChapterNumber] = useState(0);
  // constructor(props) {
  //   super(props);
  //    = {
  //     error: null,
  //     response: {},
  //     chapter: null,
  //     filteredChapters: [],
  //     subjectList: [],
  //     chapterList: [],
  //     selectedSubject: "",
  //     flagLoading: false,
  //     searchText: "",
  //     sortedField: "",
  //     sortedDirection: false,
  //     sectionNumber: 1,
  //   };
  //   onFormSubmit = onFormSubmit.bind(this);
  // }
  function getChapterListFromDatabase(subject, subjectId) {
    setFlagChapterLoading(true);
    axios
      .all([axios.get(window.routerPrefix + "/chapter/chapters/" + subjectId)])
      .then(
        axios.spread((res1) => {
          let chapterList = res1.data;
          let updatedChapters = [...chapterList];
          chapterList.map((chapter, index) => {
            chapter.subject = subject;
          }); //map
          chapterList = updatedChapters;
          getLastChapterNumber(chapterList);
          setFlagChapterLoading(false);
          setChapterList(chapterList);
          setFilteredChapters(chapterList);
          setChapterdb(new ChapterDB(chapterList));
        })
      )
      .catch((err) => {
        console.log(err);
        setFlagChapterLoading(false);
        setError(err);
      });
  }
  useEffect(() => {
    init();
  }, []);
  function init() {
    //  A subject has got chapter
    // so chapter has got subjectId
    // So first get all subjects from database
    // We need to replace subject field to chapter record
    // List of subjects should be ready
    setFlagLoading(true);
    axios
      .all([axios.get(window.routerPrefix + "/subject/subjects/")])
      .then(
        axios.spread((res1) => {
          let subjectList = res1.data;
          setSubjectList(res1.data);
          setFlagLoading(false);
        })
      )
      .catch((err) => {
        setFlagLoading(false);
        setError(err);
      });
  }
  function handleSearchTextBoxKeyUp(event) {
    let searchText = event.target.value;
    setSearchText(searchText);
    performSearchOperation(searchText);
  }
  function handleSearchTextChange(event) {
    let searchText = event.target.value;
    setSearchText(searchText);
    performSearchOperation(searchText);
  }
  function performSearchOperation(searchText) {
    let query = searchText.trim();
    let searchedChapters = [];
    searchedChapters = chapterdb.filterByName(query);
    setFilteredChapters(searchedChapters);
  }
  function onFormSubmit(chapter) {
    let { action } = props;
    setFlagLoading(true);
    if (action === "UPDATE") {
      //EDIT / PUT
      axios
        .put(window.routerPrefix + "/chapter/update", chapter)
        .then((res) => {
          setFlagLoading(false);
          if (res.data) {
            updateChapterList(chapter);
          } else {
            // Error, something went wrong
          }
        })
        .catch((err) => {
          setError(err);
          setFlagLoading(false);
        });
    } else if (action === "ADD") {
      //ADD / POST
      axios
        .post(window.routerPrefix + "/chapter/addChapter", chapter, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
          },
        })
        .then((res) => {
          setFlagLoading(false);
          if (res.data) {
            chapter.chapterId = res.data.chapterId; // id of the added chapter is returned as response
            addToChapterList(chapter);
          } else {
            //error, something went wrong
          }

          // setState({
          //   subjectToRetain: chapter.subject,
          //   subjectIdToRetain: chapter.subjectId,
          // });
        })
        .catch((err) => {
          setError(err);
          setFlagLoading(false);
        });
    } //else
  }
  function handleEditChapter(chapter) {
    setChapter(chapter);
    setSearchText("");
    props.onEditClick();
  }
  function addToChapterList(chapter) {
    let cList = [...chapterList];
    cList.unshift(chapter); // insert at the beginning
    let fChapters = [...filteredChapters];
    fChapters.unshift(chapter);
    setFilteredChapters(fChapters);
    setChapterList(cList);
    let message = "The chapter '" + chapter.name + "' added successfully.";
    getLastChapterNumber(cList);
    props.onCrudAction(message, "ADD");
  }
  function updateChapterList(chapter) {
    let cList = [...chapterList];
    for (let i = 0; i < cList.length; i++) {
      if (cList[i].chapterId == chapter.chapterId) {
        if (cList[i].subjectId == chapter.subjectId) {
          // update, but it should be at the beginning
          // chapterList[i] = chapter;
          cList.splice(i, 1); // remove 1 element from position i
          // and insert at the beginning as we want latest modified at the top
          cList.unshift(chapter);
        } else {
          //delete from current list
          cList.splice(i, 1); // remove 1 element from position i
        }
        break;
      } //if
    } //for
    // remove from filteredchapters also
    let fChapters = [...filteredChapters];
    for (let i = 0; i < fChapters.length; i++) {
      if (fChapters[i].chapterId == chapter.chapterId) {
        if (fChapters[i].subjectId == chapter.subjectId) {
          // update
          fChapters.splice(i, 1); // remove 1 element from position i
          // and insert at the beginning as we want latest modified at the top
          fChapters.unshift(chapter);
        } else {
          //delete from current list
          fChapters.splice(i, 1); // remove 1 element from position i
        }
        break;
      } //if
    } //for
    setFilteredChapters(fChapters);
    setChapterList(cList);
    let message = "The chapter '" + chapter.name + "' updated successfully.";
    props.onCrudAction(message, "LIST", "Add a Chapter");
  }
  function handleDeleteChapter(chapter) {
    let cList = [...chapterList];
    for (let i = 0; i < cList.length; i++) {
      if (cList[i].chapterId == chapter.chapterId) {
        cList.splice(i, 1); // remove 1 element from position i
      }
    } //for
    // remove from filteredchapters also
    let fChapters = [...filteredChapters];
    for (let i = 0; i < fChapters.length; i++) {
      if (fChapters[i].chapterId == chapter.chapterId) {
        fChapters.splice(i, 1); // remove 1 element from position i
      }
    } //for
    setFilteredChapters(fChapters);
    setChapterList(cList);
    let message = "The chapter '" + chapter.name + "' deleted successfully.";
    props.onCrudAction(message, "LIST");
  }
  function getLastChapterNumber(chapterList) {
    let lastChapterNumber = 0;
    for (let i = 0; i < chapterList.length; i++) {
      if (chapterList[i].chapterNumber > lastChapterNumber) {
        lastChapterNumber = chapterList[i].chapterNumber;
      } //if
    } //for
    setLastChapterNumber(lastChapterNumber);
  }
  function handleHeaderClick(event) {
    let field = event.target.id;

    let d = false;
    if (field === sortedField) {
      // same button clicked twice
      d = !direction;
    } else {
      // different field
      d = false;
    }
    setDirection(d);
    if (d == false) {
      //in ascending order
      filteredChapters = filteredChapters.sort((a, b) => {
        if (a[field] > b[field]) {
          return 1;
        }
        if (a[field] < b[field]) {
          return -1;
        }
        return 0;
      });
    } else {
      //in descending order
      filteredChapters = filteredChapters.sort((a, b) => {
        if (a[field] < b[field]) {
          return 1;
        }
        if (a[field] > b[field]) {
          return -1;
        }
        return 0;
      });
    }
    setFilteredChapters(filteredChapters);
    setSortedField(field);
  }
  function handleSectionNumberClick(event) {
    setSectionNumber(event.target.id);
  }
  function handleSelectSubjectChange(event) {
    // This is called for LIST as well ADD operation.
    let index = event.target.selectedIndex; // get selected index, instad of selected value
    var optionElement = event.target.childNodes[index];
    var selectedSubjectId = optionElement.getAttribute("id");
    let selectedSubject = event.target.value;
    setSelectedSubject(selectedSubject);
    setSelectedSubjectId(selectedSubjectId);
    getChapterListFromDatabase(selectedSubject, selectedSubjectId);
    if (selectedSubject) {
      props.showAddButton();
    }
  }

  let optionsSubject = subjectList.map((subject, index) => (
    <option value={subject.name} key={index} id={subject.subjectId}>
      {subject.name}
    </option>
  ));
  let { action } = props;
  let { accessLevel } = props;
  let { adminType } = props;

  return (
    <div className="container-fluid container-md container-content-page p-0">
      {flagLoading && (
        <div className="text-center">
          <BeatLoader size={16} color={"blue"} flagLoading />
        </div>
      )}
      {action == "LIST" && (
        <div className="row justify-content-center ">
          {!flagLoading && (
            <div className="col-12 col-md-7 text-center  ">
              <select
                className="form-control"
                name="selectedSubject"
                value={selectedSubject}
                onChange={handleSelectSubjectChange}
              >
                <option> Select Subject </option>
                {optionsSubject}
              </select>
            </div>
          )}
          {flagChapterLoading && (
            <div className="col-12 col-md-7 text-center">
              <BeatLoader size={16} color={"blue"} flagChapterLoading />
            </div>
          )}
          {!flagChapterLoading &&
            selectedSubject &&
            chapterList.length != 0 && (
              <div className="form-heading column col-12">
                LIST OF chapters ({filteredChapters.length})
              </div>
            )}
          {!flagChapterLoading &&
            selectedSubject &&
            chapterList.length == 0 && (
              <div className="form-heading column col-12 thick-red-text">
                No Chapters
              </div>
            )}
          {selectedSubject &&
            chapterList.length != 0 &&
            !flagChapterLoading && (
              <div className="col-6 my-2 column">
                <input
                  type="search"
                  className="container-fluid form-control"
                  value={searchText}
                  onKeyUp={handleSearchTextBoxKeyUp}
                  onChange={handleSearchTextChange}
                  placeholder="Search chapters"
                  id=""
                />
              </div>
            )}
        </div>
      )}
      {/* row ends */}
      {!flagChapterLoading && action === "LIST" && (
        <AdminChapterList
          subjectList={subjectList}
          onSelectSubjectChange={handleSelectSubjectChange}
          selectedSubject={selectedSubject}
          onEditChapter={handleEditChapter}
          onDeleteChapter={handleDeleteChapter}
          filteredChapters={filteredChapters}
          searchText={searchText}
          onSearchTextChange={handleSearchTextChange}
          onSearchTextBoxKeyUp={handleSearchTextBoxKeyUp}
          onHeaderClick={handleHeaderClick}
          onSectionNumberClick={handleSectionNumberClick}
          sectionNumber={sectionNumber}
          currentSize={chapterList.length}
          accessLevel={accessLevel}
          adminType={adminType}
        />
      )}
      {!flagChapterLoading && (action === "ADD" || action === "UPDATE") && (
        <div>
          <AdminChapterAdd
            onFormSubmit={onFormSubmit}
            subjectList={subjectList}
            chapter={chapter}
            action={action}
            selectedSubject={selectedSubject}
            selectedSubjectId={selectedSubjectId}
            lastChapterNumber={lastChapterNumber}
            // subjectToRetain={subjectToRetain}
            // subjectIdToRetain={subjectIdToRetain}
          />
        </div>
      )}
    </div>
  );
}
export default AdminChapter;
