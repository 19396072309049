import React, { Component } from "react";
import AdminCoursebundleList from "./admin-coursebundle-list";
import AdminCoursebundleAdd from "./admin-coursebundle-add";
import { CoursebundleDB } from "../CoursebundleDB";
import { BeatLoader } from "react-spinners";
import axios from "axios";

class AdminCoursebundle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      response: {},
      coursebundle: null,
      filteredCoursebundles: [],
      courseList: [],
      subjectList: [],
      coursebundleList: [],
      selectedCourse: "",
      selectedCourseId: "",
      flagLoading: false,
      searchText: "",
      sortedField: "",
      sortedDirection: false,
      sectionNumber: 1,
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  getCoursebundleListFromDatabase = (course, courseId) => {
    this.setState({
      flagLoading: true,
    });
    // This will give all entries which match with selected course
    // So we will get list of subjects
    let { subjectList } = this.state;
    axios
      .all([
        axios.get(
          window.routerPrefix + "/coursebundle/coursebundles/" + courseId
        ),
      ])
      .then(
        axios.spread((res1) => {
          let coursebundleList = res1.data;
          let updatedCoursebundles = [...coursebundleList];
          coursebundleList.map((coursebundle, index) => {
            updatedCoursebundles[index].course = course;
            // now subject should be added as per subjectId
            for (let i = 0; i < subjectList.length; i++) {
              if (coursebundle.subjectId === subjectList[i].subjectId) {
                updatedCoursebundles[index].subject = subjectList[i].name;
                break;
              }
            }
          });
          // productList = updatedProducts;
          coursebundleList = updatedCoursebundles;
          this.setState({
            coursebundleList: updatedCoursebundles,
            filteredCoursebundles: coursebundleList,
            coursebundledb: new CoursebundleDB(coursebundleList),
            flagLoading: false,
          });
        })
      )
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  };
  componentDidMount() {
    // List of courses should be ready
    // Also List of subjects be ready
    this.setState({
      flagLoading: true,
    });
    axios
      .all([
        axios.get(window.routerPrefix + "/course/courses/"),
        axios.get(window.routerPrefix + "/subject/subjects/"),
      ])
      .then(
        axios.spread((res1, res2) => {
          let courseList = res1.data;
          let subjectList = res2.data;
          this.setState({
            courseList: courseList,
            subjectList: subjectList,
            flagLoading: false,
          });
        })
      )
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  }
  handleSearchTextBoxKeyUp = (event) => {
    let searchText = event.target.value;
    this.setState({ searchText: searchText });
    this.performSearchOperation(searchText);
  };
  handleSearchTextChange = (event) => {
    let searchText = event.target.value;
    this.setState({ searchText: searchText });
    this.performSearchOperation(searchText);
  };
  performSearchOperation = (searchText) => {
    let query = searchText.trim();
    let searchedCoursebundles = [];
    searchedCoursebundles = this.state.coursebundledb.filterByName(query);
    this.setState({
      filteredCoursebundles: searchedCoursebundles,
    });
  };
  onFormSubmit(data) {
    let message;
    let { action } = this.props;
    this.setState({
      flagLoading: true,
    });
    if (action === "UPDATE") {
      //EDIT / PUT
      axios
        .put(window.routerPrefix + "/coursebundle/update", data)
        .then((res) => {
          this.updateCoursebundleList(data);
          this.setState({
            response: res.data,
            flagLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            flagLoading: false,
          });
          //   this.props.askToLogin();
        });
    } else if (action === "ADD") {
      //ADD / POST
      // data.courseId=this.state.selectedCourseId;
      axios
        .post(window.routerPrefix + "/coursebundle/addCoursebundle", data, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
          },
        })
        .then((res) => {
          this.setState({
            flagLoading: false,
            response: res.data,
          });
          data.coursebundleId = res.data.coursebundleId; // id of the added coursebundle is returned as response
          this.setState({
            courseToRetain: data.course,
            courseIdToRetain: data.courseId,
            flagLoading: false,
          });

          this.addToCoursebundleList(data);
        })
        .catch((err) => {
          console.log("error!");
          this.setState({
            flagLoading: false,
          });
        });
    } //else
  }
  handleEditCoursebundle = (coursebundle) => {
    this.setState({
      coursebundle: coursebundle,
      searchText: "",
    });
    this.props.onEditClick();
  };
  handleShowPrograms = (coursebundle) => {
    this.props.onShowPrograms(coursebundle);
  };
  handleShowExerPrograms = (coursebundle) => {
    this.props.onShowExerPrograms(coursebundle);
  };
  handleShowMiscPrograms = (coursebundle) => {
    this.props.onShowMiscPrograms(coursebundle);
  };
  addToCoursebundleList = (coursebundle) => {
    let coursebundleList = [...this.state.coursebundleList];
    coursebundleList.unshift(coursebundle); // insert at the beginning
    let filteredCoursebundles = [...this.state.filteredCoursebundles];
    filteredCoursebundles.unshift(coursebundle);
    this.setState({
      filteredCoursebundles: filteredCoursebundles,
      coursebundleList: coursebundleList,
    });
    let message =
      "The coursebundle '" + coursebundle.name + "' added successfully.";
    this.props.onCrudAction(message, "ADD");
  };
  updateCoursebundleList = (coursebundle) => {
    let coursebundleList = [...this.state.coursebundleList];
    for (let i = 0; i < coursebundleList.length; i++) {
      if (coursebundleList[i].coursebundleId == coursebundle.coursebundleId) {
        if (coursebundleList[i].courseId == coursebundle.courseId) {
          // update, but it should be at the beginning
          // coursebundleList[i] = coursebundle;
          coursebundleList.splice(i, 1); // remove 1 element from position i
          // and insert at the beginning as we want latest modified at the top
          coursebundleList.unshift(coursebundle);
        } else {
          //delete from current list
          coursebundleList.splice(i, 1); // remove 1 element from position i
        }
        break;
      } //if
    } //for
    // remove from filteredcoursebundles also
    let filteredCoursebundles = [...this.state.filteredCoursebundles];
    for (let i = 0; i < filteredCoursebundles.length; i++) {
      if (
        filteredCoursebundles[i].coursebundleId == coursebundle.coursebundleId
      ) {
        if (filteredCoursebundles[i].courseId == coursebundle.courseId) {
          // update
          // filteredCoursebundles[i] = coursebundle;
          filteredCoursebundles.splice(i, 1); // remove 1 element from position i
          // and insert at the beginning as we want latest modified at the top
          filteredCoursebundles.unshift(coursebundle);
        } else {
          //delete from current list
          filteredCoursebundles.splice(i, 1); // remove 1 element from position i
        }
        break;
      } //if
    } //for
    this.setState({
      filteredCoursebundles: filteredCoursebundles,
      coursebundleList: coursebundleList,
      // searchText: "",
    });
    let message =
      "The coursebundle '" + coursebundle.name + "' updated successfully.";
    this.props.onCrudAction(message, "LIST", "Add a Coursebundle");
  };

  handleDeleteCoursebundle = (coursebundle) => {
    let coursebundleList = [...this.state.coursebundleList];
    for (let i = 0; i < coursebundleList.length; i++) {
      if (coursebundleList[i].coursebundleId == coursebundle.coursebundleId) {
        coursebundleList.splice(i, 1); // remove 1 element from position i
      }
    } //for
    // remove from filteredcoursebundles also
    let filteredCoursebundles = [...this.state.filteredCoursebundles];
    for (let i = 0; i < filteredCoursebundles.length; i++) {
      if (
        filteredCoursebundles[i].coursebundleId == coursebundle.coursebundleId
      ) {
        filteredCoursebundles.splice(i, 1); // remove 1 element from position i
      }
    } //for

    this.setState({
      //   coursebundledb: new CoursebundleDB(coursebundleList),
      filteredCoursebundles: filteredCoursebundles,
      coursebundleList: coursebundleList,
      // searchText: "",
    });
    let message =
      "The coursebundle '" + coursebundle.name + "' deleted successfully.";
    this.props.onCrudAction(message, "LIST");
  };
  handleDuplicateCoursebundle = (coursebundle, howMany) => {
    this.setState({
      flagLoading: true,
    });
    axios
      .post(
        window.routerPrefix +
          "/coursebundles/" +
          this.props.shopUrl +
          "/" +
          howMany,
        coursebundle,
        {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            // "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
          },
        }
      )
      .then((res) => {
        let message = coursebundle.name + " duplicated " + howMany;
        if (howMany == 1) message += " time.";
        else message += " times.";
        this.props.onCrudAction(message, "LIST"); // Add again
        this.getCoursebundleListFromDatabase();
        this.setState({
          flagLoading: false,
          response: res.data,
        });
      })
      .catch((err) => {
        console.log("error!");
        this.setState({
          flagLoading: false,
        });
      });
  };
  handleHeaderClick = (event) => {
    let field = event.target.id;

    let direction = false;
    if (field === this.state.sortedField) {
      // same button clicked twice
      direction = !this.state.direction;
    } else {
      // different field
      direction = false;
    }
    this.setState({ direction: direction });
    let { filteredCoursebundles } = this.state;
    if (direction == false) {
      //in ascending order
      filteredCoursebundles = filteredCoursebundles.sort((a, b) => {
        if (a[field] > b[field]) {
          return 1;
        }
        if (a[field] < b[field]) {
          return -1;
        }
        return 0;
      });
    } else {
      //in descending order
      filteredCoursebundles = filteredCoursebundles.sort((a, b) => {
        if (a[field] < b[field]) {
          return 1;
        }
        if (a[field] > b[field]) {
          return -1;
        }
        return 0;
      });
    }
    this.setState({
      filteredCoursebundles: filteredCoursebundles,
      sortedField: field,
    });
  };
  handleSectionNumberClick = (event) => {
    this.setState({ sectionNumber: event.target.id });
  };
  handleSelectCourseChange = (selectedCourse, selectedCourseId) => {
    // This is called for LIST as well ADD operation.
    // as course is changed, courseId is changed.
    // We want subjects of this courseId
    this.getCoursebundleListFromDatabase(selectedCourse, selectedCourseId);
    this.setState({
      selectedCourse: selectedCourse,
      selectedCourseId: selectedCourseId,
    });
    if (selectedCourse) {
      this.props.showAddButton();
    }
  };
  render() {
    let { flagLoading } = this.state;
    let { action } = this.props;
    let { coursebundle } = this.state;
    let { courseList } = this.state;
    let { subjectList } = this.state;
    let { selectedCourse } = this.state;
    let { selectedCourseId } = this.state;
    let content;
    if (flagLoading) {
      content = (
        <div className="text-center">
          <BeatLoader size={16} color={"blue"} flagLoading />
        </div>
      );
    } else if (action === "LIST") {
      // First show list
      content = (
        <AdminCoursebundleList
          courseList={courseList}
          onSelectCourseChange={this.handleSelectCourseChange}
          selectedCourse={selectedCourse}
          onEditCoursebundle={this.handleEditCoursebundle}
          onShowPrograms={this.handleShowPrograms}
          onShowExerPrograms={this.handleShowExerPrograms}
          onShowMiscPrograms={this.handleShowMiscPrograms}
          onDeleteCoursebundle={this.handleDeleteCoursebundle}
          filteredCoursebundles={this.state.filteredCoursebundles}
          searchText={this.state.searchText}
          onSearchTextChange={this.handleSearchTextChange}
          onSearchTextBoxKeyUp={this.handleSearchTextBoxKeyUp}
          onHeaderClick={this.handleHeaderClick}
          onSectionNumberClick={this.handleSectionNumberClick}
          sectionNumber={this.state.sectionNumber}
          onDuplicateCoursebundle={this.handleDuplicateCoursebundle}
          currentSize={this.state.coursebundleList.length}
        />
      );
    } else if (action === "ADD" || action === "UPDATE") {
      // After pressing Add Button, show only form and not the list
      content = (
        <div>
          {/* content={" "} */}
          <AdminCoursebundleAdd
            onFormSubmit={this.onFormSubmit}
            courseList={courseList}
            subjectList={subjectList}
            coursebundle={coursebundle}
            action={action}
            selectedCourse={selectedCourse}
            selectedCourseId={selectedCourseId}
            courseToRetain={this.state.courseToRetain}
            courseIdToRetain={this.state.courseIdToRetain}
          />
        </div>
      );
    } else if (action === "DELETE") {
      content = <div>Delete action in Progress...</div>;
    }
    return (
      <div className="container-fluid container-md container-content-page p-0">
        {content}
      </div>
    );
  }
}
export default AdminCoursebundle;
