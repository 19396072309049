import React from "react";
import { BeatLoader } from "react-spinners";

class AdminAccessAdd extends React.Component {
  emptyAccess = {
    accessId: "",
    chapterLimit: 0,
    admissionStatus: "",
    admissionType: "",
  };
  errorAccess = {
    chapterLimit: { message: "", mxLen: 2, mnLen: 1, onlyDigits: true },
    admissionStatus: { message: "" },
    admissionType: { message: "" },
  };
  constructor(props) {
    super(props);
    let access;
    let flagFormInvalid;
    let { action } = this.props;
    if (action === "ADD") {
      flagFormInvalid = true;
      access = this.emptyAccess;
    } else if (action === "UPDATE") {
      // in edit mode, keep the update button enabled at the beginning
      flagFormInvalid = false;
      access = this.props.access;
    }
    this.state = {
      access: access,
      errorAccess: this.errorAccess,
      flagFormInvalid: flagFormInvalid,
    };
  }

  handleChange = (event) => {
    let { errorAccess } = this.state;
    let name = event.target.name;
    let message;
    let userInput;
    const isCheckbox = event.target.type === "checkbox";
    if (isCheckbox) {
      // for checkbox, no checking of validations.
      userInput = event.target.checked;
      this.setState({
        access: { ...this.state.access, [name]: userInput },
      });
      return;
    }
    let value = event.target.value;
    //check of max. length
    let mxLen = errorAccess[`${name}`].mxLen;
    let onlyDigits = errorAccess[`${name}`].onlyDigits;
    if (value.length > mxLen) {
      value = value.slice(0, mxLen + 1);
      if (!onlyDigits) message = "Maximum " + mxLen + " characters allowed";
      else message = "Maximum " + mxLen + " digits allowed";
    } //if...
    else {
      message = "";
    }
    errorAccess[`${name}`].message = message;
    this.setState({
      errorAccess: errorAccess,
      access: { ...this.state.access, [name]: value },
    });
    this.checkAllErrors(errorAccess);
  };
  handleBlur = (event) => {
    let name,
      value,
      requiredMessage = " is required",
      type,
      message = "",
      mnLen,
      onlyDigits;
    let { errorAccess } = this.state;
    name = event.target.name;
    type = event.target.type;
    if (type === "text" || type === "textarea") {
      // check if 'required', also check min no. of characters
      value = event.target.value.trim();
      mnLen = errorAccess[`${name}`].mnLen;
      onlyDigits = errorAccess[`${name}`].onlyDigits;
      if (value.length === 0) {
        message = name + requiredMessage;
      } else if (value.length < mnLen) {
        if (!onlyDigits) message = "Min " + mnLen + " characters required";
        else message = "Min " + mnLen + " digits required";
      } //else
      else if (onlyDigits) {
        for (let i = value.length - 1; i >= 0; i--) {
          const d = value.charCodeAt(i);
          if (d < 48 || d > 57) {
            message = "Enter only digits";
            break;
          } //if
        } //for
      } //else if... onlyDigits
      else {
        message = "";
      }
    } //if... text || textarea
    else if (event.target.type === "select-one") {
      value = event.target.selectedIndex;

      if (value === 0) {
        // message = name + requiredMessage;
        // Currently no checking of category and brand
        message = "";
      } else {
        message = "";
      }
    } //else if
    errorAccess[`${name}`].message = message;
    this.setState({ errorAccess: errorAccess });
    this.checkAllErrors(errorAccess);
  };
  handleFocus = (event) => {
    let name = event.target.name;
    let errorAccess = this.state.errorAccess;
    errorAccess[`${name}`].message = "";
    this.setState({ errorAccess: errorAccess });
    this.checkAllErrors(errorAccess);
  };
  checkAllErrors = (errorAccess) => {
    // let { errorAccess } = this.state;
    let flagFormInvalid = false;
    for (let field in errorAccess) {
      if (errorAccess[field].message !== "") {
        // error is there
        flagFormInvalid = true;
        break;
      } //if
    } //for
    this.setState({ flagFormInvalid: flagFormInvalid });
  };
  handleRatingClick = (value) => {
    this.setState({
      access: { ...this.state.access, rating: value },
    });
  };
  handleUpload = (event) => {
    this.setState({
      imageName: event.target.files[0],
    });
  };
  handleSelectStudentChange = (event) => {
    let index = event.target.selectedIndex; // get selected index, instad of selected value
    var optionElement = event.target.childNodes[index];
    var selectedStudentId = optionElement.getAttribute("id");
    let student = event.target.value;
    let studentId = selectedStudentId;
    this.setState({
      access: {
        ...this.state.access,
        student: student,
        studentId: studentId,
      },
    });
  };
  handleSelectStatusChange = (event) => {
    let admissionStatus = event.target.value;
    this.setState({
      access: {
        ...this.state.access,
        admissionStatus: admissionStatus,
      },
    });
  };
  handleSelectAdmissionTypeChange = (event) => {
    let admissionType = event.target.value;
    this.setState({
      access: {
        ...this.state.access,
        admissionType: admissionType,
      },
    });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    window.scrollTo(0, 0); // moving scrollbar at the top
    this.props.onFormSubmit(this.state.access); //this.state
  };
  render() {
    let { studentList } = this.props;
    let { access } = this.state;
    let { errorAccess } = this.state;
    let { flagFormInvalid } = this.state;
    let { selectedStudent } = this.props;
    let { action } = this.props;
    let statusList = ["Admitted", "Demo", "Block"];
    let typeList = ["Online", "Offline"];
    // prepare options
    let optionsStatus = statusList.map((status, index) => (
      <option value={status} key={index}>
        {status}
      </option>
    ));
    let optionsType = typeList.map((type, index) => (
      <option value={type} key={index}>
        {type}
      </option>
    ));
    let heading;
    if (action === "ADD") {
      heading = "Add a Access (" + selectedStudent + ")";
    } else if (action === "UPDATE") {
      heading = "Update the Access";
    }
    return (
      <React.Fragment>
        <div className="form-heading column col-12">{heading}</div>
        <form className="text-dark bg-mycyan p-4" onSubmit={this.handleSubmit}>
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Status</label>
            </div>
            <div className="col-7 px-0">
              <select
                className="form-control"
                name="admissionStatus"
                value={access.admissionStatus}
                onChange={this.handleSelectStatusChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
              >
                <option> Select Status </option>
                {optionsStatus}
              </select>
            </div>
            <div className="offset-5">
              {errorAccess.admissionStatus.message ? (
                <span className="error-text">
                  {errorAccess.admissionStatus.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Type</label>
            </div>
            <div className="col-7 px-0">
              <select
                className="form-control"
                name="admissionType"
                value={access.admissionType}
                onChange={this.handleSelectAdmissionTypeChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
              >
                <option> Select Type </option>
                {optionsType}
              </select>
            </div>
            <div className="offset-5">
              {errorAccess.admissionType.message ? (
                <span className="error-text">
                  {errorAccess.admissionType.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}

          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Chapter Limit</label>
            </div>
            <div className="col-7 column px-0">
              <input
                type="number"
                step="1"
                className="form-control"
                name="chapterLimit"
                value={access.chapterLimit}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                placeholder=""
                required
              />
            </div>
            <div className="offset-5">
              {errorAccess.chapterLimit.message ? (
                <span className="error-text">
                  {errorAccess.chapterLimit.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row offset-5 text-center mb-0">
            <button
              className="btn-mycyan"
              type="submit"
              disabled={flagFormInvalid}
            >
              {action}
            </button>
          </div>
          <input type="hidden" name="accessId" value={access.accessId} />
        </form>
      </React.Fragment>
    );
  }
}
export default AdminAccessAdd;
