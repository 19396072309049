export class BatchDB {
  constructor(batches) {
    this.batches = batches;
  }
  allBatches() {
    return this.batches;
  }
  searchByBatchId(id) {
    for (let i = 0; i < this.batches.length; i++) {
      if (this.batches[i].batchId == id) {
        return this.batches[i];
      }
    }
    return null; // not found
  }
  filterByName(query) {
    let filteredBatches = [];
    for (let i = 0; i < this.batches.length; i++) {
      if (
        this.batches[i].name.toLowerCase().includes(query.toLowerCase())
      ) {
        filteredBatches.push(this.batches[i]);
      }
    } //for
    return filteredBatches;
  }
}
export default BatchDB;
