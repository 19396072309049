import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import axios from "axios";
function AdminChapterListSingle(props) {
  let [flagLoading, setFlagLoading] = useState(false);
  let [error, setError] = useState("");
  function handleEditChapter(chapter) {
    props.onEditChapter(chapter);
  }
  function handleDeleteChapter(chapter) {
    // Now call for delete api
    setFlagLoading(true);
    axios
      .delete(window.routerPrefix + "/chapter/delete/" + chapter.chapterId)
      .then((res) => {
        setFlagLoading(false);
        if (res.data) {
          props.onDeleteChapter(chapter);
        } else {
          //Error, something went wrong
        }
      })
      .catch((err) => {
        setFlagLoading(false);
        setError(err);
      });
  }
  let { chapter } = props;
  let { accessLevel } = props;
  let { adminType } = props;

  return (
    <div className="row myborder-top justify-content-center mb-2">
      <div className="col-2 col-md-1">{chapter.chapterNumber}.</div>
      <div className="col-6 col-md-4 ">{chapter.name}</div>
      <div className="col-2 col-md-1">
        {adminType >= accessLevel && (
          <Link to="#" onClick={() => handleEditChapter(chapter)}>
            <i className="fas fa-edit admin-icons"></i>
          </Link>
        )}
      </div>
      <div className="col-2 col-md-1">
        {!flagLoading && adminType >= accessLevel && (
          <Link
            to="#"
            onClick={() => {
              if (
                window.confirm(
                  "You really want to delete Chapter - " + chapter.name + " ?"
                )
              )
                handleDeleteChapter(chapter);
            }}
          >
            <i className="far fa-trash-alt admin-icons"></i>
          </Link>
        )}
        {flagLoading && <BeatLoader size={8} color={"blue"} flagLoading />}
      </div>
    </div>
  );
}
export default AdminChapterListSingle;
