import React, { Component } from "react";
import { Link } from "react-router-dom";
class DropDownAdminManage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleAdminManageClick = (event) => {
    this.props.onAdminManageClick(event);
  };
  render() {
    let { adminManageList } = this.props;
    let { title } = this.props;
    let { shortTitle } = this.props;
    let { adminType } = this.props;
    return (
      <>
        <div className="nav-item dropdown">
          <Link
            className="nav-link dropdown-toggle"
            to="#"
            id="dropdown01"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span className="normal-text">{title}</span>
            <span className="short-text">{shortTitle}</span>
          </Link>
          <div className="dropdown-menu" aria-labelledby="dropdown01">
            {adminManageList.map(
              (menu, index) =>
                adminType >= menu.dispLevel && (
                  <Link
                    className="dropdown-item"
                    to="#"
                    key={index}
                    id={index}
                    onClick={this.handleAdminManageClick}
                  >
                    {menu.name}
                  </Link>
                )
            )}
          </div>
        </div>
      </>
    );
  }
}
export default DropDownAdminManage;
