import React, { Component } from "react";
import { BeatLoader } from "react-spinners";
import axios from "axios";
import "./css/final-yaadi.css";
import { Link } from "react-router-dom";
class ReferredRegistration extends Component {
  errorObject = {
    emailId: { message: "" },
  };
  constructor(props) {
    super(props);

    this.state = {
      object: {
        emailId: "",
      },
      errorObject: this.errorObject,
      flagFormInvalid: false,
      flagWhatsappChecked: false,
      student: { fullName: "" },
      flagLoadingProfileDetails: true,
      flagSubmittingEmail: false,
      flagSubmittedEmail: false,
      flagRegisterClick: false,
      offerStatus: "",
      emailSubmitResult: "",
    };
  }
  handleChange = (event) => {
    let { errorObject } = this.state;
    let name = event.target.name;
    let message;
    let value = event.target.value;
    let requiredMessage = " is required";
    // check if 'required', also check min no. of characters
    value = event.target.value.trim();
    if (event.target.type === "email") {
      value = event.target.value.trim();
      if (/\S+@\S+\.\S+/.test(value) == 0) {
        message = name + requiredMessage;
      } else {
        message = "";
      }
    } //else if
    else {
      message = "";
    }
    errorObject[`${name}`].message = message;
    this.setState({
      errorObject: errorObject,
      object: { ...this.state.object, [name]: value },
    });
    this.checkAllErrors(errorObject);
  };
  checkAllErrors = (errorObject) => {
    // let { errorProduct } = this.state;
    let flagFormInvalid = false;
    for (let field in errorObject) {
      if (errorObject[field].message !== "") {
        // error is there
        flagFormInvalid = true;
        break;
      } //if
    } //for
    this.setState({ flagFormInvalid: flagFormInvalid });
  };
  handleFocus = (event) => {
    let name = event.target.name;
    let errorObject = this.state.errorObject;
    errorObject[`${name}`].message = "";
    this.setState({ errorObject: errorObject });
    this.checkAllErrors(errorObject);
  };
  componentDidMount = () => {
    let path = this.props.location.pathname; //.substring(11)
    let pos = path.indexOf("_"); //this.props.location.pathname;//.substring(11)
    // console.log(path);
    let p = path.indexOf("/", 1);
    // console.log(p);
    if (p == -1) {
      //wrong link
      this.setState({
        flagLinkOk: false,
        message: "Invalid Link",
      });
      return;
    }
    let studentId = path.substring(p + 1); //.length;
    // console.log(s.length);
    if (studentId.length != 36) {
      //wrong link (size of student id is 36 characters 8-4-4-4-12)
      this.setState({
        flagLinkOk: false,
        message: "Invalid Link",
      });
      return;
    }
    this.setState({
      path: path,
    });

    // get details of the referring student
    axios
      .get("/" + window.routerPrefix + "/student/welcome")
      .then((res) => {
        this.setState({
          flagCheckSession: false,
          flagLoadingReferralDetails: true,
        });
        axios
          .get("/" + window.routerPrefix + "/student/studentById/" + studentId)
          .then((res) => {
            let student = res.data;
            this.setState({
              flagLoadingReferralDetails: false,
            });
            if (student) {
              this.setState({
                student: student,
                flagLinkOk: true,
              });
            } else {
              this.setState({
                flagLinkOk: false,
              });
            }
          })
          .catch((err) => {
            this.setState({
              error: err,
              message: "Something went wrong",
              flagLoadingReferralDetails: false,
            }); // error is set
          });
      })
      .catch((err) => {
        this.setState({
          error: err,
          message: "Something went wrong",
          flagCheckSession: false,
        }); // error is set
      });
  };
  handleEmailSubmit = (event) => {
    event.preventDefault();
    // add email-id of referring student is to be added to data to be submitted
    let data = {
      referredEmailId: this.state.object.emailId,
      referredByStudentId: this.state.student.studentId,
      referredByStudentName: this.state.student.fullName,
    };

    this.setState({
      flagSubmittingEmail: true,
    });
    axios
      .post("/" + window.routerPrefix + "/referral/addReferral", data, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          // "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        },
      })
      .then((res) => {
        // response is whether this email-id is already registered/Ok/referred
        this.setState({
          flagSubmittingEmail: false,
          flagSubmittedEmail: true,
          emailSubmitResult: res.data, //"Registered" or "Ok" or "By...."
        });
      })
      .catch((err) => {
        this.setState({
          flagSubmittingEmail: false,
          message: "Something went wrong",
        });
        // this.hideMessage();
      });
  };
  handleRegisterClick = () => {
    this.setState({
      flagRegisterClick: true,
    });
  };
  render() {
    let { student } = this.state;
    let { flagLoadingReferralDetails } = this.state;
    let { flagSubmittingEmail } = this.state;
    let { flagSubmittedEmail } = this.state;

    let { flagFormInvalid } = this.state;
    let { flagLinkOk } = this.state;
    let { emailSubmitResult } = this.state;
    let { object } = this.state;
    let { message } = this.state;
    let { errorObject } = this.state;
    let { flagRegisterClick } = this.state;

    let information;
    let lastDate = new Date("2023-11-25");
    let today = new Date();
    if (today <= lastDate) {
      information = `You are entitled for discount of Rs. 500/- 
    in the fees of our most popular course -  
    "Programming using 3 languages (C,C++,Python)".
    Last date for this offer is ${lastDate.getDate()}/${
        lastDate.getMonth() + 1
      }/${lastDate.getFullYear()}.
    `;
    } else {
      information = `The offer of Rs. 500/- discount 
      in the fees of our most popular course -  
    "Programming using 3 languages (C,C++,Python)
      is expired... 
    "`;
    }
    return (
      <>
        <div className="container container-reference-page">
          <div className="row">
            <div className="col-12 text-center mb-3">
              <img
                src="/images/rjac_logo.jpg"
                alt="No logo"
                className="img-fluid "
                onClick={this.handleLogoClick}
              />
            </div>
          </div>
          <div className="row div-reference-page text-center">
            <div className="col-12">Welcome to Rajesh Jain Academy.</div>
          </div>
          <div className="row text-center mb-10">
            <div className="col-12">
              To know more about us, click &nbsp;&nbsp;
              <Link to={"/"}>here</Link>
            </div>
          </div>
          {!flagLinkOk && message && (
            <div className="row div-reference-page text-center">
              <div className="col-12 text-thick text-error ">{message}</div>
            </div>
          )}
          {flagLoadingReferralDetails && (
            <div className="text-center mt-5">
              <BeatLoader size={16} color={"blue"} flagLoadingReferralDetails />
            </div>
          )}

          {flagLinkOk && (
            <div className="row div-reference-page text-center mt-5">
              <div className="col-12 text-thick">
                You are being referred by our student - {student.fullName}.
              </div>
            </div>
          )}
          {flagLinkOk && (
            <div className="row div-reference-page text-center">
              <div className="col-12 text-thick">
                You are eligible for discount of Rs. 200/-.
              </div>
            </div>
          )}
          {flagLinkOk && !flagRegisterClick && (
            <div className="row text-center mb-10">
              <div className="col-12">
                <Link to="#" onClick={this.handleRegisterClick}>
                  Register{" "}
                </Link>{" "}
                Now.
              </div>
            </div>
          )}
          {/* {flagLinkOk && (
            <div className="row div-reference-page text-center">
              <div className="col-12" style={{ whiteSpace: "pre-wrap" }}>
                {information}
              </div>
            </div>
          )} */}
          {flagLinkOk && !flagRegisterClick && (
            <div className="row div-reference-page justify-content-center my-2">
              <div className=" text-center ">
                <img
                  src={
                    "/" +
                    window.routerPrefix +
                    "/files/downloadReferenceImageFile/reference.gif"
                  }
                  alt="No Photo"
                  className="img-fluid reference-photo"
                  // onClick={this.handleLogoClick}
                />
              </div>
            </div>
          )}
          {flagSubmittingEmail && (
            <div className="text-center mt-5">
              <BeatLoader size={16} color={"blue"} flagSubmittingEmail />
            </div>
          )}
          {flagLinkOk &&
            flagRegisterClick &&
            !flagSubmittingEmail &&
            !flagSubmittedEmail && (
              <div className="row div-reference-page text-center">
                <div className="col-12">
                  For Registration, submit your email-id
                </div>
              </div>
            )}
          {flagSubmittedEmail && emailSubmitResult == "Ok" && (
            <div className="row div-reference-page text-center text-message">
              <div className="col-12">
                Your Email-id submitted successfully.
              </div>
              <div className="col-12">
                A link has been sent to your email-account.
              </div>
              <div className="col-12">
                Use it for registration and admission purpose.
              </div>
            </div>
          )}
          {flagSubmittedEmail && emailSubmitResult == "Registered" && (
            <div className="row div-reference-page text-center text-message">
              <div className="col-12">
                This Email-id is already registered with us.
              </div>
              <div className="col-12">
                <Link
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Click{" "}
                </Link>{" "}
                to submit other email-id
              </div>
            </div>
          )}
          {flagSubmittedEmail && emailSubmitResult.startsWith("By") && (
            <div className="row div-reference-page text-center text-message">
              <div className="col-12">
                This Email-id has already been referred by{" "}
                {emailSubmitResult.substring(2)}.
              </div>
              <div className="col-12">
                <Link
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Click{" "}
                </Link>{" "}
                to submit other email-id
              </div>
            </div>
          )}
        </div>

        {flagLinkOk &&
          flagRegisterClick &&
          !flagSubmittingEmail &&
          !flagSubmittedEmail && (
            <div className="container container-student-login-form">
              {/* // row starts */}
              {/* // row starts */}
              <div className="form-student-login">
                <form className="text-dark " onSubmit={this.handleEmailSubmit}>
                  <div className="text-white bg-darkcolor my-2 px-4 py-4">
                    <div className="form-group row align-items-center justify-content-center px-2">
                      {/* row starts */}
                      <div className="col-10  text-center px-0">
                        <input
                          type="email"
                          className="form-control"
                          name="emailId"
                          value={object.emailId}
                          onChange={this.handleChange}
                          onFocus={this.handleFocus}
                          placeholder="Enter email-id"
                          required
                        />
                      </div>
                      <div className="offset-5">
                        {errorObject.emailId.message ? (
                          <span className="error-text">
                            {this.errorObject.emailId.message}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    {/* row ends */}
                    {/* row starts */}
                    <div className="form-group row offset-5 text-center mb-0">
                      <button
                        className="bg-lightcolor text-dark-color no-border p-2"
                        type="submit"
                        disabled={flagFormInvalid}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
      </>
    );
  }
}
export default ReferredRegistration;
