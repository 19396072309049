import React, { Component } from "react";
import { FaStar } from "react-icons/fa";


class TestPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
    };
  }

  sh_Test = () => {
    return true;
  };
  handleTryTestClick = () => {
    let { testNumber } = this.props;
    let { flagFirstAttempt } = this.props;
    this.props.onTryTestClick(testNumber, flagFirstAttempt);
  };
  getAppearedDate = () => {
    let { test } = this.props;
    let dateAppeared = test.appearedDate;
    let date = new Date(dateAppeared);
    dateAppeared =
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
    return dateAppeared;
  };
  render() {
    let { test } = this.props;
    let { flagFirstAttempt } = this.props;
    let { testNumber } = this.props;
    // let { testNumber } = this.props;

    return (
      <React.Fragment>
        {!flagFirstAttempt && (
          <div className="row no-gutters mb-2  ">
            <div className="col-2 text-left">{test.testNumber}.</div>
            <div className="col-4 text-left">
              FA on {this.getAppearedDate()}
            </div>
            <div className="col-6 col-md-4 text-left">
              {test.marksObtained}/{test.outOfMarks}
              {"  "}
              {[...Array(test.outOfMarks)].map((star, i) => {
                return (
                  <label key={i + 1}>
                    <input
                      className="form-control-inline star-radio-buttons"
                      type="radio"
                      name="rating"
                      value={i + 1}
                    />
                    <FaStar
                      className="star"
                      size={12}
                      color={i + 1 <= test.marksObtained ? "green" : "red"}
                    />
                  </label>
                );
              })}
            </div>
            <div className="col-12 col-md-2 text-right">
              <button
                to="/"
                className="btn-darkcolor"
                onClick={this.handleTryTestClick}
                style={{ whiteSpace: "pre-wrap" }}
              >
                Try again
              </button>
            </div>
          </div>
        )}
        {flagFirstAttempt && testNumber != 1 && (
          <div className="row no-gutters mb-2">
            <div className="col-2 text-left">{testNumber}.</div>
            <div className="col-4 text-left"> Not tried yet </div>
            <div className="col-4 text-left">
              <button
                to="/"
                className="btn-darkcolor"
                onClick={this.handleTryTestClick}
                style={{ whiteSpace: "pre-wrap" }}
              >
                Try Now
              </button>
            </div>
          </div>
        )}
        {flagFirstAttempt && testNumber == 1 && (
          <div className="row no-gutters justify-content-center  mb-2">
            <div className="col-12 text-center ">
              Test No. {testNumber}. &nbsp; &nbsp;
              <button
                to="/"
                className="btn-darkcolor"
                onClick={this.handleTryTestClick}
                style={{ whiteSpace: "pre-wrap" }}
              >
                Try Now
              </button>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
export default TestPage;
