import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import axios from "axios";

import {
  faYoutube,
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
class OfflineHomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flagDownnLoadingPdf: false,
      message: "",
    };
  }
  handleExploreClick = () => {
    this.props.onExploreClick();
  };
  handleStartLearningProgrammingClick = () => {
    this.handleStartLearningClick("Programming");
  };
  handleStartLearningWebClick = () => {
    this.handleStartLearningClick("Web");
  };
  handleStartLearningJavaClick = () => {
    this.handleStartLearningClick("Core");
  };

  handleStartLearningClick = (learningSubject) => {
    this.props.onStartLearningClick(learningSubject);
  };

  hideMessage = () => {
    window.setTimeout(() => {
      this.setState({
        message: "",
      });
    }, 3000);
  };
  handleContactNowClick = () => {
    let whatsappNumber = "919422035020";
    let whatsAppMessage =
      "Hi! I am interested to join the course of 'Programming using 3 languages'";
    window.location = `https://wa.me/${whatsappNumber}?text=${whatsAppMessage}`;
  };
  handleDownloadCourseDetailsPdfClick = () => {
    let requestUrl = "/general/CourseDetailsPdf";
    this.setState({
      flagDownnLoadingPdf: true,
    });
    axios
      .get(window.routerPrefix + requestUrl, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/pdf",
        },
      })
      .then((res) => {
        let type = "application/pdf";
        const url = window.URL.createObjectURL(
          new Blob([res.data], {
            type: type,
          })
        );
        let fileName = res.headers["content-disposition"].split('"')[1];
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.setState({
          flagDownnLoadingPdf: false,
        });
        this.setState({
          message: "downloaded...",
        });
        this.hideMessage();
        this.unselectMenu();
      })
      .catch((err) => {
        this.setState({
          error: err,
          message: "Something went wrong",
          flagDownnLoadingPdf: false,
        }); // error is set
        this.hideMessage();
      });
  };
  render() {
    let { student } = this.props;
    let { flagLoggingOut } = this.props;
    let { flagDownnLoadingPdf } = this.state;
    let { message } = this.state;

    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="container-fluid container-jumbotron p-0 pb-1 mb-1 text-center">
                <img
                  className="position-relative w-100"
                  src="images/class_photo3.jpg"
                  style={{ minHeight: "300px", objectFit: "cover" }}
                />
                <h2 className="text-block1 text-center">
                  Enter Confidently in the world of I.T.
                </h2>
                {/* <h3 className="text-block2 text-center">
                  Now learn online at your convenience!
                </h3> */}
              </div>
            </div>
          </div>
        </div>

        <div className="row"></div>
        {/* <!-- Why to Join Starts --> */}
        <div className="container-fluid py-2 mt-2">
          <div className="container py-2">
            <div className="row align-items-center ">
              <div className="col-lg-7 text-justify">
                <div className="text-left mb-4">
                  <h5
                    className="text-primarycolor mb-3"
                    style={{ letterSpacing: "3px" }}
                  >
                    Why to Join Rajesh Jain Academy
                  </h5>
                </div>
                <p>25+ years of experience.</p>
                <p>Direct mentorship of Rajesh sir and his able team.</p>
                <p>
                  Thousands of students have mastered Coding and have cracked
                  jobs at product based companies.
                </p>
                <p>
                  Well curated syllabus to become Interview ready as well as
                  excel in University exams.
                </p>

                <p>
                  Exhaustive notes, exercises, interview questions/answers on
                  each topic.
                </p>
                <p>
                  300+{" "}
                  <img
                    src="images/logo_google.png"
                    alt="No logo"
                    className="google-image"
                    onClick={this.handleLogoClick}
                  />
                  &nbsp;
                  <a href="#">Reviews.</a> 4.9 star-ratings.
                </p>
                <p>Personal Attention.</p>
                <p>
                  Certificate of Completion (with credentials) after completion
                  of course.
                </p>
              </div>
              <div className="col-lg-5  ">
                <img
                  className="img-fluid rounded mb-4 mb-lg-0"
                  src="images/class_photo5.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Why to join ends --> */}
        {/* <!-- Proven formula Starts --> */}
        <div className="container-fluid py-2">
          <div className="container py-2">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <img
                  className="img-fluid rounded mb-4 mb-lg-0"
                  src="images/formula.jpg"
                  alt=""
                />
              </div>
              <div className="col-lg-5">
                <div className="text-left mb-4">
                  <h5
                    className="text-primarycolor mb-3"
                    style={{ letterSpacing: "3px" }}
                  >
                    Our Proven Formula Of Teaching
                  </h5>
                </div>

                <p>1. Watch a video lecture OR Attend live lectures</p>
                <p>2. Clear all doubts from our team.</p>
                <p>3. Write down key points in notes.</p>
                <p>4. Solve assignments and get them checked</p>
                <p>5. Give Test based on the completed topic.</p>
                <p>6. Go through Interview Questions/Answers.</p>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Proven formula Ends --> */}
        <div className="container  p-1">
          <div className="row no-gutters text-left align-items-center">
            {/* column ends */}
          </div>
          {this.props.status == "success" &&
            !flagLoggingOut &&
            student.eligibleForPromoCode == "yes" && (
              <div className="div-highlight">
                <div className="text-highlight">
                  Now, refer our "Programming" course to your friend and get Rs.
                  500/-.{" "}
                </div>
                <div className="text-highlight">
                  Click to your profile for more details.
                </div>
              </div>
            )}
        </div>
        {/* container ends */}
        <div className="col-12 ">
          <h5
            className="text-primarycolor  mb-1 text-center"
            style={{ letterSpacing: "5px" }}
          >
            Sample Certificate
          </h5>
        </div>
        <div className="row justify-content-center mx-4">
          <div className="col-12 text-center">
            <img
              src="images/varadchaudhari2004_1951.jpg"
              alt="No logo"
              className="img-fluid"
              onClick={this.handleLogoClick}
            />
          </div>
        </div>

        <div
          className="container-fluid bg-dark text-white py-1 px-sm-1 px-lg-1"
          style={{ marginTop: "90px" }}
        >
          <div className="row pt-1">
            <div className="col-md-12 mb-1">
              <p
                className="text-uppercase text-center mb-4"
                style={{ letterSpacing: "5px" }}
              >
                Get In Touch
              </p>
              <p className=" text-center mb-4">
                <i className="fa fa-map-marker-alt mr-2"></i>Sinhagad College
                Campus, Pune-41, India
              </p>
              <p className=" text-center mb-4">
                <i className="fa fa-phone-alt mr-2"></i>9422035020
              </p>
              <p className=" text-center mb-1">
                <i className="fa fa-envelope mr-2"></i>
                admin@rajeshjainacademy.com
              </p>
            </div>
          </div>
        </div>
        <div className="container container-footer text-center">
          <div>Follow us on</div>
          <a
            href="https://www.youtube.com/@rajeshjainacademypune/playlists"
            className="youtube social"
          >
            <FontAwesomeIcon icon={faYoutube} size="2x" />
          </a>
          <a
            href="https://www.facebook.com/rajeshjainacademy/"
            className="facebook social"
          >
            <FontAwesomeIcon icon={faFacebook} size="2x" />
          </a>

          <a
            href="https://www.instagram.com/rjacpune"
            className="instagram social"
          >
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </a>
        </div>
        <div
          className="container-fluid  bg-dark text-white border-top py-1 px-sm-1 px-md-1"
          style={{ borderColor: "rgba(256, 256, 256, .1) important" }}
        >
          <div className="row">
            <div className="col-12 text-center text-md-left mb-3 mb-md-0">
              <p className="m-0 text-white text-center">
                &copy; RajeshJainAcademy.com. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default OfflineHomePage;
