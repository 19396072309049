import React, { Component } from "react";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import axios from "axios";
class AdminAdmissionBatchListSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      response: {},
      flagLoading: false,
    };
  }
  handleEditAdmission = (admission) => {
    this.props.onEditAdmission(admission);
  };

  handleDuplicateAdmission = (admission) => {
    let selection = window.prompt(
      "How many duplicates of " + admission.name + " to be created (1 to 10)"
    );
    if (selection == null) return;
    if (/^[0-9]+$/.test(selection)) {
      this.props.onDuplicateAdmission(admission, selection);
    } //if
    else {
      window.alert("You didn't enter a valid number.");
    }
  };
  handleDeleteAdmission = (admission) => {
    // Now call for delete api
    let { shopUrl } = this.props;
    this.setState({
      flagLoading: true,
      // action: "LIST", // Back to list view
    });
    axios
      .delete(
        window.routerPrefix + "/admission/delete/" + admission.admissionId
      )
      .then((res) => {
        // let message = admission.name + " deleted successfully";
        this.setState({
          response: res.data,
          flagLoading: false,
          // action: "LIST", // Back to list view
        });
        this.props.onDeleteAdmission(admission);
      })
      .catch((err) => {
        this.setState({
          flagLoading: false,
          // action: "LIST", // Back to list view
        });
        console.log(err);
      });
  };

  render() {
    let { error } = this.state;
    let { admission } = this.props;
    let { flagLoading } = this.state;
    let { accessLevel } = this.props;
    let { adminType } = this.props;

    let content = (
      <>
        <tr className="myborder-top">
          <td>{admission.studentName}</td>
          <td>{admission.course}</td>
          <td>{admission.feesPaid}</td>
          <td>
            {admission.courseFees - admission.discount - admission.feesPaid}
          </td>
          <td>{admission.status}</td>
          <td>
            <div className="row justify-content-around">
              <div className="col-4">
                {adminType >= accessLevel && (
                  <Link
                    to="#"
                    onClick={() => this.handleEditAdmission(admission)}
                  >
                    {/* Edit */}
                    <i className="fas fa-edit admin-icons"></i>
                  </Link>
                )}
              </div>
              {!flagLoading && adminType >= accessLevel && (
                <div className="col-4">
                  <Link
                    to="#"
                    onClick={() => {
                      if (
                        window.confirm(
                          "You really want to delete Admission - " +
                            admission.name +
                            " ?"
                        )
                      )
                        this.handleDeleteAdmission(admission);
                    }}
                  >
                    {/* Delete */}
                    <i className="far fa-trash-alt admin-icons"></i>
                  </Link>
                </div>
              )}

              {flagLoading && (
                <div className="col-4">
                  <BeatLoader size={8} color={"blue"} flagLoading />
                </div>
              )}
            </div>
          </td>
        </tr>
      </>
    );
    if (error) {
      return <div>Error retriving Admissions </div>;
    } else {
      return content;
    } //else
  }
}
export default AdminAdmissionBatchListSingle;
