export class LoginRecordDB {
  constructor(loginRecords) {
    this.loginRecords = loginRecords;
  }
  allLoginRecords() {
    return this.loginRecords;
  }
  searchByLoginRecordId(id) {
    for (let i = 0; i < this.loginRecords.length; i++) {
      if (this.loginRecords[i].loginRecordId == id) {
        return this.loginRecords[i];
      }
    }
    return null; // not found
  }
  filterByName(query) {
    let filteredLoginRecords = [];
    for (let i = 0; i < this.loginRecords.length; i++) {
      if (this.loginRecords[i].name.toLowerCase().includes(query.toLowerCase())) {
        filteredLoginRecords.push(this.loginRecords[i]);
      }
    } //for
    return filteredLoginRecords;
  }
}
export default LoginRecordDB;
