import React from "react";
import { Link } from "react-router-dom";
import AdminChapterListSingle from "./admin-chapter-list-single";
function AdminChapterList(props) {
  function handleEditChapter(chapter) {
    props.onEditChapter(chapter);
  }
  function handleCrudAction(message, action) {
    props.onCrudAction(message, action);
  }
  function handleDeleteChapter(chapter) {
    props.onDeleteChapter(chapter);
  }
  function handleSearchTextBoxKeyUp(event) {
    props.onSearchTextBoxKeyUp(event);
  }
  function handleSearchTextChange(event) {
    props.onSearchTextChange(event);
  }
  function handleHeaderClick(event) {
    props.onHeaderClick(event);
  }
  function handleSectionNumberClick(event) {
    props.onSectionNumberClick(event);
  }
  function handleSelectSubjectChange(event) {
    let index = event.target.selectedIndex; // get selected index, instad of selected value
    var optionElement = event.target.childNodes[index];
    var selectedSubjectId = optionElement.getAttribute("id");
    let selectedSubject = event.target.value;
    props.onSelectSubjectChange(selectedSubject, selectedSubjectId);
  }
  let { selectedSubject } = props;
  let { filteredChapters } = props;
  let { subjectList } = props;
  let { searchText } = props;
  let { currentSize } = props;
  let { accessLevel } = props;
  let { adminType } = props;

  let contentNochapters = <div className="text-center">No chapters !</div>;
  // prepare options
  let optionsSubject = subjectList.map((subject, index) => (
    <option value={subject.name} key={index} id={subject.subjectId}>
      {subject.name}
    </option>
  ));
  return (
    <>
      {selectedSubject && filteredChapters.length != 0 && (
        <>
          <div className="row p-0 justify-content-center ">
            <div className="col-2 col-md-1">
              <Link to="#" onClick={handleHeaderClick} id="chapterNumber">
                Sn
              </Link>
            </div>
            <div className="col-8 col-md-4">
              <Link to="#" onClick={handleHeaderClick} id="name">
                Name
              </Link>
            </div>
            <div className="col-2">&nbsp;</div>
          </div>
          {filteredChapters.map((chapter, index) => (
            <AdminChapterListSingle
              key={index}
              chapter={chapter}
              index={index}
              onDeleteChapter={handleDeleteChapter}
              onEditChapter={handleEditChapter}
              onCrudAction={handleCrudAction}
              currentSize={currentSize}
              accessLevel={accessLevel}
              adminType={adminType}
            />
          ))}
        </>
      )}
    </>
  );
}
export default AdminChapterList;
