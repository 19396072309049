import React, { Component } from "react";
import "../App.css";
import { BeatLoader } from "react-spinners";

class NavBtnChapter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      loginTitle: "Login",
    };
  }
  handleChapterClick = () => {
    let { chapter } = this.props;
    this.props.onChapterClick(chapter);
  };
  render() {
    let { chapter } = this.props;
    let { index } = this.props;
    let { flagLoadingChapters } = this.props;
    let { message } = this.props;
    return (
      <React.Fragment>
        {flagLoadingChapters  && !message &&(
          <button
            className={"button-chapter btn-darkcolor"}
            to="#"
            onClick={() => this.handleChapterClick()}
          >
            <BeatLoader size={8} color={"blue"} flagLoadingChapters />
          </button>
        )}
        {!flagLoadingChapters  && !message &&(
          <button
            className={
              "button-chapter " +
              (chapter.flagActive ? " btn-lightcolor" : " btn-darkcolor")
            }
            to="#"
            onClick={() => this.handleChapterClick()}
          >
            {index + 1}. {chapter.name}
          </button>
        )}
      </React.Fragment>
    );
  }
}
export default NavBtnChapter;
