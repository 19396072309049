import React, { useState } from "react";
import { BeatLoader } from "react-spinners";
import axios from "axios";
import LazyLoad from "react-lazyload";
import Compressor from "compressorjs";
// import imageCompression from 'browser-image-compression';
function StudentPaymentForm(props) {
  let [flagLoading, setFlagLoading] = useState(false);
  let [error, setError] = useState("");
  let [previewImage, setPreviewImage] = useState(false);
  let [flagImageChange, setFlagImageChange] = useState(false);
  let [flagImageUploaded, setFlagImageUploaded] = useState(false);
  let [message, setMessage] = useState("");
  let [file, setFile] = useState("");
  let [compressedFile, setCompressedFile] = useState(null);
  function handleChangeImageClick() {
    setFlagImageChange(true);
  }
  function handleCancelScreenShotClick() {
    props.onCancelScreenShotClick(true);
  }
  function handleUploadImageClick() {
    const formData = new FormData();
    // formData.append("imageFile", file);
    formData.append("imageFile", compressedFile, "somename.jpg");
    setFlagLoading(true);
    axios
      .post(
        window.routerPrefix +
          "/files/uploadPaymentScreenShot/" +
          props.joiningStudentData.whatsappNumber +
          "/" +
          props.joiningStudentData.firstName,
        formData,
        {
          headers: {
            accept: "application/json",
          },
        }
      )
      .then((res) => {
        setFlagLoading(false);
        if (res.data != "####") {
          //successfully uploaded
          let imageName = res.data;
          setFlagImageChange(false);
          setFlagImageUploaded(false);
          setPreviewImage(false);

          setFlagImageUploaded(true);
          props.onAdmissionBeingDone();
          // Now firstly add this student to registered student list
          // then add his/her payment details
          // then remove entry from enquiredStudent table
          // finally perform admission
          createRegisteredStudentRecord(imageName);
        } //if
      })
      .catch((err) => {
        setError(err);
        setFlagLoading(false);
      });
  }
  function createRegisteredStudentRecord(imageName) {
    // let student = { ...props.joiningStudentData };
    // This is deep copy
    let student = JSON.parse(JSON.stringify(props.joiningStudentData));
    let fullName =
      props.joiningStudentData.firstName +
      " " +
      props.joiningStudentData.middleName +
      " " +
      props.joiningStudentData.surName;
    student.fullName = fullName;
    delete student.firstName;
    delete student.middleName;
    delete student.surName;
    delete student.enquiredStudentId;
    student.otherInfo =
      props.joiningStudentData.studyingYear +
      " Year - " +
      props.joiningStudentData.studying +
      " " +
      props.joiningStudentData.studyingBranch +
      " " +
      props.joiningStudentData.college;
    student.studentNumber = 1;
    student.allowLoginUsing = "both";
    student.eligibleForPromoCode = "no";
    student.loggedIn = 0;
    student.status = "Registered";
    setFlagLoading(true);
    axios
      .post(window.routerPrefix + "/student/addStudent", student, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
        },
      })
      .then((res) => {
        setFlagLoading(false);
        // Got the studentId
        // Now post payment data to payments table
        // Then create admission for the selected course
        let payment = {
          studentId: res.data,
          courseId: props.selectedCourse.courseId,
          imageName: imageName,
        };
        postPaymentData(payment);
      })
      .catch((err) => {
        setError(err);
        setFlagLoading(false);
      });
    //   existingStudent.setPassword(student.getPassword());
    //   existingStudent.setStatus(student.getStatus());
  }
  function postPaymentData(payment) {
    setFlagLoading(true);
    axios
      .post(window.routerPrefix + "/payment/addFirstPayment", payment, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
        },
      })
      .then((res) => {
        setFlagLoading(false);
        // Now add admission and access entries
        addAdmissionRecord(payment);
        // Now lastly delete this entry from enquiredStudent table
      })
      .catch((err) => {
        setError(err);
        setFlagLoading(false);
      });
  }
  function addAdmissionRecord(payment) {
    setFlagLoading(true);
    let admission = {
      studentId: payment.studentId,
      courseId: payment.courseId,
      status: "Admitted",
      discount: 0,
      feesPaid: payment.amount,
      certificateUrl: "",
      batchId: -1,
    };
    axios
      .post(window.routerPrefix + "/admission/addAdmission", admission, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
        },
      })
      .then((res) => {
        setFlagLoading(false);
        // Now lastly delete this entry from enquiredStudent table
        deleteEnquiredStudentRecord();
      })
      .catch((err) => {
        setError(err);
        setFlagLoading(false);
      });
  }
  function deleteEnquiredStudentRecord() {
    setFlagLoading(true);
    axios
      .delete(
        window.routerPrefix +
          "/enquiredStudent/delete/" +
          props.joiningStudentData.enquiredStudentId
      )
      .then((res) => {
        setFlagLoading(false);
        props.onAdmissionDone();
      })
      .catch((err) => {
        setFlagLoading(false);
        setError(err);
      });
  }
  function handleCancelChangeImageClick() {
    setMessage("");
    setFile("");
    setPreviewImage("");
    setFlagImageChange(false);
  }
  function fileChangedHandler(event) {
    let file = event.target.files[0];
    if (!file) {
      setMessage("");
      return;
    }
    let fileType = file.type.substring(0, file.type.indexOf("/"));
    if (fileType != "image") {
      setMessage("Invalid file...");
      setFile("");
    }
    //  else if (file.size > 5000) {
    //   setState({ message: "File-size should be below 5kb" });
    // }
    else {
      setMessage("");
      let previewImage = URL.createObjectURL(file);
      setFile(file);
      setPreviewImage(previewImage);
    }
  }
  function handleCompressedUpload(event) {
    let file = event.target.files[0];
    if (!file) {
      setMessage("");
      return;
    }
    let fileType = file.type.substring(0, file.type.indexOf("/"));
    if (fileType != "image") {
      setMessage("Invalid file...");
      setFile("");
      return;
    }
    setMessage("");
    let previewImage = URL.createObjectURL(file);
    setFile(file);
    setPreviewImage(previewImage);
    new Compressor(file, {
      quality: 0.4, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.
        setCompressedFile(compressedResult);
      },
    });
  }

  let paymentInfo = `Mobile Number: 8975425083
  Name: Rajesh Jain
  UPI ID: mehuljain210200-1@okicici
  `;
  return (
    <>
      {!flagLoading && !flagImageUploaded && (
        <div className="container-fluid container-md">
          <div className="row justify-content-center">
            <div className="col-12  text-smaller text-center">
              For online-payment refer to following information. After the
              payment, upload screenshot of the payment screen.
            </div>
            <div className="col-12  text-center my-2 p-1">
              <div className=" div-center payment-info ">{paymentInfo}</div>
            </div>
            <div className="col-12  text-smaller text-center">
              For cash payment, collect "signed and stamped receipt" from our
              class-office and upload its image.
            </div>
          </div>

          {!flagLoading && (
            <div className="row justify-content-center">
              {flagImageChange && previewImage && !message && (
                <div className="col-12 text-center mb-3">
                  <LazyLoad>
                    <img
                      className="div-screenshot-image"
                      src={previewImage}
                      alt=""
                    />
                  </LazyLoad>
                </div>
              )}

              {/* row ends */}

              {!flagImageChange && (
                <div className="col-12 text-center ">
                  <button
                    className="btn-mycyan"
                    onClick={handleChangeImageClick}
                  >
                    Upload Screenshot/Image
                  </button>
                  &nbsp;&nbsp;&nbsp;
                  <button
                    className="btn-mycyan"
                    onClick={handleCancelScreenShotClick}
                  >
                    Cancel
                  </button>
                </div>
              )}
              {flagImageChange && !previewImage && (
                <div className="col-12 text-center ">
                  {/*Commented to implement file-size-compression  */}
                  {/* <input
                    type="file"
                    name="file"
                    onChange={fileChangedHandler}
                  /> */}
                  <input
                    // accept="image/*,capture=camera"
                    // capture="”camera"
                    type="file"
                    name="file"
                    onChange={(event) => handleCompressedUpload(event)}
                  />
                </div>
              )}
              {flagImageChange && (
                <div className="col-12 text-center mb-2">
                  <button
                    className="btn-mycyan"
                    onClick={handleCancelChangeImageClick}
                  >
                    Cancel
                  </button>
                </div>
              )}
              {flagImageChange && message && (
                <div className="col-12 text-center text-error">{message}</div>
              )}

              {flagImageChange &&
                previewImage &&
                !message &&
                !flagImageUploaded && (
                  <div className="col-12 text-center ">
                    <button
                      className="btn-mycyan"
                      onClick={handleUploadImageClick}
                    >
                      Submit Screenshot/Image
                    </button>
                  </div>
                )}
            </div>
          )}
        </div>
      )}
      {error && (
        <div className="text-center text-error">
          ... Something went wrong... Try again.{" "}
        </div>
      )}
      {flagLoading && (
        <div>
          <div className="text-center">
            <BeatLoader size={16} color={"blue"} flagLoading />
          </div>
          <div className="text-center text-loading">
            Please wait...Don't refresh the screen.
          </div>
        </div>
      )}
    </>
  );
}
export default StudentPaymentForm;
