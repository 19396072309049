import React, { Component } from "react";
import "./css/login.css";
class AdminLoginForm extends Component {
  errorAdmin = {
    emailId: { message: "" },
    password: { message: "", onlyDigits: false },
  };
  constructor(props) {
    super(props);
    this.state = {
      admin: {
        emailId: "",
        password: "",
      },
      errorAdmin: this.errorAdmin,
      flagFormInvalid: false,
      flagWhatsappChecked: false,
    };
  }
  handleChange = (event) => {
    let { errorAdmin } = this.state;
    let name = event.target.name;
    let message;
    let value = event.target.value;
    //check of max. length
    let mxLen = errorAdmin[`${name}`].mxLen;
    let onlyDigits = errorAdmin[`${name}`].onlyDigits;
    if (value.length > mxLen) {
      value = value.slice(0, mxLen);
      if (!onlyDigits) message = "Maximum " + mxLen + " characters allowed";
      else message = "Maximum " + mxLen + " digits allowed";
    } //if...
    else {
      message = "";
    }
    errorAdmin[`${name}`].message = message;
    this.setState({
      errorAdmin: errorAdmin,
      admin: { ...this.state.admin, [name]: value },
    });
    this.checkAllErrors(errorAdmin);
  };
  handleBlur = (event) => {
    let name,
      value,
      requiredMessage = " is required",
      type,
      message = "",
      mnLen,
      onlyDigits;
    let { errorAdmin } = this.state;
    name = event.target.name;
    type = event.target.type;
    if (type === "text" || type === "textarea") {
      // check if 'required', also check min no. of characters
      value = event.target.value.trim();
      mnLen = errorAdmin[`${name}`].mnLen;
      onlyDigits = errorAdmin[`${name}`].onlyDigits;
      if (value.length === 0) {
        message = name + requiredMessage;
      } else if (value.length < mnLen) {
        if (!onlyDigits) message = "Min " + mnLen + " characters required";
        else message = "Min " + mnLen + " digits required";
      } //else
      else if (onlyDigits) {
        for (let i = value.length - 1; i >= 0; i--) {
          const d = value.charCodeAt(i);
          if (d < 48 || d > 57) {
            message = "Enter only digits";
            break;
          } //if
        } //for
      } //else if... onlyDigits
      else {
        message = "";
      }
    } //if... text || textarea
    errorAdmin[`${name}`].message = message;
    this.setState({ errorAdmin: errorAdmin });
    this.checkAllErrors(errorAdmin);
  };
  handleFocus = (event) => {
    let name = event.target.name;
    let errorAdmin = this.state.errorAdmin;
    errorAdmin[`${name}`].message = "";
    this.setState({ errorAdmin: errorAdmin });
    this.checkAllErrors(errorAdmin);
  };
  checkAllErrors = (errorAdmin) => {
    // let { errorProduct } = this.state;
    let flagFormInvalid = false;
    for (let field in errorAdmin) {
      if (errorAdmin[field].message !== "") {
        // error is there
        flagFormInvalid = true;
        break;
      } //if
    } //for
    this.setState({ flagFormInvalid: flagFormInvalid });
  };
  handleUserLogIn = () => {
    this.props.onUserLogIn();
  };
  handleAdminLogIn = () => {
    this.props.onAdminLogIn();
    // change content in the menubars in the navbar
  };
  handleFormSubmit = (event) => {
    event.preventDefault();
    let data = this.state.admin; //this.state
    this.props.onFormSubmit(data);
  };

  render() {
    let { errorAdmin } = this.state;
    let { admin } = this.state;
    let { flagFormInvalid } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid container-login-form container-md ">
          {/* // row starts */}
          <div className="row">
            <div className="form-heading  column col-12">Admin Login</div>
          </div>
          {/* // row ends */}
          {/* // row starts */}
          <div className="form-login column">
            <form
              className="text-dark bg-mycyan"
              onSubmit={this.handleFormSubmit}
            >
              <div className="text-dark bg-mycyan my-2 px-4 py-4">
                <div className="form-group row column align-items-center px-2">
                  {/* row starts */}
                  <div className="col-5 text-right">
                    <label>User Name</label>
                  </div>
                  <div className="col-7  column px-0">
                    <input
                      type="text"
                      className="form-control"
                      name="emailId"
                      value={admin.emailId}
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                      onFocus={this.handleFocus}
                      placeholder="Enter email id"
                      required
                    />
                  </div>
                  <div className="offset-5">
                    {errorAdmin.emailId.message ? (
                      <span className="error-text">
                        {this.errorAdmin.emailId.message}
                      </span>
                    ) : null}
                  </div>
                </div>
                {/* row ends */}
                {/* row starts */}
                <div className="form-group row column align-items-center">
                  <div className="col-5 text-right">
                    <label>Password</label>
                  </div>
                  <div className="col-7 px-0">
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      value={admin.password}
                      onChange={this.handleChange}
                      placeholder="Enter Password"
                    />
                  </div>
                </div>
                {/* row ends */}
                {/* row starts */}
                <div className="form-group row offset-5 text-center mb-0">
                  <button
                    className="btn-mycyan"
                    type="submit"
                    disabled={flagFormInvalid}
                  >
                    submit
                  </button>
                </div>
                {/* <input type="hidden" name="productId" value={product.productId} /> */}
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default AdminLoginForm;
