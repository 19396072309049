import React, { Component } from "react";
import Program from "./program";
import QuestionAnswer from "./question-answer";
import Tests from "./tests";
import NavBarMenus from "./nav-menus";
import { BeatLoader } from "react-spinners";
class ActualContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
    };
  }
  handlePlayVideoClick = (program, link) => {
    this.props.onPlayVideoClick(program, link);
  };
  handleShowCodeClick = (program) => {
    this.props.onShowCodeClick(program);
  };
  handleShowAnswerClick = (program) => {
    this.props.onShowAnswerClick(program);
  };
  handleDownloadNoClick = () => {
    this.props.onDownloadNoClick();
  };
  handleDownloadYesClick = () => {
    this.props.onDownloadYesClick();
  };
  handleMenuClick = (menu) => {
    this.setState({
      message: "",
    });
    // Check here only, whether  student has taken admission
    //  for this course or not, or is it a Demo
    let { selectedSubjectName } = this.props;
    let { selectedSubjectAvailableFor } = this.props;
    let { isSelectedChapterReady } = this.props;
    let { selectedChapterNumber } = this.props;
    let { selectedSubjectFees } = this.props;

    let message1 =
      `Sorry… This content is available only for registered students.
      Want to join this course (online/offline)? Fees is only Rs. ` +
      selectedSubjectFees +
      `. Contact us at rjacpune@gmail.com 
      or
      WhatsApp at 9422035020`;
    let message2 =
      `Currently, content of following courses can be viewed (without login).
      1. Programming (C, C++, Python)
      2. OOP using C++ and Python
      3. Data Structures
      Want to join the course - ` +
      selectedSubjectName +
      `?\nContact us at rjacpune@gmail.com 
      or WhatsApp at 9422035020`;
    let message5 = "Work in Progress!";
    let message6 =
      "Sorry, content can be viewed only by the students who have joined this course.";
    let { visitorInfo } = this.props;
    let message;
    if (selectedSubjectAvailableFor == "All") {
      // subject available for All
      if (visitorInfo.status == "Guest") {
        if (!menu.availableForGuest) {
          // For Guest - Tests, Interview Questions, downloads not allowed
          message = message1;
        } else if (!isSelectedChapterReady) {
          message = message1;
        } else {
          message = "";
        }
      } // end of Guest
      else if (visitorInfo.admissionStatus == "Guest") {
        message = message6;
      } else if (visitorInfo.admissionStatus == "Admitted") {
        if (!isSelectedChapterReady) {
          message = message5;
        } else if (!menu.isReady) {
          message = message5;
        }
        // for Tests (category2),  interview questions (category2), pdf download(category3), check whether such chapter is allowed or not
        else if (menu.category == "category2" || menu.category == "category3") {
          if (selectedChapterNumber > visitorInfo.chapterLimit) {
            message = "Please request Admin to access data of this chapter";
            // message =
            //   "You have not completed chapter no. " +
            //   visitorInfo.chapterLimit +
            //   " yet.";
          }
        }
      } //end of Admitted
    } // end of "All"
    else if (selectedSubjectAvailableFor == "Nobody") {
      if (visitorInfo.status == "Guest") {
        message = message2;
      } // end of Guest
      else if (
        visitorInfo.admissionStatus == "Guest" // logged in but guest for the subject
      ) {
        message = message6;
      } // logged in but guest for the subject
    } //end of "Nobody"
    else {
      // subject NOT available for Guest
      if (visitorInfo.status == "Guest") {
        // Guest
        message = message2;
      } else if (
        visitorInfo.admissionStatus == "Admitted" &&
        !isSelectedChapterReady
      ) {
        // logged in but guest for the subject
        // Guest
        message = message6;
      } else if (visitorInfo.admissionStatus == "Guest") {
        // logged in but guest for the subject
        // Guest
        message = message6;
      }
    }
    if (message) {
      this.setState({
        message: message,
      });
    }
    this.props.onMenuClick(menu, message);
  };
  sh_Program = () => {
    let { message } = this.state;
    let { flagLoadingChapterContent } = this.props;
    let { selectedChapterId } = this.props;
    let { selectedMenu } = this.props;

    let { isSelectedChapterReady } = this.props;
    if (
      !message &&
      !flagLoadingChapterContent &&
      selectedChapterId != -1 &&
      selectedMenu &&
      selectedMenu.category == "category1" &&
      selectedMenu.isReady &&
      isSelectedChapterReady
    )
      return true;
    return false;
  };
  sh_QA = () => {
    let { message } = this.state;
    let { flagLoadingChapterContent } = this.props;
    let { selectedChapterId } = this.props;
    let { selectedMenu } = this.props;
    let { isSelectedChapterReady } = this.props;
    if (
      !message &&
      !flagLoadingChapterContent &&
      selectedChapterId != -1 &&
      selectedMenu &&
      selectedMenu.isReady &&
      selectedMenu.name == "questionanswer" &&
      isSelectedChapterReady
    )
      return true;
    return false;
  };
  sh_Test = () => {
    let { message } = this.state;
    let { flagLoadingChapterContent } = this.props;
    let { selectedChapterId } = this.props;
    let { selectedMenu } = this.props;
    let { isSelectedChapterReady } = this.props;
    if (
      !message &&
      !flagLoadingChapterContent &&
      selectedChapterId != -1 &&
      selectedMenu &&
      selectedMenu.isReady &&
      selectedMenu.name == "test" &&
      isSelectedChapterReady
    )
      return true;
    return false;
  };
  sh_Menus = () => {
    let { selectedChapterId } = this.props;
    let { selectedSubjectId } = this.props;
    if (selectedSubjectId != -1 && selectedChapterId != -1) return true;
    return false;
  };
  sh_info = () => {
    let info, duration;
    let { programList } = this.props;
    let { message } = this.state;
    let { selectedMenu } = this.props;
    let { videoDuration } = this.props;
    let { testCount } = this.props;
    let { selectedChapterNumber } = this.props;
    let { flagLoadingChapterContent } = this.props;

    if (
      message ||
      !selectedMenu ||
      !selectedMenu.isReady ||
      flagLoadingChapterContent
    ) {
      info = "";
    } else if (selectedMenu.category == "category1") {
      // programs, videos
      if (selectedMenu.showLength) {
        //videos
        let hr = Math.floor(videoDuration / (60 * 60));
        let x = videoDuration % (60 * 60);
        let min = Math.floor(x / 60);
        let sec = x % 60;
        if (hr >= 0 && hr < 10) hr = "0" + hr;
        if (min >= 0 && min < 10) min = "0" + min;
        if (sec >= 0 && sec < 10) sec = "0" + sec;
        duration = hr + " h " + min + " m";
        if (selectedMenu.name == "video")
          info =
            programList.length + " Lectures (Total length- " + duration + ")";
        else
          info =
            programList.length + " Videos (Total length- " + duration + ")";
      } else {
        //programs
        info = "Total " + selectedMenu.display + "-" + programList.length;
      }
    } else if (selectedMenu.name == "questionanswer") {
      info = "Total " + programList.length + " questions ";
    } else if (selectedMenu.name == "test") {
      info = "Total " + testCount + " Tests ";
    } else if (selectedMenu.name == "download") {
      info =
        "Want to Download pdf file containing source code of programs of chapter No. " +
        selectedChapterNumber +
        " ?";
    }
    return info;
  };
  getContentFromDatabase = () => {
    let { selectedChapterId } = this.props;
    let { selectedMenu } = this.props;
    this.props.getContentFromDatabase(selectedChapterId, selectedMenu);
  };
  render() {
    let { programList } = this.props;
    let { testList } = this.props;
    let { testCount } = this.props;
    let { menuList } = this.props;
    let { student } = this.props;
    let { message } = this.state;

    let { selectedSubject } = this.props;
    let { selectedSubjectId } = this.props;
    let { selectedChapterId } = this.props;
    let { flagLoadingChapterContent } = this.props;
    let { filePrefixWOExtension } = this.props;
    let { selectedSubjectFilePrefix } = this.props;
    let { selectedChapterNumber } = this.props;
    let { selectedSubjectName } = this.props;
    let { selectedSubjectAvailableFor } = this.props;
    let { selectedSubjectFees } = this.props;

    let { selectedSubjectIncludesAssignments } = this.props;

    let { selectedMenu } = this.props;
    let { isSelectedChapterReady } = this.props;
    let { visitorInfo } = this.props;
    let info;
    info = this.sh_info();
    return (
      <React.Fragment>
        {this.sh_Menus() && (
          <NavBarMenus
            selectedChapterId={selectedChapterId}
            selectedSubjectId={selectedSubjectId}
            selectedChapterNumber={selectedChapterNumber}
            flagLoadingChapterContent={flagLoadingChapterContent}
            selectedMenu={selectedMenu}
            menuList={menuList}
            isSelectedChapterReady={isSelectedChapterReady}
            onMenuClick={this.handleMenuClick}
          />
        )}
        {info && !flagLoadingChapterContent && isSelectedChapterReady && (
          <div className="div-info col-12 mt-2 text-darkcolor ">{info}</div>
        )}
        {flagLoadingChapterContent && (
          <div className="text-center mt-5">
            <BeatLoader size={16} color={"blue"} flagLoadingChapterContent />
          </div>
        )}
        {info && selectedMenu.name == "download" && (
          <div className="container-yesno-buttons text-center">
            <div className="row  ">
              <div className="col-6">
                <button
                  to="#"
                  onClick={this.handleDownloadYesClick}
                  className=" btn-block bg-darkcolor text-lightcolor no-border"
                >
                  Yes
                </button>
              </div>
              <div className="col-6">
                <button
                  to="#"
                  onClick={this.handleDownloadNoClick}
                  className="btn-block bg-darkcolor text-lightcolor no-border"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        )}

        {this.sh_Program() && (
          <div>
            {programList.map((program, index) => (
              <Program
                key={index + 1}
                program={program}
                onPlayVideoClick={this.handlePlayVideoClick}
                onShowCodeClick={this.handleShowCodeClick}
                filePrefixWOExtension={filePrefixWOExtension}
                selectedChapterNumber={selectedChapterNumber}
                selectedSubjectName={selectedSubjectName}
                selectedSubjectAvailableFor={selectedSubjectAvailableFor}
                visitorInfo={visitorInfo}
                selectedMenu={selectedMenu}
                selectedSubject={selectedSubject}
                selectedSubjectFees={selectedSubjectFees}
                selectedSubjectFilePrefix={selectedSubjectFilePrefix}
              />
            ))}
          </div>
        )}
        {this.sh_QA() && (
          <div>
            {programList.map((program, index) => (
              <QuestionAnswer
                key={index + 1}
                program={program}
                onShowAnswerClick={this.handleShowAnswerClick}
                visitorInfo={visitorInfo}
                selectedChapterNumber={selectedChapterNumber}
                selectedSubjectName={selectedSubjectName}
                selectedSubjectAvailableFor={selectedSubjectAvailableFor}
              />
            ))}
          </div>
        )}
        {this.sh_Test() && (
          <div>
            <Tests
              testList={testList}
              testCount={testCount}
              selectedSubjectFilePrefix={selectedSubjectFilePrefix}
              selectedChapterNumber={selectedChapterNumber}
              selectedChapterId={selectedChapterId}
              student={student}
              getContentFromDatabase={this.getContentFromDatabase}
            />
          </div>
        )}
        {message && selectedMenu && (
          <div className="text-center mt-2">{message}</div>
        )}
        {/* row-ends */}
      </React.Fragment>
    );
  }
}
export default ActualContent;
