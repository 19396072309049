import React from "react";
import { Link } from "react-router-dom";
import AdminProgramListSingle from "./admin-program-list-single";

function AdminProgramList(props) {
  function handleEditProgram(program) {
    props.onEditProgram(program);
  }
  function handleCrudAction(message, action) {
    props.onCrudAction(message, action);
  }
  function handleDeleteProgram(program) {
    props.onDeleteProgram(program);
  }
  function handleSearchTextBoxKeyUp(event) {
    props.onSearchTextBoxKeyUp(event);
  }
  function handleSearchTextChange(event) {
    props.onSearchTextChange(event);
  }
  function handleHeaderClick(event) {
    props.onHeaderClick(event);
  }
  function handleSectionNumberClick(event) {
    props.onSectionNumberClick(event);
  }
  let { selectedChapter } = props;
  let { filteredPrograms } = props;
  let { currentSize } = props;
  let { programType } = props;
  let { accessLevel } = props;
  let { adminType } = props;

  let contentNoprograms = <div className="text-center">No Programs !</div>;
  // prepare options
  return (
    <>
      {/* row ends */}
      {selectedChapter && filteredPrograms.length != 0 && (
        <>
          <div className="row p-0 justify-content-center ">
            <div className="col-2 col-md-1">
              <Link to="#" onClick={handleHeaderClick} id="programNumber">
                Sn
              </Link>
            </div>
            <div className="col-8 col-md-4">
              <Link to="#" onClick={handleHeaderClick} id="description">
                Description
              </Link>
            </div>
            <div className="col-2">&nbsp;</div>
          </div>
          {filteredPrograms.map((program, index) => (
            <AdminProgramListSingle
              program={program}
              key={index}
              index={index}
              programType={programType}
              onDeleteProgram={handleDeleteProgram}
              onEditProgram={handleEditProgram}
              onCrudAction={handleCrudAction}
              currentSize={currentSize}
              accessLevel={accessLevel}
              adminType={adminType}
            />
          ))}
        </>
      )}
    </>
  );
  // if (filteredPrograms.length >= 0) {
  //   return <div className="">{content}</div>;
  // } //else
  // else if (filteredPrograms.length == 0) {
  //   return contentNoprograms;
  // } //else
}
export default AdminProgramList;
