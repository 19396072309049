export class CoursebundleDB {
  constructor(coursebundles) {
    this.coursebundles = coursebundles;
  }
  allCoursebundles() {
    return this.coursebundles;
  }
  searchByCoursebundleId(id) {
    for (let i = 0; i < this.coursebundles.length; i++) {
      if (this.coursebundles[i].coursebundleId == id) {
        return this.coursebundles[i];
      }
    }
    return null; // not found
  }
  filterByName(query) {
    let filteredCoursebundles = [];
    for (let i = 0; i < this.coursebundles.length; i++) {
      if (this.coursebundles[i].name.toLowerCase().includes(query.toLowerCase())) {
        filteredCoursebundles.push(this.coursebundles[i]);
      }
    } //for
    return filteredCoursebundles;
  }
}
export default CoursebundleDB;
