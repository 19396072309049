import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import axios from "axios";
function AdminPaymentListSingle(props) {
  let [flagLoading, setFlagLoading] = useState(false);
  let [error, setError] = useState("");

  function handleEditPayment(payment) {
    props.onEditPayment(payment);
  }
  function handleDeletePayment(payment) {
    // Now call for delete api
    setFlagLoading(true);
    axios
      .delete(window.routerPrefix + "/payment/delete/" + payment.paymentId)
      .then((res) => {
        setFlagLoading(false);
        if (res.data) {
          props.onDeletePayment(payment);
        } else {
          //Error, Something went wrong
        }
      })
      .catch((err) => {
        setFlagLoading(false);
        setError(err);
      });
  }
  let { payment } = props;
  let { index } = props;
  let { accessLevel } = props;
  let { adminType } = props;
  return (
    <div className="row myborder-top justify-content-center mb-2">
      <div className="col-2 col-md-1">{index + 1}.</div>
      <div className="col-6 col-md-4 ">{payment.name}</div>
      <div className="col-6 col-md-4 ">{payment.courseName} - {payment.isVerified ? "Yes" : "No"}</div>
      <div className="col-2 col-md-1">
        {adminType >= accessLevel && (
          <Link to="#" onClick={() => handleEditPayment(payment)}>
            <i className="fas fa-edit admin-icons"></i>
          </Link>
        )}{" "}
      </div>
      <div className="col-2 col-md-1">
        {!flagLoading && adminType >= accessLevel && (
          <Link
            to="#"
            onClick={() => {
              if (
                window.confirm(
                  "You really want to delete Payment - " + payment.name + " ?"
                )
              )
                handleDeletePayment(payment);
            }}
          >
            <i className="far fa-trash-alt admin-icons"></i>
          </Link>
        )}
        {flagLoading && <BeatLoader size={8} color={"blue"} flagLoading />}
      </div>
    </div>
  );
}
export default AdminPaymentListSingle;
