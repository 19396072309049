import React, { Component } from "react";
import { Link } from "react-router-dom";
import AdminBatchListSingle from "./admin-batch-list-single";

class AdminBatchList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      response: {},
    };
  }
  handleEditBatch = (batch) => {
    this.props.onEditBatch(batch);
  };
  handleShowChapters = (batch) => {
    this.props.onShowChapters(batch);
  };
  handleCrudAction = (message, action) => {
    this.props.onCrudAction(message, action);
  };
  handleDuplicateBatch = (batch, selection) => {
    this.props.onDuplicateBatch(batch, selection);
  };
  handleDeleteBatch = (batch) => {
    this.props.onDeleteBatch(batch);
  };
  handleSearchTextBoxKeyUp = (event) => {
    this.props.onSearchTextBoxKeyUp(event);
  };
  handleSearchTextChange = (event) => {
    this.props.onSearchTextChange(event);
  };
  handleHeaderClick = (event) => {
    this.props.onHeaderClick(event);
  };
  handleSectionNumberClick = (event) => {
    this.props.onSectionNumberClick(event);
  };
  render() {
    let { error } = this.state;
    let { filteredBatches } = this.props;
    let { searchText } = this.props;
    let { currentSize } = this.props;
    let contentNobatches = <div className="text-center">No batches !</div>;
    let content = (
      <React.Fragment>
        <div className="form-heading column col-12">
          LIST OF batches ({filteredBatches.length})
        </div>
        {/* row starts */}
        <div className="row justify-content-center column">
          <div className="col-6 my-2 ">
            <input
              type="search"
              className="container-fluid form-control"
              value={searchText}
              onKeyUp={this.handleSearchTextBoxKeyUp}
              onChange={this.handleSearchTextChange} // This is contolled by parent
              placeholder="Search batches"
              id=""
            />
          </div>
        </div>
        {/* row ends */}
        <div className="row p-0 ">
          <div className="col-12 justify-content-center">
            <div className="table-responsive">
              <table className="table mx-auto w-auto table-sm table-hover table-borderless bigger-text">
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      <Link to="#" onClick={this.handleHeaderClick} id="name">
                        Name
                      </Link>
                    </th>
                  </tr>
                </thead>
                {filteredBatches.map((batch, index) => (
                  <tbody key={index}>
                    <AdminBatchListSingle
                      batch={batch}
                      index={index}
                      onDeleteBatch={this.handleDeleteBatch}
                      onDuplicateBatch={this.handleDuplicateBatch}
                      onEditBatch={this.handleEditBatch}
                      onCrudAction={this.handleCrudAction}
                      currentSize={currentSize}
                    />
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
    if (error) {
      return <div>Error retriving batches </div>;
    } else if (filteredBatches.length >= 0) {
      return (
        // <div className="container-fluid bg-blue">
        // <div className="row">
        <div className="">{content}</div>
        // </div>
        // </div>
      );
    } //else
    else if (filteredBatches.length == 0) {
      return contentNobatches;
    } //else
  }
}
export default AdminBatchList;
