import React, { useEffect, useState } from "react";
import AdminSubjectList from "./admin-subject-list";
import AdminSubjectAdd from "./admin-subject-add";
import { SubjectDB } from "../SubjectDB";
import { BeatLoader } from "react-spinners";
import axios from "axios";

function AdminSubject(props) {
  let [flagLoading, setFlagLoading] = useState(false);
  let [error, setError] = useState("");
  let [searchText, setSearchText] = useState("");
  let [direction, setDirection] = useState("");
  let [sortedField, setSortedField] = useState("");
  let [sectionNumber, setSectionNumber] = useState(1);
  let [filteredSubjects, setFilteredSubjects] = useState([]);
  let [subjectList, setSubjectList] = useState([]);
  let [subjectdb, setSubjectdb] = useState({});
  let [subject, setSubject] = useState(null);
  // constructor(props) {
  //   super(props);
  //   state = {
  //     error: null,
  //     response: {},
  //     subject: null,
  //     filteredSubjects: [],
  //     subjectList: [],
  //     subjectdb: {},
  //     flagLoading: false,
  //     searchText: "",
  //     sortedField: "",
  //     sortedDirection: false,
  //     sectionNumber: 1,
  //   };
  //   onFormSubmit = onFormSubmit.bind(this);
  // }
  function getSubjectListFromDatabase() {
    setFlagLoading(true);
    axios
      .all([axios.get(window.routerPrefix + "/subject/subjects")])
      .then(
        axios.spread((res1) => {
          let subjectList = res1.data;
          let updatedSubjects = [...subjectList];

          subjectList = updatedSubjects;

          setFlagLoading(false);
          // filteredSubjects: subjectList,
          // subjectList: subjectList,
          setFilteredSubjects(subjectList);
          setSubjectList(subjectList);
          setSubjectdb(new SubjectDB(subjectList));
        })
      )
      .catch((err) => {
        console.log(err);
        setError(err);
        setFlagLoading(false);
      });
  }
  useEffect(() => {
    init();
  }, []);
  function init() {
    setFlagLoading(true);
    axios
      .all([axios.get(window.routerPrefix + "/subject/subjects/")])
      .then(
        axios.spread((res1) => {
          let subjectList = res1.data;
          let updatedSubjects = [...subjectList];
          subjectList = updatedSubjects;
          setFlagLoading(false);
          // filteredSubjects: subjectList,
          setFilteredSubjects(subjectList);
          setSubjectList(subjectList);
          setSubjectdb(new SubjectDB(subjectList));
        })
      )
      .catch((err) => {
        setError(err);
        setFlagLoading(false);
      });
  }
  function handleSearchTextBoxKeyUp(event) {
    let searchText = event.target.value;
    setSearchText(searchText);
    performSearchOperation(searchText);
  }
  function handleSearchTextChange(event) {
    let searchText = event.target.value;
    setSearchText(searchText);
    performSearchOperation(searchText);
  }
  function performSearchOperation(searchText) {
    let query = searchText.trim();
    let searchedSubjects = [];
    searchedSubjects = subjectdb.filterByName(query);
    setFilteredSubjects(searchedSubjects);
  }

  function onFormSubmit(subject) {
    let { action } = props;
    setFlagLoading(true);
    if (action === "UPDATE") {
      //EDIT / PUT
      axios
        .put(window.routerPrefix + "/subject/update", subject)
        .then((res) => {
          if (res.data) {
            //success
            updateSubjectList(subject);
          } else {
            //error
            //something went wrong
          }
          setFlagLoading(false);
        })
        .catch((err) => {
          setError(err);
          setFlagLoading(false);
        });
    } else if (action === "ADD") {
      axios
        .post(window.routerPrefix + "/subject/addSubject", subject, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
          },
        })
        .then((res) => {
          setFlagLoading(false);
          subject.subjectId = res.data.subjectId; // id of the added subject is returned as response
          addToSubjectList(subject);
        })
        .catch((err) => {
          setError(err);
          setFlagLoading(false);
        });
    } //else
  }
  function handleEditSubject(subject) {
    setSubject(subject);
    // setState({
    //   subject: subject,
    // });
    props.onEditClick();
  }
  // handleShowChapters = (subject) => {
  //   props.onShowChapters(subject);
  // };
  function addToSubjectList(subject) {
    let sList = [...subjectList];
    sList.unshift(subject);
    let fSubjects = [...filteredSubjects];
    fSubjects.unshift(subject);
    setFilteredSubjects(fSubjects);
    setSubjectList(sList);
    let message = "The subject '" + subject.name + "' added successfully.";
    props.onCrudAction(message, "ADD");
  }
  function updateSubjectList(subject) {
    let sList = [...subjectList];
    for (let i = 0; i < sList.length; i++) {
      if (sList[i].subjectId == subject.subjectId) {
        sList.splice(i, 1); // remove 1 element from position i
        // and insert at the beginning as we want latest modified at the top
        sList.unshift(subject);
      }
    } //for
    // remove from filteredchapters also
    let fSubjects = [...filteredSubjects];
    for (let i = 0; i < fSubjects.length; i++) {
      if (fSubjects[i].subjectId == subject.subjectId) {
        fSubjects.splice(i, 1); // remove 1 element from position i
        // and insert at the beginning as we want latest modified at the top
        fSubjects.unshift(subject);
      }
    } //for
    //sorting
    setSubjectList(sList);
    setFilteredSubjects(fSubjects);
    let message = "The subject '" + subject.name + "' updated successfully.";
    props.onCrudAction(message, "LIST", "Add a Subject");
  }

  function handleDeleteSubject(subject) {
    let sList = [...subjectList];
    for (let i = 0; i < sList.length; i++) {
      if (sList[i].subjectId == subject.subjectId) {
        sList.splice(i, 1); // remove 1 element from position i
      }
    } //for
    // remove from filteredsubjects also
    let fSubjects = [...filteredSubjects];
    for (let i = 0; i < fSubjects.length; i++) {
      if (fSubjects[i].subjectId == subject.subjectId) {
        fSubjects.splice(i, 1); // remove 1 element from position i
      }
    } //for
    setSubjectList(sList);
    setFilteredSubjects(fSubjects);
    setSubjectdb(new SubjectDB(sList));
    let message = "The subject '" + subject.name + "' deleted successfully.";
    props.onCrudAction(message, "LIST");
  }
  function handleHeaderClick(event) {
    let field = event.target.id;
    let d = false;
    if (field === sortedField) {
      // same button clicked twice
      d = !direction;
    } else {
      // different field
      d = false;
    }
    setDirection(d);
    if (d == false) {
      //in ascending order
      filteredSubjects = filteredSubjects.sort((a, b) => {
        if (a[field] > b[field]) {
          return 1;
        }
        if (a[field] < b[field]) {
          return -1;
        }
        return 0;
      });
    } else {
      //in descending order
      filteredSubjects = filteredSubjects.sort((a, b) => {
        if (a[field] < b[field]) {
          return 1;
        }
        if (a[field] > b[field]) {
          return -1;
        }
        return 0;
      });
    }
    setFilteredSubjects(filteredSubjects);
    setSortedField(field);
  }
  function handleSectionNumberClick(event) {
    setSectionNumber(event.target.id);
  }
  let { action } = props;
  let { accessLevel } = props;
  let { adminType } = props;

  let content;
  return (
    <div className="container-fluid container-md container-content-page p-0">
      {flagLoading && (
        <div className="text-center">
          <BeatLoader size={16} color={"blue"} flagLoading />
        </div>
      )}
      {!flagLoading && action === "LIST" && (
        <AdminSubjectList
          onEditSubject={handleEditSubject}
          onDeleteSubject={handleDeleteSubject}
          filteredSubjects={filteredSubjects}
          searchText={searchText}
          onSearchTextChange={handleSearchTextChange}
          onSearchTextBoxKeyUp={handleSearchTextBoxKeyUp}
          onHeaderClick={handleHeaderClick}
          onSectionNumberClick={handleSectionNumberClick}
          sectionNumber={sectionNumber}
          accessLevel={accessLevel}
          adminType={adminType}
        />
      )}
      {!flagLoading && (action === "ADD" || action === "UPDATE") && (
        <div>
          {/* content={" "} */}
          <AdminSubjectAdd
            onFormSubmit={onFormSubmit}
            subject={subject}
            action={action}
          />
        </div>
      )}
      {action === "DELETE" && <div>Delete action in Progress...</div>}
    </div>
  );
}
export default AdminSubject;
