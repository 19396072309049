import React, { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import { Link } from "react-router-dom";
import axios from "axios";
import StudentDemoRequest from "./student-demo-request";
import StudentRegistration from "./student-registration";
import StudentWhatsappCheck from "./student-whatsapp-check";
import StudentEmailCheck from "./student-email-check";
import StudentJoinCourseForm from "./student-join-course-form";
import StudentPaymentForm from "./student-payment-form";
/*
Documentation:
1. Student registering through reference (....../joinus/.....)
2. Student registering without reference ( at class/ some workshop/through book advt.)
(......./joinus)
3.  
*/
function StudentJoinClass(props) {
  let emptyEnquiredStudent;
  let [validEnquiredStudent, setValidEnquiredStudent] = useState(false);
  let [joiningStudentData, setJoiningStudentData] = useState({});

  let [flagCourseSelected, setFlagCourseSelected] = useState(false);
  let [flagAdmissionBeingDone, setFlagAdmissionBeingDone] = useState(false);
  let [flagAdmissionDone, setFlagAdmissionDone] = useState(false);

  let [selectedCourse, setSelectedCourse] = useState({});

  let [action, setAction] = useState("home");
  let [flagLoading, setFlagLoading] = useState(false);
  let [message, setMessage] = useState("");
  let [error, setError] = useState("");
  let [flagFormInvalid, setFlagFormInvalid] = useState(false);
  let [flagReferral, setFlagReferral] = useState(false);
  let [referredEmailId, setReferredEmailId] = useState("");
  let [referredByStudentId, setReferredByStudentId] = useState("");
  let [referredBy, setReferredBy] = useState("");
  let [referredByName, setReferredByName] = useState("");

  let [flagLinkOk, setFlagLinkOk] = useState(true);

  useEffect(() => {
    window.scroll(0, 0);
    init();
  }, []);
  function init() {
    // console.log(props.location.pathname.substring(48)); //
    let path = props.location.pathname;
    let p = path.indexOf("/", 1);
    // let ss=
    // let len = path.length;
    // console.log(path);
    setFlagLoading(true);
    axios
      .get("/" + window.routerPrefix + "/enquiredStudent/welcome")
      .then((res) => {
        setFlagLoading(false);
      })
      .catch((err) => {
        setFlagLoading(false);
      });

    if (p == -1) {
      // no extra information along with this route
      // just give session and return;
      return;
    }

    //might be click by referred student, added on 20.11.2023
    let pos = path.indexOf("_");
    let referredByStudentId = path.substring(p + 1, pos);
    let referredEmailId = path.substring(pos + 1);
    setReferredEmailId(referredEmailId);
    setReferredByStudentId(referredByStudentId);
    setFlagReferral(true);
    setFlagLoading(true);
    // check whether link is correct or not
    axios
      .get(
        "/" +
          window.routerPrefix +
          "/referral/referralByReferredByStudentId/" +
          referredByStudentId +
          "?emailId=" +
          referredEmailId
      )
      .then((res) => {
        let referral = res.data;
        setFlagLoading(false);
        if (!referral) {
          setFlagLinkOk(false);
          setMessage("Link is invalid");
        } else if (referral.referralStatus == "Registered") {
          setFlagLinkOk(false);
          setMessage("Already registered");
        } else {
          // Now get name of the student who referred this student
          setFlagLoading(true);
          axios
            .get(
              "/" +
                window.routerPrefix +
                "/student/studentById/" +
                referredByStudentId
            )
            .then((res) => {
              let referredByName = res.data.fullName;
              setReferredByName(referredByName);
              setReferredBy(referredBy);
              setFlagLinkOk(true);
              setMessage("");
              setAction("register");
              setFlagLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setError(err);
              setFlagLoading(false);
            });
        }
      })
      .catch((err) => {
        setFlagLoading(false);
      });
  }
  //
  function handleCancelScreenShotClick() {
    setValidEnquiredStudent(false);
    setFlagCourseSelected(false);
    setMessage("");
  }
  function handleCourseSelectData(course) {
    setSelectedCourse(course);
  }
  function handleJoiningStudentData(joiningStudentData) {
    setJoiningStudentData(joiningStudentData);
  }
  function handleActionSelection(value) {
    // setProgram({ ...program, availableForDemo: value });
  }
  function handleBackButtonClick() {
    setAction("home");
    clearAll();
  }
  function clearAll() {
    setMessage("");
    // setJoiningStudentData({});
    setFlagCourseSelected(false);
    setFlagAdmissionDone(false);
    setFlagAdmissionBeingDone(false);
  }
  function handleCancelFormClick() {
    //   setValidEnquiredStudent(false);
    //   setFlagCourseSelected(false);
    //   setMessage("");
  }
  function handleAdmissionDone() {
    setFlagAdmissionDone(true);
  }
  function handleAdmissionBeingDone() {
    setFlagAdmissionBeingDone(true);
  }

  let fullName =
    joiningStudentData.firstName +
    " " +
    joiningStudentData.middleName +
    " " +
    joiningStudentData.surName;

  return (
    <div className="container-fluid container-md container-certificate-page">
      <div className="row justify-content-center">
        <div className="col-6 col-md-4 text-center mb-2 ">
          <img
            src="/images/rjac_logo.jpg"
            alt="No logo"
            className="img-fluid "
            // onClick={this.handleLogoClick}
          />
        </div>
        <div className="col-12 text-center">
          To know more about us, click &nbsp;&nbsp;
          <Link to={"/"}>here</Link>
        </div>
      </div>
      {action != "home" && !referredByName && (
        <div className="col-12 text-center mt-3">
          <Link to="#" onClick={handleBackButtonClick}>
            Back
          </Link>
        </div>
      )}
      {flagLoading && (
        <div className="text-center mt-3">
          <BeatLoader size={16} color={"blue"} flagLoading />
        </div>
      )}
      {action == "home" && !flagReferral && (
        <div className="col-12 text-center text-thick mt-3">
          What do you want?
        </div>
      )}
      {/* row starts */}
      {action == "home" && !flagReferral && (
        <div className="form-group row justify-content-center align-items-center">
          <div className="col-8 col-md-4 px-0 ">
            <div
              className="div-center my-1"
              onClick={() => {
                setAction("register");
                clearAll();
              }}
              onChange={() => {
                setAction("register");
                clearAll();
              }}
            >
              <input
                type="radio"
                className="form-control-inline"
                name="action"
                value="register"
                onClick={() => {
                  setAction("register");
                  clearAll();
                }}
                onChange={() => {
                  setAction("register");
                  clearAll();
                }}
                checked={action == "register"}
              />
              &nbsp; Registration
            </div>
          </div>

          {/* <div className="col-8 col-md-4 px-0 ">
            <div
              className="div-center my-1"
              onClick={() => {
                setAction("demo");
                clearAll();
              }}
              onChange={() => {
                setAction("demo");
                clearAll();
              }}
            >
              <input
                type="radio"
                className="form-control-inline"
                name="action"
                value="demo"
                // onClick={() => setAction("demo")}
                // onChange={() => setAction("demo")}

                checked={action == "demo"}
              />
              &nbsp; Attend Demo Class
            </div>
          </div> */}

          <div className="col-8 col-md-4 px-0  ">
            <div
              className="div-center my-1"
              onClick={() => {
                setAction("join");
                setMessage("");
              }}
              onChange={() => {
                setAction("join");
                setMessage("");
              }}
            >
              <input
                type="radio"
                className="form-control-inline"
                name="action"
                value="join"
                onClick={() => {
                  setAction("join");
                  setMessage("");
                }}
                onChange={() => {
                  setAction("join");
                  setMessage("");
                }}
                checked={action == "join"}
              />
              &nbsp;Join a Course/Bootcamp
            </div>
          </div>
          {/* <div className="col-8 col-md-4 px-0  ">
            <div
              className="div-center my-1"
              onClick={() => {
                setAction("bootcamp");
                setMessage("");
              }}
              onChange={() => {
                setAction("bootcamp");
                setMessage("");
              }}
            >
              <input
                type="radio"
                className="form-control-inline"
                name="action"
                value="bootcamp"
                onClick={() => {
                  setAction("bootcamp");
                  setMessage("");
                }}
                onChange={() => {
                  setAction("bootcamp");
                  setMessage("");
                }}
                checked={action == "bootcamp"}
              />
              &nbsp;Attend a Bootcamp
            </div>
          </div> */}
        </div>
      )}

      {message && <div className="text-center text-error">{message}</div>}
      {validEnquiredStudent && fullName && action == "join" && (
        <div className="text-center text-thick mt-2">Hello {fullName}!</div>
      )}
      {flagCourseSelected && action == "join" && (
        <>
          {selectedCourse.courseType <= 1 && (
            <div className="text-center text-thick mt-2">
              Want to join course of {selectedCourse.name}?
            </div>
          )}
          {selectedCourse.courseType == 2 && (
            <div className="text-center text-thick mt-2">
              Want to join "{selectedCourse.name}" Bootcamp
            </div>
          )}
          {!flagAdmissionDone && (
            <div className="text-center text-thick mt-2">
              Its fees is Rs. {selectedCourse.fees}/-
            </div>
          )}
          {!flagAdmissionDone &&
            joiningStudentData.referredBy &&
            selectedCourse.referralDiscount != 0 && (
              <div className="text-center text-thick mt-2">
                Discount of Rs. {selectedCourse.referralDiscount}/- is
                applicable to you.
              </div>
            )}
          {!flagAdmissionDone &&
            joiningStudentData.referredBy &&
            selectedCourse.referralDiscount != 0 && (
              <div className="text-center text-thick mt-2">
                Please pay Rs.{" "}
                {selectedCourse.fees - selectedCourse.referralDiscount}/-
              </div>
            )}
          {!flagAdmissionDone &&
            joiningStudentData.referredBy &&
            selectedCourse.referralDiscount == 0 && (
              <div className="text-center text-thick mt-2">
                Please pay Rs. {selectedCourse.installment}/-
                {selectedCourse.fees != selectedCourse.installment &&
                  " as first installment."}
              </div>
            )}
          {!flagAdmissionDone && !joiningStudentData.referredBy && (
            <div className="text-center text-thick mt-2">
              Please pay Rs. {selectedCourse.installment}/-
              {selectedCourse.fees != selectedCourse.installment &&
                " as first installment."}
            </div>
          )}
          {/* {!flagAdmissionDone && !joiningStudentData.referredBy && (
            <div className="text-center text-thick mt-2">
              Please pay Rs. {selectedCourse.installment}/-
              {selectedCourse.isBundle == 1 && " as first installment."}
            </div>
          )} */}
        </>
      )}
      {flagAdmissionDone && action == "join" && (
        <>
          <div className="text-center text-thick mt-2">
            Your payment-proof is uploaded successfully. It will be verified
            within (max.) 24 hrs and will be communicated to you through
            WhatsApp.
          </div>
          <div className="text-center text-thick mt-2"></div>
          <div className="text-center text-thick mt-2">
            Password is sent to your email account.
          </div>
          <div className="text-center text-thick mt-2">Thanks!</div>
        </>
      )}
      {action == "register" && setFlagLinkOk && (
        <StudentRegistration
          onSetMessage={setMessage}
          flagReferral={flagReferral}
          referredEmailId={referredEmailId}
          referredByStudentId={referredByStudentId}
          referredBy={referredBy}
          referredByName={referredByName}
        />
      )}
      {action == "demo" && <StudentDemoRequest onSetMessage={setMessage} />}
      {/* {(action == "join" || action == "demo") && !validEnquiredStudent && (
        <StudentWhatsappCheck
          onSetMessage={setMessage}
          onSetValidEnquiredStudent={setValidEnquiredStudent}
          onSetJoiningStudentData={handleJoiningStudentData}
        />
      )} */}
      {action == "join" && !validEnquiredStudent && (
        <StudentEmailCheck
          onSetMessage={setMessage}
          onSetValidEnquiredStudent={setValidEnquiredStudent}
          onSetJoiningStudentData={handleJoiningStudentData}
        />
      )}
      {validEnquiredStudent &&
        !flagCourseSelected &&
        !flagAdmissionDone &&
        action == "join" && (
          <StudentJoinCourseForm
            onSetMessage={setMessage}
            joiningStudentData={joiningStudentData}
            onJoiningStudentData={setJoiningStudentData}
            onCourseSelectData={setSelectedCourse}
            // onCancelFormClick={handleCancelFormClick}
            onCourseSelect={setFlagCourseSelected}
          />
        )}
      {flagCourseSelected && (
        <StudentPaymentForm
          onCancelScreenShotClick={handleCancelScreenShotClick}
          onAdmissionDone={handleAdmissionDone}
          onAdmissionBeingDone={handleAdmissionBeingDone}
          joiningStudentData={joiningStudentData}
          selectedCourse={selectedCourse}
        />
      )}
    </div>
  );
}
export default StudentJoinClass;
