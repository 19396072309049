import React, { Component } from "react";
import { Link } from "react-router-dom";
class DropDownAdminReport extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleAdminReportClick = (event) => {
    this.props.onAdminReportClick(event);
  };
  render() {
    let { adminReportList } = this.props;
    let { title } = this.props;
    let { shortTitle } = this.props;
    return (
      <React.Fragment>
        <div className="nav-item dropdown">
          <Link
            className="nav-link dropdown-toggle"
            to="#"
            id="dropdown01"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span className="normal-text">{title}</span>
            <span className="short-text">{shortTitle}</span>
          </Link>
          <div className="dropdown-menu" aria-labelledby="dropdown01">
            {adminReportList.map((menu, index) => (
              <Link
                className="dropdown-item"
                to="#"
                key={index}
                id={index}
                onClick={this.handleAdminReportClick}
              >
                {menu.name}
              </Link>
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default DropDownAdminReport;
