export class AccessDB {
  constructor(accesss) {
    this.accesss = accesss;
  }
  allAccesss() {
    return this.accesss;
  }
  searchByAccessId(id) {
    for (let i = 0; i < this.accesss.length; i++) {
      if (this.accesss[i].accessId == id) {
        return this.accesss[i];
      }
    }
    return null; // not found
  }
  filterByName(query) {
    let filteredAccesss = [];
    for (let i = 0; i < this.accesss.length; i++) {
      if (this.accesss[i].name.toLowerCase().includes(query.toLowerCase())) {
        filteredAccesss.push(this.accesss[i]);
      }
    } //for
    return filteredAccesss;
  }
}
export default AccessDB;
