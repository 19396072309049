import React from "react";

class AdminAdmissionAdd extends React.Component {
  emptyAdmission = {
    admissionId: "",
    student: " ",
    studentId: -1,
    course: "",
    courseId: -1,
    batch: "",
    batchId: -1,
    courseFees: "",
    discount: 0,
    feesPaid: 0,
    status: "Demo",
    certificateUrl: "",
    certificateId: "",
    certificateFile: "",
    certificateIssued: "",
  };
  errorAdmission = {
    name: { message: "", mxLen: 80, mnLen: 1, onlyDigits: false },
    description: { message: "", onlyDigits: false },
    student: { message: "" },
    studentId: { message: "" },
    batch: { message: "" },
    batchId: { message: "" },
    course: { message: "" },
    courseId: { message: "" },
    courseFees: { message: "", onlyDigits: true },
    discount: { message: "", onlyDigits: true },
    feesPaid: { message: "", onlyDigits: true },
    status: { message: "" },
    certificateUrl: { message: "" },
    certificateId: { message: "" },
    certificateFile: { message: "" },
    certificateIssued: { message: "" },
  };
  constructor(props) {
    super(props);
    let admission;
    let flagFormInvalid;
    let { action } = this.props;
    if (action === "ADD") {
      flagFormInvalid = true;
      this.emptyAdmission.student = this.props.selectedStudent;
      this.emptyAdmission.studentId = this.props.selectedStudentId;
      admission = this.emptyAdmission;
    } else if (action === "UPDATE") {
      // in edit mode, keep the update button enabled at the beginning
      flagFormInvalid = false;
      admission = this.props.admission;
    }
    this.state = {
      admission: admission,
      errorAdmission: this.errorAdmission,
      flagFormInvalid: flagFormInvalid,
    };
  }

  handleChange = (event) => {
    let { errorAdmission } = this.state;
    let name = event.target.name;
    let message;
    let userInput;
    const isCheckbox = event.target.type === "checkbox";
    if (isCheckbox) {
      // for checkbox, no checking of validations.
      userInput = event.target.checked;
      this.setState({
        admission: { ...this.state.admission, [name]: userInput },
      });
      return;
    }
    let value = event.target.value;
    //check of max. length
    let mxLen = errorAdmission[`${name}`].mxLen;
    let onlyDigits = errorAdmission[`${name}`].onlyDigits;
    if (value.length > mxLen) {
      value = value.slice(0, mxLen + 1);
      if (!onlyDigits) message = "Maximum " + mxLen + " characters allowed";
      else message = "Maximum " + mxLen + " digits allowed";
    } //if...
    else {
      message = "";
    }
    errorAdmission[`${name}`].message = message;
    this.setState({
      errorAdmission: errorAdmission,
      admission: { ...this.state.admission, [name]: value },
    });
    this.checkAllErrors(errorAdmission);
  };
  handleBlur = (event) => {
    let name,
      value,
      requiredMessage = " is required",
      type,
      message = "",
      mnLen,
      onlyDigits;
    let { errorAdmission } = this.state;
    name = event.target.name;
    type = event.target.type;
    if (type === "text" || type === "textarea") {
      // check if 'required', also check min no. of characters
      value = event.target.value.trim();
      mnLen = errorAdmission[`${name}`].mnLen;
      onlyDigits = errorAdmission[`${name}`].onlyDigits;
      if (value.length === 0) {
        message = name + requiredMessage;
      } else if (value.length < mnLen) {
        if (!onlyDigits) message = "Min " + mnLen + " characters required";
        else message = "Min " + mnLen + " digits required";
      } //else
      else if (onlyDigits) {
        for (let i = value.length - 1; i >= 0; i--) {
          const d = value.charCodeAt(i);
          if (d < 48 || d > 57) {
            message = "Enter only digits";
            break;
          } //if
        } //for
      } //else if... onlyDigits
      else {
        message = "";
      }
    } //if... text || textarea
    else if (event.target.type === "select-one") {
      value = event.target.selectedIndex;

      if (value === 0) {
        // message = name + requiredMessage;
        // Currently no checking of category and brand
        message = "";
      } else {
        message = "";
      }
    } //else if
    errorAdmission[`${name}`].message = message;
    this.setState({ errorAdmission: errorAdmission });
    this.checkAllErrors(errorAdmission);
  };
  handleFocus = (event) => {
    let name = event.target.name;
    let errorAdmission = this.state.errorAdmission;
    errorAdmission[`${name}`].message = "";
    this.setState({ errorAdmission: errorAdmission });
    this.checkAllErrors(errorAdmission);
  };
  checkAllErrors = (errorAdmission) => {
    // let { errorAdmission } = this.state;
    let flagFormInvalid = false;
    for (let field in errorAdmission) {
      if (errorAdmission[field].message !== "") {
        // error is there
        flagFormInvalid = true;
        break;
      } //if
    } //for
    this.setState({ flagFormInvalid: flagFormInvalid });
  };
  handleRatingClick = (value) => {
    this.setState({
      admission: { ...this.state.admission, rating: value },
    });
  };
  handleUpload = (event) => {
    this.setState({
      imageName: event.target.files[0],
    });
  };
  handleSelectStudentChange = (event) => {
    let index = event.target.selectedIndex; // get selected index, instad of selected value
    var optionElement = event.target.childNodes[index];
    var selectedStudentId = optionElement.getAttribute("id");
    let student = event.target.value;
    this.setState({
      admission: {
        ...this.state.admission,
        student: student,
        studentId: selectedStudentId,
      },
    });
  };
  handleSelectCourseChange = (event) => {
    let index = event.target.selectedIndex; // get selected index, instad of selected value
    var optionElement = event.target.childNodes[index];
    var selectedCourseId = optionElement.getAttribute("id");
    let course = event.target.value;
    let courseId = selectedCourseId;
    // Get Course Fees of selected Course
    // courseList will be required
    let { courseList } = this.props;
    let courseFees;
    for (let i = 0; i < courseList.length; i++) {
      if (courseList[i].courseId == selectedCourseId) {
        courseFees = courseList[i].fees;
        break;
      }
    }
    this.setState({
      admission: {
        ...this.state.admission,
        course: course,
        courseId: courseId,
        courseFees: courseFees,
      },
    });
  };
  handleSelectBatchChange = (event) => {
    let index = event.target.selectedIndex; // get selected index, instad of selected value
    var optionElement = event.target.childNodes[index];
    var selectedBatchId = optionElement.getAttribute("id");
    let batch = event.target.value;
    let batchId = selectedBatchId;
    this.setState({
      admission: {
        ...this.state.admission,
        batch: batch,
        batchId: batchId,
      },
    });
  };
  handleSelectStatusChange = (event) => {
    let status = event.target.value;
    this.setState({
      admission: {
        ...this.state.admission,
        status: status,
      },
    });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    window.scrollTo(0, 0); // moving scrollbar at the top
    this.props.onFormSubmit(this.state.admission); //this.state
  };
  render() {
    let { studentList } = this.props;
    let { courseList } = this.props;
    let { batchList } = this.props;
    let { admission } = this.state;
    let { errorAdmission } = this.state;
    let { flagFormInvalid } = this.state;
    let { selectedStudent } = this.props;
    let { action } = this.props;
    // ={studentList}
    let optionsStudent = studentList.map((student, index) => (
      <option value={student.fullName} key={index} id={student.studentId}>
        {student.fullName}
      </option>
    ));
    let optionsCourse = courseList.map((course, index) => (
      <option value={course.name} key={index} id={course.courseId}>
        {course.name}
      </option>
    ));
    let optionsBatch = batchList.map((batch, index) => (
      <option value={batch.name} key={index} id={batch.batchId}>
        {batch.name}
      </option>
    ));
    let statusList = ["Admitted", "Demo", "Block"];
    // prepare options
    let optionsStatus = statusList.map((status, index) => (
      <option value={status} key={index}>
        {status}
      </option>
    ));

    let heading;
    if (action === "ADD") {
      heading = "Add a Admission (" + selectedStudent + ")";
    } else if (action === "UPDATE") {
      heading = "Update the Admission";
    }
    return (
      <React.Fragment>
        <div className="form-heading column col-12">{heading}</div>
        <form className="text-dark bg-mycyan p-4" onSubmit={this.handleSubmit}>
          {/* row starts */}
          {action === "UPDATE" && (
            <div className="form-group row column align-items-center">
              <div className="col-5 text-right">
                <label>Student</label>
              </div>
              <div className="col-7 px-0">
                <select
                  className="form-control"
                  name="student"
                  value={admission.student}
                  onChange={this.handleSelectStudentChange}
                  onBlur={this.handleBlur}
                  onFocus={this.handleFocus}
                  disabled
                >
                  <option> Select Student </option>
                  {optionsStudent}
                </select>
              </div>
              <div className="offset-5">
                {errorAdmission.student.message ? (
                  <span className="error-text">
                    {errorAdmission.student.message}
                  </span>
                ) : null}
              </div>
            </div>
          )}
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Course</label>
            </div>
            <div className="col-7 px-0">
              <select
                className="form-control"
                name="course"
                value={admission.course}
                onChange={this.handleSelectCourseChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
              >
                <option> Select Course </option>
                {optionsCourse}
              </select>
            </div>
            <div className="offset-5">
              {errorAdmission.course.message ? (
                <span className="error-text">
                  {errorAdmission.course.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Course Fees</label>
            </div>
            <div className="col-7 column px-0">
              <input
                type="text"
                className="form-control"
                name="courseFees"
                value={admission.courseFees}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                required
                readOnly
              />
            </div>
            <div className="offset-5">
              {errorAdmission.courseFees.message ? (
                <span className="error-text">
                  {errorAdmission.courseFees.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Batch</label>
            </div>
            <div className="col-7 px-0">
              <select
                className="form-control"
                name="batch"
                value={admission.batch}
                onChange={this.handleSelectBatchChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
              >
                <option> Select Batch </option>
                {optionsBatch}
              </select>
            </div>
            <div className="offset-5">
              {errorAdmission.batch.message ? (
                <span className="error-text">
                  {errorAdmission.batch.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Discount</label>
            </div>
            <div className="col-7 px-0">
              <input
                type="text"
                className="form-control"
                name="discount"
                value={admission.discount}
                onChange={this.handleChange}
                placeholder="0"
              />
            </div>
            <div className="offset-5">
              {errorAdmission.discount.message ? (
                <span className="error-text">
                  {errorAdmission.discount.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Fees Paid</label>
            </div>
            <div className="col-7 px-0">
              <input
                type="text"
                className="form-control"
                name="feesPaid"
                value={admission.feesPaid}
                onChange={this.handleChange}
                placeholder="0"
              />
            </div>
            <div className="offset-5">
              {errorAdmission.feesPaid.message ? (
                <span className="error-text">
                  {errorAdmission.feesPaid.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Status</label>
            </div>
            <div className="col-7 px-0">
              <select
                className="form-control"
                name="status"
                value={admission.status}
                onChange={this.handleSelectStatusChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
              >
                <option> Select Status </option>
                {optionsStatus}
              </select>
            </div>
            <div className="offset-5">
              {errorAdmission.status.message ? (
                <span className="error-text">
                  {errorAdmission.status.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Certificate url</label>
            </div>
            <div className="col-7 column px-0">
              <input
                type="text"
                className="form-control"
                name="certificateUrl"
                value={admission.certificateUrl}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                readOnly
              />
            </div>
            <div className="offset-5">
              {errorAdmission.certificateUrl.message ? (
                <span className="error-text">
                  {errorAdmission.certificateUrl.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Certificate Id</label>
            </div>
            <div className="col-7 column px-0">
              <input
                type="text"
                className="form-control"
                name="certificateId"
                value={admission.certificateId}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                readOnly
              />
            </div>
            <div className="offset-5">
              {errorAdmission.certificateId.message ? (
                <span className="error-text">
                  {errorAdmission.certificateId.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Certificate Image File</label>
            </div>
            <div className="col-7 column px-0">
              <input
                type="text"
                className="form-control"
                name="certificateFile"
                value={admission.certificateFile}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                readOnly
              />
            </div>
            <div className="offset-5">
              {errorAdmission.certificateFile.message ? (
                <span className="error-text">
                  {errorAdmission.certificateFile.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row offset-5 text-center mb-0">
            <button
              className="btn-mycyan"
              type="submit"
              disabled={flagFormInvalid}
            >
              {action}
            </button>
          </div>
          <input
            type="hidden"
            name="admissionId"
            value={admission.admissionId}
          />
        </form>
      </React.Fragment>
    );
  }
}
export default AdminAdmissionAdd;
