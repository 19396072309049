import React, { useEffect, useState } from "react";
function AdminChapterAdd(props) {
  let emptyChapter;
  let [chapter, setChapter] = useState({
    chapterId: "",
    name: "",
    description: "",
    chapterNumber: props.lastChapterNumber + 1,
    subject: "",
    subjectId: -1,
    isReady: "1",
  });
  let [errorChapter, setErrorChapter] = useState({
    name: { message: "", mxLen: 80, mnLen: 1, onlyDigits: false },
    description: { message: "", onlyDigits: false },
    subject: { message: "" },
    subjectId: { message: "" },
    chapterNumber: { message: "", mxLen: 2, mnLen: 1, onlyDigits: true },
  });
  let [flagFormInvalid, setFlagFormInvalid] = useState(false);
  useEffect(() => {
    window.scroll(0, 0);
    init();
  }, []);
  function init() {
    emptyChapter = chapter;
    let { action } = props;
    if (action === "ADD") {
      setFlagFormInvalid(true);
      emptyChapter.subject = props.selectedSubject;
      emptyChapter.subjectId = props.selectedSubjectId;
      emptyChapter.lastChapterNumber = props.lastChapterNumber + 1;
      setChapter(emptyChapter);
    } else if (action === "UPDATE") {
      // in edit mode, keep the update button enabled at the beginning
      setFlagFormInvalid(false);
      setChapter(props.chapter);
    }
  }
  function handleChange(event) {
    let name = event.target.name;
    let userInput;
    let inputType = event.target.type;
    if (inputType == "checkbox") {
      // for checkbox, no checking of validations.
      userInput = event.target.checked;
      let value;
      if (userInput) {
        value = 1;
      } else {
        value = 0;
      }
      setChapter({ ...chapter, [name]: value });
      return;
    }
    setChapter({ ...chapter, [name]: event.target.value });
    isValid(event);
  }
  function handleBlur(event) {
    isValid(event);
  }
  function isValid(event) {
    let i,
      flag,
      name,
      value,
      requiredMessage = " is required",
      type,
      message = "",
      mnLen,
      mxLen,
      onlyDigits,
      allDigits,
      noSymbols;
    name = event.target.name;
    type = event.target.type;
    if (type === "text" || type === "textarea" || type === "password") {
      // check if 'required', also check min no. of characters
      value = event.target.value.trim();
      mnLen = errorChapter[`${name}`].mnLen;
      mxLen = errorChapter[`${name}`].mxLen;
      onlyDigits = errorChapter[`${name}`].onlyDigits;
      allDigits = errorChapter[`${name}`].allDigits;
      noSymbols = errorChapter[`${name}`].noSymbols;
      if (value.length === 0) {
        message = name + requiredMessage;
      } else if (value.length < mnLen) {
        if (!onlyDigits) message = "Min " + mnLen + " characters required";
        else message = "Min " + mnLen + " digits required";
      } //else
      else if (value.length > mxLen) {
        if (!onlyDigits) message = "Maximum " + mxLen + " characters allowed";
        else message = "Maximum " + mxLen + " digits allowed";
      } //else
      else if (onlyDigits) {
        for (let i = value.length - 1; i >= 0; i--) {
          const d = value.charCodeAt(i);
          if (d < 48 || d > 57) {
            message = "Enter only digits";
            break;
          } //if
        } //for
      } //else if... onlyDigits
      else if (noSymbols && !allDigits) {
        let d,
          count = 0;
        for (i = value.length - 1, flag = false; i >= 0; i--) {
          d = value.charCodeAt(i);
          if (d >= 48 && d <= 57) {
            count++; // digits are being counted
          } //if
          if (
            (d >= 97 && d <= 122) ||
            (d >= 65 && d <= 90) ||
            (d >= 48 && d <= 57) ||
            value.charAt(i) == "_"
          ) {
            continue;
          } //if
          else {
            // symbol is there, may be space
            flag = true;
            break;
          }
        } //for
        if (flag == true) {
          let q = String.fromCharCode(d);
          if (d == 32) {
            message = "space is not allowed";
          } else
            message =
              "Symbols other than underscore (_) are not allowed, you have used " +
              q;
        } else {
          if (count == value.length) {
            message = "Only digits are not allowed, use some alphabets also";
          }
        }
      } //else if... noSymbols
      else {
        message = "";
      }
    } //if... text || textarea
    else if (event.target.type === "select-one") {
      value = event.target.selectedIndex;
      if (value == 0) {
        message = name + requiredMessage;
      } else {
        message = "";
      }
    } //else if
    else if (type === "email") {
      value = event.target.value.trim();
      if (/\S+@\S+\.\S+/.test(value) == 0) {
        message = "valid email-id " + requiredMessage;
      } else {
        message = "";
      }
    } //else if
    else {
      message = "";
    }
    let errChapter = { ...errorChapter };
    errChapter[`${name}`].message = message;
    setErrorChapter(errChapter);
    checkAllErrors();
  }
  function handleFocus(event) {
    checkAllErrors();
  }
  function checkAllErrors() {
    for (let field in errorChapter) {
      if (errorChapter[field].message !== "") {
        setFlagFormInvalid(true);
        return;
      } //if
    } //for
    setFlagFormInvalid(false);
  }
  function handleSelectSubjectChange(event) {
    let index = event.target.selectedIndex; // get selected index, instad of selected value
    var optionElement = event.target.childNodes[index];
    var selectedSubjectId = optionElement.getAttribute("id");
    let subject = event.target.value;
    let subjectId = selectedSubjectId;
    setChapter({ ...chapter, subject: subject, subjectId: subjectId });
  }
  function handleIsReadySelection(value) {
    setChapter({ ...chapter, isReady: value });
  }
  function handleSubmit(event) {
    event.preventDefault();
    window.scrollTo(0, 0); // moving scrollbar at the top
    props.onFormSubmit(chapter); 
  }
  let { subjectList } = props;
  let { selectedSubject } = props;
  let { action } = props;
  let optionsSubject = subjectList.map((subject, index) => (
    <option value={subject.name} key={index} id={subject.subjectId}>
      {subject.name}
    </option>
  ));
  let heading;
  if (action === "ADD") {
    heading = "Add a Chapter (" + selectedSubject + ")";
  } else if (action === "UPDATE") {
    heading = "Update the Chapter";
  }
  return (
    <>
      <div className="form-heading column col-12">{heading}</div>
      <form className="text-dark bg-mycyan p-4" onSubmit={handleSubmit}>
        {/* row starts */}
        <div className="form-group row column align-items-center">
          <div className="col-5 text-right">
            <label>Chapter Name</label>
          </div>
          <div className="col-7 column px-0">
            <input
              type="text"
              className="form-control"
              name="name"
              value={chapter.name}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholder="Chapter Name"
              required
            />
          </div>
          <div className="offset-5">
            {errorChapter.name.message ? (
              <span className="error-text">{errorChapter.name.message}</span>
            ) : null}
          </div>
        </div>
        {/* row ends */}
        {/* row starts */}
        {action === "UPDATE" && (
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Subject</label>
            </div>
            <div className="col-7 px-0">
              <select
                className="form-control"
                name="subject"
                value={chapter.subject}
                onChange={handleSelectSubjectChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
              >
                <option> Select Subject </option>
                {optionsSubject}
              </select>
            </div>
            <div className="offset-5">
              {errorChapter.subject.message ? (
                <span className="error-text">
                  {errorChapter.subject.message}
                </span>
              ) : null}
            </div>
          </div>
        )}
        {/* row ends */}
        {/* row starts */}
        <div className="form-group row column align-items-center">
          <div className="col-5 text-right">
            <label>Chapter Number</label>
          </div>
          <div className="col-7 column px-0">
            <input
              type="text"
              className="form-control"
              name="chapterNumber"
              value={chapter.chapterNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholder="Chapter Number"
              required
            />
          </div>
          <div className="offset-5">
            {errorChapter.chapterNumber.message ? (
              <span className="error-text">
                {errorChapter.chapterNumber.message}
              </span>
            ) : null}
          </div>
        </div>
        {/* row ends */}

        {/* row starts */}
        <div className="form-group row column align-items-center">
          <div className="col-5 text-right">
            <label>Description</label>
          </div>
          <div className="col-7 px-0">
            <textarea
              type="text"
              className="form-control"
              rows="3"
              cols="50"
              name="description"
              value={chapter.description}
              onChange={handleChange}
              placeholder="Enter description of the  chapter"
            />
          </div>
          <div className="offset-5">
            {errorChapter.description.message ? (
              <span className="error-text">
                {errorChapter.description.message}
              </span>
            ) : null}
          </div>
        </div>
        {/* row ends */}
        <div className="form-group row column align-items-center">
          <div className="col-5 text-right">
            <label>Is it Ready?</label>
          </div>
          <div className="col-7 column px-0">
            <input
              type="radio"
              className="form-control-inline"
              name="isReady"
              value="0"
              onClick={() => handleIsReadySelection("0")}
              onChange={() => handleIsReadySelection("0")}
              checked={chapter.isReady == "0"}
            />
            &nbsp;No &nbsp;&nbsp;&nbsp;
            <input
              type="radio"
              className="form-control-inline"
              name="isReady"
              value="1"
              onClick={() => handleIsReadySelection("1")}
              onChange={() => handleIsReadySelection("1")}
              checked={chapter.isReady == "1"}
            />
            &nbsp;Yes&nbsp;&nbsp;
          </div>
        </div>
        {/* row starts */}
        <div className="form-group row offset-5 text-center mb-0">
          <button
            className="btn-mycyan"
            type="submit"
            disabled={flagFormInvalid}
          >
            {action}
          </button>
        </div>
        <input type="hidden" name="chapterId" value={chapter.chapterId} />
      </form>
    </>
  );
}
export default AdminChapterAdd;