import React, { Component } from "react";
import "../App.css";
import axios from "axios";
import { Link } from "react-router-dom";

class OfflineProgram extends Component {
  messageOfOffline = "This lecture is available in offline class-room";
  constructor(props) {
    super(props);
    this.state = {
      flagFileDownload: false,
      fileName: "",
      code: "",
      message: "",
      videoStatus: "",
      programStatus: "",
      flagLoadingVideoLink: false,
      isImage: false,
      isDemoVideo: false,
      isResources: false,
      showWhat: "code",
    };
  }
  hideMessage = () => {
    window.setTimeout(() => {
      this.setState({
        message: "",
      });
    }, 7000);
  };
  getContentToDisplay = () => {
    let { program } = this.props;
    let s = "";
    s = program.programNumber + ". " + program.description + "  ";
    return s;
  };

  handleShowContentClick = () => {
    let { selectedMenu } = this.props;

    if (selectedMenu.name == "video" || selectedMenu.name == "note") {
      this.handlePlayVideoClick();
      return;
    } else {
      this.handleShowProgramCodeClick();
      return;
    }
  };
  // get link for the unlisted videos, it is --- made from server side

  handleShowProgramCodeClick = () => {
    // Check here only, whether  student has taken admission
    //  for this course or not, or is it a Demo
    let { selectedSubjectName } = this.props;
    // let { selectedSubjectAvailableForDemo } = this.props;
    let { selectedSubjectAvailableFor } = this.props;
    let { selectedSubjectFees } = this.props;
    // selectedSubjectAvailableForDemo = Boolean(selectedSubjectAvailableForDemo);
    let message1 =
      "Sorry… This content is available only for registered students. Fees of this course is only Rs. " +
      selectedSubjectFees +
      ". Want to join this course (online/offline)? Contact us at rjacpune@gmail.com or WhatsApp at 9422035020";
    let message2 =
      `Currently, content of following courses can be viewed.
      1. Programming (C, C++, Python)
      2. OOP using C++ and Python
      3. Data Structures
      Want to join the course ` +
      selectedSubjectName +
      `
      ? Contact us at rjacpune@gmail.com or WhatsApp at 9422035020`;
    let message3 =
      "Join this course - " + selectedSubjectName + " (online/offline)";
    let { visitorInfo } = this.props;
    let { selectedChapterNumber } = this.props;
    let { program } = this.props;
    let availableForDemo = Boolean(program.availableForDemo);
    let message;
    let requestUrl;
    // subject available for Demo
    if (selectedSubjectAvailableFor == "All") {
      if (availableForDemo) {
        message = "";
        requestUrl = "/files/downloadDemoFile/";
      } else if (visitorInfo.status == "Guest") {
        // Guest
        message = message1;
      }
      // not guest, so a registered student
      else if (visitorInfo.admissionStatus == "Admitted") {
        // check chapter-limit
        if (selectedChapterNumber > visitorInfo.chapterLimit) {
          message = "Please request Admin to access data of this chapter";
          // message =
          //   "You have not completed chapter no. " +
          //   visitorInfo.chapterLimit +
          //   " yet.";
        } else {
          message = "";
          requestUrl = "/files/downloadFromFileOffline/";
        }
      } else if (
        (visitorInfo.status =
          "Registered" && visitorInfo.admissionStatus == "Guest")
      ) {
        message = message3;
      }
    } //end of "All" (Programming, OOP, DS)
    else {
      // subject NOT available for Demo
      if (visitorInfo.status == "Guest") {
        // Guest
        message = message2;
      } else {
        requestUrl = "/files/downloadFromFile/";
      }
    }
    if (message) {
      this.setState({
        message: message,
      });
      this.hideMessage();
      return;
    }

    this.props.onShowCodeClick(program);
    let { filePrefixWOExtension } = this.props;
    let fileName = filePrefixWOExtension + program.programNumber;
    // Program name is not saved
    // It is "filePrefix"+program number
    // E.g. c1p1
    // Content-Type': 'application/pdf'
    //Now check is it demo file
    this.setState({
      flagLoadingActualContent: true,
      flagLoading: true,
    });
    axios
      .get(window.routerPrefix + requestUrl + fileName)
      .then((response) => {
        this.setState({
          flagFileDownload: true, // downloaded successfully
        });
        // If no code file is there (e.g. in Web technology), then a file with
        // "nothing" written in it, is stored
        if (response.data != "nothing") {
          this.setState({
            code: response.data,
          });
        } else {
          this.setState({
            code: "",
          });
        }
        this.setState({
          message: "",
        });
        let fileName1 = fileName;
        fileName = response.headers["content-disposition"].split('"')[1];
        this.setState({
          fileName: fileName,
        });
        // Now check whether some image / video is there related with this program
        axios
          .get(window.routerPrefix + "/files/programResourcesInfo/" + fileName1)
          .then((res) => {
            let isImage, isDemoVideo, isResources;
            let data = res.data;
            let s = data[0];
            isImage = Boolean(s);
            s = data[1];
            isResources = Boolean(s);
            s = data[2];
            isDemoVideo = Boolean(s);
            let demoVideoLink;
            if (isDemoVideo) {
              demoVideoLink = data[2];
            } else {
              demoVideoLink = "---";
            }
            this.setState({
              isImage: isImage,
              isDemoVideo: isDemoVideo,
              isResources: isResources,
              demoVideoLink: demoVideoLink,
              flagLoadingActualContent: false,
            });
          })
          .catch((err) => {
            this.setState({
              error: err,
              message: "Something went wrong",
              flagLoadingActualContent: false,
            }); // error is set
            this.hideMessage();
          });
      })
      .catch((err) => {
        this.setState({
          error: err,
          flagLoadingActualContent: false,
          message: "Something went wrong",
          flagLoading: false,
        }); // error is set
        this.hideMessage();
      });
  };
  handleCopyToClipboard = () => {
    navigator.clipboard.writeText(this.state.code);
  };
  handleShowImageClick = () => {
    this.setState({
      showWhat: "image",
    });
  };
  handleShowDemoVideoClick = () => {
    this.setState({
      showWhat: "demoVideo",
    });
  };
  handleDownloadFilesClick = () => {
    this.setState({
      showWhat: "resources",
    });
  };
  handleShowCodeClick = () => {
    this.setState({
      showWhat: "code",
    });
  };
  handleDownloadVideoCode = () => {
    let { program } = this.props;
    let requestUrl =
      "/general/videoCode/" +
      this.props.selectedSubjectFilePrefix +
      this.props.selectedChapterNumber +
      "v" +
      program.programNumber;
    this.setState({
      programList: [], // programlist should be empty
    });
    this.downloadPdfFile(requestUrl);
  };
  downloadPdfFile = (requestUrl) => {
    this.setState({
      flagLoadingChapterContent: true,
    });
    axios
      .get(window.routerPrefix + requestUrl, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/pdf",
        },
      })
      .then((res) => {
        let type = "application/pdf";
        const url = window.URL.createObjectURL(
          new Blob([res.data], {
            type: type,
          })
        );
        let fileName = res.headers["content-disposition"].split('"')[1];
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.setState({
          flagLoadingChapterContent: false,
        });
        this.setState({
          message: "pdf file downloaded successfully!",
        });
        this.hideMessage();
        // this.unselectMenu();
      })
      .catch((err) => {
        this.setState({
          error: err,
          message: "Something went wrong",
          flagLoadingChapterContent: false,
        }); // error is set
        this.hideMessage();
      });
  };

  handleCodeDownloadClick = () => {
    let type = "text/plain";
    const url = window.URL.createObjectURL(
      new Blob([this.state.code], {
        type: type,
      })
    );
    let fileName = this.state.fileName;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove(); // you need to remove that elelment which is created before.
  };
  showAvailability = () => {
    let availability = "";
    let { program } = this.props;
    let { visitorInfo } = this.props;
    let availableForDemo = Boolean(program.availableForDemo);
    let { selectedMenu } = this.props;
    if (selectedMenu.name == "video" || selectedMenu.name == "note") {
      if (window.hosting == "offline") {
        if (program.location == "YouTubePublic") {
          availability = "Available on YouTube";
        } else if (program.location == "YouTubeUnlisted") {
        } else if (program.location == "YouTubeUnlisted") {
        } else {
          availability = "";
        }
      }
    } else {
      // program, exercise, misc.
      if (window.hosting == "offline") {
        if (availableForDemo && visitorInfo.admissionStatus == "Guest") {
          availability = "Preview";
        } else {
          availability = "";
        }
      } else if (window.hosting == "internet") {
        if (availableForDemo && visitorInfo.admissionStatus == "Guest") {
          availability = "Preview";
        } else {
          availability = "";
        }
      }
    }
    return availability;
  };
  getImageLink = () => {
    let { filePrefixWOExtension } = this.props;
    let { program } = this.props;
    let serverResponse =
      window.routerPrefix +
      "/files/downloadImageFile/" +
      (filePrefixWOExtension + "" + program.programNumber);
    return serverResponse;
  };

  sh_Code = () => {
    let { program } = this.props;
    let { selectedMenu } = this.props;
    let { flagFileDownload } = this.state;
    if (
      program.flagActive &&
      flagFileDownload && // file downloaded successfully
      selectedMenu.name != "video" &&
      selectedMenu != "note"
    )
      return true;
    return false;
  };

  handleDownloadResourcesFileClick = () => {
    // pdf download
    let { filePrefixWOExtension } = this.props;
    let { program } = this.props;
    let fileName = filePrefixWOExtension + program.programNumber;
    let requestUrl = "/files/resources/" + fileName;
    this.setState({
      flagLoadingResourcesFile: true,
    });
    axios
      .get(window.routerPrefix + requestUrl, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/pdf",
        },
      })
      .then((res1) => {
        let type = "application/pdf";
        const url = window.URL.createObjectURL(
          new Blob([res1.data], {
            type: type,
          })
        );
        let fileName = res1.headers["content-disposition"].split('"')[1];
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.setState({
          flagLoadingResourcesFile: false,
        });
        this.setState({
          message: "File downloaded successfully!",
        });
        this.hideMessage();
        this.unselectMenu();
      })
      .catch((err) => {
        this.setState({
          error: err,
          message: "Something went wrong",
          flagLoadingResourcesFile: false,
        }); // error is set
        this.hideMessage();
      });
  };
  render() {
    let { program } = this.props;
    let { flagLoadingActualContent } = this.state;
    let { message } = this.state;
    let { selectedMenu } = this.props;
    let { filePrefixWOExtension } = this.props;
    let { code } = this.state;
    let { isImage } = this.state;
    let { isDemoVideo } = this.state;
    let { isResources } = this.state;
    let { isVideoCode } = this.state;
    let { showWhat } = this.state;
    let content = this.getContentToDisplay();
    return (
      <>
        {/* row starts - Actual content */}
        {content && (
          <div className="row mb-2">
            <button
              to="/"
              className={
                "div-actual-content " +
                (program.flagActive ? " border-mypurple " : " ")
              }
              onClick={this.handleShowContentClick}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {content}
              &nbsp;
              {selectedMenu.showLength && (
                <span className="bg-whitecolor ">{this.getVideoLength()}</span>
              )}
              &nbsp;
              <span className="text-whitecolor  bg-darkcolor span-preview">
                {this.showAvailability()}
              </span>
            </button>
          </div>
        )}
        {/* row ends - Actual content */}
        {/* Code to be displayed after click to program */}
        {/* row starts - program-details */}
        {this.sh_Code() && (
          <div className="program-details ">
            {(isImage || isDemoVideo || isResources) && (
              <div className="row div-code-image-video">
                {code && (
                  <div className="col-4 ">
                    <button
                      to="#"
                      onClick={this.handleShowCodeClick}
                      className={
                        "no-border " +
                        (showWhat == "code"
                          ? "text-lightcolor bg-darkcolor"
                          : "text-darkcolor bg-lightcolor")
                      }
                      disabled={showWhat == "code"}
                    >
                      &nbsp; code &nbsp;
                    </button>
                  </div>
                )}
                {isImage && (
                  <div className="col-4">
                    <button
                      to="#"
                      onClick={this.handleShowImageClick}
                      className={
                        "no-border " +
                        (showWhat == "image"
                          ? "text-lightcolor bg-darkcolor"
                          : "text-darkcolor bg-lightcolor")
                      }
                      disabled={showWhat == "image"}
                    >
                      &nbsp;Image&nbsp;
                    </button>
                  </div>
                )}
                {isDemoVideo && (
                  <div className="col-4 ">
                    <button
                      to="#"
                      onClick={this.handleShowDemoVideoClick}
                      className={
                        "no-border " +
                        (showWhat == "demoVideo"
                          ? "text-lightcolor bg-darkcolor"
                          : "text-darkcolor bg-lightcolor")
                      }
                      disabled={showWhat == "demoVideo"}
                    >
                      &nbsp;Demo Video&nbsp;
                    </button>
                  </div>
                )}
                {isResources && (
                  <div className="col-4 ">
                    <button
                      to="#"
                      onClick={this.handleDownloadFilesClick}
                      className={
                        "no-border " +
                        (showWhat == "resources"
                          ? "text-lightcolor bg-darkcolor"
                          : "text-darkcolor bg-lightcolor")
                      }
                      disabled={showWhat == "resources"}
                    >
                      &nbsp;Resources&nbsp;
                    </button>
                  </div>
                )}
              </div>
            )}
            <div className="row">
              {showWhat == "code" && <div className="col-2"></div>}
              {showWhat == "code" && code && (
                <div className="col-4">
                  <button
                    to="#"
                    onClick={this.handleCopyToClipboard}
                    className="bg-darkcolor text-lightcolor no-border"
                  >
                    &nbsp;Copy&nbsp;
                  </button>
                </div>
              )}
              {showWhat == "code" && code && (
                <div className="col-2 ">
                  <button
                    to="#"
                    onClick={this.handleCodeDownloadClick}
                    className="bg-darkcolor text-lightcolor no-border"
                  >
                    &nbsp;Download&nbsp;
                  </button>
                </div>
              )}
            </div>
            {!flagLoadingActualContent && showWhat == "code" && (
              <div className="program-code" style={{ whiteSpace: "pre-wrap" }}>
                {code}
              </div>
            )}
            {!flagLoadingActualContent && showWhat == "image" && (
              <div className="program-code">
                <img
                  src={this.getImageLink()}
                  alt="No logo"
                  className="img-fluid "
                  onClick={this.handleLogoClick}
                />
              </div>
            )}
            {!flagLoadingActualContent && showWhat == "demoVideo" && (
              <iframe
                src={
                  "https://www.youtube.com/embed/" + this.state.demoVideoLink
                }
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            )}
            {!flagLoadingActualContent && showWhat == "resources" && (
              <div className="text-center div-rar">
                <Link
                  to="#"
                  className="col-12 text-center"
                  onClick={this.handleDownloadResourcesFileClick}
                >
                  Download
                </Link>{" "}
                rar file containing required resources.
              </div>
            )}
          </div>
        )}
        {/* row ends  - program-details */}
        {/* Below is for videos */}
        {message && <div className="error-text text-left">{message}</div>}

        {message == this.messageOfOffline && isVideoCode && (
          <div className="text-primarycolor">
            <Link to="#" onClick={this.handleDownloadVideoCode}>
              Download{" "}
            </Link>
            code discussed in this video
          </div>
        )}
        {program.instructions && (
          <div className="text-instructions" style={{ whiteSpace: "pre" }}>
            {program.instructions}
          </div>
        )}
      </>
    );
  }
}
export default OfflineProgram;
