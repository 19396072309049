import React, { Component } from "react";
import { Link } from "react-router-dom";
import { WhatsappShareButton, WhatsappIcon } from "react-share";
class ExploreSubject extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleSubjectClick = () => {
    let { subject } = this.props;
    this.props.onSubjectClick(subject);
  };
  handleContactNowClick = () => {
    let whatsappNumber = "919422035020";
    let whatsAppMessage =
      "Hi! I am interested to join the course of 'Programming using 3 languages'";
    window.location = `https://wa.me/${whatsappNumber}?text=${whatsAppMessage}`;
  };
  render() {
    let { subject } = this.props;
    return (
      <React.Fragment>
        <div className="col-lg-4 col-md-6 mb-4">
          <div className="rounded overflow-hidden mb-2 text-center">
            <Link onClick={() => this.handleSubjectClick()} to="#">
              <img
                className="img-fluid"
                src={"images/subject_" + subject.filePrefix + ".jpg"}
                alt=""
              />
            </Link>
            <div className="p-4  text-left">
              <div className="d-flex justify-content-between mb-1 px-2">
                {/* <small className="m-0"> */}
                  {/* <i class="fa fa-users text-primary mr-2"></i>25 Students */}
                  {/* <i className="fa fa-indian-rupee-sign text-primary mr-2"></i> */}
                  {/* <span className="text-striked">{subject.feesOriginal}</span>{" "} */}
                  {/* {subject.fees}/- */}
                  {/* text-striked */}
                {/* </small> */}
                {/* <small className="m-0">
                  <i className="far fa-clock text-primary mr-2"></i>
                  {subject.duration} hrs.
                </small> */}
              </div>
              <div className="h6" href="">
                {subject.description}
              </div>
              {/* {subject.firstChapterDemo == 1 && (
                <div className="text-center" href="">
                  <Link to="#">Start</Link> Demo (first chapter).
                </div>
              )} */}
              {/* <div className="border-top mt-4 pt-4">
                <div className="d-flex justify-content-between">
                  <h6 className="m-0">
                    <i className="fa fa-star text-primary mr-2"></i>4.5
                    <small>(250)</small>
                  </h6>
                  <h5 className="m-0">$99</h5>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default ExploreSubject;
