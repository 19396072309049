import React, { Component } from "react";
import AdminVlengthList from "./admin-vlength-list";
import { ChapterDB } from "../ChapterDB";
import { BeatLoader } from "react-spinners";
import axios from "axios";
class AdminVlength extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      response: {},
      chapter: null,
      filteredChapters: [],
      subjectList: [],
      chapterList: [],
      selectedSubject: "",
      selectedSubjectId: "",
      flagLoading: false,
      searchText: "",
      sortedField: "",
      sortedDirection: false,
      sectionNumber: 1,
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  getChapterListFromDatabase = (subject, subjectId) => {
    this.setState({
      flagLoading: true,
    });
    axios
      .all([axios.get(window.routerPrefix + "/chapter/chapters/" + subjectId)])
      .then(
        axios.spread((res1) => {
          let chapterList = res1.data;
          let updatedChapters = [...chapterList];
          // let updatedProducts = [...productList];
          chapterList.map((chapter, index) => {
            chapter.subject = subject;
          }); //map
          chapterList = updatedChapters;
          this.setState({
            chapterList: chapterList,
            filteredChapters: chapterList,
            chapterdb: new ChapterDB(chapterList),
            flagLoading: false,
            // initFlag: true,
            /* Reason for using this initFlag: This parent has got ajax calls so,
during rendering of child error obtained, undefined props property,
so as log as initFlag is false, (i.e. data is not fetched), do not render
the parent ( so obviously child is also not rendered). 21.05.2020
https://stackoverflow.com/questions/54219364/react-function-call-after-render-and-before-childs-constructor
*/
          });
        })
      )
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  };
  componentDidMount() {
    // List of subjects should be ready
    this.setState({
      flagLoading: true,
    });
    axios
      .all([axios.get(window.routerPrefix + "/subject/subjects/")])
      .then(
        axios.spread((res1) => {
          let subjectList = res1.data;
          this.setState({
            subjectList: subjectList,
            flagLoading: false,
            // selectedSubject: this.props.selectedSubject,
          });
        })
      )
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  }
  handleSearchTextBoxKeyUp = (event) => {
    let searchText = event.target.value;
    this.setState({ searchText: searchText });
    this.performSearchOperation(searchText);
  };
  handleSearchTextChange = (event) => {
    let searchText = event.target.value;
    this.setState({ searchText: searchText });
    this.performSearchOperation(searchText);
  };
  performSearchOperation = (searchText) => {
    let query = searchText.trim();
    let searchedChapters = [];
    searchedChapters = this.state.chapterdb.filterByName(query);
    this.setState({
      filteredChapters: searchedChapters,
    });
  };
  onFormSubmit(data) {
    let { action } = this.props;
    this.setState({
      flagLoading: true,
    });
    if (action === "UPDATE") {
      //EDIT / PUT
      axios
        .put(window.routerPrefix + "/chapter/update", data)
        .then((res) => {
          this.updateChapterList(data);
          this.setState({
            response: res.data,
            flagLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            flagLoading: false,
          });
          //   this.props.askToLogin();
        });
    } else if (action === "ADD") {
      //ADD / POST
      axios
        .post(window.routerPrefix + "/chapter/addChapter", data, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
          },
        })
        .then((res) => {
          this.setState({
            flagLoading: false,
            response: res.data,
          });
          data.chapterId = res.data.chapterId; // id of the added chapter is returned as response
          this.setState({
            subjectToRetain: data.subject,
            subjectIdToRetain: data.subjectId,
            flagLoading: false,
          });

          this.addToChapterList(data);
        })
        .catch((err) => {
          console.log("error!");
          this.setState({
            flagLoading: false,
          });
        });
    } //else
  }
  handleEditChapter = (chapter) => {
    this.setState({
      chapter: chapter,
      searchText: "",
    });
    this.props.onEditClick();
  };
  // handleShowPrograms = (chapter) => {
  //   this.props.onShowPrograms(chapter);
  // };
  // handleShowExerPrograms = (chapter) => {
  //   this.props.onShowExerPrograms(chapter);
  // };
  // handleShowMiscPrograms = (chapter) => {
  //   this.props.onShowMiscPrograms(chapter);
  // };
  addToChapterList = (chapter) => {
    let chapterList = [...this.state.chapterList];
    chapterList.unshift(chapter); // insert at the beginning
    let filteredChapters = [...this.state.filteredChapters];
    filteredChapters.unshift(chapter);
    this.setState({
      filteredChapters: filteredChapters,
      chapterList: chapterList,
    });
    let message = "The chapter '" + chapter.name + "' added successfully.";
    this.props.onCrudAction(message, "ADD");
  };
  updateChapterList = (chapter) => {
    let chapterList = [...this.state.chapterList];
    for (let i = 0; i < chapterList.length; i++) {
      if (chapterList[i].chapterId == chapter.chapterId) {
        if (chapterList[i].subjectId == chapter.subjectId) {
          // update, but it should be at the beginning
          // chapterList[i] = chapter;
          chapterList.splice(i, 1); // remove 1 element from position i
          // and insert at the beginning as we want latest modified at the top
          chapterList.unshift(chapter);
        } else {
          //delete from current list
          chapterList.splice(i, 1); // remove 1 element from position i
        }
        break;
      } //if
    } //for
    // remove from filteredchapters also
    let filteredChapters = [...this.state.filteredChapters];
    for (let i = 0; i < filteredChapters.length; i++) {
      if (filteredChapters[i].chapterId == chapter.chapterId) {
        if (filteredChapters[i].subjectId == chapter.subjectId) {
          // update
          // filteredChapters[i] = chapter;
          filteredChapters.splice(i, 1); // remove 1 element from position i
          // and insert at the beginning as we want latest modified at the top
          filteredChapters.unshift(chapter);
        } else {
          //delete from current list
          filteredChapters.splice(i, 1); // remove 1 element from position i
        }
        break;
      } //if
    } //for
    this.setState({
      filteredChapters: filteredChapters,
      chapterList: chapterList,
      // searchText: "",
    });
    let message = "The chapter '" + chapter.name + "' updated successfully.";
    this.props.onCrudAction(message, "LIST", "Add a Chapter");
  };

  handleDeleteChapter = (chapter) => {
    let chapterList = [...this.state.chapterList];
    for (let i = 0; i < chapterList.length; i++) {
      if (chapterList[i].chapterId == chapter.chapterId) {
        chapterList.splice(i, 1); // remove 1 element from position i
      }
    } //for
    // remove from filteredchapters also
    let filteredChapters = [...this.state.filteredChapters];
    for (let i = 0; i < filteredChapters.length; i++) {
      if (filteredChapters[i].chapterId == chapter.chapterId) {
        filteredChapters.splice(i, 1); // remove 1 element from position i
      }
    } //for

    this.setState({
      //   chapterdb: new ChapterDB(chapterList),
      filteredChapters: filteredChapters,
      chapterList: chapterList,
      // searchText: "",
    });
    let message = "The chapter '" + chapter.name + "' deleted successfully.";
    this.props.onCrudAction(message, "LIST");
  };
  handleDuplicateChapter = (chapter, howMany) => {
    this.setState({
      flagLoading: true,
    });
    axios
      .post(
        window.routerPrefix + "/chapters/" + this.props.shopUrl + "/" + howMany,
        chapter,
        {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            // "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
          },
        }
      )
      .then((res) => {
        let message = chapter.name + " duplicated " + howMany;
        if (howMany == 1) message += " time.";
        else message += " times.";
        this.props.onCrudAction(message, "LIST"); // Add again
        this.getChapterListFromDatabase();
        this.setState({
          flagLoading: false,
          response: res.data,
        });
      })
      .catch((err) => {
        console.log("error!");
        this.setState({
          flagLoading: false,
        });
      });
  };
  handleHeaderClick = (event) => {
    let field = event.target.id;

    let direction = false;
    if (field === this.state.sortedField) {
      // same button clicked twice
      direction = !this.state.direction;
    } else {
      // different field
      direction = false;
    }
    this.setState({ direction: direction });
    let { filteredChapters } = this.state;
    if (direction == false) {
      //in ascending order
      filteredChapters = filteredChapters.sort((a, b) => {
        if (a[field] > b[field]) {
          return 1;
        }
        if (a[field] < b[field]) {
          return -1;
        }
        return 0;
      });
    } else {
      //in descending order
      filteredChapters = filteredChapters.sort((a, b) => {
        if (a[field] < b[field]) {
          return 1;
        }
        if (a[field] > b[field]) {
          return -1;
        }
        return 0;
      });
    }
    this.setState({ filteredChapters: filteredChapters, sortedField: field });
  };
  handleSectionNumberClick = (event) => {
    this.setState({ sectionNumber: event.target.id });
  };
  handleSelectSubjectChange = (selectedSubject, selectedSubjectId) => {
    // This is called for LIST as well ADD operation.
    this.getChapterListFromDatabase(selectedSubject, selectedSubjectId);
    this.setState({
      selectedSubject: selectedSubject,
      selectedSubjectId: selectedSubjectId,
    });
    // if (selectedSubject) {
    //   this.props.showAddButton();
    // }
  };
  render() {
    let { flagLoading } = this.state;
    let { action } = this.props;
    let { chapter } = this.state;
    let { subjectList } = this.state;
    let { selectedSubject } = this.state;
    let { selectedSubjectId } = this.state;
    let content;
    if (flagLoading) {
      content = (
        <div className="text-center">
          <BeatLoader size={16} color={"blue"} flagLoading />
        </div>
      );
    } else if (action === "LIST" || action === "DO") {
      // First show list
      content = (
        <AdminVlengthList
          subjectList={subjectList}
          onSelectSubjectChange={this.handleSelectSubjectChange}
          selectedSubject={selectedSubject}
          selectedSubjectId={selectedSubjectId}
          filteredChapters={this.state.filteredChapters}
          searchText={this.state.searchText}
          onSearchTextChange={this.handleSearchTextChange}
          onSearchTextBoxKeyUp={this.handleSearchTextBoxKeyUp}
          onHeaderClick={this.handleHeaderClick}
          onSectionNumberClick={this.handleSectionNumberClick}
          sectionNumber={this.state.sectionNumber}
          currentSize={this.state.chapterList.length}
          action={action}
        />
      );
    }
    return (
      <div className="container-fluid container-md container-content-page p-0">
        {content}
      </div>
    );
  }
}
export default AdminVlength;
