import React, { Component } from "react";
import { Link } from "react-router-dom";
import AdminStudentListSingle from "./admin-student-list-single";

class AdminStudentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      response: {},
    };
  }
  handleEditStudent = (student) => {
    this.props.onEditStudent(student);
  };
  handleShowChapters = (student) => {
    this.props.onShowChapters(student);
  };
  handleCrudAction = (message, action) => {
    this.props.onCrudAction(message, action);
  };
  handleDuplicateStudent = (student, selection) => {
    this.props.onDuplicateStudent(student, selection);
  };
  handleDeleteStudent = (student) => {
    this.props.onDeleteStudent(student);
  };
  handleSearchTextBoxKeyUp = (event) => {
    this.props.onSearchTextBoxKeyUp(event);
  };
  handleSearchTextChange = (event) => {
    this.props.onSearchTextChange(event);
  };
  handleHeaderClick = (event) => {
    this.props.onHeaderClick(event);
  };
  handleSectionNumberClick = (event) => {
    this.props.onSectionNumberClick(event);
  };
  render() {
    let { error } = this.state;
    let { filteredStudents } = this.props;
    let { searchText } = this.props;
    let { currentSize } = this.props;
    let { accessLevel } = this.props;
    let { adminType } = this.props;
    let contentNostudents = <div className="text-center">No students !</div>;
    let content = (
      <React.Fragment>
        <div className="form-heading column col-12">
          LIST OF students ({filteredStudents.length})
        </div>
        {/* row starts */}
        <div className="row justify-content-center column">
          <div className="col-6 my-2 ">
            <input
              type="search"
              className="container-fluid form-control"
              value={searchText}
              onKeyUp={this.handleSearchTextBoxKeyUp}
              onChange={this.handleSearchTextChange} // This is contolled by parent
              placeholder="Search students"
              id=""
            />
          </div>
        </div>
        {/* row ends */}
        <div className="row p-0 ">
          <div className="col-12 justify-content-center">
            <div className="table-responsive">
              <table className="table mx-auto w-auto table-sm table-hover table-borderless bigger-text">
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      <Link to="#" onClick={this.handleHeaderClick} id="name">
                        Name
                      </Link>
                    </th>
                    <th>
                      <Link to="#" onClick={this.handleHeaderClick} id="name">
                        Email_id
                      </Link>
                    </th>
                  </tr>
                </thead>
                {filteredStudents.map((student, index) => (
                  <tbody key={index}>
                    <AdminStudentListSingle
                      student={student}
                      index={index}
                      onDeleteStudent={this.handleDeleteStudent}
                      onDuplicateStudent={this.handleDuplicateStudent}
                      onEditStudent={this.handleEditStudent}
                      onCrudAction={this.handleCrudAction}
                      currentSize={currentSize}
                      accessLevel={accessLevel}
                      adminType={adminType}
                    />
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
    if (error) {
      return <div>Error retriving students </div>;
    } else if (filteredStudents.length >= 0) {
      return (
        // <div className="container-fluid bg-blue">
        // <div className="row">
        <div className="">{content}</div>
        // </div>
        // </div>
      );
    } //else
    else if (filteredStudents.length == 0) {
      return contentNostudents;
    } //else
  }
}
export default AdminStudentList;
