import React, { useEffect, useState } from "react";
import axios from "axios";
import { BeatLoader } from "react-spinners";
function AdminProgramAdd(props) {
  let emptyProgram;
  let [program, setProgram] = useState({
    programId: "1",
    description: "",
    programNumber: props.lastProgramNumber + 1,
    subject: "",
    subjectId: -1,
    chapter: "",
    chapterId: -1,
    filename: "",
    instructions: "",
    location: "",
    link: "",
    availableForDemo: "",
  });
  let [errorProgram, setErrorProgram] = useState({
    description: { message: "", onlyDigits: false },
    programNumber: { message: "", mxLen: 2, mnLen: 1, onlyDigits: true },
    subject: { message: "" },
    subjectId: { message: "" },
    chapter: { message: "" },
    chapterId: { message: "" },
    instructions: { message: "" },
    location: { message: "" },
    link: { message: "" },
  });
  let [flagFormInvalid, setFlagFormInvalid] = useState(false);
  let [flagLoading, setFlagLoading] = useState(false);
  let [error, setError] = useState("");
  let [chapterList, setChapterList] = useState([]);
  useEffect(() => {
    window.scroll(0, 0);
    init();
    setChapterList(props.chapterList);
    // this.setState({ chapterList: this.props.chapterList });
  }, []);
  function init() {
    emptyProgram = program;
    let { action } = props;
    if (action === "ADD") {
      setFlagFormInvalid(true);
      emptyProgram.subject = props.selectedSubject;
      emptyProgram.subjectId = props.selectedSubjectId;
      emptyProgram.chapter = props.selectedChapter;
      emptyProgram.chapterId = props.selectedChapterId;
      setProgram(emptyProgram);
    } else if (action === "UPDATE") {
      // in edit mode, keep the update button enabled at the beginning
      setFlagFormInvalid(false);
      setProgram(props.program);
    }
  }
  function handleChange(event) {
    let name = event.target.name;
    let userInput;
    let inputType = event.target.type;
    if (inputType == "checkbox") {
      // for checkbox, no checking of validations.
      userInput = event.target.checked;
      let value;
      if (userInput) {
        value = 1;
      } else {
        value = 0;
      }
      setProgram({ ...program, [name]: value });
      return;
    }
    setProgram({ ...program, [name]: event.target.value });
    isValid(event);
  }
  function handleBlur(event) {
    isValid(event);
  }
  function isValid(event) {
    let i,
      flag,
      name,
      value,
      requiredMessage = " is required",
      type,
      message = "",
      mnLen,
      mxLen,
      onlyDigits,
      allDigits,
      noSymbols;
    name = event.target.name;
    type = event.target.type;
    if (type === "text" || type === "textarea" || type === "password") {
      // check if 'required', also check min no. of characters
      value = event.target.value.trim();
      mnLen = errorProgram[`${name}`].mnLen;
      mxLen = errorProgram[`${name}`].mxLen;
      onlyDigits = errorProgram[`${name}`].onlyDigits;
      allDigits = errorProgram[`${name}`].allDigits;
      noSymbols = errorProgram[`${name}`].noSymbols;
      if (value.length === 0) {
        message = name + requiredMessage;
      } else if (value.length < mnLen) {
        if (!onlyDigits) message = "Min " + mnLen + " characters required";
        else message = "Min " + mnLen + " digits required";
      } //else
      else if (value.length > mxLen) {
        if (!onlyDigits) message = "Maximum " + mxLen + " characters allowed";
        else message = "Maximum " + mxLen + " digits allowed";
      } //else
      else if (onlyDigits) {
        for (let i = value.length - 1; i >= 0; i--) {
          const d = value.charCodeAt(i);
          if (d < 48 || d > 57) {
            message = "Enter only digits";
            break;
          } //if
        } //for
      } //else if... onlyDigits
      else if (noSymbols && !allDigits) {
        let d,
          count = 0;
        for (i = value.length - 1, flag = false; i >= 0; i--) {
          d = value.charCodeAt(i);
          if (d >= 48 && d <= 57) {
            count++; // digits are being counted
          } //if
          if (
            (d >= 97 && d <= 122) ||
            (d >= 65 && d <= 90) ||
            (d >= 48 && d <= 57) ||
            value.charAt(i) == "_"
          ) {
            continue;
          } //if
          else {
            // symbol is there, may be space
            flag = true;
            break;
          }
        } //for
        if (flag == true) {
          let q = String.fromCharCode(d);
          if (d == 32) {
            message = "space is not allowed";
          } else
            message =
              "Symbols other than underscore (_) are not allowed, you have used " +
              q;
        } else {
          if (count == value.length) {
            message = "Only digits are not allowed, use some alphabets also";
          }
        }
      } //else if... noSymbols
      else {
        message = "";
      }
    } //if... text || textarea
    else if (event.target.type === "select-one") {
      value = event.target.selectedIndex;
      if (value == 0) {
        message = name + requiredMessage;
      } else {
        message = "";
      }
    } //else if
    else if (type === "email") {
      value = event.target.value.trim();
      if (/\S+@\S+\.\S+/.test(value) == 0) {
        message = "valid email-id " + requiredMessage;
      } else {
        message = "";
      }
    } //else if
    else {
      message = "";
    }
    let errProgram = { ...errorProgram };
    errProgram[`${name}`].message = message;
    setErrorProgram(errProgram);
    checkAllErrors();
  }
  function handleFocus(event) {
    checkAllErrors();
  }
  function checkAllErrors() {
    for (let field in errorProgram) {
      if (errorProgram[field].message !== "") {
        setFlagFormInvalid(true);
        return;
      } //if
    } //for
    setFlagFormInvalid(false);
  }
  function handleAvailableForDemoSelection(value) {
    setProgram({ ...program, availableForDemo: value });
  }
  function handleSelectSubjectChange(event) {
    let index = event.target.selectedIndex; // get selected index, instad of selected value
    var optionElement = event.target.childNodes[index];
    var selectedSubjectId = optionElement.getAttribute("id");
    let subject = event.target.value;
    let subjectId = +selectedSubjectId;
    setProgram({ ...program, subject: subject, subjectId: subjectId });
    // Here only, get list of chapters
    getChapterListFromDatabase(subject, subjectId);
  }
  function getChapterListFromDatabase(subject, subjectId) {
    setFlagLoading(true);
    axios
      .get(window.routerPrefix + "/chapter/chapters/" + subjectId)
      .then((res) => {
        setChapterList(res.data);
        setFlagLoading(false);
      })
      .catch((err) => {
        setFlagLoading(false);
        setError(err);
      });
  }
  function handleSelectChapterChange(event) {
    let index = event.target.selectedIndex; // get selected index, instad of selected value
    var optionElement = event.target.childNodes[index];
    var selectedChapterId = optionElement.getAttribute("id");
    let chapter = event.target.value;
    let chapterId = +selectedChapterId;
    setProgram({ ...program, chapter: chapter, chapterId: chapterId });
  }
  function handleSelectLocationChange(event) {
    setProgram({ ...program, location: event.target.value });
  }
  function handleSubmit(event) {
    event.preventDefault();
    window.scrollTo(0, 0); // moving scrollbar at the top
    props.onFormSubmit(program);
  }
  if (flagLoading) {
    return (
      <div className="text-center">
        <BeatLoader size={16} color={"blue"} flagLoading />
      </div>
    );
  }
  let { subjectList } = props;
  let { selectedSubject } = props;
  let { selectedChapter } = props;
  let { action } = props;
  let { flagSubjectChange } = props;
  let optionsSubject = subjectList.map((subject, index) => (
    <option value={subject.name} key={index} id={subject.subjectId}>
      {subject.name}
    </option>
  ));
  let optionsChapter;
  optionsChapter = chapterList.map((chapter, index) => (
    <option value={chapter.name} key={index} id={chapter.chapterId}>
      {chapter.name}
    </option>
  ));
  let locationList = [
    "SiteLocal",
    "Local",
    "GoogleDrive",
    "YouTubePublic",
    "YouTubeUnlisted",
  ];
  // prepare options
  let optionsLocation = locationList.map((location, index) => (
    <option value={location} key={index}>
      {location}
    </option>
  ));

  let heading;
  let { programType } = props;
  let s = programType.charAt(0).toUpperCase() + programType.slice(1);
  if (action === "ADD") {
    heading = "Add a " + s;
  } else if (action === "UPDATE") {
    heading = "Update the " + s;
  }
  return (
    <>
      <div className="form-heading  col-12">
        {heading}{" "}
        <span className="thick-text">
          ({selectedSubject} - {selectedChapter})
        </span>
      </div>
      <form className="text-dark bg-mycyan p-4" onSubmit={handleSubmit}>
        {/* row starts */}
        <div className="form-group row  align-items-center">
          <div className="col-5 text-right">
            <label>{s} Number</label>
          </div>
          <div className="col-7 column px-0">
            <input
              type="text"
              className="form-control"
              name="programNumber"
              value={program.programNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              required
            />
          </div>
          <div className="offset-5">
            {errorProgram.programNumber.message ? (
              <span className="error-text">
                {errorProgram.programNumber.message}
              </span>
            ) : null}
          </div>
        </div>
        {/* row ends */}
        {/* row starts */}
        <div className="form-group row column align-items-center">
          <div className="col-5 text-right">
            <label>Description</label>
          </div>
          <div className="col-7 column px-0">
            <input
              type="text"
              className="form-control"
              name="description"
              value={program.description}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholder="Program Description"
              required
            />
          </div>
          <div className="offset-5">
            {errorProgram.description.message ? (
              <span className="error-text">
                {errorProgram.description.message}
              </span>
            ) : null}
          </div>
        </div>
        {/* row ends */}
        {/* row starts */}
        {action === "UPDATE" && (
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Subject</label>
            </div>
            <div className="col-7 px-0">
              <select
                className="form-control"
                name="subject"
                value={program.subject}
                onChange={handleSelectSubjectChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
              >
                <option> Select Subject </option>
                {optionsSubject}
              </select>
            </div>
            <div className="offset-5">
              {errorProgram.subject.message ? (
                <span className="error-text">
                  {errorProgram.subject.message}
                </span>
              ) : null}
            </div>
          </div>
        )}
        {/* row ends */}
        {/* row starts */}
        {action === "UPDATE" && (
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Chapter</label>
            </div>
            <div className="col-7 px-0">
              <select
                className="form-control"
                name="chapter"
                value={program.chapter}
                onChange={handleSelectChapterChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
              >
                <option> Select Chapter </option>
                {optionsChapter}
              </select>
            </div>
            <div className="offset-5">
              {errorProgram.chapter.message ? (
                <span className="error-text">
                  {errorProgram.chapter.message}
                </span>
              ) : null}
            </div>
          </div>
        )}
        {/* row ends */}
        {/* row starts */}
        <div className="form-group row column align-items-center">
          <div className="col-5 text-right">
            <label>Instructions</label>
          </div>
          <div className="col-7 px-0">
            <textarea
              type="text"
              className="form-control"
              rows="4"
              cols="50"
              name="instructions"
              value={program.instructions}
              onChange={handleChange}
              placeholder="Nothing"
            />
          </div>
          <div className="offset-5">
            {errorProgram.instructions.message ? (
              <span className="error-text">
                {errorProgram.instructions.message}
              </span>
            ) : null}
          </div>
        </div>
        {/* row ends */}
        {/* row starts */}
        <div className="form-group row column align-items-center">
          <div className="col-5 text-right">
            <label>File Location</label>
          </div>
          <div className="col-7 px-0">
            <select
              className="form-control"
              name="location"
              value={program.location}
              onChange={handleSelectLocationChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
            >
              <option> Select </option>
              {optionsLocation}
            </select>
          </div>
          <div className="offset-5">
            {errorProgram.location.message ? (
              <span className="error-text">
                {errorProgram.location.message}
              </span>
            ) : null}
          </div>
        </div>
        {/* row ends */}
        {/* row starts */}
        <div className="form-group row column align-items-center">
          <div className="col-5 text-right">
            <label>Link - if any</label>
          </div>
          <div className="col-7 column px-0">
            <input
              type="text"
              className="form-control"
              name="link"
              value={program.link}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholder="File Link"
            />
          </div>
          <div className="offset-5">
            {errorProgram.link.message ? (
              <span className="error-text">{errorProgram.link.message}</span>
            ) : null}
          </div>
        </div>
        {/* row ends */}

        {/* row starts */}
        <div className="form-group row column align-items-center">
          <div className="col-5 text-right">
            <label>Available for Demo?</label>
          </div>
          <div className="col-7 column px-0">
            <input
              type="radio"
              className="form-control-inline"
              name="availableForDemo"
              value="0"
              onClick={() => handleAvailableForDemoSelection("0")}
              onChange={() => handleAvailableForDemoSelection("0")}
              checked={program.availableForDemo == "0"}
            />
            &nbsp;No &nbsp;&nbsp;&nbsp;
            <input
              type="radio"
              className="form-control-inline"
              name="availableForDemo"
              value="1"
              onClick={() => handleAvailableForDemoSelection("1")}
              onChange={() => handleAvailableForDemoSelection("1")}
              checked={program.availableForDemo == "1"}
            />
            &nbsp;Yes&nbsp;&nbsp;
          </div>
        </div>
        {/* row starts */}
        <div className="form-group row offset-5 text-center mb-0">
          <button
            className="btn-mycyan"
            type="submit"
            disabled={flagFormInvalid}
          >
            {action}
          </button>
        </div>
        <input type="hidden" name="programId" value={program.programId} />
      </form>
    </>
  );
}
export default AdminProgramAdd;
