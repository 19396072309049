import React from "react";
import { FaStar } from "react-icons/fa";

class AdminCourseAdd extends React.Component {
  emptyCourse = {
    courseId: "",
    name: "",
    fees: "",
    courseType: "",
    isBundle: "",
    installment: "",
    referralDiscount: "",
  };
  errorCourse = {
    name: { message: "", mxLen: 80, mnLen: 1, onlyDigits: false },
    fees: { message: "", onlyDigits: true },
    installment: { message: "", onlyDigits: true },
    referralDiscount: { message: "", onlyDigits: true },
  };
  constructor(props) {
    super(props);
    let course;
    let flagFormInvalid;
    let { action } = this.props;
    if (action === "ADD") {
      flagFormInvalid = true;
      course = this.emptyCourse;
    } else if (action === "UPDATE") {
      // in edit mode, keep the update button enabled at the beginning
      flagFormInvalid = false;
      course = this.props.course;
    }
    this.state = {
      course: course,
      errorCourse: this.errorCourse,
      flagFormInvalid: flagFormInvalid,
    };
  }

  handleChange = (event) => {
    let { errorCourse } = this.state;
    let name = event.target.name;
    let message;
    let userInput;
    const isCheckbox = event.target.type === "checkbox";
    if (isCheckbox) {
      // for checkbox, no checking of validations.
      userInput = event.target.checked;
      this.setState({
        course: { ...this.state.course, [name]: userInput },
      });
      return;
    }
    let value = event.target.value;
    //check of max. length
    let mxLen = errorCourse[`${name}`].mxLen;
    let onlyDigits = errorCourse[`${name}`].onlyDigits;
    if (value.length > mxLen) {
      value = value.slice(0, mxLen + 1);
      if (!onlyDigits) message = "Maximum " + mxLen + " characters allowed";
      else message = "Maximum " + mxLen + " digits allowed";
    } //if...
    else {
      message = "";
    }
    errorCourse[`${name}`].message = message;
    this.setState({
      errorCourse: errorCourse,
      course: { ...this.state.course, [name]: value },
    });
    this.checkAllErrors(errorCourse);
  };
  handleBlur = (event) => {
    let name,
      value,
      requiredMessage = " is required",
      type,
      message = "",
      mnLen,
      onlyDigits;
    let { errorCourse } = this.state;
    name = event.target.name;
    type = event.target.type;
    if (type === "text" || type === "textarea") {
      // check if 'required', also check min no. of characters
      value = event.target.value.trim();
      mnLen = errorCourse[`${name}`].mnLen;
      onlyDigits = errorCourse[`${name}`].onlyDigits;
      if (value.length === 0) {
        message = name + requiredMessage;
      } else if (value.length < mnLen) {
        if (!onlyDigits) message = "Min " + mnLen + " characters required";
        else message = "Min " + mnLen + " digits required";
      } //else
      else if (onlyDigits) {
        for (let i = value.length - 1; i >= 0; i--) {
          const d = value.charCodeAt(i);
          if (d < 48 || d > 57) {
            message = "Enter only digits";
            break;
          } //if
        } //for
      } //else if... onlyDigits
      else {
        message = "";
      }
    } //if... text || textarea
    else if (event.target.type === "select-one") {
      value = event.target.selectedIndex;

      if (value === 0) {
        // message = name + requiredMessage;
        // Currently no checking of category and brand
        message = "";
      } else {
        message = "";
      }
    } //else if
    errorCourse[`${name}`].message = message;
    this.setState({ errorCourse: errorCourse });
    this.checkAllErrors(errorCourse);
  };
  handleFocus = (event) => {
    let name = event.target.name;
    let errorCourse = this.state.errorCourse;
    errorCourse[`${name}`].message = "";
    this.setState({ errorCourse: errorCourse });
    this.checkAllErrors(errorCourse);
  };
  checkAllErrors = (errorCourse) => {
    // let { errorCourse } = this.state;
    let flagFormInvalid = false;
    for (let field in errorCourse) {
      if (errorCourse[field].message !== "") {
        // error is there
        flagFormInvalid = true;
        break;
      } //if
    } //for
    this.setState({ flagFormInvalid: flagFormInvalid });
  };
  handleCourseTypeSelection = (value) => {
    this.setState({
      course: { ...this.state.course, courseType: value },
    });
  };
  handleIsBundleSelection = (value) => {
    this.setState({
      course: { ...this.state.course, isBundle: value },
    });
  };
  handleUpload = (event) => {
    this.setState({
      imageName: event.target.files[0],
    });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    window.scrollTo(0, 0); // moving scrollbar at the top
    this.props.onFormSubmit(this.state.course); //this.state
  };
  render() {
    let { course } = this.state;
    let { errorCourse } = this.state;
    let { flagFormInvalid } = this.state;
    let { action } = this.props;
    let { languageChoice } = this.props;
    let heading;
    if (action === "ADD") {
      heading = "Add a Course";
    } else if (action === "UPDATE") {
      heading = "Update the Course";
    }
    return (
      <React.Fragment>
        <div className="form-heading column col-12">{heading}</div>
        <form
          // className="container container-add-form text-dark bg-warning p-4"
          className="text-dark bg-mycyan p-4"
          onSubmit={this.handleSubmit}
        >
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Course Name</label>
            </div>
            <div className="col-7 column px-0">
              <input
                type="text"
                className="form-control"
                name="name"
                value={course.name}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                placeholder="Course Name"
                required
              />
            </div>
            <div className="offset-5">
              {errorCourse.name.message ? (
                <span className="error-text">{errorCourse.name.message}</span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Course Type</label>
            </div>
            <div className="col-7 column px-0">
              <input
                type="radio"
                className="form-control-inline"
                name="courseType"
                value="0"
                onClick={() => this.handleCourseTypeSelection("0")}
                onChange={() => this.handleCourseTypeSelection("0")}
                checked={course.courseType == "0"}
              />
              &nbsp;Offline &nbsp;&nbsp;&nbsp;
              <input
                type="radio"
                className="form-control-inline"
                name="courseType"
                value="1"
                onClick={() => this.handleCourseTypeSelection("1")}
                onChange={() => this.handleCourseTypeSelection("1")}
                checked={course.courseType == "1"}
              />
              &nbsp;Online+Offline&nbsp;&nbsp;
              <input
                type="radio"
                className="form-control-inline"
                name="courseType"
                value="2"
                onClick={() => this.handleCourseTypeSelection("2")}
                onChange={() => this.handleCourseTypeSelection("2")}
                checked={course.courseType == "2"}
              />
              &nbsp;Bootcamp&nbsp;
            </div>
          </div>
          {/* row starts */}

          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Fees</label>
            </div>
            <div className="col-7 column px-0">
              <input
                type="text"
                className="form-control"
                name="fees"
                value={course.fees}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                placeholder="Course Fees"
                required
              />
            </div>
            <div className="offset-5">
              {errorCourse.fees.message ? (
                <span className="error-text">{errorCourse.fees.message}</span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Bundled Course?</label>
            </div>
            <div className="col-7 column px-0">
              <input
                type="radio"
                className="form-control-inline"
                name="isBundle"
                value="0"
                onClick={() => this.handleIsBundleSelection("0")}
                onChange={() => this.handleIsBundleSelection("0")}
                checked={course.isBundle == "0"}
              />
              &nbsp;No&nbsp;&nbsp;&nbsp;
              <input
                type="radio"
                className="form-control-inline"
                name="isBundle"
                value="1"
                onClick={() => this.handleIsBundleSelection("1")}
                onChange={() => this.handleIsBundleSelection("1")}
                checked={course.isBundle == "1"}
              />
              &nbsp;Yes&nbsp;&nbsp;
            </div>
          </div>
          {/* row starts */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>
                {(+course.isBundle ? "First " : " Only") + " Installment"}
              </label>
            </div>
            <div className="col-7 column px-0">
              <input
                type="text"
                className="form-control"
                name="installment"
                value={course.installment}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                placeholder="Installment"
                required
              />
            </div>
            <div className="offset-5">
              {errorCourse.installment.message ? (
                <span className="error-text">
                  {errorCourse.installment.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>
                Referral Discount
              </label>
            </div>
            <div className="col-7 column px-0">
              <input
                type="text"
                className="form-control"
                name="referralDiscount"
                value={course.referralDiscount}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                placeholder="Discount if referred"
                required
              />
            </div>
            <div className="offset-5">
              {errorCourse.referralDiscount.message ? (
                <span className="error-text">
                  {errorCourse.referralDiscount.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row offset-5 text-center mb-0">
            <button
              className="btn-mycyan"
              type="submit"
              disabled={flagFormInvalid}
            >
              {action}
            </button>
          </div>
          <input type="hidden" name="courseId" value={course.courseId} />
        </form>
      </React.Fragment>
    );
  }
}
export default AdminCourseAdd;
