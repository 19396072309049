export class AdmissionDB {
  constructor(admissions) {
    this.admissions = admissions;
  }
  allAdmissions() {
    return this.admissions;
  }
  searchByAdmissionId(id) {
    for (let i = 0; i < this.admissions.length; i++) {
      if (this.admissions[i].admissionId == id) {
        return this.admissions[i];
      }
    }
    return null; // not found
  }
  filterByName(query) {
    let filteredAdmissions = [];
    for (let i = 0; i < this.admissions.length; i++) {
      if (this.admissions[i].name.toLowerCase().includes(query.toLowerCase())) {
        filteredAdmissions.push(this.admissions[i]);
      }
    } //for
    return filteredAdmissions;
  }
}
export default AdmissionDB;
