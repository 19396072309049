import React, { Component } from "react";
import AdminLoginRecordList from "./admin-login-record-list";
import { LoginRecordDB } from "../LoginRecordDB";
import { BeatLoader } from "react-spinners";
import axios from "axios";

class AdminLoginRecord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      response: {},
      loginRecord: null,
      filteredLoginRecords: [],
      loginRecordList: [],
      loginRecorddb: {},
      flagLoading: false,
      searchText: "",
      sortedField: "",
      sortedDirection: false,
      sectionNumber: 1,
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  getLoginRecordListFromDatabase = () => {
    this.setState({
      flagLoading: true,
    });
    axios
      .all([axios.get(window.routerPrefix + "/loginRecord/loginRecords")])
      .then(
        axios.spread((res1) => {
          let loginRecordList = res1.data;
          let updatedLoginRecords = [...loginRecordList];

          loginRecordList = updatedLoginRecords;
          this.setState({
            loginRecordList: loginRecordList,
            filteredLoginRecords: loginRecordList,
            loginRecorddb: new LoginRecordDB(loginRecordList),
            flagLoading: false,
            // initFlag: true,
            /* Reason for using this initFlag: This parent has got ajax calls so,
during rendering of child error obtained, undefined props property,
so as log as initFlag is false, (i.e. data is not fetched), do not render
the parent ( so obviously child is also not rendered). 21.05.2020
https://stackoverflow.com/questions/54219364/react-function-call-after-render-and-before-childs-constructor
*/
          });
        })
      )
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  };
  componentDidMount() {
    this.setState({
      flagLoading: true,
    });
    axios
      .all([axios.get(window.routerPrefix + "/loginRecord/loginRecords/")])
      .then(
        axios.spread((res1) => {
          let loginRecordList = res1.data;
          let updatedLoginRecords = [...loginRecordList];
          loginRecordList = updatedLoginRecords;
          this.setState({
            loginRecordList: loginRecordList,
            loginRecorddb: new LoginRecordDB(loginRecordList),
            filteredLoginRecords: loginRecordList,
            flagLoading: false,
          });
        })
      )
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  }
  handleSearchTextBoxKeyUp = (event) => {
    let searchText = event.target.value;
    this.setState({ searchText: searchText });
    this.performSearchOperation(searchText);
  };
  handleSearchTextChange = (event) => {
    let searchText = event.target.value;
    this.setState({ searchText: searchText });
    this.performSearchOperation(searchText);
  };
  performSearchOperation = (searchText) => {
    let query = searchText.trim();
    let searchedLoginRecords = [];
    searchedLoginRecords = this.state.loginRecorddb.filterByName(query);
    this.setState({
      filteredLoginRecords: searchedLoginRecords,
    });
  };

  onFormSubmit(data) {
    // No add, edit only delete available
  }
  // handleShowChapters = (loginRecord) => {
  //   this.props.onShowChapters(loginRecord);
  // };
  addToLoginRecordList = (loginRecord) => {
    let loginRecordList = [...this.state.loginRecordList];
    loginRecordList.unshift(loginRecord);
    let filteredLoginRecords = [...this.state.filteredLoginRecords];
    filteredLoginRecords.unshift(loginRecord);
    this.setState({
      filteredLoginRecords: filteredLoginRecords,
      loginRecordList: loginRecordList,
    });
    let message =
      "The loginRecord '" + loginRecord.name + "' added successfully.";
    this.props.onCrudAction(message, "ADD");
  };
  updateLoginRecordList = (loginRecord) => {
    let loginRecordList = [...this.state.loginRecordList];
    for (let i = 0; i < loginRecordList.length; i++) {
      if (loginRecordList[i].loginRecordId == loginRecord.loginRecordId) {
        //loginRecordList[i] = loginRecord;
        loginRecordList.splice(i, 1); // remove 1 element from position i
        // and insert at the beginning as we want latest modified at the top
        loginRecordList.unshift(loginRecord);
      }
    } //for
    // remove from filteredchapters also
    let filteredLoginRecords = [...this.state.filteredLoginRecords];
    for (let i = 0; i < filteredLoginRecords.length; i++) {
      if (filteredLoginRecords[i].loginRecordId == loginRecord.loginRecordId) {
        // filteredLoginRecords[i] = loginRecord;
        filteredLoginRecords.splice(i, 1); // remove 1 element from position i
        // and insert at the beginning as we want latest modified at the top
        filteredLoginRecords.unshift(loginRecord);
      }
    } //for
    //sorting

    this.setState({
      //   chapterdb: new ChapterDB(chapterList),
      filteredLoginRecords: filteredLoginRecords,
      loginRecordList: loginRecordList,
      // searchText: "",
    });
    let message =
      "The loginRecord '" + loginRecord.name + "' updated successfully.";
    this.props.onCrudAction(message, "LIST", "Add a LoginRecord");
  };

  handleDeleteLoginRecord = (loginRecord) => {
    let loginRecordList = [...this.state.loginRecordList];

    for (let i = 0; i < loginRecordList.length; i++) {
      if (loginRecordList[i].loginRecordId == loginRecord.loginRecordId) {
        loginRecordList.splice(i, 1); // remove 1 element from position i
      }
    } //for
    // remove from filteredloginRecords also
    let filteredLoginRecords = [...this.state.filteredLoginRecords];
    for (let i = 0; i < filteredLoginRecords.length; i++) {
      if (filteredLoginRecords[i].loginRecordId == loginRecord.loginRecordId) {
        filteredLoginRecords.splice(i, 1); // remove 1 element from position i
      }
    } //for

    this.setState({
      loginRecorddb: new LoginRecordDB(loginRecordList),
      filteredLoginRecords: filteredLoginRecords,
      loginRecordList: loginRecordList,
    });
    let message =
      "The loginRecord '" + loginRecord.fullName + "' deleted successfully.";
    this.props.onCrudAction(message, "LIST");
  };

  handleHeaderClick = (event) => {
    let field = event.target.id;

    let direction = false;
    if (field === this.state.sortedField) {
      // same button clicked twice
      direction = !this.state.direction;
    } else {
      // different field
      direction = false;
    }
    this.setState({ direction: direction });
    let { filteredLoginRecords } = this.state;
    if (direction == false) {
      //in ascending order
      filteredLoginRecords = filteredLoginRecords.sort((a, b) => {
        if (a[field] > b[field]) {
          return 1;
        }
        if (a[field] < b[field]) {
          return -1;
        }
        return 0;
      });
    } else {
      //in descending order
      filteredLoginRecords = filteredLoginRecords.sort((a, b) => {
        if (a[field] < b[field]) {
          return 1;
        }
        if (a[field] > b[field]) {
          return -1;
        }
        return 0;
      });
    }
    this.setState({
      filteredLoginRecords: filteredLoginRecords,
      sortedField: field,
    });
  };
  handleSectionNumberClick = (event) => {
    this.setState({ sectionNumber: event.target.id });
  };
  render() {
    let { flagLoading } = this.state;
    let { action } = this.props;
    let { loginRecord } = this.state;
    let content;
    if (flagLoading) {
      content = (
        <div className="text-center">
          <BeatLoader size={16} color={"blue"} flagLoading />
        </div>
      );
    } else if (action === "LIST") {
      // First show list
      content = (
        <AdminLoginRecordList
          onDeleteLoginRecord={this.handleDeleteLoginRecord}
          filteredLoginRecords={this.state.filteredLoginRecords}
          searchText={this.state.searchText}
          onSearchTextChange={this.handleSearchTextChange}
          onSearchTextBoxKeyUp={this.handleSearchTextBoxKeyUp}
          onHeaderClick={this.handleHeaderClick}
          onSectionNumberClick={this.handleSectionNumberClick}
          sectionNumber={this.state.sectionNumber}
        />
      );
    } else if (action === "DELETE") {
      content = <div>Delete action in Progress...</div>;
    }
    return (
      <div className="container-fluid container-md container-content-page  p-0">
        {content}
      </div>
    );
  }
}
export default AdminLoginRecord;
