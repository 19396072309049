import React from "react";
import { Link } from "react-router-dom";
import AdminPaymentListSingle from "./admin-payment-list-single";

function AdminPaymentList(props) {
  function handleEditPayment(payment) {
    props.onEditPayment(payment);
  }
  function handleShowChapters(payment) {
    props.onShowChapters(payment);
  }
  function handleCrudAction(message, action) {
    props.onCrudAction(message, action);
  }
  function handleDeletePayment(payment) {
    props.onDeletePayment(payment);
  }
  function handleSearchTextBoxKeyUp(event) {
    props.onSearchTextBoxKeyUp(event);
  }
  function handleSearchTextChange(event) {
    props.onSearchTextChange(event);
  }
  function handleHeaderClick(event) {
    props.onHeaderClick(event);
  }
  function handleSectionNumberClick(event) {
    props.onSectionNumberClick(event);
  }
  let { filteredPayments } = props;
  let { searchText } = props;
  let { currentSize } = props;
  let { accessLevel } = props;
  let { adminType } = props;
  let { recordSize } = props;

  let contentNopayments = <div className="text-center">No payments !</div>;
  return (
    <>
      {filteredPayments.length == 0 && (
        <div className="text-center">No payments !</div>
      )}
      <div className="form-heading  col-12">
        LIST OF payments ({filteredPayments.length})
      </div>
      {/* row starts */}
      {recordSize != 0 && (
        <>
          <div className="row justify-content-center myborder">
            <div className="col-6 my-2 ">
              <input
                type="search"
                className="container-fluid form-control"
                value={searchText}
                onKeyUp={handleSearchTextBoxKeyUp}
                onChange={handleSearchTextChange} // This is contolled by parent
                placeholder="Search payments"
                id=""
              />
            </div>
          </div>
          {/* row ends */}
          <div className="row p-0 justify-content-center ">
            <div className="col-2 col-md-1"></div>
            <div className="col-8 col-md-4">
              <Link to="#" onClick={handleHeaderClick} id="name">
                Name
              </Link>
            </div>
            <div className="col-2">&nbsp;</div>
          </div>
          {filteredPayments.map((payment, index) => (
            <AdminPaymentListSingle
              key={index}
              index={index}
              payment={payment}
              onDeletePayment={handleDeletePayment}
              onEditPayment={handleEditPayment}
              onCrudAction={handleCrudAction}
              currentSize={currentSize}
              accessLevel={accessLevel}
              adminType={adminType}
            />
          ))}
        </>
      )}
    </>
  );
}
export default AdminPaymentList;
