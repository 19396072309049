import React, { Component } from "react";
import AdminBatchAccessList from "./admin-batch-access-list";
import AdminBatchAccessAdd from "./admin-batch-access-add";
import { BatchAccessDB } from "../BatchAccessDB";
import { BeatLoader } from "react-spinners";
import axios from "axios";
class AdminBatchAccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      response: {},
      batchAccess: null,
      filteredAccesses: [],
      batchList: [],
      batchAccessList: [],
      selectedBatch: "",
      flagLoading: false,
      searchText: "",
      sortedField: "",
      sortedDirection: false,
      sectionNumber: 1,
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  getAccessListFromDatabase = (batch, batchId) => {
    this.setState({
      flagLoading: true,
    });
    axios
      .all([
        axios.get(
          window.routerPrefix + "/batchAccess/batchAccessesByBatchId/" + batchId
        ),
        axios.get(window.routerPrefix + "/subject/subjects/"),
      ])
      .then(
        axios.spread((res1, res2) => {
          let batchAccessList = res1.data;
          let subjectList = res2.data;
          // Add subject to batchAccesslist
          let updatedAccesss = [...batchAccessList];
          batchAccessList.map((batchAccess, index) => {
            for (let i = 0; i < subjectList.length; i++) {
              if (batchAccess.subjectId === subjectList[i].subjectId) {
                updatedAccesss[index].subject = subjectList[i].name;
                break;
              }
            }
          }); //map
          batchAccessList = updatedAccesss;
          batchAccessList.map((batchAccess, index) => {
            batchAccess.batch = batch;
          }); //map
          this.setState({
            batchAccessList: batchAccessList,
            filteredAccesses: batchAccessList,
            batchAccessdb: new BatchAccessDB(batchAccessList),
            flagLoading: false,
          });
        })
      )
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  };
  componentDidMount() {
    // List of students should be ready
    this.setState({
      flagLoading: true,
    });
    axios
      .get(window.routerPrefix + "/batch/batches/")
      .then((res) => {
        let batchList = res.data;
        // Only "offline" batches required
        batchList = batchList.filter((batch, index) => batch.type == "Offline");
        this.setState({
          batchList: batchList,
          flagLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  }
  handleSearchTextBoxKeyUp = (event) => {
    let searchText = event.target.value;
    this.setState({ searchText: searchText });
    this.performSearchOperation(searchText);
  };
  handleSearchTextChange = (event) => {
    let searchText = event.target.value;
    this.setState({ searchText: searchText });
    this.performSearchOperation(searchText);
  };
  performSearchOperation = (searchText) => {
    let query = searchText.trim();
    let searchedAccesses = [];
    searchedAccesses = this.state.batchAccessdb.filterByName(query);
    this.setState({
      filteredAccesses: searchedAccesses,
    });
  };
  onFormSubmit(data) {
    let { action } = this.props;
    this.setState({
      flagLoading: true,
    });
    if (action === "UPDATE") {
      //EDIT / PUT
      axios
        .put(window.routerPrefix + "/batchAccess/update", data)
        .then((res) => {
          this.updateAccessList(data);
          this.setState({
            response: res.data,
            flagLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            flagLoading: false,
          });
          //   this.props.askToLogin();
        });
    } else if (action === "ADD") {
      //ADD / POST
      axios
        .post(window.routerPrefix + "/batchAccess/addBatchAccess", data, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
          },
        })
        .then((res) => {
          this.setState({
            flagLoading: false,
            response: res.data,
          });
          data.batchAccessId = res.data.batchAccessId; // id of the added batchAccess is returned as response
          this.setState({
            studentToRetain: data.student,
            studentIdToRetain: data.studentId,
            flagLoading: false,
          });

          this.addToAccessList(data);
        })
        .catch((err) => {
          console.log("error!");
          this.setState({
            flagLoading: false,
          });
        });
    } //else
  }
  handleEditAccess = (batchAccess) => {
    this.setState({
      batchAccess: batchAccess,
      searchText: "",
    });
    this.props.onEditClick();
  };

  addToAccessList = (batchAccess) => {
    let batchAccessList = [...this.state.batchAccessList];
    batchAccessList.unshift(batchAccess); // insert at the beginning
    let filteredAccesses = [...this.state.filteredAccesses];
    filteredAccesses.unshift(batchAccess);
    this.setState({
      filteredAccesses: filteredAccesses,
      batchAccessList: batchAccessList,
    });
    let message =
      "The batchAccess '" + batchAccess.name + "' added successfully.";
    this.props.onCrudAction(message, "ADD");
  };
  updateAccessList = (batchAccess) => {
    let batchAccessList = [...this.state.batchAccessList];
    for (let i = 0; i < batchAccessList.length; i++) {
      if (batchAccessList[i].batchAccessId == batchAccess.batchAccessId) {
        if (batchAccessList[i].studentId == batchAccess.studentId) {
          // update, but it should be at the beginning
          // batchAccessList[i] = batchAccess;
          batchAccessList.splice(i, 1); // remove 1 element from position i
          // and insert at the beginning as we want latest modified at the top
          batchAccessList.unshift(batchAccess);
        } else {
          //delete from current list
          batchAccessList.splice(i, 1); // remove 1 element from position i
        }
        break;
      } //if
    } //for
    // remove from filteredbatchAccesss also
    let filteredAccesses = [...this.state.filteredAccesses];
    for (let i = 0; i < filteredAccesses.length; i++) {
      if (filteredAccesses[i].batchAccessId == batchAccess.batchAccessId) {
        if (filteredAccesses[i].studentId == batchAccess.studentId) {
          // update
          // filteredAccesss[i] = batchAccess;
          filteredAccesses.splice(i, 1); // remove 1 element from position i
          // and insert at the beginning as we want latest modified at the top
          filteredAccesses.unshift(batchAccess);
        } else {
          //delete from current list
          filteredAccesses.splice(i, 1); // remove 1 element from position i
        }
        break;
      } //if
    } //for
    this.setState({
      filteredAccesses: filteredAccesses,
      batchAccessList: batchAccessList,
      // searchText: "",
    });
    let message =
      "The batchAccess '" + batchAccess.name + "' updated successfully.";
    this.props.onCrudAction(message, "LIST", "Add a BatchAccess");
  };

  handleDeleteAccess = (batchAccess) => {
    let batchAccessList = [...this.state.batchAccessList];
    for (let i = 0; i < batchAccessList.length; i++) {
      if (batchAccessList[i].batchAccessId == batchAccess.batchAccessId) {
        batchAccessList.splice(i, 1); // remove 1 element from position i
      }
    } //for
    // remove from filteredbatchAccesss also
    let filteredAccesses = [...this.state.filteredAccesses];
    for (let i = 0; i < filteredAccesses.length; i++) {
      if (filteredAccesses[i].batchAccessId == batchAccess.batchAccessId) {
        filteredAccesses.splice(i, 1); // remove 1 element from position i
      }
    } //for

    this.setState({
      filteredAccesses: filteredAccesses,
      batchAccessList: batchAccessList,
    });
    let message =
      "The batchAccess '" + batchAccess.name + "' deleted successfully.";
    this.props.onCrudAction(message, "LIST");
  };

  handleHeaderClick = (event) => {
    let field = event.target.id;

    let direction = false;
    if (field === this.state.sortedField) {
      // same button clicked twice
      direction = !this.state.direction;
    } else {
      // different field
      direction = false;
    }
    this.setState({ direction: direction });
    let { filteredAccesss } = this.state;
    if (direction == false) {
      //in ascending order
      filteredAccesss = filteredAccesss.sort((a, b) => {
        if (a[field] > b[field]) {
          return 1;
        }
        if (a[field] < b[field]) {
          return -1;
        }
        return 0;
      });
    } else {
      //in descending order
      filteredAccesss = filteredAccesss.sort((a, b) => {
        if (a[field] < b[field]) {
          return 1;
        }
        if (a[field] > b[field]) {
          return -1;
        }
        return 0;
      });
    }
    this.setState({ filteredAccesss: filteredAccesss, sortedField: field });
  };
  handleSectionNumberClick = (event) => {
    this.setState({ sectionNumber: event.target.id });
  };
  handleSelectBatchChange = (selectedBatch, selectedBatchId) => {
    // This is called for LIST as well ADD operation.
    this.getAccessListFromDatabase(selectedBatch, selectedBatchId);
    this.setState({
      selectedBatch: selectedBatch,
      selectedBatchId: selectedBatchId,
    });
    if (selectedBatch) {
      this.props.showAddButton();
    }
  };
  render() {
    let { flagLoading } = this.state;
    let { action } = this.props;
    let { batchAccess } = this.state;
    let { batchList } = this.state;
    let { selectedBatch } = this.state;
    let { selectedBatchId } = this.state;
    let { accessLevel } = this.props;
    let { adminType } = this.props;

    let content;
    if (flagLoading) {
      content = (
        <div className="text-center">
          <BeatLoader size={16} color={"blue"} flagLoading />
        </div>
      );
    } else if (action === "LIST") {
      // First show list
      content = (
        <AdminBatchAccessList
          batchList={batchList}
          onSelectBatchChange={this.handleSelectBatchChange}
          selectedBatch={selectedBatch}
          onEditAccess={this.handleEditAccess}
          onDeleteAccess={this.handleDeleteAccess}
          filteredAccesses={this.state.filteredAccesses}
          searchText={this.state.searchText}
          onSearchTextChange={this.handleSearchTextChange}
          onSearchTextBoxKeyUp={this.handleSearchTextBoxKeyUp}
          onHeaderClick={this.handleHeaderClick}
          onSectionNumberClick={this.handleSectionNumberClick}
          sectionNumber={this.state.sectionNumber}
          onDuplicateAccess={this.handleDuplicateAccess}
          currentSize={this.state.batchAccessList.length}
          accessLevel={accessLevel}
          adminType={adminType}
        />
      );
    } else if (action === "ADD" || action === "UPDATE") {
      // After pressing Add Button, show only form and not the list
      content = (
        <div>
          {/* content={" "} */}
          <AdminBatchAccessAdd
            onFormSubmit={this.onFormSubmit}
            batchList={batchList}
            batchAccess={batchAccess}
            action={action}
            selectedBatch={selectedBatch}
            selectedBatchId={selectedBatchId}
            studentToRetain={this.state.studentToRetain}
            studentIdToRetain={this.state.studentIdToRetain}
          />
        </div>
      );
    } else if (action === "DELETE") {
      content = <div>Delete action in Progress...</div>;
    }
    return (
      <div className="container-fluid container-md container-content-page p-0">
        {content}
      </div>
    );
  }
}
export default AdminBatchAccess;
