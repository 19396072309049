import React, { Component } from "react";
import axios from "axios";
import { BeatLoader } from "react-spinners";
import { withRouter } from "react-router";
import NavBarAdmin from "./navbar-admin";
import AdminWorkshop from "./admin-workshop";
import AdminSubject from "./admin-subject";
import AdminStudent from "./admin-student";
import AdminEnquiredStudent from "./admin-enquired-student";
import AdminBatch from "./admin-batch";
import AdminAdmission from "./admin-admission";
import AdminAdmissionBatch from "./admin-admission-batch";
import AdminPayment from "./admin-payment";
import AdminCourseBundle from "./admin-coursebundle";
import AdminCourse from "./admin-course";
import AdminChapter from "./admin-chapter";
import AdminAccess from "./admin-access";
import AdminBatchAccess from "./admin-batch-access";
import AdminProgram from "./admin-program";
import AdminVideo from "./admin-video";
import AdminNote from "./admin-note";
import AdminExerProgram from "./admin-exer-program";
import AdminMiscProgram from "./admin-misc-program";
import AdminVLength from "./admin-vlength";
import AdminNoteslength from "./admin-noteslength";
import AdminSettings from "./admin-settings";
import AdminCertificateData from "./admin-certificate-data";
import AdminAdm from "./admin-adm";
import AdminLogin from "./admin-login";
import AdminLoginRecord from "./admin-login-record";
import AdminLoginFirst from "./admin-login-first";
import AdminReportLog from "./admin-report-log";

// There are 3 categories of subjects (subject means product, student, ...)
//1. No facility of "Add Subject"
//2. Immediate facility of "Add Subject"
//3. Facility of "Add Subject" only after getting certain data
// e.g. chapters. Only after selecting subject, chapter can be added.
// for Home-page message, no facility of add, only edit
// 4. Many to many 31.08.2022
// Many subjects in a courses.
//Same subjects may be there in many courses
// 5. Nothing to do with add/edit/list
class AdminActivity extends Component {
  adminManageList = [
    {
      name: "Workshops",
      subject: "WORKSHOP",
      type: 2,
      dispLevel: 1,
      accessLevel: 4,
    },
    {
      name: "Subjects",
      subject: "SUBJECT",
      type: 2,
      dispLevel: 1,
      accessLevel: 4,
    },
    {
      name: "Chapters",
      subject: "CHAPTER",
      type: 3,
      dispLevel: 1,
      accessLevel: 4,
    },
    { name: "Videos", subject: "VIDEO", type: 3, dispLevel: 1, accessLevel: 4 },
    {
      name: "Notes Videos",
      subject: "NOTE",
      type: 3,
      dispLevel: 1,
      accessLevel: 4,
    },
    {
      name: "Programs",
      subject: "PROGRAM",
      type: 3,
      dispLevel: 1,
      accessLevel: 4,
    },
    {
      name: "Exercise Programs",
      subject: "EXERCISE",
      type: 3,
      dispLevel: 1,
      accessLevel: 4,
    },
    {
      name: "Misc. Programs",
      subject: "MPROGRAM",
      type: 3,
      dispLevel: 1,
      accessLevel: 4,
    },
    { name: "Tests", subject: "TEST", type: 3, dispLevel: 4, accessLevel: 4 },
    {
      name: "Video Length",
      subject: "VIDEOLENGTH",
      type: 5,
      description: "",
      dispLevel: 4,
      accessLevel: 4,
    },
    {
      name: "Notes Length",
      subject: "NOTESLENGTH",
      type: 5,
      description: "",
      dispLevel: 4,
      accessLevel: 4,
    },
    {
      name: "Certificates Data",
      subject: "CERTIFICATEDATA",
      type: 5,
      description: "",
      dispLevel: 4,
      accessLevel: 4,
    },
    {
      name: "Courses",
      subject: "COURSE",
      type: 2,
      dispLevel: 4,
      accessLevel: 4,
    },
    {
      name: "Course Bundle",
      subject: "COURSEBUNDLE",
      type: 3,
      dispLevel: 4,
      accessLevel: 4,
    },
    { name: "Admin", subject: "ADMIN", type: 2, dispLevel: 4, accessLevel: 4 },
    {
      name: "Login Records",
      subject: "LOGINRECORD",
      type: 2,
      dispLevel: 1,
      accessLevel: 1,
    },
  ];
  // There are 3 categories of subjects (subject means product, student, ...)
  //1. No facility of "Add Subject"
  //2. Immediate facility of "Add Subject"
  //3. Facility of "Add Subject" only after getting certain data
  // e.g. chapters. Only after selecting subject, chapter can be added.
  // for Home-page message, no facility of add, only edit
  // 4. Many to many 31.08.2022
  // Many subjects in a courses.
  //Same subjects may be there in many courses
  adminControlList = [
    { name: "Students", subject: "STUDENT", dispLevel: 1, accessLevel: 4 },
    { name: "Enquiries", subject: "ENQUIRY", dispLevel: 1, accessLevel: 4 },
    { name: "Admissions", subject: "ADMISSION", dispLevel: 1, accessLevel: 4 },
    {
      name: "Admissions(Batchwise)",
      subject: "ADMISSIONBATCH",
      dispLevel: 2,
      accessLevel: 2,
    },
    {
      name: "Payments",
      subject: "PAYMENT",
      dispLevel: 4,
      accessLevel: 4,
    },
    {
      name: "Access (studentwise) ",
      subject: "ACCESS",
      dispLevel: 1,
      accessLevel: 1,
    },
    { name: "Batches", subject: "BATCH", dispLevel: 4, accessLevel: 4 },
    {
      name: "Batch-Access",
      subject: "BATCHACCESS",
      dispLevel: 4,
      accessLevel: 4,
    },
    {
      name: "Settings",
      subject: "SETTINGS",
      dispLevel: 1,
      accessLevel: 4,
    },
  ];
  // adminUtilitiesList
  adminReportList = [
    { name: "Logs", subject: "LOG" },
    { name: "Batches", subject: "BATCH" },
    { name: "TempPassword", subject: "TEMPPASSWORD" },
  ];
  constructor(props) {
    super(props);
    this.state = {
      target: "ADMINHOMEPAGE",
      subject: "",
      action: "LIST", // Initially show LIST, can be switched to ADD
      menu: "",
      type: "",
      message: "",
      selectedMenuIndex: 0,
      validAdmin: false, // false will show login form,
      flagFirstTimeLogin: 1, // 0 means not logged first time
      masterListName: "",
      flagLoading: false,
      programType: "",
      // true will show admin activities (valid admin)
    };
  }
  handleLoginFormSubmit = (result) => {
    // validAdmin, flagFirstTimeLogin
    let message = "";
    let validAdmin = false;
    // result=true; // added for convenience
    if (result == -1) {
      message = "Invalid username. Try again";
    } else if (result == 0) {
      message = "Invalid password. Try again";
    } else {
      // log in success, but its first time
      message = "Login Success";
      validAdmin = true;
    }
    this.setState({
      message: message,
      validAdmin: validAdmin,
      adminType: result, // Modified on 14.08.2023
      // adminType level is 4 for head, 3 for Owner, 2 for Teacher and 1 for Instructor
    });
  };
  handleLoginFirstFormSubmit = (status) => {
    let message = "";
    let flagFirstTimeLogin;
    if (status === false) {
      message = "Entered old password is wrong, try again";
      flagFirstTimeLogin = 0;
      status = !status; // user need no login using userName
    } else if (status === true) {
      // log in success, password changed successfully
      message = "Password is modified successfully";
      flagFirstTimeLogin = 1;
    }
    this.setState({
      validAdmin: status,
      flagFirstTimeLogin: flagFirstTimeLogin,
      message: message,
    });
  };

  handleLogoutClick = () => {
    // we have to destroy the session
    this.setState({
      flagLoading: true,
    });
    let data = { admin: "admin" };
    axios
      .post(window.routerPrefix + "/admin/logout", data, {
        headers: {
          "Accept-Language": "en-US,en;q=0.8",
        },
      })
      .then((res) => {
        let sessionExists = !res.data; // false if logged out
        this.setState({
          flagLoading: false,
          validAdmin: sessionExists,
          message: "",
          menu: "",
          target: "ADMINHOMEPAGE", // show admin home page when users logs-in again
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  };
  handleMasterListClick = () => {
    this.setState({
      target: "MASTERLIST",
      message: "",
      menu: "",
    });
  };
  handleAddListToggleClick = () => {
    let { action } = this.state;
    let { target } = this.state;
    let { menu } = this.state;
    let { type } = this.state;
    if (type == 5) {
      this.setState({
        action: "DO",
        menu: menu,
        message: "",
      });
      return;
    }

    if (action === "LIST") {
      action = "ADD";
      if (target == "MANAGE") {
        menu =
          "List of " +
          this.adminManageList[this.state.selectedMenuIndex]["name"];
      } else if (target == "CONTROL") {
        menu =
          "List of " +
          this.adminControlList[this.state.selectedMenuIndex]["name"];
      }
    } //if
    else if (action === "ADD" || action === "UPDATE") {
      action = "LIST";
      if (target == "MANAGE") {
        menu =
          "Add a " +
          this.adminManageList[this.state.selectedMenuIndex]["subject"];
      } else if (target == "CONTROL") {
        menu =
          "Add a " +
          this.adminControlList[this.state.selectedMenuIndex]["subject"];
      }
    } //...else if

    this.setState({
      action: action,
      menu: menu,
      message: "",
    });
  };
  componentDidMount() {}
  handleAdminControlClick = (event) => {
    let index = event.target.id;
    let subject = this.adminControlList[index]["subject"];
    let type = this.adminControlList[index]["type"];
    let accessLevel = this.adminControlList[index]["accessLevel"];
    this.setState({
      target: "CONTROL",
      selectedMenuIndex: index,
      subject: subject,
      accessLevel: accessLevel,
      action: "LIST",
      message: "",
    });
    if (subject === "STUDENT") {
      // This is type-2
      this.setState({
        menu: "Add a " + subject, //Subject
      });
    } else if (subject === "ENQUIRY") {
      // This is type-2
      this.setState({
        menu: "Add a " + subject, //Subject
      });
    } else if (subject === "BATCH") {
      // This is type-2
      this.setState({
        menu: "Add a " + subject, //Subject
      });
    } else {
      // This is type-3
      this.setState({
        menu: "",
      });
    }
    window.scrollTo(0, 0); // moving scrollbar at the top
  };
  handleAdminManageClick = (event) => {
    let index = event.target.id;
    let subject = this.adminManageList[index]["subject"];
    let type = this.adminManageList[index]["type"];
    let accessLevel = this.adminManageList[index]["accessLevel"];
    this.setState({
      target: "MANAGE",
      type: type,
      selectedMenuIndex: index,
      accessLevel: accessLevel,
      subject: subject,
      action: "LIST",
      message: "",
    });
    // There are 3 categories of subjects (subject means product, student, ...)
    //1. No facility of "Add Subject"
    //2. Immediate facility of "Add Subject"
    //3. Facility of "Add Subject" only after getting certain data
    // e.g. chapters. Only after selecting subject, chapter can be added.
    // for Home-page message, no facility of add, only edit
    // 4. Many to many 31.08.2022
    // Many subjects in a courses.
    //Same subjects may be there in many courses

    if (subject === "PROGRAM") {
      // This is type-3
      this.setState({
        programType: "program",
      });
    } else if (subject === "EXERCISE") {
      // This is type-3
      this.setState({
        programType: "exercise",
      });
    } else if (subject === "MISCPROGRAM") {
      // This is type-3
      this.setState({
        programType: "mprogram",
      });
    } else if (subject === "VIDEO") {
      // This is type-3
      this.setState({
        programType: "video",
      });
    } else if (subject === "NOTE") {
      // This is type-3
      this.setState({
        programType: "note",
      });
    } else if (subject === "LOGINRECORD") {
      // This is type-1
      this.setState({
        menu: "",
      });
    } else if (subject === "SUBJECT") {
      // This is type-2
      this.setState({
        menu: "Add a " + subject, //Subject
      });
    } else if (subject === "ADMIN") {
      // This is type-2
      this.setState({
        menu: "Add a " + subject, //Subject
      });
    } else if (subject === "COURSE") {
      // This is type-2
      this.setState({
        menu: "Add a " + subject, //Course
      });
    } else if (subject === "VIDEOLENGTH") {
      // This is type-5
      this.setState({
        menu: "Get Video Length of a chapter",
      });
    } else if (subject === "NOTESLENGTH") {
      // This is type-5
      this.setState({
        menu: "Get Notes Video Length of a chapter",
      });
    } else if (subject === "CERTIFICATEDATA") {
      // This is type-5
      this.setState({
        menu: "Get certificate related data of admissions",
      });
    } else {
      // This is type-3
      this.setState({
        menu: "",
      });
    }
    let description = this.adminManageList[index]["description"];
    if (type == 1) {
      // This is type-1
      this.setState({
        menu: "",
      });
    } else if (type == 3) {
      // This is type-3
      this.setState({
        programType: subject.toLowerCase(),
      });
    } else if (type == 2) {
      // This is type-2
      this.setState({
        menu: "Add a " + subject,
      });
    } else if (type == 5) {
      // This is type-5
      this.setState({
        menu: description,
      });
    }
    window.scrollTo(0, 0); // moving scrollbar at the top
  };

  handleAdminReportClick = (event) => {
    let index = event.target.id;
    let subject = this.adminReportList[index]["subject"];
    this.setState({
      target: "REPORT",
      subject: subject,
      action: "LIST",
      menu: "",
      message: "",
    });
    window.scrollTo(0, 0); // moving scrollbar at the top
  };
  showAddButton = () => {
    // This is type-3
    let { subject } = this.state;
    this.setState({
      menu: "Add a " + subject,
    });
  };

  handleCrudAction = (message, action, menu) => {
    if (menu) {
      this.setState({ menu: menu });
    }
    this.setState({ message: message, action: action });
    // for home-page message, show admin-home-page
    //ADMINHOMEPAGE
    if (this.state.subject === "HOMEPAGE MESSAGE") {
      this.setState({ target: "ADMINHOMEPAGE" });
    }
    // window.scrollTo(0, 0); // moving scrollbar at the top
  };
  handleEditClick = () => {
    let { target } = this.state;
    let menu; //  = this.state;
    if (target == "MANAGE") {
      menu =
        "List of " + this.adminManageList[this.state.selectedMenuIndex]["name"];
    } else if (target == "CONTROL") {
      menu =
        "List of " +
        this.adminControlList[this.state.selectedMenuIndex]["name"];
    }

    // if (subject === "SUBJECT") {
    //   menu = "List of Subjects";
    // }
    this.setState({ action: "UPDATE", menu: menu, message: "" });
    window.scrollTo(0, 0); // moving scrollbar at the top
  };
  askToLogin = () => {
    this.setState({
      validAdmin: false,
      message: "Session expired, login-again",
      target: "ADMINHOMEPAGE",
    });
  };
  handleBackClick = () => {
    this.setState({
      target: "ADMINHOMEPAGE",
      subject: "",
    });
  };
  render() {
    let { flagLoading } = this.state;
    if (flagLoading) {
      return (
        <div className="text-center">
          <BeatLoader size={16} color={"blue"} flagLoading />
        </div>
      );
    }
    let { target } = this.state;
    let { subject } = this.state;
    let { action } = this.state;
    let { menu } = this.state;
    let { message } = this.state;
    let { validAdmin } = this.state;
    let { flagFirstTimeLogin } = this.state;
    let { programType } = this.state;
    let { adminType } = this.state;
    let { accessLevel } = this.state;

    let content;
    //
    return (
      <>
        {true && (
          <NavBarAdmin
            adminManageList={this.adminManageList}
            adminControlList={this.adminControlList}
            adminReportList={this.adminReportList}
            onAdminManageClick={this.handleAdminManageClick}
            onAdminControlClick={this.handleAdminControlClick}
            onAdminReportClick={this.handleAdminReportClick}
            onAddListToggleClick={this.handleAddListToggleClick}
            onLogoutClick={this.handleLogoutClick}
            onMasterListClick={this.handleMasterListClick}
            menu={menu}
            message={message}
            validAdmin={validAdmin}
            adminType={adminType}
            flagFirstTimeLogin={flagFirstTimeLogin}
          />
        )}
        {!validAdmin && (
          <AdminLogin onLoginFormSubmit={this.handleLoginFormSubmit} />
        )}
        {validAdmin && flagFirstTimeLogin == 0 && (
          <AdminLoginFirst
            onLoginFirstFormSubmit={this.handleLoginFirstFormSubmit}
          />
        )}
        {target === "MANAGE" && subject === "WORKSHOP" && (
          <AdminWorkshop
            action={action}
            menu={menu}
            onCrudAction={this.handleCrudAction}
            onEditClick={this.handleEditClick}
            adminType={adminType}
            accessLevel={accessLevel}
          />
        )}
        {target === "MANAGE" && subject === "SUBJECT" && (
          <AdminSubject
            action={action}
            menu={menu}
            onCrudAction={this.handleCrudAction}
            onEditClick={this.handleEditClick}
            adminType={adminType}
            accessLevel={accessLevel}
          />
        )}
        {target === "MANAGE" && subject === "COURSE" && (
          <AdminCourse
            action={action}
            menu={menu}
            onCrudAction={this.handleCrudAction}
            onEditClick={this.handleEditClick}
            adminType={adminType}
            accessLevel={accessLevel}
          />
        )}
        {target === "MANAGE" && subject === "COURSEBUNDLE" && (
          <AdminCourseBundle
            action={action}
            menu={menu}
            onCrudAction={this.handleCrudAction}
            onEditClick={this.handleEditClick}
            showAddButton={this.showAddButton}
            adminType={adminType}
            accessLevel={accessLevel}
          />
        )}
        {target === "MANAGE" && subject === "CHAPTER" && (
          <AdminChapter
            action={action}
            menu={menu}
            onCrudAction={this.handleCrudAction}
            onEditClick={this.handleEditClick}
            showAddButton={this.showAddButton}
            adminType={adminType}
            accessLevel={accessLevel}
          />
        )}
        {target === "MANAGE" && subject === "VIDEO" && (
          <AdminVideo
            action={action}
            menu={menu}
            programType={programType}
            onCrudAction={this.handleCrudAction}
            onEditClick={this.handleEditClick}
            showAddButton={this.showAddButton}
            adminType={adminType}
            accessLevel={accessLevel}
          />
        )}
        {target === "MANAGE" && subject === "NOTE" && (
          <AdminNote
            action={action}
            menu={menu}
            programType={programType}
            onCrudAction={this.handleCrudAction}
            onEditClick={this.handleEditClick}
            showAddButton={this.showAddButton}
            adminType={adminType}
            accessLevel={accessLevel}
          />
        )}
        {target === "MANAGE" && subject === "PROGRAM" && (
          <AdminProgram
            action={action}
            menu={menu}
            programType={programType}
            onCrudAction={this.handleCrudAction}
            onEditClick={this.handleEditClick}
            showAddButton={this.showAddButton}
            adminType={adminType}
            accessLevel={accessLevel}
          />
        )}
        {target === "MANAGE" && subject === "MPROGRAM" && (
          <AdminMiscProgram
            action={action}
            menu={menu}
            programType={programType}
            onCrudAction={this.handleCrudAction}
            onEditClick={this.handleEditClick}
            showAddButton={this.showAddButton}
            adminType={adminType}
            accessLevel={accessLevel}
          />
        )}
        {target === "MANAGE" && subject === "EXERCISE" && (
          <AdminExerProgram
            action={action}
            menu={menu}
            programType={programType}
            onCrudAction={this.handleCrudAction}
            onEditClick={this.handleEditClick}
            showAddButton={this.showAddButton}
            adminType={adminType}
            accessLevel={accessLevel}
          />
        )}
        {target === "MANAGE" && subject === "VIDEOLENGTH" && (
          <AdminVLength
            action={action}
            // action="UPDATE" // No add option here
            // action="Find length of video" // No add option here
            menu={menu}
            onCrudAction={this.handleCrudAction}
            onEditClick={this.handleEditClick}
            adminType={adminType}
            accessLevel={accessLevel}
          />
        )}
        {target === "MANAGE" && subject === "NOTESLENGTH" && (
          <AdminNoteslength
            action={action}
            // action="UPDATE" // No add option here
            // action="Find length of video" // No add option here
            menu={menu}
            onCrudAction={this.handleCrudAction}
            onEditClick={this.handleEditClick}
            adminType={adminType}
            accessLevel={accessLevel}
          />
        )}
        {target === "MANAGE" && subject === "CERTIFICATEDATA" && (
          <AdminCertificateData
            action={action}
            menu={menu}
            onCrudAction={this.handleCrudAction}
            onEditClick={this.handleEditClick}
            adminType={adminType}
            accessLevel={accessLevel}
          />
        )}
        {target === "MANAGE" && subject === "ADMIN" && (
          <AdminAdm
            action={action}
            menu={menu}
            onCrudAction={this.handleCrudAction}
            onEditClick={this.handleEditClick}
            adminType={adminType}
            accessLevel={accessLevel}
          />
        )}
        {target === "MANAGE" && subject === "LOGINRECORD" && (
          <AdminLoginRecord
            action={action}
            menu={menu}
            onCrudAction={this.handleCrudAction}
            onEditClick={this.handleEditClick}
            adminType={adminType}
            accessLevel={accessLevel}
          />
        )}
        {target === "CONTROL" && subject === "STUDENT" && (
          <AdminStudent
            action={action}
            menu={menu}
            onCrudAction={this.handleCrudAction}
            onEditClick={this.handleEditClick}
            adminType={adminType}
            accessLevel={accessLevel}
          />
        )}
        {target === "CONTROL" && subject === "ENQUIRY" && (
          <AdminEnquiredStudent
            action={action}
            menu={menu}
            onCrudAction={this.handleCrudAction}
            onEditClick={this.handleEditClick}
            adminType={adminType}
            accessLevel={accessLevel}
          />
        )}
        {target === "CONTROL" && subject === "ADMISSION" && (
          <AdminAdmission
            action={action}
            menu={menu}
            onCrudAction={this.handleCrudAction}
            onEditClick={this.handleEditClick}
            showAddButton={this.showAddButton}
            adminType={adminType}
            accessLevel={accessLevel}
          />
        )}
        {target === "CONTROL" && subject === "ADMISSIONBATCH" && (
          <AdminAdmissionBatch
            action={action}
            menu={menu}
            onCrudAction={this.handleCrudAction}
            onEditClick={this.handleEditClick}
            showAddButton={this.showAddButton}
            adminType={adminType}
            accessLevel={accessLevel}
          />
        )}
        {target === "CONTROL" && subject === "PAYMENT" && (
          <AdminPayment
            action={action}
            menu={menu}
            onCrudAction={this.handleCrudAction}
            onEditClick={this.handleEditClick}
            showAddButton={this.showAddButton}
            adminType={adminType}
            accessLevel={accessLevel}
          />
        )}
        {target === "CONTROL" && subject === "BATCH" && (
          <AdminBatch
            action={action}
            menu={menu}
            onCrudAction={this.handleCrudAction}
            onEditClick={this.handleEditClick}
            adminType={adminType}
            accessLevel={accessLevel}
          />
        )}
        {target === "CONTROL" && subject === "ACCESS" && (
          <AdminAccess
            action={action}
            menu={menu}
            onCrudAction={this.handleCrudAction}
            onEditClick={this.handleEditClick}
            showAddButton={this.showAddButton}
            adminType={adminType}
            accessLevel={accessLevel}
          />
        )}
        {target === "CONTROL" && subject === "BATCHACCESS" && (
          <AdminBatchAccess
            action={action}
            menu={menu}
            onCrudAction={this.handleCrudAction}
            onEditClick={this.handleEditClick}
            showAddButton={this.showAddButton}
            adminType={adminType}
            accessLevel={accessLevel}
          />
        )}
        {target === "CONTROL" && subject === "SETTINGS" && (
          <AdminSettings
            action="Update" // No add option here
            menu={menu}
            onCrudAction={this.handleCrudAction}
            onEditClick={this.handleEditClick}
            showAddButton={this.showAddButton}
            adminType={adminType}
            accessLevel={accessLevel}
          />
        )}
        {target === "REPORT" && subject === "LOG" && (
          <AdminReportLog onCrudAction={this.handleCrudAction} />
        )}

        {/* {content} */}
      </>
    );
    //
  }
}
export default withRouter(AdminActivity);
