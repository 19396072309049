import React, { useEffect, useState } from "react";
import AdminProgramList from "./admin-program-list";
import AdminProgramAdd from "./admin-program-add";
import { ProgramDB } from "../ProgramDB";
import { BeatLoader } from "react-spinners";
import axios from "axios";
function AdminProgram(props) {
  let [flagLoading, setFlagLoading] = useState(false);
  let [error, setError] = useState("");
  let [searchText, setSearchText] = useState("");
  let [direction, setDirection] = useState("");
  let [sortedField, setSortedField] = useState("");
  let [sectionNumber, setSectionNumber] = useState(1);
  let [filteredPrograms, setFilteredPrograms] = useState([]);
  let [programList, setProgramList] = useState([]);
  let [programdb, setProgramdb] = useState({});
  let [program, setProgram] = useState(null);
  let [subjectList, setSubjectList] = useState([]);
  let [chapterList, setChapterList] = useState([]);
  let [lastProgramNumber, setLastProgramNumber] = useState(0);
  let [selectedSubject, setSelectedSubject] = useState("");
  let [selectedSubjectId, setSelectedSubjectId] = useState(-1);
  let [selectedChapter, setSelectedChapter] = useState("");
  let [selectedChapterId, setSelectedChapterId] = useState(-1);
  let [flagSubjectChange, setFlagSubjectChange] = useState(false);
  let [flagChapterChange, setFlagChapterChange] = useState(false);
  let [flagChapterLoading, setFlagChapterLoading] = useState(false);
  let [flagProgramLoading, setFlagProgramLoading] = useState(false);
  function getProgramListFromDatabase(chapter, chapterId) {
    let { programType } = props;
    setFlagProgramLoading(true);
    setFlagChapterChange(true);
    axios
      .get(
        window.routerPrefix +
          "/" +
          programType +
          "/" +
          programType +
          "s/" +
          chapterId
      )
      .then((res) => {
        let programList = res.data;
        let updatedPrograms = programList.map((program, index) => {
          program.chapter = chapter;
          program.subject = selectedSubject;
          return program;
        }); //map
        programList = updatedPrograms;
        getLastProgramNumber(programList);
        setProgramList(programList);
        setFilteredPrograms(programList);
        setProgramdb(new ProgramDB(programList));
        setFlagProgramLoading(false);
      })
      .catch((err) => {
        setError(err);
        setFlagProgramLoading(false);
      });
  }
  useEffect(() => {
    init();
  }, []);
  function init() {
    // For LIST as well as ADD, we will need list of subjects
    setFlagLoading(true);
    axios
      .get(window.routerPrefix + "/subject/subjects/")
      .then((res) => {
        setFlagLoading(false);
        setSubjectList(res.data);
      })
      .catch((err) => {
        setError(err);
        setFlagLoading(false);
      });
  }
  function handleSearchTextBoxKeyUp(event) {
    let searchText = event.target.value;
    setSearchText(searchText);
    performSearchOperation(searchText);
  }
  function handleSearchTextChange(event) {
    let searchText = event.target.value;
    setSearchText(searchText);
    performSearchOperation(searchText);
  }
  function performSearchOperation(searchText) {
    let query = searchText.trim();
    let searchedPrograms = [];
    searchedPrograms = programdb.filterByName(query);
    setFilteredPrograms(searchedPrograms);
  }
  function onFormSubmit(data) {
    let { action } = props;
    let { programType } = props;
    setFlagLoading(true);
    if (action === "UPDATE") {
      //EDIT / PUT
      axios
        // .put("/program/update", data)
        .put(window.routerPrefix + "/" + programType + "/update", data)
        .then((res) => {
          setFlagLoading(false);
          if (res.data) {
            updateProgramList(data);
          } else {
            // Error, something went wrong
          }
        })
        .catch((err) => {
          console.log(err);
          setFlagLoading(false);
        });
    } else if (action === "ADD") {
      // This is not uploading file 27.08.2022
      // file is directly copied to the folder
      // Here first letter is made capital
      let s = programType.charAt(0).toUpperCase() + programType.slice(1);
      axios
        // .post("/program/addProgram", data, {
        .post(window.routerPrefix + "/" + programType + "/add" + s, data, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
          },
        })
        .then((res) => {
          setFlagLoading(false);
          if (res.data) {
            data.programId = res.data.programId; // id of the added program is returned as response
            addToProgramList(data);
            // setState({
            //   subjectToRetain: data.subject,
            //   subjectIdToRetain: data.subjectId,
            // });
          } else {
            //Error, something went wrong
          }
        })
        .catch((err) => {
          setError(err);
          setFlagLoading(false);
        });
    } //else
  }
  function handleEditProgram(program) {
    setProgram(program);
    setSearchText("");
    props.onEditClick();
  }
  function addToProgramList(program) {
    let pList = [...programList];
    pList.unshift(program);
    let fPrograms = [...filteredPrograms];
    fPrograms.unshift(program);
    setFilteredPrograms(fPrograms);
    setProgramList(pList);
    let { programType } = props;
    let message =
      "The " +
      programType +
      " number'" +
      program.programNumber +
      "' added successfully.";
    getLastProgramNumber(pList);
    props.onCrudAction(message, "ADD");
  }
  function updateProgramList(program) {
    let pList = [...programList];
    for (let i = 0; i < pList.length; i++) {
      if (pList[i].programId == program.programId) {
        if (
          pList[i].subjectId == program.subjectId &&
          pList[i].chapterId == program.chapterId
        ) {
          // update, but it should be at the beginning
          //  programList[i] = program;
          pList.splice(i, 1); // remove 1 element from position i
          // and insert at the beginning as we want latest modified at the top
          pList.unshift(program);
        } else {
          //delete from current list
          pList.splice(i, 1); // remove 1 element from position i
        }
        break;
      } //if
    } //for
    // remove from filteredprograms also
    let fPrograms = [...filteredPrograms];
    for (let i = 0; i < fPrograms.length; i++) {
      if (fPrograms[i].programId == program.programId) {
        if (
          fPrograms[i].subject == program.subject &&
          fPrograms[i].chapter == program.chapter
        ) {
          // update, but it should be at the beginning
          // filteredPrograms[i] = program;

          fPrograms.splice(i, 1); // remove 1 element from position i
          // and insert at the beginning as we want latest modified at the top
          fPrograms.unshift(program);
        } else {
          //delete from current list
          fPrograms.splice(i, 1); // remove 1 element from position i
        }
        break;
      } //if
    } //for
    setFilteredPrograms(fPrograms);
    setProgramList(pList);
    let { programType } = props;
    let message = "The '" + programType + "' updated successfully.";
    props.onCrudAction(message, "LIST", "Add a Program");
  }

  function handleDeleteProgram(program) {
    let pList = [...programList];
    for (let i = 0; i < pList.length; i++) {
      if (pList[i].programId == program.programId) {
        pList.splice(i, 1); // remove 1 element from position i
      }
    } //for
    // remove from filteredprograms also
    let fPrograms = [...filteredPrograms];
    for (let i = 0; i < fPrograms.length; i++) {
      if (fPrograms[i].programId == program.programId) {
        fPrograms.splice(i, 1); // remove 1 element from position i
        break;
      }
    } //for
    setFilteredPrograms(fPrograms);
    setProgramList(pList);
    let message =
      "The program '" + program.programNumber + "' deleted successfully.";
    props.onCrudAction(message, "LIST");
  }
  function getLastProgramNumber(programList) {
    let lastProgramNumber = 0;
    for (let i = 0; i < programList.length; i++) {
      if (programList[i].programNumber > lastProgramNumber) {
        lastProgramNumber = programList[i].programNumber;
      } //if
    } //for
    setLastProgramNumber(lastProgramNumber);
  }
  function handleHeaderClick(event) {
    let field = event.target.id;
    let d = false;
    if (field === sortedField) {
      // same button clicked twice
      d = !direction;
    } else {
      // different field
      d = false;
    }
    setDirection(d);
    if (d == false) {
      //in ascending order
      filteredPrograms = filteredPrograms.sort((a, b) => {
        if (a[field] > b[field]) {
          return 1;
        }
        if (a[field] < b[field]) {
          return -1;
        }
        return 0;
      });
    } else {
      //in descending order
      filteredPrograms = filteredPrograms.sort((a, b) => {
        if (a[field] < b[field]) {
          return 1;
        }
        if (a[field] > b[field]) {
          return -1;
        }
        return 0;
      });
    }
    setFilteredPrograms(filteredPrograms);
    setSortedField(field);
  }
  function handleSectionNumberClick(event) {
    setSectionNumber(event.target.id);
  }
  function handleSelectSubjectChange(event) {
    // This is called during LIST only
    // As subject is changed, list of chapters should also change.
    let index = event.target.selectedIndex; // get selected index, instad of selected value
    let optionElement = event.target.childNodes[index];
    let selectedSubjectId = optionElement.getAttribute("id");
    let selectedSubject = event.target.value;
    setSelectedSubject(selectedSubject);
    setSelectedSubjectId(selectedSubjectId);
    getChapterListFromDatabase(selectedSubject, selectedSubjectId);
  }
  function getChapterListFromDatabase(subject, subjectId) {
    setFlagChapterLoading(true);
    setFlagSubjectChange(true);
    axios
      .get(window.routerPrefix + "/chapter/chapters/" + subjectId)
      .then((res) => {
        let chapterList = res.data;
        let updatedChapters;
        updatedChapters = chapterList.map((chapter, index) => {
          chapter.subject = subject;
          return chapter;
        }); //map
        setChapterList(updatedChapters);
        setFlagChapterLoading(false);
      })
      .catch((err) => {
        setError(err);
        setFlagChapterLoading(false);
      });
  }
  function handleSelectChapterChange(event) {
    let index = event.target.selectedIndex;
    var optionElement = event.target.childNodes[index];
    var selectedChapterId = optionElement.getAttribute("id");
    let selectedChapter = event.target.value;
    setSelectedChapter(selectedChapter);
    setSelectedChapterId(selectedChapterId);
    getProgramListFromDatabase(selectedChapter, selectedChapterId);
    if (selectedSubject && selectedChapter) {
      // Only when subject is selected and chapter is selected, show "add" button
      props.showAddButton();
    }
  }
  let { action } = props;
  let { programType } = props;
  let { accessLevel } = props;
  let { adminType } = props;

  let optionsSubject = subjectList.map((subject, index) => (
    <option value={subject.name} key={index} id={subject.subjectId}>
      {subject.name}
    </option>
  ));
  let optionsChapter = chapterList.map((chapter, index) => (
    <option value={chapter.name} key={index} id={chapter.chapterId}>
      {chapter.name}
    </option>
  ));
  return (
    <div className="container-fluid container-md container-content-page p-0">
      {flagLoading && (
        <div className="text-center">
          <BeatLoader size={16} color={"blue"} flagLoading />
        </div>
      )}
      {action == "LIST" && (
        <div className="row justify-content-center select-element">
          {!flagLoading && (
            <div className="col-7 my-1">
              <select
                className="form-control"
                name="selectedSubject"
                value={selectedSubject}
                onChange={handleSelectSubjectChange}
              >
                <option> Select Subject </option>
                {optionsSubject}
              </select>
            </div>
          )}
          {flagChapterLoading && (
            <div className="col-12 col-md-7 text-center">
              <BeatLoader size={16} color={"blue"} flagChapterLoading />
            </div>
          )}
          {/* Then select the chapter  */}
          {selectedSubject && !flagChapterLoading && flagSubjectChange && (
            <div className="col-7 my-1">
              <select
                className="form-control"
                name="selectedChapter"
                value={selectedChapter}
                onChange={handleSelectChapterChange}
              >
                <option> Select Chapter</option>
                {optionsChapter}
              </select>
            </div>
          )}
          {flagProgramLoading && (
            <div className="col-12 col-md-7 text-center">
              <BeatLoader size={16} color={"blue"} flagProgramLoading />
            </div>
          )}
          {!flagProgramLoading &&
            selectedChapter &&
            programList.length != 0 && (
              <div className="form-heading  col-12">
                LIST OF {programType}s ({filteredPrograms.length})
              </div>
            )}
          {!flagProgramLoading &&
            selectedChapter &&
            programList.length == 0 && (
              <div className="form-heading  col-12 thick-red-text">
                No {programType}s
              </div>
            )}
          {/* row starts */}
          {!flagProgramLoading &&
            selectedChapter &&
            programList.length != 0 && (
              <div className="col-6 my-2 column">
                <input
                  type="search"
                  className="container-fluid form-control"
                  value={searchText}
                  onKeyUp={handleSearchTextBoxKeyUp}
                  onChange={handleSearchTextChange} // This is contolled by parent
                  placeholder="Search"
                  id=""
                />
              </div>
            )}
        </div>
      )}
      {!flagProgramLoading && action == "LIST" && (
        <AdminProgramList
          subjectList={subjectList}
          chapterList={chapterList}
          programType={programType}
          onSelectSubjectChange={handleSelectSubjectChange}
          onSelectChapterChange={handleSelectChapterChange}
          selectedSubject={selectedSubject}
          selectedChapter={selectedChapter}
          onEditProgram={handleEditProgram}
          onDeleteProgram={handleDeleteProgram}
          filteredPrograms={filteredPrograms}
          searchText={searchText}
          onSearchTextChange={handleSearchTextChange}
          onSearchTextBoxKeyUp={handleSearchTextBoxKeyUp}
          onHeaderClick={handleHeaderClick}
          onSectionNumberClick={handleSectionNumberClick}
          sectionNumber={sectionNumber}
          flagSubjectChange={flagSubjectChange}
          accessLevel={accessLevel}
          adminType={adminType}
        />
      )}
      {!flagProgramLoading && (action === "ADD" || action === "UPDATE") && (
        <AdminProgramAdd
          onFormSubmit={onFormSubmit}
          subjectList={subjectList}
          chapterList={chapterList}
          program={program}
          programType={programType}
          action={action}
          lastProgramNumber={lastProgramNumber}
          flagSubjectChange={flagSubjectChange}
          selectedSubject={selectedSubject}
          selectedSubjectId={selectedSubjectId}
          selectedChapter={selectedChapter}
          selectedChapterId={selectedChapterId}
          // subjectToRetain={subjectToRetain}
          // subjectIdToRetain={subjectIdToRetain}
          // chapterToRetain={chapterToRetain}
          // chapterIdToRetain={chapterIdToRetain}
        />
      )}
    </div>
  );
}
export default AdminProgram;
