import React, { Component } from "react";
import { BeatLoader } from "react-spinners";
import "./css/final-yaadi.css";
import { Link } from "react-router-dom";
import axios from "axios";

class DsaBatch extends Component {
  errorObject = {
    emailId: { message: "" },
  };
  constructor(props) {
    super(props);

    this.state = {
      studentId: "",
      courseId: "",
      flagLoadingCertificate: false,
    };
  }
  handleChange = (event) => {
    let { errorObject } = this.state;
    let name = event.target.name;
    let message;
    let value = event.target.value;
    let requiredMessage = " is required";
    // check if 'required', also check min no. of characters
    value = event.target.value.trim();
    if (event.target.type === "email") {
      value = event.target.value.trim();
      if (/\S+@\S+\.\S+/.test(value) == 0) {
        message = name + requiredMessage;
      } else {
        message = "";
      }
    } //else if
    else {
      message = "";
    }
    errorObject[`${name}`].message = message;
    this.setState({
      errorObject: errorObject,
      object: { ...this.state.object, [name]: value },
    });
    this.checkAllErrors(errorObject);
  };
  checkAllErrors = (errorObject) => {
    // let { errorProduct } = this.state;
    let flagFormInvalid = false;
    for (let field in errorObject) {
      if (errorObject[field].message !== "") {
        // error is there
        flagFormInvalid = true;
        break;
      } //if
    } //for
    this.setState({ flagFormInvalid: flagFormInvalid });
  };
  handleFocus = (event) => {
    let name = event.target.name;
    let errorObject = this.state.errorObject;
    errorObject[`${name}`].message = "";
    this.setState({ errorObject: errorObject });
    this.checkAllErrors(errorObject);
  };
  getImageLink = () => {
    let { studentId } = this.state;
    let { courseId } = this.state;
    let serverResponse =
      "/" +
      window.routerPrefix +
      "/files/downloadCertificateImage/" +
      studentId +
      "?courseId=" +
      courseId;
    return serverResponse;
  };
  componentDidMount = () => {
    this.setState({
      flagCheckSession: true,
    });

    axios
      .get(window.routerPrefix + "/general/dsabatchclicked")
      .then((res) => {
        this.setState({
          flagCheckSession: false,
        });
      })
      .catch((err) => {
        this.setState({
          error: err,
          message: "Something went wrong",
          flagCheckSession: false,
        }); // error is set
      });
  };

  render() {
    let { flagCheckSession } = this.state;
    let { flagLinkOk } = this.state;
    let message;
    if (flagCheckSession) {
      return (
        <div className="text-center mt-5">
          <BeatLoader size={16} color={"blue"} flagCheckSession />
        </div>
      );
    }
    return (
      <>
        <div className="container container-certificate-page">
          <div className="row">
            <div className="col-12 text-center mb-3 ">
              <img
                src="/images/rjac_logo.jpg"
                alt="No logo"
                className="img-fluid "
                onClick={this.handleLogoClick}
              />
            </div>
          </div>
          <div className="row div-reference-page text-center">
            <div className="col-12">Welcome to Rajesh Jain Academy.</div>
          </div>
          <div className="row div-reference-page text-center">
            <div className="col-12">
              To know more about us, click &nbsp;&nbsp;
              <Link to={"/"}>here</Link>
            </div>
          </div>

          {flagLinkOk && (
            <div className="row div-reference-page text-center">
              <div className="col-12" style={{ whiteSpace: "pre-wrap" }}>
                {message}
              </div>
            </div>
          )}
          <div className="text-center">
            <img
              className="img-fluid"
              src="images/dsabatch.jpg"
              type="application/pdf"
            />
          </div>
        </div>
      </>
    );
  }
}
export default DsaBatch;
