import React, { Component } from "react";
import AdminAccessList from "./admin-access-list";
import AdminAccessAdd from "./admin-access-add";
import { AccessDB } from "../AccessDB";
import { BeatLoader } from "react-spinners";
import axios from "axios";

class AdminAccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      response: {},
      access: null,
      filteredAccesss: [],
      studentList: [],
      accessList: [],
      selectedStudent: "",
      flagLoading: false,
      searchText: "",
      sortedField: "",
      sortedDirection: false,
      sectionNumber: 1,
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  getAccessListFromDatabase = (student, studentId) => {
    this.setState({
      flagLoading: true,
    });
    axios
      .all([
        axios.get(
          window.routerPrefix + "/access/accesssByStudentId/" + studentId
        ),
        axios.get(window.routerPrefix + "/subject/subjects/"),
      ])
      .then(
        axios.spread((res1, res2) => {
          let accessList = res1.data;
          let subjectList = res2.data;
          // Add subject to accesslist
          let updatedAccesss = [...accessList];
          accessList.map((access, index) => {
            for (let i = 0; i < subjectList.length; i++) {
              if (access.subjectId === subjectList[i].subjectId) {
                updatedAccesss[index].subject = subjectList[i].name;
                break;
              }
            }
          }); //map
          accessList = updatedAccesss;
          accessList.map((access, index) => {
            access.student = student;
          }); //map
          this.setState({
            accessList: accessList,
            filteredAccesss: accessList,
            accessdb: new AccessDB(accessList),
            flagLoading: false,
          });
        })
      )
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  };
  componentDidMount() {
    // List of students should be ready
    this.setState({
      flagLoading: true,
    });
    axios
      .all([axios.get(window.routerPrefix + "/student/students/")])
      .then(
        axios.spread((res1) => {
          let studentList = res1.data;
          this.setState({
            studentList: studentList,
            flagLoading: false,
            // selectedStudent: this.props.selectedStudent,
          });
        })
      )
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  }
  handleSearchTextBoxKeyUp = (event) => {
    let searchText = event.target.value;
    this.setState({ searchText: searchText });
    this.performSearchOperation(searchText);
  };
  handleSearchTextChange = (event) => {
    let searchText = event.target.value;
    this.setState({ searchText: searchText });
    this.performSearchOperation(searchText);
  };
  performSearchOperation = (searchText) => {
    let query = searchText.trim();
    let searchedAccesss = [];
    searchedAccesss = this.state.accessdb.filterByName(query);
    this.setState({
      filteredAccesss: searchedAccesss,
    });
  };
  onFormSubmit(data) {
    let { action } = this.props;
    this.setState({
      flagLoading: true,
    });
    if (action === "UPDATE") {
      //EDIT / PUT
      axios
        .put(window.routerPrefix + "/access/update", data)
        .then((res) => {
          this.updateAccessList(data);
          this.setState({
            response: res.data,
            flagLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            flagLoading: false,
          });
          //   this.props.askToLogin();
        });
    } else if (action === "ADD") {
      //ADD / POST
      axios
        .post(window.routerPrefix + "/access/addAccess", data, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
          },
        })
        .then((res) => {
          this.setState({
            flagLoading: false,
            response: res.data,
          });
          data.accessId = res.data.accessId; // id of the added access is returned as response
          this.setState({
            studentToRetain: data.student,
            studentIdToRetain: data.studentId,
            flagLoading: false,
          });

          this.addToAccessList(data);
        })
        .catch((err) => {
          console.log("error!");
          this.setState({
            flagLoading: false,
          });
        });
    } //else
  }
  handleEditAccess = (access) => {
    this.setState({
      access: access,
      searchText: "",
    });
    this.props.onEditClick();
  };
  // handleShowPrograms = (access) => {
  //   this.props.onShowPrograms(access);
  // };
  // handleShowExerPrograms = (access) => {
  //   this.props.onShowExerPrograms(access);
  // };
  // handleShowMiscPrograms = (access) => {
  //   this.props.onShowMiscPrograms(access);
  // };
  addToAccessList = (access) => {
    let accessList = [...this.state.accessList];
    accessList.unshift(access); // insert at the beginning
    let filteredAccesss = [...this.state.filteredAccesss];
    filteredAccesss.unshift(access);
    this.setState({
      filteredAccesss: filteredAccesss,
      accessList: accessList,
    });
    let message = "The access '" + access.name + "' added successfully.";
    this.props.onCrudAction(message, "ADD");
  };
  updateAccessList = (access) => {
    let accessList = [...this.state.accessList];
    for (let i = 0; i < accessList.length; i++) {
      if (accessList[i].accessId == access.accessId) {
        if (accessList[i].studentId == access.studentId) {
          // update, but it should be at the beginning
          // accessList[i] = access;
          accessList.splice(i, 1); // remove 1 element from position i
          // and insert at the beginning as we want latest modified at the top
          accessList.unshift(access);
        } else {
          //delete from current list
          accessList.splice(i, 1); // remove 1 element from position i
        }
        break;
      } //if
    } //for
    // remove from filteredaccesss also
    let filteredAccesss = [...this.state.filteredAccesss];
    for (let i = 0; i < filteredAccesss.length; i++) {
      if (filteredAccesss[i].accessId == access.accessId) {
        if (filteredAccesss[i].studentId == access.studentId) {
          // update
          // filteredAccesss[i] = access;
          filteredAccesss.splice(i, 1); // remove 1 element from position i
          // and insert at the beginning as we want latest modified at the top
          filteredAccesss.unshift(access);
        } else {
          //delete from current list
          filteredAccesss.splice(i, 1); // remove 1 element from position i
        }
        break;
      } //if
    } //for
    this.setState({
      filteredAccesss: filteredAccesss,
      accessList: accessList,
      // searchText: "",
    });
    let message = "The access '" + access.name + "' updated successfully.";
    this.props.onCrudAction(message, "LIST", "Add a Access");
  };

  handleDeleteAccess = (access) => {
    let accessList = [...this.state.accessList];
    for (let i = 0; i < accessList.length; i++) {
      if (accessList[i].accessId == access.accessId) {
        accessList.splice(i, 1); // remove 1 element from position i
      }
    } //for
    // remove from filteredaccesss also
    let filteredAccesss = [...this.state.filteredAccesss];
    for (let i = 0; i < filteredAccesss.length; i++) {
      if (filteredAccesss[i].accessId == access.accessId) {
        filteredAccesss.splice(i, 1); // remove 1 element from position i
      }
    } //for

    this.setState({
      //   accessdb: new AccessDB(accessList),
      filteredAccesss: filteredAccesss,
      accessList: accessList,
      // searchText: "",
    });
    let message = "The access '" + access.name + "' deleted successfully.";
    this.props.onCrudAction(message, "LIST");
  };

  handleHeaderClick = (event) => {
    let field = event.target.id;

    let direction = false;
    if (field === this.state.sortedField) {
      // same button clicked twice
      direction = !this.state.direction;
    } else {
      // different field
      direction = false;
    }
    this.setState({ direction: direction });
    let { filteredAccesss } = this.state;
    if (direction == false) {
      //in ascending order
      filteredAccesss = filteredAccesss.sort((a, b) => {
        if (a[field] > b[field]) {
          return 1;
        }
        if (a[field] < b[field]) {
          return -1;
        }
        return 0;
      });
    } else {
      //in descending order
      filteredAccesss = filteredAccesss.sort((a, b) => {
        if (a[field] < b[field]) {
          return 1;
        }
        if (a[field] > b[field]) {
          return -1;
        }
        return 0;
      });
    }
    this.setState({ filteredAccesss: filteredAccesss, sortedField: field });
  };
  handleSectionNumberClick = (event) => {
    this.setState({ sectionNumber: event.target.id });
  };
  handleSelectStudentChange = (selectedStudent, selectedStudentId) => {
    // This is called for LIST as well ADD operation.
    this.getAccessListFromDatabase(selectedStudent, selectedStudentId);
    this.setState({
      selectedStudent: selectedStudent,
      selectedStudentId: selectedStudentId,
    });
    if (selectedStudent) {
      this.props.showAddButton();
    }
  };
  render() {
    let { flagLoading } = this.state;
    let { action } = this.props;
    let { access } = this.state;
    let { studentList } = this.state;
    let { selectedStudent } = this.state;
    let { selectedStudentId } = this.state;
    let { accessLevel } = this.props;
    let { adminType } = this.props;

    let content;
    if (flagLoading) {
      content = (
        <div className="text-center">
          <BeatLoader size={16} color={"blue"} flagLoading />
        </div>
      );
    } else if (action === "LIST") {
      // First show list
      content = (
        <AdminAccessList
          studentList={studentList}
          onSelectStudentChange={this.handleSelectStudentChange}
          selectedStudent={selectedStudent}
          onEditAccess={this.handleEditAccess}
          onDeleteAccess={this.handleDeleteAccess}
          filteredAccesss={this.state.filteredAccesss}
          searchText={this.state.searchText}
          onSearchTextChange={this.handleSearchTextChange}
          onSearchTextBoxKeyUp={this.handleSearchTextBoxKeyUp}
          onHeaderClick={this.handleHeaderClick}
          onSectionNumberClick={this.handleSectionNumberClick}
          sectionNumber={this.state.sectionNumber}
          onDuplicateAccess={this.handleDuplicateAccess}
          currentSize={this.state.accessList.length}
          accessLevel={accessLevel}
          adminType={adminType}
        />
      );
    } else if (action === "ADD" || action === "UPDATE") {
      // After pressing Add Button, show only form and not the list
      content = (
        <div>
          {/* content={" "} */}
          <AdminAccessAdd
            onFormSubmit={this.onFormSubmit}
            studentList={studentList}
            access={access}
            action={action}
            selectedStudent={selectedStudent}
            selectedStudentId={selectedStudentId}
            studentToRetain={this.state.studentToRetain}
            studentIdToRetain={this.state.studentIdToRetain}
          />
        </div>
      );
    } else if (action === "DELETE") {
      content = <div>Delete action in Progress...</div>;
    }
    return (
      <div className="container-fluid container-md container-content-page p-0">
        {content}
      </div>
    );
  }
}
export default AdminAccess;
