import React, { Component } from "react";
import NavBtnMenu from "./nav-btn-menu";

class NavBarMenus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }
  handleMenuClick = (menu) => {
    this.props.onMenuClick(menu);
  };
  sh_Menus = () => {
    let { selectedChapterId } = this.props;
    let { selectedSubjectId } = this.props;
    if (selectedSubjectId != -1 && selectedChapterId != -1) return true;
    return false;
  };

  render() {
    let { menuList } = this.props;
    let { flagLoadingChapterContent } = this.props;
    let { selectedMenu } = this.props;
    let { videoDuration } = this.props;
    let { isSelectedChapterReady } = this.props;
    return (
      <React.Fragment>
        <div className="div-nav-menus">
          {this.sh_Menus() &&
            menuList.map(
              (menu, index) =>
                (menu.show == "yes" && (
                  <NavBtnMenu
                    index={index}
                    key={index}
                    menu={menu}
                    videoDuration={videoDuration}
                    selectedMenu={selectedMenu}
                    flagLoadingChapterContent={flagLoadingChapterContent}
                    isSelectedChapterReady={isSelectedChapterReady}
                    onMenuClick={this.handleMenuClick}
                  />
                ))
            )}
        </div>
      </React.Fragment>
    );
  }
}
export default NavBarMenus;
