import React, { Component } from "react";
import "./css/login.css";
import { BeatLoader } from "react-spinners";
import axios from "axios";
class OfflineSecurity extends Component {
  errorSecurity = {
    emailId: { message: "" },
    password: { message: "", onlyDigits: true },
  };
  constructor(props) {
    super(props);
    this.state = {
      security: {
        password: "",
      },
      errorSecurity: this.errorSecurity,
      flagFormInvalid: false,
      flagWhatsappChecked: false,
    };
  }
  handleLoginCancelClick = () => {
    this.props.onLoginCancelClick();
  };
  hideMessage = () => {
    window.setTimeout(() => {
      this.setState({
        message: "",
      });
    }, 5000);
  };
  handleSecurityFormSubmit = (event) => {
    event.preventDefault();
    let data = this.state.security; //this.state
        this.setState({
          flagSecurityCheck: true,
        });
        axios
          .post(window.routerPrefix + "/general/securitycheck", data, {
            headers: {
              accept: "application/json",
              "Accept-Language": "en-US,en;q=0.8",
            },
          })
          .then((res) => {
            this.setState({
              flagLoadingLogin: false,
            });
            let r = res.data; 
            if (r == 0) {
              this.setState({
                message: "Invalid Email-id",
              });
              this.hideMessage();
            } else if (r == 2) {
              // Valid login
               
            }
          })
          .catch((err) => {
            this.setState({
                flagSecurityCheck: false,
              message: "Something went wrong",
            });
            this.hideMessage();
          });
      };
  handleChange = (event) => {
    let { errorSecurity } = this.state;
    let name = event.target.name;
    let message;
    let value = event.target.value;
    let mxLen = errorSecurity[`${name}`].mxLen;
    let onlyDigits = errorSecurity[`${name}`].onlyDigits;
    if (value.length > mxLen) {
      value = value.slice(0, mxLen);
      if (!onlyDigits) message = "Maximum " + mxLen + " characters allowed";
      else message = "Maximum " + mxLen + " digits allowed";
    } //if...
    else {
      message = "";
    }
    errorSecurity[`${name}`].message = message;
    this.setState({
      errorSecurity: errorSecurity,
      security: { ...this.state.security, [name]: value },
    });
    this.checkAllErrors(errorSecurity);
  };
  handleBlur = (event) => {
    let name,
      value,
      requiredMessage = " is required",
      type,
      message = "",
      mnLen,
      onlyDigits;
    let { errorSecurity } = this.state;
    name = event.target.name;
    type = event.target.type;
    if (type === "text" || type === "textarea") {
      // check if 'required', also check min no. of characters
      value = event.target.value.trim();
      mnLen = errorSecurity[`${name}`].mnLen;
      onlyDigits = errorSecurity[`${name}`].onlyDigits;
      if (value.length === 0) {
        message = name + requiredMessage;
      } else if (value.length < mnLen) {
        if (!onlyDigits) message = "Min " + mnLen + " characters required";
        else message = "Min " + mnLen + " digits required";
      } //else
      else if (onlyDigits) {
        for (let i = value.length - 1; i >= 0; i--) {
          const d = value.charCodeAt(i);
          if (d < 48 || d > 57) {
            message = "Enter only digits";
            break;
          } //if
        } //for
      } //else if... onlyDigits
      else if (event.target.type === "email") {
        value = event.target.value.trim();
        if (/\S+@\S+\.\S+/.test(value) == 0) {
          message = name + requiredMessage;
        } else {
          message = "";
        }
      } //else if
      else {
        message = "";
      }
    } //if... text || textarea
    errorSecurity[`${name}`].message = message;
    this.setState({ errorSecurity: errorSecurity });
    this.checkAllErrors(errorSecurity);
  };
  handleFocus = (event) => {
    let name = event.target.name;
    let errorSecurity = this.state.errorSecurity;
    errorSecurity[`${name}`].message = "";
    this.setState({ errorSecurity: errorSecurity });
    this.checkAllErrors(errorSecurity);
  };
  checkAllErrors = (errorSecurity) => {
    // let { errorProduct } = this.state;
    let flagFormInvalid = false;
    for (let field in errorSecurity) {
      if (errorSecurity[field].message !== "") {
        // error is there
        flagFormInvalid = true;
        break;
      } //if
    } //for
    this.setState({ flagFormInvalid: flagFormInvalid });
  };
  handleUserLogIn = () => {
    this.props.onUserLogIn();
  };
  handleSecurityLogIn = () => {
    this.props.onSecurityLogIn();
    // change content in the menubars in the navbar
  };
  handleFormSubmit = (event) => {
    event.preventDefault();
    let data = this.state.security; //this.state
    this.props.onFormSubmit(data);
  };
  responseFacebook = (response) => {
    console.log(response);
    this.props.onFacebookLoginSubmit(response.email);
  };
  handleFacebookLoginClick = (data) => {
    console.log(data);
  };
  render() {
    let { errorSecurity } = this.state;
    let { security } = this.state;
    let { flagFormInvalid } = this.state;
    let { maxSecurityLimit } = this.props;
    let { loggedinSecuritys } = this.props;
    let { flagLoadingLogin } = this.props;
    if (flagLoadingLogin) {
      return (
        <div className="text-center mt-5">
          <BeatLoader size={16} color={"blue"} flagLoadingLogin />
        </div>
      );
    }
    return (
      <React.Fragment>
        <div className="container container-student-login-form">
          {/* // row starts */}
          {/* <div className="form-heading  text-center ">OR</div> */}
          {/* <div className="form-heading  text-center "></div> */}
          {/* // row starts */}
          <div className="form-student-login">
            <form className="text-dark " onSubmit={this.handleSecurityFormSubmit}>
              <div className="text-white bg-darkcolor my-2 px-3 py-3">
                {/* row ends */}
                {/* row starts */}
                <div className="form-group row column align-items-center">
                  <div className="col-4 text-right">
                    <label>Password</label>
                  </div>
                  <div className="col-8 px-0">
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      value={security.password}
                      onChange={this.handleChange}
                      placeholder="Enter Password"
                    />
                  </div>
                </div>
                {/* row ends */}
                {/* row starts */}
                <div className="form-group row offset-5 text-center mb-0">
                  <button
                    className="bg-lightcolor text-dark-color no-border p-2"
                    type="submit"
                    disabled={flagFormInvalid}
                  >
                    Submit
                  </button>
                  &nbsp;&nbsp;&nbsp;
                  <button
                    className="bg-lightcolor text-dark-color no-border p-2"
                    type="cancel"
                    onClick={this.handleLoginCancelClick}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default OfflineSecurity;
