import React, { Component } from "react";
import AdminAdmList from "./admin-adm-list";
import AdminAdmAdd from "./admin-adm-add";
import { AdminDB } from "../AdminDB";
import { BeatLoader } from "react-spinners";
import axios from "axios";

class AdminAdm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      response: {},
      admin: null,
      filteredAdmins: [],
      adminList: [],
      admindb: {},
      flagLoading: false,
      searchText: "",
      sortedField: "",
      sortedDirection: false,
      sectionNumber: 1,
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  getAdminListFromDatabase = () => {
    this.setState({
      flagLoading: true,
    });
    axios
      .all([axios.get(window.routerPrefix+"/admin/admins")])
      .then(
        axios.spread((res1) => {
          let adminList = res1.data;
          let updatedAdmins = [...adminList];

          adminList = updatedAdmins;
          this.setState({
            adminList: adminList,
            filteredAdmins: adminList,
            admindb: new AdminDB(adminList),
            flagLoading: false,
            // initFlag: true,
            /* Reason for using this initFlag: This parent has got ajax calls so,
during rendering of child error obtained, undefined props property,
so as log as initFlag is false, (i.e. data is not fetched), do not render
the parent ( so obviously child is also not rendered). 21.05.2020
https://stackoverflow.com/questions/54219364/react-function-call-after-render-and-before-childs-constructor
*/
          });
        })
      )
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  };
  componentDidMount() {
    this.setState({
      flagLoading: true,
    });
    axios
      .all([axios.get(window.routerPrefix+"/admin/admins/")])
      .then(
        axios.spread((res1) => {
          let adminList = res1.data;
          let updatedAdmins = [...adminList];
          adminList = updatedAdmins;
          this.setState({
            adminList: adminList,
            admindb: new AdminDB(adminList),
            filteredAdmins: adminList,
            flagLoading: false,
          });
        })
      )
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  }
  handleSearchTextBoxKeyUp = (event) => {
    let searchText = event.target.value;
    this.setState({ searchText: searchText });
    this.performSearchOperation(searchText);
  };
  handleSearchTextChange = (event) => {
    let searchText = event.target.value;
    this.setState({ searchText: searchText });
    this.performSearchOperation(searchText);
  };
  performSearchOperation = (searchText) => {
    let query = searchText.trim();
    let searchedAdmins = [];
    searchedAdmins = this.state.admindb.filterByName(query);
    this.setState({
      filteredAdmins: searchedAdmins,
    });
  };
  onFormSubmit(data) {
    let { action } = this.props;
    this.setState({
      flagLoading: true,
    });

    if (action === "UPDATE") {
      //EDIT / PUT
      axios
        // .put("/admin/update/" + data.adminId, data)
        .put(window.routerPrefix+"/admin/update", data)
        .then((res) => {
          this.updateAdminList(data);
          this.setState({
            response: res.data,
            flagLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            flagLoading: false,
          });
          //   this.props.askToLogin();
        });
    } else if (action === "ADD") {
      axios
        .post(window.routerPrefix+"/admin/addAdmin", data, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
          },
        })
        .then((res) => {
          this.setState({
            flagLoading: false,
            response: res.data,
          });
          data.adminId = res.data.adminId; // id of the added admin is returned as response
          this.setState({
            flagLoading: false,
          });
          this.addToAdminList(data);
        })
        .catch((err) => {
          console.log("error!");
          this.setState({
            flagLoading: false,
          });
        });
    } //else
  }
  handleEditAdmin = (admin) => {
    this.setState({
      admin: admin,
      // searchText: "",
    });
    this.props.onEditClick();
  };
  // handleShowChapters = (admin) => {
  //   this.props.onShowChapters(admin);
  // };
  addToAdminList = (admin) => {
    let adminList = [...this.state.adminList];
    adminList.unshift(admin);
    let filteredAdmins = [...this.state.filteredAdmins];
    filteredAdmins.unshift(admin);
    this.setState({
      filteredAdmins: filteredAdmins,
      adminList: adminList,
    });
    let message = "The admin '" + admin.name + "' added successfully.";
    this.props.onCrudAction(message, "ADD");
  };
  updateAdminList = (admin) => {
    let adminList = [...this.state.adminList];
    for (let i = 0; i < adminList.length; i++) {
      if (adminList[i].adminId == admin.adminId) {
        //adminList[i] = admin;
        adminList.splice(i, 1); // remove 1 element from position i
        // and insert at the beginning as we want latest modified at the top
        adminList.unshift(admin);
      }
    } //for
    // remove from filteredchapters also
    let filteredAdmins = [...this.state.filteredAdmins];
    for (let i = 0; i < filteredAdmins.length; i++) {
      if (filteredAdmins[i].adminId == admin.adminId) {
        // filteredAdmins[i] = admin;
        filteredAdmins.splice(i, 1); // remove 1 element from position i
        // and insert at the beginning as we want latest modified at the top
        filteredAdmins.unshift(admin);
      }
    } //for
    //sorting

    this.setState({
      //   chapterdb: new ChapterDB(chapterList),
      filteredAdmins: filteredAdmins,
      adminList: adminList,
      // searchText: "",
    });
    let message = "The admin '" + admin.name + "' updated successfully.";
    this.props.onCrudAction(message, "LIST", "Add a Admin");
  };

  handleDeleteAdmin = (admin) => {
    let adminList = [...this.state.adminList];

    for (let i = 0; i < adminList.length; i++) {
      if (adminList[i].adminId == admin.adminId) {
        adminList.splice(i, 1); // remove 1 element from position i
      }
    } //for
    // remove from filteredadmins also
    let filteredAdmins = [...this.state.filteredAdmins];
    for (let i = 0; i < filteredAdmins.length; i++) {
      if (filteredAdmins[i].adminId == admin.adminId) {
        filteredAdmins.splice(i, 1); // remove 1 element from position i
      }
    } //for

    this.setState({
      admindb: new AdminDB(adminList),
      filteredAdmins: filteredAdmins,
      adminList: adminList,
    });
    let message = "The admin '" + admin.name + "' deleted successfully.";
    this.props.onCrudAction(message, "LIST");
  };
  handleDuplicateAdmin = (admin, howMany) => {
    this.setState({
      flagLoading: true,
    });
    axios
      .post(window.routerPrefix+"/admins/" + "/" + howMany, admin, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
        },
      })
      .then((res) => {
        let message = admin.name + " duplicated " + howMany;
        if (howMany == 1) message += " time.";
        else message += " times.";
        this.props.onCrudAction(message, "LIST"); // Add again
        this.getAdminListFromDatabase();
        this.setState({
          flagLoading: false,
          response: res.data,
        });
      })
      .catch((err) => {
        console.log("error!");
        this.setState({
          flagLoading: false,
        });
      });
  };
  handleHeaderClick = (event) => {
    let field = event.target.id;

    let direction = false;
    if (field === this.state.sortedField) {
      // same button clicked twice
      direction = !this.state.direction;
    } else {
      // different field
      direction = false;
    }
    this.setState({ direction: direction });
    let { filteredAdmins } = this.state;
    if (direction == false) {
      //in ascending order
      filteredAdmins = filteredAdmins.sort((a, b) => {
        if (a[field] > b[field]) {
          return 1;
        }
        if (a[field] < b[field]) {
          return -1;
        }
        return 0;
      });
    } else {
      //in descending order
      filteredAdmins = filteredAdmins.sort((a, b) => {
        if (a[field] < b[field]) {
          return 1;
        }
        if (a[field] > b[field]) {
          return -1;
        }
        return 0;
      });
    }
    this.setState({ filteredAdmins: filteredAdmins, sortedField: field });
  };
  handleSectionNumberClick = (event) => {
    this.setState({ sectionNumber: event.target.id });
  };
  render() {
    let { flagLoading } = this.state;
    let { action } = this.props;
    let { admin } = this.state;
    let content;
    if (flagLoading) {
      content = (
        <div className="text-center">
          <BeatLoader size={16} color={"blue"} flagLoading />
        </div>
      );
    } else if (action === "LIST") {
      // First show list
      content = (
        <AdminAdmList
          onEditAdmin={this.handleEditAdmin}
          onDeleteAdmin={this.handleDeleteAdmin}
          filteredAdmins={this.state.filteredAdmins}
          searchText={this.state.searchText}
          onSearchTextChange={this.handleSearchTextChange}
          onSearchTextBoxKeyUp={this.handleSearchTextBoxKeyUp}
          onHeaderClick={this.handleHeaderClick}
          onSectionNumberClick={this.handleSectionNumberClick}
          sectionNumber={this.state.sectionNumber}
          onDuplicateAdmin={this.handleDuplicateAdmin}
        />
      );
    } else if (action === "ADD" || action === "UPDATE") {
      // After pressing Add Button, show only form and not the list
      content = (
        <div>
          {/* content={" "} */}
          <AdminAdmAdd
            onFormSubmit={this.onFormSubmit}
            admin={admin}
            action={action}
          />
        </div>
      );
    } else if (action === "DELETE") {
      content = <div>Delete action in Progress...</div>;
    }
    return (
      <div className="container-fluid container-md container-content-page p-0">
        {content}
      </div>
    );
  }
}
export default AdminAdm;
