import React, { Component } from "react";
import { Link } from "react-router-dom";
import AdminAdmissionBatchListSingle from "./admin-admission-batch-list-single";
import Select from "react-select";

class AdminAdmissionBatchList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      response: {},
    };
  }
  handleEditAdmission = (admission) => {
    this.props.onEditAdmission(admission);
  };
  handleCrudAction = (message, action) => {
    this.props.onCrudAction(message, action);
  };
  handleDuplicateAdmission = (admission, selection) => {
    this.props.onDuplicateAdmission(admission, selection);
  };
  handleDeleteAdmission = (admission) => {
    this.props.onDeleteAdmission(admission);
  };
  handleSearchTextBoxKeyUp = (event) => {
    this.props.onSearchTextBoxKeyUp(event);
  };
  handleSearchTextChange = (event) => {
    this.props.onSearchTextChange(event);
  };
  handleHeaderClick = (event) => {
    this.props.onHeaderClick(event);
  };
  handleSectionNumberClick = (event) => {
    this.props.onSectionNumberClick(event);
  };
  handleSelectBatchChange = (event) => {
    let index = event.target.selectedIndex; // get selected index, instad of selected value
    var optionElement = event.target.childNodes[index];
    var selectedStudentId = optionElement.getAttribute("id");
    let selectedStudent = event.target.value;
    this.props.onSelectStudentChange(selectedStudent, selectedStudentId);
  };
  handleSelectBatchChangeNew = (value) => {
    let selectedBatch = value; //event.target.value;
    let selectedBatchId = value.batchId;
    this.props.onSelectBatchChange(selectedBatch, selectedBatchId);
  };
  render() {
    let { error } = this.state;
    let { selectedBatch } = this.props;
    let { filteredAdmissions } = this.props;
    let { studentList } = this.props;
    let { batchList } = this.props;
    let { searchText } = this.props;
    let { currentSize } = this.props;
    let { accessLevel } = this.props;
    let { adminType } = this.props;

    let contentNoadmissions = (
      <div className="text-center">No admissions !</div>
    );
    // let optionsStudentNew = [...studentList];
    let optionsBatchNew = [...batchList];
    batchList.map((batch, index) => {
    optionsBatchNew[index].label = batch.name;
    }); //map
    let content = (
      <>
        {/* First select the batch  */}
        <div className="row justify-content-center">
          <div className="col-6 ">
            <Select
              className="form-control"
              name="selectedBatch"
              value={selectedBatch.name}
              onChange={this.handleSelectBatchChangeNew}
              options={optionsBatchNew}
            />
          </div>
        </div>
        {selectedBatch && currentSize != 0 && (
          <div className="form-heading column col-12">
            LIST OF admissions ({selectedBatch.name})
          </div>
        )}
        {selectedBatch && currentSize == 0 && (
          <div className="form-heading column col-12 thick-red-text">
            No Admissions
          </div>
        )}
        {/* row starts */}
        {selectedBatch && currentSize != 0 && (
          <div className="row justify-content-center column">
            <div className="col-6 my-2 column">
              <input
                type="search"
                className="container-fluid form-control"
                value={searchText}
                onKeyUp={this.handleSearchTextBoxKeyUp}
                onChange={this.handleSearchTextChange} // This is contolled by parent
                placeholder="Search admissions"
                id=""
              />
            </div>
          </div>
        )}
        {/* row ends */}
        {selectedBatch && filteredAdmissions.length != 0 && (
          <div className="row p-0 ">
            <div className="col-12 justify-content-center">
              <div className="table-responsive">
                <table className="table mx-auto w-auto table-sm table-hover table-borderless bigger-text">
                  <thead>
                    <tr>
                      <th>
                        {" "}
                        <Link
                          to="#"
                          onClick={this.handleHeaderClick}
                          id="admissionNumber"
                        >
                          Number
                        </Link>
                      </th>
                      <th>
                        <Link to="#" onClick={this.handleHeaderClick} id="name">
                          Name
                        </Link>
                      </th>
                      <th>
                        <Link to="#" onClick={this.handleHeaderClick} id="name">
                          Fees Paid
                        </Link>
                      </th>
                      <th>
                        <Link to="#" onClick={this.handleHeaderClick} id="name">
                          FeesRemaining
                        </Link>
                      </th>
                      <th>
                        <Link to="#" onClick={this.handleHeaderClick} id="name">
                          Status
                        </Link>
                      </th>
                    </tr>
                  </thead>
                  {filteredAdmissions.map((admission, index) => (
                    <tbody key={index}>
                      <AdminAdmissionBatchListSingle
                        admission={admission}
                        index={index}
                        onDeleteAdmission={this.handleDeleteAdmission}
                        onDuplicateAdmission={this.handleDuplicateAdmission}
                        onEditAdmission={this.handleEditAdmission}
                        onCrudAction={this.handleCrudAction}
                        currentSize={currentSize}
                        accessLevel={accessLevel}
                        adminType={adminType}
                      />
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
          </div>
        )}
      </>
    );
    if (error) {
      return <div>Error retriving admissions </div>;
    } else if (filteredAdmissions.length >= 0) {
      return (
        // <div className="container-fluid bg-blue">
        // <div className="row">
        <div className="">{content}</div>
        // </div>
        // </div>
      );
    } //else
    else if (filteredAdmissions.length == 0) {
      return contentNoadmissions;
    } //else
  }
}
export default AdminAdmissionBatchList;
