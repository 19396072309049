import React, { Component } from "react";
import "../App.css";
import { BeatLoader } from "react-spinners";

class QuestionAnswer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flagLoadingVideoLink: false,
      message: "",
    };
  }
  hideMessage = () => {
    window.setTimeout(() => {
      this.setState({
        message: "",
      });
    }, 7000);
  };
  getContentToDisplay = () => {
    let { program } = this.props;
    let s = "";
      s = program.questionNumber + ". " + program.question;
    return s;
  };
  sh_Answer = () => {
    let { program } = this.props;
    if (
      program.flagActive
    )
      return true;
    return false;
  };
  handleShowAnswerClick = () => {
    // let { program } = this.props;
    // this.props.onShowAnswerClick(program);
    // Check here only, whether  student has taken admission
    //  for this course or not, or is it a Demo
    let { selectedSubjectName } = this.props;
    // let { selectedSubjectAvailableForDemo } = this.props;
    let { selectedSubjectAvailableFor } = this.props;
    // selectedSubjectAvailableForDemo = Boolean(selectedSubjectAvailableForDemo);
    let message1 =
      "Sorry… This content is available only for registered students.\n\rWant to join this course (online/offline)? Contact us at rjacpune@gmail.com or WhatsApp at 9422035020";
    let message2 =
      `Currently, content of following courses can be viewed.
      1. Programming (C, C++, Python)
      2. OOP using C++ and Python
      3. Data Structures
      Want to join the course ` +
      selectedSubjectName +
      `
      ? Contact us at rjacpune@gmail.com or WhatsApp at 9422035020`;
    let message3 =
      "Join this course - " + selectedSubjectName + " (online/offline)";
    let { visitorInfo } = this.props;
    let { selectedChapterNumber } = this.props;
    let { program } = this.props;
    let availableForDemo = Boolean(program.availableForDemo);
    let message;
    // subject available for Demo
    if (selectedSubjectAvailableFor=="All") {
      if (availableForDemo) {
        message = "";
      } else if (visitorInfo.status == "Guest") {
        // Guest
        message = message1;
      }
      // not guest, so a registered student
      else if (visitorInfo.admissionStatus == "Admitted") {
        // check chapter-limit
        if (selectedChapterNumber > visitorInfo.chapterLimit) {
          message = "Please request Admin to access data of this chapter";
          // message =
          //   "You have not completed chapter no. " +
          //   visitorInfo.chapterLimit +
          //   " yet.";
        } else {
          message = "";
        }
      } else if (
        (visitorInfo.status =
          "Registered" && visitorInfo.admissionStatus == "Guest")
      ) {
        message = message3;
      }
    } //end of "All" (Programming, OOP, DS)
    else {
      // subject NOT available for Demo
      if (visitorInfo.status == "Guest") {
        // Guest
        message = message2;
      }
    }
    if (message) {
      this.setState({
        message: message,
      });
      this.hideMessage();
      return;
    }
    this.props.onShowAnswerClick(program);
  };
  // get link for the unlisted videos, it is --- made from server side  
  render() {
    let { program } = this.props;
    let { flagLoadingActualContent } = this.state;
    let { message } = this.state;
    let content = this.getContentToDisplay();
    return (
      <React.Fragment>
        {content && (
          <div className="row mb-2">
            <button
              to="/"
              className={
                "div-actual-content " +
                (program.flagActive ? " border-mypurple " : " ")
              }
              onClick={this.handleShowAnswerClick}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {content}
              &nbsp;              
              &nbsp;
            </button>
          </div>
        )}
        {/* Answer to be displayed after click to Interview Question */}
        {this.sh_Answer() && (
          <div className="program-details ">
              {!flagLoadingActualContent && (
              <div className="program-code" style={{ whiteSpace: "pre-wrap" }}>
                {program.answer}
              </div>
            )}
          </div>
        )}
        {message && <div className="error-text text-left">{message}</div>}
      </React.Fragment>
    );
  }
}
export default QuestionAnswer;
