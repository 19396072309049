export class EnquiredStudentDB {
  constructor(students) {
    this.students = students;
  }
  allStudents() {
    return this.students;
  }
  searchByStudentId(id) {
    for (let i = 0; i < this.students.length; i++) {
      if (this.students[i].studentId == id) {
        return this.students[i];
      }
    }
    return null; // not found
  }
  filterByName(query) {
    let filteredStudents = [];
    let fullName;
    for (let i = 0; i < this.students.length; i++) {
      fullName =
      this.students[i].firstName +
        " " +
        this.students[i].middleName +
        " " +
        this.students[i].surName;
      if (fullName.toLowerCase().includes(query.toLowerCase())) {
        filteredStudents.push(this.students[i]);
      }
    } //for
    return filteredStudents;
  }
}
export default EnquiredStudentDB;
