import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import axios from "axios";
function AdminWorkshopListSingle(props) {
  let [flagLoading, setFlagLoading] = useState(false);
  let [error, setError] = useState("");

  function handleEditWorkshop(workshop) {
    props.onEditWorkshop(workshop);
  }
  function handleDeleteWorkshop(workshop) {
    // Now call for delete api
    setFlagLoading(true);
    axios
      .delete(window.routerPrefix + "/workshop/delete/" + workshop.workshopId)
      .then((res) => {
        setFlagLoading(false);
        if (res.data) {
          props.onDeleteWorkshop(workshop);
        } else {
          //Error, Something went wrong
        }
      })
      .catch((err) => {
        setFlagLoading(false);
        setError(err);
      });
  }
  let { workshop } = props;
  let { index } = props;
  let { accessLevel } = props;
  let { adminType } = props;
  return (
    <div className="row myborder-top justify-content-center mb-2">
      <div className="col-2 col-md-1">{index + 1}.</div>
      <div className="col-6 col-md-4 ">{workshop.name}</div>
      <div className="col-2 col-md-1">
        {adminType >= accessLevel && (
          <Link to="#" onClick={() => handleEditWorkshop(workshop)}>
            <i className="fas fa-edit admin-icons"></i>
          </Link>
        )}{" "}
      </div>
      <div className="col-2 col-md-1">
        {!flagLoading && adminType >= accessLevel && (
          <Link
            to="#"
            onClick={() => {
              if (
                window.confirm(
                  "You really want to delete Workshop - " + workshop.name + " ?"
                )
              )
                handleDeleteWorkshop(workshop);
            }}
          >
            <i className="far fa-trash-alt admin-icons"></i>
          </Link>
        )}
        {flagLoading && <BeatLoader size={8} color={"blue"} flagLoading />}
      </div>
    </div>
  );
}
export default AdminWorkshopListSingle;
