import React, { Component } from "react";
import { WhatsappIcon } from "react-share";
import axios from "axios";
import { BeatLoader } from "react-spinners";
class CppClassDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleWhatsAppClick = () => {
    let whatsappNumber = "919422035020";
    let whatsAppMessage = `Hello Sir, I am interested to Join Masterclass of "OOP using C++ - 2 Units`;
    window.location = `https://wa.me/${whatsappNumber}?text=${whatsAppMessage}`;
  };
  getImageLink = () => {
    let serverResponse =
      "/" + window.routerPrefix + "/files/downloadImageFile/classJava";
    return serverResponse;
  };
  componentDidMount = () => {
    this.setState({
      flagCheckSession: true,
    });

    axios
      .get(window.routerPrefix + "/general/externalVisit/Cpp")
      .then((res) => {
        this.setState({
          flagCheckSession: false,
        });
      })
      .catch((err) => {
        this.setState({
          error: err,
          message: "Something went wrong",
          flagCheckSession: false,
        }); // error is set
      });
  };

  render() {
    let { flagCheckSession } = this.state;
    if (flagCheckSession) {
      return (
        <div className="text-center mt-5">
          <BeatLoader size={16} color={"blue"} flagCheckSession />
        </div>
      );
    }
    return (
      <>
        {/* row-begins */}
        <div className="container-fluid container-md">
          <div className="row justify-content-center">
            <div className="col-6 col-md-4 text-center mb-2 ">
              <img
                src="/images/rjac_logo.jpg"
                alt="No logo"
                className="img-fluid "
                // onClick={this.handleLogoClick}
              />
            </div>
          </div>
        </div>
        <h3 className="text-center my-2">Masterclass - OOP</h3>{" "}
        <h6 className="text-center my-2">(First 2 Units) </h6>{" "}
        <h5 className="text-center my-2">SE (E&T/C)</h5>{" "}
        <div className="container py-2">
          <div className="row align-items-center justify-content-center text-center">
            <div className="col-8 col-lg-5">
              <img
                className="img-fluid rounded mb-4 mb-lg-0"
                src="images/class_photo_aboutus.jpg"
                alt=""
              />
            </div>
            <h5 className="my-2">
              Learn under perfect guidance of Rajesh Jain Sir (BE from COEP)
              with experience of 25+ years!
            </h5>{" "}
          </div>
          {/* row ends */}
          <h5 className="advt-page-highlight my-2">Course Content </h5>{" "}
          <div className="container ">
            <div className="row  bulleted-data mx-auto">
              <div className="col-12">
                <i className="fa-solid fa-circle text-smaller"></i> &nbsp;
                <span className="text-thick">Chapter 1: </span>Foundation of
                Object Oriented Programming
              </div>
              <div className="col-12 ">
                <i className="fa-solid fa-circle text-smaller"></i> &nbsp;
                <span className="text-thick">Chapter 2: </span> Classes &
                Objects
              </div>
            </div>
            {/* row ends */}
          </div>
          {/* container  ends */}
          <h5 className="advt-page-highlight my-2">
            What you will get in this course?{" "}
          </h5>{" "}
          <div className="container ">
            <div className="row  bulleted-data mx-auto">
              <div className="col-12 ">
                {" "}
                <i className="fa-solid fa-circle text-smaller"></i> &nbsp;Theory
                notes{" "}
              </div>
              <div className="col-12 ">
                {" "}
                <i className="fa-solid fa-circle text-smaller"></i>{" "}
                &nbsp;Printed class-room notes
              </div>
              <div className="col-12 ">
                {" "}
                <i className="fa-solid fa-circle text-smaller"></i> &nbsp;Online
                Tests
              </div>
              <div className="col-12 ">
                {" "}
                <i className="fa-solid fa-circle text-smaller"></i> &nbsp;PU -
                Previous Years Question Papers - With answers{" "}
              </div>
              <div className="col-12 ">
                {" "}
                <i className="fa-solid fa-circle text-smaller"></i>{" "}
                &nbsp;Certificate of Course-Completion (with credentials){" "}
              </div>
            </div>
          </div>
          {/* container  ends */}
          <h5 className="advt-page-highlight my-2">
            Why to join Rajesh Jain Academy?{" "}
          </h5>
          <div className="container myborder">
            <div className="row  bulleted-data mx-auto">
              <div className="col-12 ">
                {" "}
                <i className="fa-solid fa-circle text-smaller"></i>{" "}
                &nbsp;Hands-on training{" "}
              </div>
              <div className="col-12 ">
                {" "}
                <i className="fa-solid fa-circle text-smaller"></i> &nbsp;Direct
                mentorship of Rajesh sir and his able team
              </div>
              <div className="col-12 ">
                {" "}
                <i className="fa-solid fa-circle text-smaller"></i> &nbsp;Set-up
                of 80+ computers
              </div>
              <div className="col-12 ">
                {" "}
                <i className="fa-solid fa-circle text-smaller"></i> &nbsp;Team
                of instructors for guidance
              </div>
              <div className="col-12 ">
                {" "}
                <i className="fa-solid fa-circle text-smaller"></i>{" "}
                &nbsp;Thousands of students have mastered coding and have
                cracked jobs at product based companies
              </div>
              <div className="col-12 ">
                {" "}
                <i className="fa-solid fa-circle text-smaller"></i> &nbsp;Well
                curated syllabus to become interview-ready as well as excel in
                University exams{" "}
              </div>
              <div className="col-12 ">
                <i className="fa-solid fa-circle text-smaller"></i> &nbsp;
                Exhaustive notes, exercises, online tests, interview
                questions/answers on each topic{" "}
              </div>
              <div className="col-12 ">
                <i className="fa-solid fa-circle text-smaller"></i>{" "}
                &nbsp;Personal Attention{" "}
              </div>
              <div className="col-12 ">
                <i className="fa-solid fa-circle text-smaller"></i>{" "}
                &nbsp;Reasonable fees{" "}
              </div>
              <div className="col-12 ">
                {" "}
                <i className="fa-solid fa-circle text-smaller"></i> &nbsp;350+{" "}
                <img
                  src="images/logo_google.png"
                  alt="No logo"
                  className="google-image"
                  onClick={this.handleLogoClick}
                />
                &nbsp;
                <a href="https://www.google.com/maps/place/Rajesh+Jain+Academy/@18.4647228,73.8344826,17z/data=!4m8!3m7!1s0x3bc2954eca5c3561:0x172c6cc113ad7c34!8m2!3d18.4647228!4d73.8344826!9m1!1b1!16s%2Fg%2F11c0rq8bvm">
                  Reviews
                </a>{" "}
                (4.9 star-ratings){" "}
              </div>
            </div>
          </div>
          {/* container  ends */}
        </div>
        <div className="border-mycyan my-2 w-75 text-center mx-auto"></div>
        <div className="w-75 text-center mx-auto">
          <img
            src={this.getImageLink()}
            alt="Invalid Link"
            className="img-fluid "
          />
        </div>
        {/* <div className="border-mycyan my-2 w-75 text-center mx-auto"></div> */}
        <h4 className="text-center w-75 mx-auto my-2">
          Take wise decision and join our placement-oriented training{" "}
        </h4>
        <div className="text-center w-75 mx-auto">
          Remember, Its perfect knowledge of Java which will surely give you a
          job.
        </div>
        <div className="text-center w-75 mx-auto">
          Java is the most demanding programming language in I.T. Industry.{" "}
        </div>
        <div className="border-mycyan my-2 w-75 text-center mx-auto"></div>
        <div className="text-center">
          <button onClick={this.handleWhatsAppClick}>
            <WhatsappIcon size={32} round />
          </button>
        </div>
      </>
    );
  }
}
export default CppClassDetails;
