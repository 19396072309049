import React, { useEffect, useState } from "react";
import AdminWorkshopList from "./admin-workshop-list";
import AdminWorkshopAdd from "./admin-workshop-add";
import { WorkshopDB } from "../WorkshopDB";
import { BeatLoader } from "react-spinners";
import axios from "axios";

function AdminWorkshop(props) {
  let [flagLoading, setFlagLoading] = useState(false);
  let [error, setError] = useState("");
  let [searchText, setSearchText] = useState("");
  let [direction, setDirection] = useState("");
  let [sortedField, setSortedField] = useState("");
  let [sectionNumber, setSectionNumber] = useState(1);
  let [filteredWorkshops, setFilteredWorkshops] = useState([]);
  let [workshopList, setWorkshopList] = useState([]);
  let [workshopdb, setWorkshopdb] = useState({});
  let [workshop, setWorkshop] = useState(null);
  // constructor(props) {
  //   super(props);
  //   state = {
  //     error: null,
  //     response: {},
  //     workshop: null,
  //     filteredWorkshops: [],
  //     workshopList: [],
  //     workshopdb: {},
  //     flagLoading: false,
  //     searchText: "",
  //     sortedField: "",
  //     sortedDirection: false,
  //     sectionNumber: 1,
  //   };
  //   onFormSubmit = onFormSubmit.bind(this);
  // }
  function getWorkshopListFromDatabase() {
    setFlagLoading(true);
    axios
      .all([axios.get(window.routerPrefix + "/workshop/workshops")])
      .then(
        axios.spread((res1) => {
          let workshopList = res1.data;
          let updatedWorkshops = [...workshopList];

          workshopList = updatedWorkshops;

          setFlagLoading(false);
          // filteredWorkshops: workshopList,
          // workshopList: workshopList,
          setFilteredWorkshops(workshopList);
          setWorkshopList(workshopList);
          setWorkshopdb(new WorkshopDB(workshopList));
        })
      )
      .catch((err) => {
        console.log(err);
        setError(err);
        setFlagLoading(false);
      });
  }
  useEffect(() => {
    init();
  }, []);
  function init() {
    setFlagLoading(true);
    axios
      .all([axios.get(window.routerPrefix + "/workshop/workshops/")])
      .then(
        axios.spread((res1) => {
          let workshopList = res1.data;
          let updatedWorkshops = [...workshopList];
          workshopList = updatedWorkshops;
          setFlagLoading(false);
          // filteredWorkshops: workshopList,
          setFilteredWorkshops(workshopList);
          setWorkshopList(workshopList);
          setWorkshopdb(new WorkshopDB(workshopList));
        })
      )
      .catch((err) => {
        setError(err);
        setFlagLoading(false);
      });
  }
  function handleSearchTextBoxKeyUp(event) {
    let searchText = event.target.value;
    setSearchText(searchText);
    performSearchOperation(searchText);
  }
  function handleSearchTextChange(event) {
    let searchText = event.target.value;
    setSearchText(searchText);
    performSearchOperation(searchText);
  }
  function performSearchOperation(searchText) {
    let query = searchText.trim();
    let searchedWorkshops = [];
    searchedWorkshops = workshopdb.filterByName(query);
    setFilteredWorkshops(searchedWorkshops);
  }

  function onFormSubmit(workshop) {
    let { action } = props;
    setFlagLoading(true);
    if (action === "UPDATE") {
      //EDIT / PUT
      axios
        .put(window.routerPrefix + "/workshop/update", workshop)
        .then((res) => {
          if (res.data) {
            //success
            updateWorkshopList(workshop);
          } else {
            //error
            //something went wrong
          }
          setFlagLoading(false);
        })
        .catch((err) => {
          setError(err);
          setFlagLoading(false);
        });
    } else if (action === "ADD") {
      axios
        .post(window.routerPrefix + "/workshop/addWorkshop", workshop, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
          },
        })
        .then((res) => {
          setFlagLoading(false);
          workshop.workshopId = res.data.workshopId; // id of the added workshop is returned as response
          addToWorkshopList(workshop);
        })
        .catch((err) => {
          setError(err);
          setFlagLoading(false);
        });
    } //else
  }
  function handleEditWorkshop(workshop) {
    setWorkshop(workshop);
    // setState({
    //   workshop: workshop,
    // });
    props.onEditClick();
  }
  // handleShowChapters = (workshop) => {
  //   props.onShowChapters(workshop);
  // };
  function addToWorkshopList(workshop) {
    let sList = [...workshopList];
    sList.unshift(workshop);
    let fWorkshops = [...filteredWorkshops];
    fWorkshops.unshift(workshop);
    setFilteredWorkshops(fWorkshops);
    setWorkshopList(sList);
    let message = "The workshop '" + workshop.name + "' added successfully.";
    props.onCrudAction(message, "ADD");
  }
  function updateWorkshopList(workshop) {
    let sList = [...workshopList];
    for (let i = 0; i < sList.length; i++) {
      if (sList[i].workshopId == workshop.workshopId) {
        sList.splice(i, 1); // remove 1 element from position i
        // and insert at the beginning as we want latest modified at the top
        sList.unshift(workshop);
      }
    } //for
    // remove from filteredchapters also
    let fWorkshops = [...filteredWorkshops];
    for (let i = 0; i < fWorkshops.length; i++) {
      if (fWorkshops[i].workshopId == workshop.workshopId) {
        fWorkshops.splice(i, 1); // remove 1 element from position i
        // and insert at the beginning as we want latest modified at the top
        fWorkshops.unshift(workshop);
      }
    } //for
    //sorting
    setWorkshopList(sList);
    setFilteredWorkshops(fWorkshops);
    let message = "The workshop '" + workshop.name + "' updated successfully.";
    props.onCrudAction(message, "LIST", "Add a Workshop");
  }

  function handleDeleteWorkshop(workshop) {
    let sList = [...workshopList];
    for (let i = 0; i < sList.length; i++) {
      if (sList[i].workshopId == workshop.workshopId) {
        sList.splice(i, 1); // remove 1 element from position i
      }
    } //for
    // remove from filteredworkshops also
    let fWorkshops = [...filteredWorkshops];
    for (let i = 0; i < fWorkshops.length; i++) {
      if (fWorkshops[i].workshopId == workshop.workshopId) {
        fWorkshops.splice(i, 1); // remove 1 element from position i
      }
    } //for
    setWorkshopList(sList);
    setFilteredWorkshops(fWorkshops);
    setWorkshopdb(new WorkshopDB(sList));
    let message = "The workshop '" + workshop.name + "' deleted successfully.";
    props.onCrudAction(message, "LIST");
  }
  function handleHeaderClick(event) {
    let field = event.target.id;
    let d = false;
    if (field === sortedField) {
      // same button clicked twice
      d = !direction;
    } else {
      // different field
      d = false;
    }
    setDirection(d);
    if (d == false) {
      //in ascending order
      filteredWorkshops = filteredWorkshops.sort((a, b) => {
        if (a[field] > b[field]) {
          return 1;
        }
        if (a[field] < b[field]) {
          return -1;
        }
        return 0;
      });
    } else {
      //in descending order
      filteredWorkshops = filteredWorkshops.sort((a, b) => {
        if (a[field] < b[field]) {
          return 1;
        }
        if (a[field] > b[field]) {
          return -1;
        }
        return 0;
      });
    }
    setFilteredWorkshops(filteredWorkshops);
    setSortedField(field);
  }
  function handleSectionNumberClick(event) {
    setSectionNumber(event.target.id);
  }
  let { action } = props;
  let { accessLevel } = props;
  let { adminType } = props;

  let content;
  return (
    <div className="container-fluid container-md container-content-page p-0">
      {flagLoading && (
        <div className="text-center">
          <BeatLoader size={16} color={"blue"} flagLoading />
        </div>
      )}
      {!flagLoading && action === "LIST" && (
        <AdminWorkshopList
          onEditWorkshop={handleEditWorkshop}
          onDeleteWorkshop={handleDeleteWorkshop}
          filteredWorkshops={filteredWorkshops}
          searchText={searchText}
          onSearchTextChange={handleSearchTextChange}
          onSearchTextBoxKeyUp={handleSearchTextBoxKeyUp}
          onHeaderClick={handleHeaderClick}
          onSectionNumberClick={handleSectionNumberClick}
          sectionNumber={sectionNumber}
          accessLevel={accessLevel}
          adminType={adminType}
        />
      )}
      {!flagLoading && (action === "ADD" || action === "UPDATE") && (
        <div>
          {/* content={" "} */}
          <AdminWorkshopAdd
            onFormSubmit={onFormSubmit}
            workshop={workshop}
            action={action}
          />
        </div>
      )}
      {action === "DELETE" && <div>Delete action in Progress...</div>}
    </div>
  );
}
export default AdminWorkshop;
