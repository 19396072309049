import "../App.css";
import React, { Component } from "react";
import { BeatLoader } from "react-spinners";
class AboutsUsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleSubjectClick = (subject) => {
    this.props.onSubjectClick(subject);
  };
  render() {
    let { subjectList } = this.props;
    let { flagLoadingSubjects } = this.props;

    return (
      <React.Fragment>
        {flagLoadingSubjects && (
          <div className="text-center mt-5">
            <BeatLoader size={16} color={"blue"} flagLoadingSubjects />
          </div>
        )}
        {/* row-begins */}
        <div className="container-fluid py-5">
          <div className="container py-5">
            <div className="row align-items-center">
              <div className="col-lg-5">
                <img
                  className="img-fluid rounded mb-4 mb-lg-0"
                  src="images/class_photo_aboutus.jpg"
                  alt=""
                />
              </div>
              <div className="col-lg-7 text-justify">
                <div className="text-left mb-4">
                  <h5
                    className="text-primarycolor text-uppercase mb-3"
                    style={{letterSpacing: "5px"}}
                  >
                    About Us
                  </h5>
                </div>
                <p>
                  Rajesh Jain sir started his journey of teaching programming
                  language to UG students in 1997. His passion was simple: don’t
                  just teach concepts, bring out curiousity for the learners.
                </p>
                <p>
                  What started as an effort to teach fundamentals using
                  whiteboard, has now turned to an fully integrated coaching
                  centre, combining modern tools like real-time practice
                  sessions, video lectures and interactive content. He believes
                  for any person to have a successful career in IT, their
                  learning should be focussed on critical thinking and approach
                  than writing just any piece of code. Hence he is on mission to
                  provide a platform where students can learn, question,
                  practice and create.
                </p>
                <p>
                  Rajesh sir graduated in E&TC from College of Engineering Pune
                  (CoEP) in 1992. He has founded B2B e-commerce startup
                  meriYaadi.com. In free time, he loves to learn new
                  technologies. His areas of interest include Full Stack
                  Development and Cloud Computing.
                </p>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default AboutsUsPage;
