import React, { Component } from "react";
import "../App.css";
import axios from "axios";
import AnimationImage from "./animation-image";

class Animation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flagFileDownload: false,
      fileName: "",
      code: "",
      message: "",
      videoStatus: "",
      programStatus: "",
      flagLoading: false,
      isImage: false,
      isDemoVideo: false,
      isResources: false,
      showWhat: "code",
      flagShowAnimationImages: false,
    };
  }
  hideMessage = () => {
    window.setTimeout(() => {
      this.setState({
        message: "",
      });
    }, 7000);
  };
  getContentToDisplay = () => {
    let { program } = this.props;
    let s = "";
    s = program.programNumber + ". " + program.description + "  ";
    return s;
  };
  handleCopyToClipboard = () => {
    navigator.clipboard.writeText(this.state.code);
  };
  handleShowImageClick = () => {
    this.setState({
      showWhat: "image",
    });
  };
  handleShowDemoVideoClick = () => {
    this.setState({
      showWhat: "demoVideo",
    });
  };
  handleDownloadFilesClick = () => {
    this.setState({
      showWhat: "resources",
    });
  };
  handleShowCodeClick = () => {
    this.setState({
      showWhat: "code",
    });
  };
  handleDownloadYesClick = () => {
    // pdf download
    let requestUrl =
      "/general/codePdf/" +
      this.props.selectedSubjectFilePrefix +
      this.state.selectedChapterNumber;
    this.setState({
      programList: [], // programlist should be empty
    });
    this.downloadPdfFile(requestUrl);
  };
  handleCodeDownloadClick = () => {
    let type = "text/plain";
    const url = window.URL.createObjectURL(
      new Blob([this.state.code], {
        type: type,
      })
    );
    let fileName = this.state.fileName;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove(); // you need to remove that elelment which is created before.
  };
  showAvailability = () => {
    let availability = "";
    let { program } = this.props;
    let { visitorInfo } = this.props;
    let availableForDemo = Boolean(program.availableForDemo);
    let { selectedMenu } = this.props;
    if (selectedMenu.name == "video" || selectedMenu.name == "note") {
      if (window.hosting == "offline") {
        if (program.location == "YouTubePublic") {
          availability = "Available on YouTube";
        } else if (program.location == "YouTubeUnlisted") {
        } else if (program.location == "YouTubeUnlisted") {
        } else {
          availability = "";
        }
      } else if (window.hosting == "internet") {
        // video on internet
        if (program.location == "YouTubePublic") {
          if (visitorInfo.admissionStatus == "Guest") {
            availability = "Preview";
          } else if (
            // For Admitted (offline)
            visitorInfo.admissionStatus == "Admitted" &&
            visitorInfo.admissionType == "Offline"
          ) {
            availability = "Available";
          } else if (
            // For Admitted (offline)
            visitorInfo.admissionStatus == "Admitted" &&
            visitorInfo.admissionType == "Online"
          ) {
            availability = "";
          }
        } // end of YouTubePublic
        else if (program.location == "YouTubeUnlisted") {
          if (visitorInfo.admissionStatus == "Guest") {
            availability = "";
          } else if (
            // For Admitted (offline)
            visitorInfo.admissionStatus == "Admitted" &&
            visitorInfo.admissionType == "Offline"
          ) {
            if (selectedMenu.name == "note") {
              //notes
              availability = "Available";
            } else if (selectedMenu.name == "video") {
              // video
              availability = "";
            }
          } else if (
            // For Admitted (online)
            visitorInfo.admissionStatus == "Admitted" &&
            visitorInfo.admissionType == "Online"
          ) {
            availability = "";
          }
        } // end of YouTubeUnlisted
        else if (
          program.location != "YouTubePublic" &&
          program.location != "YouTubeUnlisted"
        ) {
          if (visitorInfo.admissionStatus == "Guest") {
            availability = "";
          } else if (
            // For Admitted (offline)
            visitorInfo.admissionStatus == "Admitted" &&
            visitorInfo.admissionType == "Offline"
          ) {
            availability = "";
          } else if (
            // For Admitted (online)
            visitorInfo.admissionStatus == "Admitted" &&
            visitorInfo.admissionType == "Online"
          ) {
            availability = "";
          }
        } // end of local (not YouTubePublic and not YouTubeUnlisted)
      } // end of video/note
    } else {
      // program, exercise, misc.
      if (window.hosting == "offline") {
        if (availableForDemo && visitorInfo.admissionStatus == "Guest") {
          availability = "Preview";
        } else {
          availability = "";
        }
      } else if (window.hosting == "internet") {
        if (availableForDemo && visitorInfo.admissionStatus == "Guest") {
          availability = "Preview";
        } else {
          availability = "";
        }
      }
    }
    return availability;
  };
  handlePlayVideoClick = () => {
    // Check here only, whether  student has taken admission
    //  for this course or not, or is it a Demo, or Guest
    let { visitorInfo } = this.props;
    let { selectedChapterNumber } = this.props;
    let { selectedSubjectFees } = this.props;

    let { program } = this.props;
    let { selectedMenu } = this.props;
    let message1 =
      "Sorry… This content is available only for registered students. Fees of this course is only Rs. " +
      selectedSubjectFees +
      ". Want to join this course (online/offline)? Contact us at rjacpune@gmail.com or WhatsApp at 9422035020";
    // let availableForDemo = Boolean(program.availableForDemo);
    let message;
    if (window.hosting == "internet") {
      if (program.location == "YouTubePublic") {
        // Available to all : Guest, Registerd, Registerd + Admitted, Guest_to_subject
        message = "";
      } //end of YouTubePublic
      else if (program.location == "YouTubeUnlisted") {
        if (visitorInfo.status == "Guest") {
          // For guest - Join this course message
          message = message1;
        } else if (
          // Registered, but not for this course (So, Guest for this course)
          visitorInfo.admissionStatus == "Guest"
        ) {
          message = "Join this course (Online/Offline) to access the videos";
        } else if (
          // For Admitted (offline)
          visitorInfo.admissionStatus == "Admitted" &&
          visitorInfo.admissionType == "Offline"
        ) {
          if (selectedMenu.name == "note") {
            //notes
            //check chapter-limit
            if (selectedChapterNumber > visitorInfo.chapterLimit) {
              message =
                "You have not completed chapter no. " +
                visitorInfo.chapterLimit +
                " yet.";
            } else {
              message = "";
            }
          } else {
            // video
            message = "This lecture is available in offline class-room.";
          }
        } else if (
          // For Admitted (Online)
          visitorInfo.admissionStatus == "Admitted" &&
          visitorInfo.admissionType == "Online"
        ) {
          //check chapter-limit
          if (selectedChapterNumber > visitorInfo.chapterLimit) {
            message =
              "You have not completed chapter no. " +
              visitorInfo.chapterLimit +
              " yet.";
          } else {
            message = "";
          }
        }
      } //end of YouTubeUnlisted
      else if (
        program.location != "YouTubePublic" &&
        program.location != "YouTubeUnlisted"
      ) {
        // Local videos
        if (visitorInfo.status == "Guest") {
          // For guest - Join this course message
          message = "Join the course (Online/Offline) to access the videos";
        } else if (
          // For Admitted (offline)
          visitorInfo.admissionStatus == "Admitted" &&
          visitorInfo.admissionType == "Offline"
        ) {
          message = "This lecture is available in offline class-room.";
        } else if (
          // For Admitted (Online)
          visitorInfo.admissionStatus == "Admitted" &&
          visitorInfo.admissionType == "Online"
        ) {
          //check chapter-limit
          if (selectedChapterNumber > visitorInfo.chapterLimit) {
            message =
              "You have not completed chapter number " +
              visitorInfo.chapterLimit +
              " yet.";
          } else {
            message = "";
          }
        }
      } //end of Neither "YouTubePublic" nor "YouTubeUnlisted"
    } //end of window.hosting==internet
    else if (window.hosting == "offline") {
      if (visitorInfo.admissionStatus == "Guest") {
        // Guest
        message = "Please login to access the content"; // chapterLimit crossed
      } else if (visitorInfo.admissionStatus == "Admitted") {
        if (selectedChapterNumber > visitorInfo.chapterLimit) {
          // message = "Please request Admin to access videos of this chapter";
          message =
            "You have not finished chapter number " +
            visitorInfo.chapterLimit +
            " yet.";
        } else {
          message = "";
        }
      }
    }
    if (message) {
      this.setState({
        message: message,
      });
      this.hideMessage();
      return;
    }
    if (program.link != "---") {
      this.props.onPlayVideoClick(program, "");
    } else {
      let { program } = this.props;
      this.setState({
        flagLoadingVideoLink: true,
      });
      axios
        .get(window.routerPrefix + "/video/videoById/" + program.programId)
        .then((res) => {
          let data = res.data;
          if (!data) {
            this.setState({
              message: "Timeout... Refresh and Login again",
            }); // error is set
            this.hideMessage();
          }
          this.setState({
            flagLoadingVideoLink: false,
          });
          this.props.onPlayVideoClick(program, data.link);
        })
        .catch((err) => {
          this.setState({
            error: err,
            message: "Something went wrong",
            flagLoadingVideoLink: false,
          }); // error is set
          this.hideMessage();
        });
    }
    // this.props.onShowCodeClick(program);
  };
  getDownloadLink = () => {
    let { filePrefixWOExtension } = this.props;
    let { program } = this.props;
    let serverResponse =
      window.routerPrefix +
      "/files/downloadVideoFromFile/" +
      (filePrefixWOExtension + "" + program.programNumber);
    return serverResponse;
  };
  getDownloadDemoVideoLink = () => {
    let { filePrefixWOExtension } = this.props;
    let { program } = this.props;
    let serverResponse =
      window.routerPrefix +
      "/files/downloadDemoVideoFromFile/" +
      (filePrefixWOExtension + "" + program.programNumber);
    return serverResponse;
  };
  getVideoLength = () => {
    let { program } = this.props;
    let min = 0,
      sec = 0;
    if (program.length) {
      min = Math.floor(program.length / 60);
      sec = program.length % 60;
      if (min < 10 && min > 0) {
        // its single digit
        min = "0" + min;
      }
      if (sec < 10 && sec > 0) {
        // its single digit
        sec = "0" + sec;
      }
    }
    return min + ":" + sec;
  };
  sh_Code = () => {
    let { program } = this.props;
    let { selectedMenu } = this.props;
    let { flagFileDownload } = this.state;
    if (
      program.flagActive &&
      flagFileDownload && // file downloaded successfully
      selectedMenu.name != "video" &&
      selectedMenu != "note"
    )
      return true;
    return false;
  };
  sh_OfflineVideo = () => {
    let { program } = this.props;
    let { selectedMenu } = this.props;
    if (
      (selectedMenu.name == "video" || selectedMenu.name == "note") &&
      program.flagActive &&
      window.hosting == "offline"
    )
      return true;
    return false;
  };
  sh_YoutubeVideo = () => {
    let { program } = this.props;
    let { selectedMenu } = this.props;
    let { message } = this.state;
    let { flagLoadingVideoLink } = this.state;

    if (
      (selectedMenu.name == "video" || selectedMenu.name == "note") &&
      program.flagActive &&
      window.hosting == "internet" &&
      !message &&
      !flagLoadingVideoLink
    )
      return true;
    return false;
  };
  handlePresentationImageClick = (imageNumber) => {
    // Give this image a border to know that it is clicked
    let { imageAnimationList } = this.state;

    let updatedImageAnimationList = imageAnimationList.map((image, index) => {
      if (imageNumber == index + 1) {
        image.status = true;
      } else {
        image.status = false;
      }
      if( index+1==imageNumber-1)
      {
        image.show=false;
      }
      return image;
    });
    this.setState({ imageAnimationList: updatedImageAnimationList });
  };
  handleAnimationFirstImageClick = () => {
    // get count of images in this particular folder
    // show all images of animation folder
    let { selectedSubjectFilePrefix } = this.props;
    let { selectedChapterNumber } = this.props;
    let { folderNumber } = this.props;
    let folder_Info =
      selectedSubjectFilePrefix +
      "_" +
      selectedChapterNumber +
      "_" +
      folderNumber;
    this.setState({
      flagLoading: true,
    });
    axios
      .get(window.routerPrefix + "/files/countAnimationImages/" + folder_Info)
      .then((res) => {
        let imageAnimationList = [];
        for (let i = 0; i < res.data; i++) {
          imageAnimationList.push({ status: false, show:true });
        } //for
        this.setState({
          imageAnimationList: imageAnimationList,
          flagLoading: false,
          flagShowAnimationImages: true,
        });
      })
      .catch((err) => {
        this.setState({
          error: err,
          message: "Something went wrong",
          flagLoading: false,
        }); // error is set
        this.hideMessage();
      });
  };
  handleAnimationImageClick = () => {
    let { imageNumber } = this.props;
    axios
      .post(
        window.routerPrefix + "/files/presentation",
        { fileName: "d" + imageNumber },
        {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
          },
        }
      )
      .then((res) => {
        this.setState({
          flagLoading: false,
          response: res.data,
        });
        this.setState({
          flagLoading: false,
        });
      })
      .catch((err) => {
        console.log("error!");
        this.setState({
          flagLoading: false,
        });
      });
    // this.props.onPresentationImageClick(imageNumber);
  };
  render() {
    let { message } = this.state;
    let { flagShowAnimationImages } = this.state;
    let { imageAnimationList } = this.state;
    let { selectedSubjectFilePrefix } = this.props;
    let { selectedChapterNumber } = this.props;
    let { folderNumber } = this.props;
    let { filePrefixWOExtension } = this.props;
    let { selectedSubjectName } = this.props;
    let { selectedSubjectAvailableFor } = this.props;
    let image_info =
      selectedSubjectFilePrefix +
      "_" +
      selectedChapterNumber +
      "_" +
      folderNumber;
    return (
      <>
        {/* row starts - program-details */}
        {!flagShowAnimationImages && (
          <div className="col-4 col-md-4">
            {folderNumber}
            <img
              className="img-fluid"
              src={
                "api/files/downloadTeachingContentAnimationFirstFile/" +
                image_info
              }
              onClick={this.handleAnimationFirstImageClick}
            />
          </div>
        )}
        {flagShowAnimationImages && (
          <div className="row mb-2">
            {imageAnimationList.map((image, index) => (
              <AnimationImage
                key={index + 1}
                status={image.status}
                show={image.show}
                imageNumber={index + 1}
                folderNumber={folderNumber}
                filePrefixWOExtension={filePrefixWOExtension}
                selectedSubjectName={selectedSubjectName}
                selectedSubjectAvailableFor={selectedSubjectAvailableFor}
                onPresentationImageClick={this.handlePresentationImageClick}
                selectedSubjectFilePrefix={selectedSubjectFilePrefix}
                selectedChapterNumber={selectedChapterNumber}
              />
            ))}
          </div>
        )}
        {/* row ends  - program-details */}
        {/* Below is for videos */}
        {message && <div className="error-text text-left">{message}</div>}
      </>
    );
  }
}
export default Animation;
