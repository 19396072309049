import React, { Component } from "react";
import "../App.css";
import { Link } from "react-router-dom";

class NavBtnMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      loginTitle: "Login",
    };
  }
  handleMenuClick = () => {
    let { menu } = this.props;
    this.props.onMenuClick(menu);
  };
  render() {
    let { menu } = this.props;
    return ( 
      
      <button
        className={
          "button-menu " +
          (menu.flagActive ? " btn-lightcolor" : " btn-darkcolor")
        }
        to="#"
        onClick={() => this.handleMenuClick()}
      >
        {menu.display}{" "}
      </button>
    );
  }
}
export default NavBtnMenu;
