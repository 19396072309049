import React, { Component } from "react";
import { BeatLoader } from "react-spinners";
import axios from "axios";
import "./css/final-yaadi.css";
import { Link } from "react-router-dom";
class AdmissionStatus extends Component {
  errorObject = {
    emailId: { message: "" },
  };
  constructor(props) {
    super(props);

    this.state = {
      object: {
        emailId: "",
      },
      errorObject: this.errorObject,
      flagFormInvalid: false,
      flagWhatsappChecked: false,
      student: { fullName: "" },
      flagLoadingProfileDetails: true,
      offerStatus: "",
      referral: null,
    };
  }
  componentDidMount = () => {
    let path = this.props.location.pathname; //.substring(11)
    // Check whether link is correct or not.
    let pos = path.indexOf("_"); //this.props.location.pathname;//.substring(11)
    if (pos == -1) {
      //wrong link
      this.setState({
        flagLinkOk: false,
      });
      return;
    } else if (pos != 44) {
      //wrong link (size of student id is 36 characters 8-4-4-4-12)
      // /status/
      this.setState({
        flagLinkOk: false,
      });
      return;
    }
    // First check whether the referring and referred students are correct or not
    this.setState({
      flagLinkOk: true,
      flagLoadingStatusDetails: true,
    });
    let referredByStudentId = path.substring(8, pos);
    let referredEmailId = path.substring(pos + 1);
    // console.log(referredByStudentId + "..." + referredEmailId);
    axios
      .get(
        "/" +
          window.routerPrefix +
          "/referral/referralByReferredByStudentId/" +
          referredByStudentId +
          "?emailId=" +
          referredEmailId
      )
      .then((res) => {
        //  {"referralId":2659,
        // "referredStudentId":null,
        // "referredStudentName":null,
        // "referredEmailId":"j_rajesh@hotmail.com",
        // "referredByStudentId":"4ed0659d-615e-4b14-bd40-b61fac8f3351",
        // "referredByStudentName":"Smita Rajesh Jain","referralStatus":"Email-id submitted"}
        let referral = res.data;
        this.setState({
          flagLoadingStatusDetails: false,
          referral: referral,
        });
        if (referral) {
          // yes link is ok
          // But this student might be registered already, check it
          
        
          this.setState({
            flagLinkOk: true,
          });

        } else {
          this.setState({
            flagLinkOk: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          error: err,
          message: "Something went wrong",
          flagLoadingStatusDetails: false,
        }); // error is set
      });
  };
  render() {
    let { referral } = this.state;
    let { flagLoadingStatusDetails } = this.state;
    let { flagLinkOk } = this.state;
    let { offerStatus } = this.state;
    if (flagLoadingStatusDetails) {
      return (
        <div className="text-center mt-5">
          <BeatLoader size={16} color={"blue"} flagLoadingStatusDetails />
        </div>
      );
    }
    let message;
    let lastDate = new Date("2023-2-28");
    let today = new Date();
    if (today <= lastDate) {
      message = `You are eligible for discount of Rs. 500/- 
    in the fees of our most popular course -  
    "Programming using 3 languages (C,C++,Python)".
    Last date for this offer is ${lastDate.getDate()}/${
        lastDate.getMonth() + 1
      }/${lastDate.getFullYear()}.
    `;
    } else {
      message = `The offer of Rs. 500/- discount 
      in the fees of our most popular course -  
    "Programming using 3 languages (C,C++,Python)
      is expired... 
    "`;
    }
    if (flagLoadingStatusDetails) {
      return (
        <div className="text-center mt-5">
          <BeatLoader size={16} color={"blue"} flagLoading />
        </div>
      );
    }

    return (
      <React.Fragment>
        <div className="container container-reference-page">
          <div className="col-12 text-center mb-3">
            <img
              src="/images/rjac_logo.jpg"
              alt="No logo"
              className="img-fluid "
              onClick={this.handleLogoClick}
            />
          </div>
          <div className="row div-reference-page text-center">
            <div className="col-12">Welcome to Rajesh Jain Academy.</div>
          </div>
          <div className="row div-reference-page text-center">
            <div className="col-12">
              To know more about us, click &nbsp;&nbsp;
              <Link to={"/"}>here</Link>
            </div>
          </div>
          {flagLinkOk && (
            <div className="row div-reference-page text-center">
              <div className="col-12 text-thick">
                Details of Referred Student
              </div>
            </div>
          )}
          <div className="container container-profile-admission  ">
            {referral && (
              <div>
                <div className="row mx-auto">
                  {referral.referredStudentName && (
                    <div className="col-4 col-md-6 text-right">Name:</div>
                  )}
                  {referral.referredStudentName && (
                    <div className="text-left col-8 col-md-6">
                      {referral.referredStudentName}
                    </div>
                  )}
                  <div className="col-4 col-md-6  text-right">Email-id:</div>
                  <div className="text-left col-8 col-md-6 ">
                    {referral.referredEmailId}
                  </div>
                  <div className="col-4 col-md-6 text-right  ">Referred By:</div>
                  <div className="text-left col-8 col-md-6">
                    {referral.referredByStudentName}
                  </div>
                  <div className="col-4 col-md-6 text-right">Status:</div>
                  <div className="text-left col-8 col-md-6">
                    {referral.referralStatus}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default AdmissionStatus;
