import React, { Component } from "react";
import ExploreSubject from "./explore-subject";
import { BeatLoader } from "react-spinners";
class CoursesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleSubjectClick = (subject) => {
    this.props.onSubjectClick(subject);
  };
  render() {
    let { subjectList } = this.props;
    let { flagLoadingSubjects } = this.props;

    return (
      <React.Fragment>
        <div className="text-center mb-4">
          <h5
            className="text-primarycolor  mb-3"
            style={{ letterSpacing: "5px" }}
          >
            Try Our Popular Courses
          </h5>
        </div>
        {flagLoadingSubjects && (
          <div className="text-center mt-5">
            <BeatLoader size={16} color={"blue"} flagLoadingSubjects />
          </div>
        )}
        {/* row-begins */}

        {!flagLoadingSubjects && (
          <div className="container p-1">
            <div className="row">
              {subjectList.map((subject, index) => (
                <ExploreSubject
                  subject={subject}
                  key={index}
                  index={index}
                  onSubjectClick={this.handleSubjectClick}
                />
              ))}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
export default CoursesPage;
