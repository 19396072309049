import React, { Component } from "react";
import AdminAdmissionList from "./admin-admission-list";
import AdminAdmissionAdd from "./admin-admission-add";
import { AdmissionDB } from "../AdmissionDB";
import { BeatLoader } from "react-spinners";
import axios from "axios";

class AdminAdmission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      response: {},
      admission: null,
      filteredAdmissions: [],
      studentList: [],
      courseList: [],
      admissionList: [],
      selectedStudent: "",
      flagLoading: false,
      searchText: "",
      sortedField: "",
      sortedDirection: false,
      sectionNumber: 1,
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  getAdmissionListFromDatabase = (student, studentId) => {
    let { batchList } = this.state;
    let { courseList } = this.state;
    this.setState({
      flagLoading: true,
    });
    axios
      .all([
        axios.get(
          window.routerPrefix + "/admission/admissionsByStudent/" + studentId
        ),
      ])
      .then(
        axios.spread((res1) => {
          let admissionList = res1.data;
          let updatedAdmissions = [...admissionList];
          admissionList.map((admission, index) => {
            // get batch  (string) from batchId
            for (let i = 0; i < batchList.length; i++) {
              if (admission.batchId === batchList[i].batchId) {
                updatedAdmissions[index].batch = batchList[i].name;
                break;
              }
            }
            // get course  (string) from courseId
            for (let i = 0; i < courseList.length; i++) {
              if (admission.courseId === courseList[i].courseId) {
                updatedAdmissions[index].course = courseList[i].name;
                break;
              }
            }
            admission.student = student;
          }); //map
          admissionList = updatedAdmissions;
          // admissionList.map((admission, index) => {
          //  admission.student = student;
          // }); //map

          this.setState({
            admissionList: admissionList,
            filteredAdmissions: admissionList,
            admissiondb: new AdmissionDB(admissionList),
            flagLoading: false,
            // initFlag: true,
            /* Reason for using this initFlag: This parent has got ajax calls so,
during rendering of child error obtained, undefined props property,
so as log as initFlag is false, (i.e. data is not fetched), do not render
the parent ( so obviously child is also not rendered). 21.05.2020
https://stackoverflow.com/questions/54219364/react-function-call-after-render-and-before-childs-constructor
*/
          });
        })
      )
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  };
  componentDidMount() {
    // List of students, courses, batches should be ready
    this.setState({
      flagLoading: true,
    });
    axios
      .all([
        axios.get(window.routerPrefix + "/student/students/"),
        axios.get(window.routerPrefix + "/course/courses"),
        axios.get(window.routerPrefix + "/batch/batches"),
      ])
      .then(
        axios.spread((res1, res2, res3) => {
          let studentList = res1.data;
          let courseList = res2.data;
          let batchList = res3.data;
          this.setState({
            studentList: studentList,
            courseList: courseList,
            batchList: batchList,
            flagLoading: false,
          });
        })
      )
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  }
  handleSearchTextBoxKeyUp = (event) => {
    let searchText = event.target.value;
    this.setState({ searchText: searchText });
    this.performSearchOperation(searchText);
  };
  handleSearchTextChange = (event) => {
    let searchText = event.target.value;
    this.setState({ searchText: searchText });
    this.performSearchOperation(searchText);
  };
  performSearchOperation = (searchText) => {
    let query = searchText.trim();
    let searchedAdmissions = [];
    searchedAdmissions = this.state.admissiondb.filterByName(query);
    this.setState({
      filteredAdmissions: searchedAdmissions,
    });
  };
  onFormSubmit(data) {
    let { action } = this.props;
    this.setState({
      flagLoading: true,
    });
    if (action === "UPDATE") {
      //EDIT / PUT
      axios
        .put(window.routerPrefix + "/admission/update", data)
        .then((res) => {
          this.updateAdmissionList(data);
          this.setState({
            response: res.data,
            flagLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            flagLoading: false,
          });
          //   this.props.askToLogin();
        });
    } else if (action === "ADD") {
      //ADD / POST
      axios
        .post(window.routerPrefix + "/admission/addAdmission", data, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
          },
        })
        .then((res) => {
          this.setState({
            flagLoading: false,
            response: res.data,
          });
          data.admissionId = res.data.admissionId; // id of the added admission is returned as response
          this.setState({
            studentToRetain: data.student,
            studentIdToRetain: data.studentId,
            flagLoading: false,
          });

          this.addToAdmissionList(data);
        })
        .catch((err) => {
          console.log("error!");
          this.setState({
            flagLoading: false,
          });
        });
    } //else
  }
  handleEditAdmission = (admission) => {
    this.setState({
      admission: admission,
      searchText: "",
    });
    this.props.onEditClick();
  };
  addToAdmissionList = (admission) => {
    let admissionList = [...this.state.admissionList];
    admissionList.unshift(admission); // insert at the beginning
    let filteredAdmissions = [...this.state.filteredAdmissions];
    filteredAdmissions.unshift(admission);
    this.setState({
      filteredAdmissions: filteredAdmissions,
      admissionList: admissionList,
    });
    let message = "The admission done successfully.";
    this.props.onCrudAction(message, "ADD");
  };
  updateAdmissionList = (admission) => {
    let admissionList = [...this.state.admissionList];
    for (let i = 0; i < admissionList.length; i++) {
      if (admissionList[i].admissionId == admission.admissionId) {
        if (admissionList[i].studentId == admission.studentId) {
          // update, but it should be at the beginning
          // admissionList[i] = admission;
          admissionList.splice(i, 1); // remove 1 element from position i
          // and insert at the beginning as we want latest modified at the top
          admissionList.unshift(admission);
        } else {
          //delete from current list
          admissionList.splice(i, 1); // remove 1 element from position i
        }
        break;
      } //if
    } //for
    // remove from filteredadmissions also
    let filteredAdmissions = [...this.state.filteredAdmissions];
    for (let i = 0; i < filteredAdmissions.length; i++) {
      if (filteredAdmissions[i].admissionId == admission.admissionId) {
        if (filteredAdmissions[i].studentId == admission.studentId) {
          // update
          // filteredAdmissions[i] = admission;
          filteredAdmissions.splice(i, 1); // remove 1 element from position i
          // and insert at the beginning as we want latest modified at the top
          filteredAdmissions.unshift(admission);
        } else {
          //delete from current list
          filteredAdmissions.splice(i, 1); // remove 1 element from position i
        }
        break;
      } //if
    } //for
    this.setState({
      filteredAdmissions: filteredAdmissions,
      admissionList: admissionList,
      // searchText: "",
    });
    let message =
      "The admission '" + admission.name + "' updated successfully.";
    this.props.onCrudAction(message, "LIST", "Add a Admission");
  };

  handleDeleteAdmission = (admission) => {
    let admissionList = [...this.state.admissionList];
    for (let i = 0; i < admissionList.length; i++) {
      if (admissionList[i].admissionId == admission.admissionId) {
        admissionList.splice(i, 1); // remove 1 element from position i
      }
    } //for
    // remove from filteredadmissions also
    let filteredAdmissions = [...this.state.filteredAdmissions];
    for (let i = 0; i < filteredAdmissions.length; i++) {
      if (filteredAdmissions[i].admissionId == admission.admissionId) {
        filteredAdmissions.splice(i, 1); // remove 1 element from position i
      }
    } //for

    this.setState({
      //   admissiondb: new AdmissionDB(admissionList),
      filteredAdmissions: filteredAdmissions,
      admissionList: admissionList,
      // searchText: "",
    });
    let message =
      "The admission '" + admission.name + "' deleted successfully.";
    this.props.onCrudAction(message, "LIST");
  };

  handleHeaderClick = (event) => {
    let field = event.target.id;

    let direction = false;
    if (field === this.state.sortedField) {
      // same button clicked twice
      direction = !this.state.direction;
    } else {
      // different field
      direction = false;
    }
    this.setState({ direction: direction });
    let { filteredAdmissions } = this.state;
    if (direction == false) {
      //in ascending order
      filteredAdmissions = filteredAdmissions.sort((a, b) => {
        if (a[field] > b[field]) {
          return 1;
        }
        if (a[field] < b[field]) {
          return -1;
        }
        return 0;
      });
    } else {
      //in descending order
      filteredAdmissions = filteredAdmissions.sort((a, b) => {
        if (a[field] < b[field]) {
          return 1;
        }
        if (a[field] > b[field]) {
          return -1;
        }
        return 0;
      });
    }
    this.setState({
      filteredAdmissions: filteredAdmissions,
      sortedField: field,
    });
  };
  handleSectionNumberClick = (event) => {
    this.setState({ sectionNumber: event.target.id });
  };
  handleSelectStudentChange = (selectedStudent, selectedStudentId) => {
    // This is called for LIST as well ADD operation.
    this.getAdmissionListFromDatabase(selectedStudent, selectedStudentId);
    this.setState({
      selectedStudent: selectedStudent,
      selectedStudentId: selectedStudentId,
    });
    if (selectedStudent) {
      this.props.showAddButton();
    }
  };
  render() {
    let { flagLoading } = this.state;
    let { action } = this.props;
    let { admission } = this.state;
    let { studentList } = this.state;
    let { courseList } = this.state;
    let { batchList } = this.state;
    let { selectedStudent } = this.state;
    let { selectedStudentId } = this.state;
    let { accessLevel } = this.props;
    let { adminType } = this.props;

    let content;
    if (flagLoading) {
      content = (
        <div className="text-center">
          <BeatLoader size={16} color={"blue"} flagLoading />
        </div>
      );
    } else if (action === "LIST") {
      // First show list
      content = (
        <AdminAdmissionList
          studentList={studentList}
          onSelectStudentChange={this.handleSelectStudentChange}
          selectedStudent={selectedStudent}
          onEditAdmission={this.handleEditAdmission}
          onDeleteAdmission={this.handleDeleteAdmission}
          filteredAdmissions={this.state.filteredAdmissions}
          searchText={this.state.searchText}
          onSearchTextChange={this.handleSearchTextChange}
          onSearchTextBoxKeyUp={this.handleSearchTextBoxKeyUp}
          onHeaderClick={this.handleHeaderClick}
          onSectionNumberClick={this.handleSectionNumberClick}
          sectionNumber={this.state.sectionNumber}
          onDuplicateAdmission={this.handleDuplicateAdmission}
          currentSize={this.state.admissionList.length}
          accessLevel={accessLevel}
          adminType={adminType}
        />
      );
    } else if (action === "ADD" || action === "UPDATE") {
      // After pressing Add Button, show only form and not the list
      content = (
        <div>
          {/* content={" "} */}
          <AdminAdmissionAdd
            onFormSubmit={this.onFormSubmit}
            studentList={studentList}
            courseList={courseList}
            batchList={batchList}
            admission={admission}
            action={action}
            selectedStudent={selectedStudent}
            selectedStudentId={selectedStudentId}
            studentToRetain={this.state.studentToRetain}
            studentIdToRetain={this.state.studentIdToRetain}
          />
        </div>
      );
    } else if (action === "DELETE") {
      content = <div>Delete action in Progress...</div>;
    }
    return (
      <div className="container-fluid container-md container-content-page p-0">
        {content}
      </div>
    );
  }
}
export default AdminAdmission;
