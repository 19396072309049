import React, { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import axios from "axios";
import Select from "react-select";

function StudentJoinCourseForm(props) {
  let defaultCourse = {
    name: "Select Course",
    label: "Select Course",
  };
  let [joiningStudent, setJoiningStudent] = useState({
    courseType: 3,
    course: "",
  });
  let [errorJoiningStudent, setErrorJoiningStudent] = useState({
    courseType: { message: "" },
    course: { message: "" },
  });
  let [flagLoading, setFlagLoading] = useState(false);
  let [error, setError] = useState("");
  let [message, setMessage] = useState("");
  let [flagFormInvalid, setFlagFormInvalid] = useState(false);
  let [courseList, setCourseList] = useState([]);
  let [selectedCourse, setSelectedCourse] = useState(defaultCourse);
  let [optionsCourse, setOptionsCourse] = useState([]);
  useEffect(() => {
    window.scroll(0, 0);
    init();
  }, []);
  function init() {
    //get the courses from backend

    setFlagLoading(true);
    axios
      .get(window.routerPrefix + "/course/courses")
      .then((res) => {
        setFlagLoading(false);
        let courseList = res.data;
        // Let us sort as per course Fees in Ascedning order
        courseList = courseList.sort((a, b) => {
          if (a["fees"] > b["fees"]) {
            return 1;
          }
          if (a["fees"] < b["fees"]) {
            return -1;
          }
          return 0;
        });
        // to be done for React Select Component
        let updatedCourseList = courseList.map((course, index) => {
          course.value = course.name;
          course.label = course.name + " (Rs. " + course.fees + ")";
          return course;
        });
        setCourseList(updatedCourseList);
      })
      .catch((err) => {
        setError(err);
        setFlagLoading(false);
      });
  }
  function handleChange(event) {
    props.onSetMessage("");
    setMessage("");
    let name = event.target.name;
    let userInput;
    let inputType = event.target.type;
    if (inputType == "checkbox") {
      // for checkbox, no checking of validations.
      userInput = event.target.checked;
      let value;
      if (userInput) {
        value = 1;
      } else {
        value = 0;
      }
      setJoiningStudent({ ...joiningStudent, [name]: value });
      return;
    }
    setJoiningStudent({ ...joiningStudent, [name]: event.target.value });
    isValid(event);
  }
  function handleBlur(event) {
    isValid(event);
  }
  function isValid(event) {
    let i,
      flag,
      name,
      value,
      requiredMessage = " is required",
      type,
      message = "",
      mnLen,
      mxLen,
      onlyDigits,
      allDigits,
      noSymbols;
    name = event.target.name;
    type = event.target.type;
    if (type === "text" || type === "textarea" || type === "password") {
      // check if 'required', also check min no. of characters
      value = event.target.value.trim();
      mnLen = errorJoiningStudent[`${name}`].mnLen;
      mxLen = errorJoiningStudent[`${name}`].mxLen;
      onlyDigits = errorJoiningStudent[`${name}`].onlyDigits;
      allDigits = errorJoiningStudent[`${name}`].allDigits;
      noSymbols = errorJoiningStudent[`${name}`].noSymbols;
      if (value.length === 0) {
        message = name + requiredMessage;
      } else if (value.length < mnLen) {
        if (!onlyDigits) message = "Min " + mnLen + " characters required";
        else message = "Min " + mnLen + " digits required";
      } //else
      else if (value.length > mxLen) {
        if (!onlyDigits) message = "Maximum " + mxLen + " characters allowed";
        else message = "Maximum " + mxLen + " digits allowed";
      } //else
      else if (onlyDigits) {
        for (let i = value.length - 1; i >= 0; i--) {
          const d = value.charCodeAt(i);
          if (d < 48 || d > 57) {
            message = "Enter only digits";
            break;
          } //if
        } //for
      } //else if... onlyDigits
      else if (noSymbols && !allDigits) {
        let d,
          count = 0;
        for (i = value.length - 1, flag = false; i >= 0; i--) {
          d = value.charCodeAt(i);
          if (d >= 48 && d <= 57) {
            count++; // digits are being counted
          } //if
          if (
            (d >= 97 && d <= 122) ||
            (d >= 65 && d <= 90) ||
            (d >= 48 && d <= 57) ||
            value.charAt(i) == "_"
          ) {
            continue;
          } //if
          else {
            // symbol is there, may be space
            flag = true;
            break;
          }
        } //for
        if (flag == true) {
          let q = String.fromCharCode(d);
          if (d == 32) {
            message = "space is not allowed";
          } else
            message =
              "Symbols other than underscore (_) are not allowed, you have used " +
              q;
        } else {
          if (count == value.length) {
            message = "Only digits are not allowed, use some alphabets also";
          }
        }
      } //else if... noSymbols
      else {
        message = "";
      }
    } //if... text || textarea
    else if (event.target.type === "select-one") {
      value = event.target.selectedIndex;

      if (value == 0) {
        message = name + requiredMessage;
      } else {
        message = "";
      }
    } //else if
    else if (type === "email") {
      value = event.target.value.trim();
      if (/\S+@\S+\.\S+/.test(value) == 0) {
        message = "valid email-id " + requiredMessage;
      } else {
        message = "";
      }
    } //else if
    else {
      message = "";
    }
    let errJoiningStudent = { ...errorJoiningStudent };
    errJoiningStudent[`${name}`].message = message;
    setErrorJoiningStudent(errJoiningStudent);
    checkAllErrors();
  }
  function handleFocus(event) {
    checkAllErrors();
  }
  function checkAllErrors() {
    for (let field in errorJoiningStudent) {
      if (errorJoiningStudent[field].message != "") {
        // setFlagFormInvalid(true);
        return;
      } //if
    } //for
    // setFlagFormInvalid(false);
  }

  function checkFormInvalidity() {
    // if error messages are there, form is invalid
    for (let field in joiningStudent) {
      if (errorJoiningStudent[field].message != "") {
        setFlagFormInvalid(true);
        return true;
      } //if
    } //for
    let errJoiningStudent = { ...errorJoiningStudent };
    let flag = false;
    // Now check for required fields, (mainly added for select components)
    for (let field in joiningStudent) {
      if (joiningStudent[field] == "") {
        errJoiningStudent[field].message = "Required...";
        flag = true;
      } //if
    } //for
    setErrorJoiningStudent(errJoiningStudent);
    if (flag == false) {
      setFlagFormInvalid(false);
      return false;
    } else {
      setFlagFormInvalid(true);
      return true;
    }
  }

  // React-Select Component
  function handleSelectedCourse(course) {
    setSelectedCourse(course);
    setJoiningStudent({ ...joiningStudent, course: course.name });
    //Remove error message
    let errJoiningStudent = { ...errorJoiningStudent };
    errJoiningStudent["course"].message = "";
    setErrorJoiningStudent(errJoiningStudent);
  }
  function onFormSubmit() {
    // event.preventDefault();
    if (checkFormInvalidity()) {
      return;
    }
    setFlagLoading(true);
    // Joining student is the enquired student (ready to join) with additional info
    let enquiredStudent = { ...joiningStudent };
    let { joiningStudentData } = props;
    enquiredStudent.enquiredStudentId = joiningStudentData.enquiredStudentId;
    enquiredStudent.courseId = selectedCourse.courseId;

    console.log(JSON.stringify(enquiredStudent));
    axios
      .put(
        // window.routerPrefix + "/enquiredStudent/updateEnquiredStudent",
        window.routerPrefix + "/enquiredStudent/updateEnquiredStudent",
        enquiredStudent,
        {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
          },
        }
      )
      .then((res) => {
        setFlagLoading(false);
        let response = res.data;
        if (!response) {
          //Error, Something went wrong
        } else if (response.enquiredStudentId == "-1") {
          props.onSetMessage(
            "This email-id is already registered. Join a course through your profile, after logging in."
          );
        } else {
          props.onCourseSelect(true);
          props.onCourseSelectData(selectedCourse);
          props.onJoiningStudentData(res.data);
        }
      })
      .catch((err) => {
        setError(err);
        setFlagLoading(false);
      });
  }
  function handleSubmit(event) {
    event.preventDefault();
    window.scrollTo(0, 0); // moving scrollbar at the top
    onFormSubmit();
  }
  function handleCancelFormClick() {
    props.onCancelFormClick();
  }
  function handleCourseTypeSelection(value) {
    setJoiningStudent({ ...joiningStudent, courseType: value });
    //

    let oCourse = courseList.filter(
      (course, index) => course.courseType == value
    );
    setOptionsCourse(oCourse);
    setSelectedCourse(null);
  }
  // let oCourse = courseList.filter(
  //   (course, index) => course.courseType == joiningStudent.courseType
  // );
  if (flagLoading) {
    return (
      <div className="form-group row offset-5 text-center mb-0">
        <BeatLoader size={16} color={"blue"} flagLoading />
      </div>
    );
  }
  return (
    <>
      <div className="container">
        <div className="row div-reference-page ">
          <div className="col-12 text-thick text-center mt-2">
            Select the desired course / Bootcamp.
          </div>
        </div>

        <form className="text-dark bg-mycyan p-4" onSubmit={handleSubmit}>
          {/* row starts */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Course Type</label>
            </div>
            <div className="col-7  px-0">
              <input
                type="radio"
                className="form-control-inline"
                name="courseType"
                value="0"
                onClick={() => handleCourseTypeSelection("0")}
                onChange={() => handleCourseTypeSelection("0")}
                checked={joiningStudent.courseType == "0"}
              />
              &nbsp;Offline
            </div>
            <div className="col-5  px-0"></div>
            <div className="col-7  px-0">
              <input
                type="radio"
                className="form-control-inline"
                name="courseType"
                value="1"
                onClick={() => handleCourseTypeSelection("1")}
                onChange={() => handleCourseTypeSelection("1")}
                checked={joiningStudent.courseType == "1"}
              />
              &nbsp;Offline+Online
            </div>
            <div className="col-5  px-0"></div>
            <div className="col-7  px-0">
              <input
                type="radio"
                className="form-control-inline"
                name="courseType"
                value="2"
                onClick={() => handleCourseTypeSelection("2")}
                onChange={() => handleCourseTypeSelection("2")}
                checked={joiningStudent.courseType == "2"}
              />
              &nbsp;Bootcamp
            </div>
          </div>
          {/* row starts */}
          {/* row starts */}
          <div className="form-group row  align-items-center">
            <div className="col-5 text-right">
              <label>Course</label>
            </div>
            <div className="col-7  px-0">
              <Select
                defaultValue={selectedCourse}
                onChange={handleSelectedCourse}
                options={optionsCourse}
              />
            </div>
            <div className="offset-5">
              {errorJoiningStudent.course.message ? (
                <span className="error-text">
                  {errorJoiningStudent.course.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          {!flagLoading && (
            <div className="form-group row offset-5 text-center mb-0">
              <button
                className="btn-mycyan"
                type="submit"
                // disabled={flagFormInvalid}
              >
                Submit
              </button>
              {/* &nbsp;&nbsp;
              <button className="btn-mycyan" onClick={handleCancelFormClick}>
                Cancel
              </button> */}
            </div>
          )}

          {message && <div className="text-error text-center">{message}</div>}
        </form>
      </div>
    </>
  );
}
export default StudentJoinCourseForm;
