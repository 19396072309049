import React, { Component } from "react";
import { BeatLoader } from "react-spinners";
// import "./css/final-yaadi.css";
import { Link } from "react-router-dom";
class Certificate extends Component {
  errorObject = {
    emailId: { message: "" },
  };
  constructor(props) {
    super(props);

    this.state = {
      studentId: "",
      courseId: "",
      flagLoadingCertificate: false,
    };
  }
  getImageLink = () => {
    let { studentId } = this.state;
    let { courseId } = this.state;
    let serverResponse =
      "/" +
      window.routerPrefix +
      "/files/downloadCertificateImage/" +
      studentId +
      "?courseId=" +
      courseId;
    return serverResponse;
  };
  componentDidMount = () => {
    let path = this.props.location.pathname; //.substring(11)
    let studentId = path.substring(14, 14 + 36); // student id is of 36 length
    let pos = path.indexOf("_"); //this.props.location.pathname;//.substring(11)
    let courseId = path.substring(pos + 1);
    if (pos == -1) {
      //wrong link
      this.setState({
        flagLinkOk: false,
      });
      return;
    } else if (studentId.length != 36) {
      //wrong link (size of student id is 36 characters 8-4-4-4-12)
      this.setState({
        flagLinkOk: false,
      });
      return;
    }
    this.setState({
      studentId: studentId,
      courseId: courseId,
      path: path,
    });
    return;
  };

  render() {
    let { flagLoadingCertificate } = this.state;
    let { flagLinkOk } = this.state;
    let message;
    if (flagLoadingCertificate) {
      return (
        <div className="text-center mt-5">
          <BeatLoader size={16} color={"blue"} flagLoadingCertificate />
        </div>
      );
    }
    return (
      <>
        <div className="container container-certificate-page">
          <div className="row">
            <div className="col-12 text-center mb-3 ">
              <img
                src="/images/rjac_logo.jpg"
                alt="No logo"
                className="img-fluid "
                onClick={this.handleLogoClick}
              />
            </div>
          </div>
          <div className="row div-reference-page text-center">
            <div className="col-12">Welcome to Rajesh Jain Academy.</div>
          </div>
          <div className="row div-reference-page text-center">
            <div className="col-12">
              To know more about us, click &nbsp;&nbsp;
              <Link to={"/"}>here</Link>
            </div>
          </div>

          {flagLinkOk && (
            <div className="row div-reference-page text-center">
              <div className="col-12" style={{ whiteSpace: "pre-wrap" }}>
                {message}
              </div>
            </div>
          )}
          {true && (
            <div className="div-certificate">
              <img
                src={this.getImageLink()}
                alt="Invalid Link"
                className="img-fluid "
              />
            </div>
          )}
          {/* <div className="text-center">
            <iframe
              height="500"
              width="800"
              src={pdf_url}
              type="application/pdf"
            />
          </div> */}
        </div>
      </>
    );
  }
}
export default Certificate;
