import React, { Component } from "react";
import AdminProgramList from "./admin-program-list";
import AdminProgramAdd from "./admin-program-add";
import { ProgramDB } from "../ProgramDB";
import { BeatLoader } from "react-spinners";
import axios from "axios";

class AdminNote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      response: {},
      program: null,
      filteredPrograms: [],
      subjectList: [],
      chapterList: [],
      programList: [],
      selectedSubject: "",
      selectedChapter: "",
      flagLoading: false,
      flagSubjectChange: false,
      searchText: "",
      sortedField: "",
      sortedDirection: false,
      sectionNumber: 1,
      lastProgramNumber: 0,
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  getProgramListFromDatabase = (chapter, chapterId) => {
    let { selectedSubject } = this.state;
    let { programType } = this.props;
    this.setState({
      flagLoading: true,
    });
    axios
      // .all([axios.get("/"+program/programs/" + chapterId)])
      .all([
        axios.get(window.routerPrefix+"/" + programType + "/" + programType + "s/" + chapterId),
      ])
      .then(
        axios.spread((res1) => {
          let programList = res1.data;

          let updatedPrograms = [...programList];
          programList.map((program, index) => {
            program.chapter = chapter;
            program.subject = selectedSubject;
          }); //map
          programList = updatedPrograms;
          this.getLastProgramNumber(programList);
          this.setState({
            programList: programList,
            filteredPrograms: programList,
            programdb: new ProgramDB(programList),
            flagLoading: false,
          });
        })
      )
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  };
  componentDidMount() {
    // For LIST as well as ADD, we will need list of subjects
    this.setState({
      flagLoading: true,
    });
    axios
      .all([axios.get(window.routerPrefix+"/subject/subjects/")])
      .then(
        axios.spread((res1) => {
          let subjectList = res1.data;
          this.setState({
            subjectList: subjectList,
            flagLoading: false,
          });
        })
      )
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  }
  handleSearchTextBoxKeyUp = (event) => {
    let searchText = event.target.value;
    this.setState({ searchText: searchText });
    this.performSearchOperation(searchText);
  };
  handleSearchTextChange = (event) => {
    let searchText = event.target.value;
    this.setState({ searchText: searchText });
    this.performSearchOperation(searchText);
  };
  performSearchOperation = (searchText) => {
    let query = searchText.trim();
    let searchedPrograms = [];
    searchedPrograms = this.state.programdb.filterByName(query);
    this.setState({
      filteredPrograms: searchedPrograms,
    });
  };

  onFormSubmit(data, selectedFile) {
    let { action } = this.props;
    let { programType } = this.props;
    if (action === "UPDATE") {
      //EDIT / PUT
      axios
        .put(window.routerPrefix+"/" + programType + "/update", data)
        .then((res) => {
          this.updateProgramList(data);
          this.setState({
            response: res.data,
            flagLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            flagLoading: false,
          });
        });
    } else if (action === "ADD") {
      // This is not uploading file 27.08.2022
      // file is directly copied to the folder
      // Here first letter is made capital
      let s = programType.charAt(0).toUpperCase() + programType.slice(1);
      axios
        // .post("/program/addProgram", data, {
        .post(window.routerPrefix+"/" + programType + "/add" + s, data, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
          },
        })
        .then((res) => {
          this.setState({
            flagLoading: false,
            response: res.data,
          });
          data.programId = res.data.programId; // id of the added program is returned as response
          this.setState({
            subjectToRetain: data.subject,
            subjectIdToRetain: data.subjectId,
            flagLoading: false,
          });
          this.addToProgramList(data);
        })
        .catch((err) => {
          console.log("error!");
          this.setState({
            flagLoading: false,
          });
        });
    } //else
    else if (action === "ADDFILE") {
      //ADD / POST
      // This is uploading file
      data.fileId = "";
      const formData = new FormData();
      Object.keys(data).forEach((key) => formData.append(key, data[key]));
      formData.append("afile", selectedFile);
      this.setState({
        flagLoading: true,
      });
      axios
        .post(window.routerPrefix+"/program/addProgramToFile", formData, {
          headers: {
            accept: "application/json",
          },
        })
        .then((res) => {
          // Now send file
          // this.uploadFile(selectedFile);
          this.setState({
            flagLoading: false,
            response: res.data,
          });
          data.programId = res.data.programId; // id of the added program is returned as response
          this.setState({
            subjectToRetain: data.subject,
            subjectIdToRetain: data.subjectId,
            flagLoading: false,
          });
          this.addToProgramList(data);
        })
        .catch((err) => {
          console.log("error!");
          this.setState({
            flagLoading: false,
          });
        });
    } //else
  }
  uploadFile = (selectedFile) => {
    this.setState({
      flagLoading: true,
    });
    const formdata = new FormData();
    formdata.append("file", selectedFile);
    axios
      .post(window.routerPrefix+"/uploads", formdata, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        // Now send file
        this.setState({
          flagLoading: false,
          response: res.data,
        });
      })
      .catch((err) => {
        console.log("error!");
        this.setState({
          flagLoading: false,
        });
      });
  };
  handleEditProgram = (program) => {
    this.setState({
      program: program,
      searchText: "",
    });
    this.props.onEditClick();
  };
  addToProgramList = (program) => {
    let programList = [...this.state.programList];
    programList.push(program);
    let filteredPrograms = [...this.state.filteredPrograms];
    filteredPrograms.push(program);
    this.setState({
      filteredPrograms: filteredPrograms,
      programList: programList,
    });
    let { programType } = this.props;
    let message =
      "The " +
      programType +
      " number'" +
      program.programNumber +
      "' added successfully.";
    this.setState({
      filteredPrograms: filteredPrograms,
      programList: programList,
    });
    this.getLastProgramNumber(programList);
    this.props.onCrudAction(message, "ADD");
  };
  updateProgramList = (program) => {
    let programList = [...this.state.programList];
    for (let i = 0; i < programList.length; i++) {
      if (programList[i].programId == program.programId) {
        if (
          programList[i].subjectId == program.subjectId &&
          programList[i].chapterId == program.chapterId
        ) {
          // update, but it should be at the beginning
          //  programList[i] = program;
          programList.splice(i, 1); // remove 1 element from position i
          // and insert at the beginning as we want latest modified at the top
          programList.unshift(program);
        } else {
          //delete from current list
          programList.splice(i, 1); // remove 1 element from position i
        }
        break;
      } //if
    } //for
    // remove from filteredprograms also
    let filteredPrograms = [...this.state.filteredPrograms];
    for (let i = 0; i < filteredPrograms.length; i++) {
      if (filteredPrograms[i].programId == program.programId) {
        if (
          filteredPrograms[i].subject == program.subject &&
          filteredPrograms[i].chapter == program.chapter
        ) {
          // update, but it should be at the beginning
          // filteredPrograms[i] = program;

          filteredPrograms.splice(i, 1); // remove 1 element from position i
          // and insert at the beginning as we want latest modified at the top
          filteredPrograms.unshift(program);
        } else {
          //delete from current list
          filteredPrograms.splice(i, 1); // remove 1 element from position i
        }
        break;
      } //if
    } //for
    this.setState({
      filteredPrograms: filteredPrograms,
      programList: programList,
    });
    let { programType } = this.props;
    let message = "The '" + programType + "' updated successfully.";
    this.props.onCrudAction(message, "LIST", "Add a Program");
  };

  handleDeleteProgram = (program) => {
    let programList = [...this.state.programList];
    for (let i = 0; i < programList.length; i++) {
      if (programList[i].programId == program.programId) {
        programList.splice(i, 1); // remove 1 element from position i
      }
    } //for
    // remove from filteredprograms also
    let filteredPrograms = [...this.state.filteredPrograms];
    for (let i = 0; i < filteredPrograms.length; i++) {
      if (filteredPrograms[i].programId == program.programId) {
        filteredPrograms.splice(i, 1); // remove 1 element from position i
      }
    } //for

    this.setState({
      //   programdb: new ProgramDB(programList),
      filteredPrograms: filteredPrograms,
      programList: programList,
      // searchText: "",
    });
    let message =
      "The program '" + program.programNumber + "' deleted successfully.";
    this.props.onCrudAction(message, "LIST");
  };
  getLastProgramNumber = (programList) => {
    // let programList = this.state.programList;
    let lastProgramNumber = 0;
    for (let i = 0; i < programList.length; i++) {
      if (programList[i].programNumber > lastProgramNumber) {
        lastProgramNumber = programList[i].programNumber;
      } //if
    } //for
    console.log("lastProgramNumber...." + lastProgramNumber);
    this.setState({
      lastProgramNumber: lastProgramNumber,
    });
  };

  handleHeaderClick = (event) => {
    let field = event.target.id;
    let direction = false;
    if (field === this.state.sortedField) {
      // same button clicked twice
      direction = !this.state.direction;
    } else {
      // different field
      direction = false;
    }
    this.setState({ direction: direction });
    let { filteredPrograms } = this.state;
    if (direction == false) {
      //in ascending order
      filteredPrograms = filteredPrograms.sort((a, b) => {
        if (a[field] > b[field]) {
          return 1;
        }
        if (a[field] < b[field]) {
          return -1;
        }
        return 0;
      });
    } else {
      //in descending order
      filteredPrograms = filteredPrograms.sort((a, b) => {
        if (a[field] < b[field]) {
          return 1;
        }
        if (a[field] > b[field]) {
          return -1;
        }
        return 0;
      });
    }
    this.setState({ filteredPrograms: filteredPrograms, sortedField: field });
  };
  handleSectionNumberClick = (event) => {
    this.setState({ sectionNumber: event.target.id });
  };

  handleSelectSubjectChange = (selectedSubject, selectedSubjectId) => {
    // This is called during LIST only
    // As subject is changed, list of chapters should also change.
    this.setState({
      selectedSubject: selectedSubject,
      selectedSubjectId: selectedSubjectId,
    });
    this.getChapterListFromDatabase(selectedSubject, selectedSubjectId);
  };
  getChapterListFromDatabase = (subject, subjectId) => {
    this.setState({
      flagSubjectChange: true,
    });
    axios
      .all([axios.get(window.routerPrefix+"/chapter/chapters/" + subjectId)])
      .then(
        axios.spread((res1) => {
          let chapterList = res1.data;
          let updatedChapters = [...chapterList];
          chapterList.map((chapter, index) => {
            chapter.subject = subject;
          }); //map
          chapterList = updatedChapters;
          this.setState({
            chapterList: chapterList,
            flagSubjectChange: false,
          });
        })
      )
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagSubjectChange: false });
      });
  };
  handleSelectChapterChange = (selectedChapter, selectedChapterId) => {
    this.setState({
      selectedChapter: selectedChapter,
      selectedChapterId: selectedChapterId,
    });
    this.getProgramListFromDatabase(selectedChapter, selectedChapterId);
    let { selectedSubject } = this.state;
    if (selectedSubject && selectedChapter) {
      // Only when subject is selected and chapter is selected, show "add" button
      this.props.showAddButton();
    }
  };
  render() {
    let { flagLoading } = this.state;
    let { flagSubjectChange } = this.state;
    let { action } = this.props;
    let { program } = this.state;
    let { programType } = this.props;
    let { subjectList } = this.state;
    let { chapterList } = this.state;
    let { selectedSubject } = this.state;
    let { selectedSubjectId } = this.state;
    let { selectedChapter } = this.state;
    let { selectedChapterId } = this.state;
    let { lastProgramNumber } = this.state;

    let content;
    if (flagLoading) {
      content = (
        <div className="text-center">
          <BeatLoader size={16} color={"blue"} flagLoading />
        </div>
      );
    } else if (action === "LIST") {
      // First show list
      content = (
        <AdminProgramList
          subjectList={subjectList}
          chapterList={chapterList}
          programType={programType}
          onSelectSubjectChange={this.handleSelectSubjectChange}
          onSelectChapterChange={this.handleSelectChapterChange}
          selectedSubject={selectedSubject}
          selectedChapter={selectedChapter}
          onEditProgram={this.handleEditProgram}
          onDeleteProgram={this.handleDeleteProgram}
          filteredPrograms={this.state.filteredPrograms}
          searchText={this.state.searchText}
          onSearchTextChange={this.handleSearchTextChange}
          onSearchTextBoxKeyUp={this.handleSearchTextBoxKeyUp}
          onHeaderClick={this.handleHeaderClick}
          onSectionNumberClick={this.handleSectionNumberClick}
          sectionNumber={this.state.sectionNumber}
          onDuplicateChapter={this.handleDuplicateChapter}
          currentSize={this.state.programList.length}
        />
      );
    } else if (action === "ADD" || action === "UPDATE") {
      // After pressing Add Button, show only form and not the list
      content = (
        <div>
          {/* content={" "} */}
          <AdminProgramAdd
            onFormSubmit={this.onFormSubmit}
            subjectList={subjectList}
            chapterList={chapterList}
            program={program}
            programType={programType}
            action={action}
            lastProgramNumber={lastProgramNumber}
            flagSubjectChange={flagSubjectChange}
            selectedSubject={selectedSubject}
            selectedSubjectId={selectedSubjectId}
            selectedChapter={selectedChapter}
            selectedChapterId={selectedChapterId}
            subjectToRetain={this.state.subjectToRetain}
            subjectIdToRetain={this.state.subjectIdToRetain}
            chapterToRetain={this.state.chapterToRetain}
            chapterIdToRetain={this.state.chapterIdToRetain}
          />
        </div>
      );
    } else if (action === "DELETE") {
      content = <div>Delete action in Progress...</div>;
    }
    return (
      <div className="container-fluid container-md container-content-page p-0">
        {content}
      </div>
    );
  }
}
export default AdminNote;
