import React, {Component} from 'react';

class  ActionMessage extends Component {
    render()
    {
    return (
      <React.Fragment>
        <h6 style={ {color :'red'} }>{this.props.message}</h6>
      </React.Fragment>
    );
    }//render
}
export default ActionMessage;
  
