import React, { Component } from "react";
import { BeatLoader } from "react-spinners";
class ContactUsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleSubjectClick = (subject) => {
    this.props.onSubjectClick(subject);
  };
  render() {
    let { subjectList } = this.props;
    let { flagLoadingSubjects } = this.props;

    return (
      <React.Fragment>
        {flagLoadingSubjects && (
          <div className="text-center mt-5">
            <BeatLoader size={16} color={"blue"} flagLoadingSubjects />
          </div>
        )}
        {/* row-begins */}
        <div className="container-contact-us ">
          <div className="div-contact-us">
            {"   "}email-id : rjacpune@gmail.com
          </div>
          <div className="div-contact-us">{"   "}WhatsApp : 94220 35020</div>
        </div>
        <div>
          {/* <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15137.671395487376!2d73.8344826!3d18.4647228!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2954eca5c3561%3A0x172c6cc113ad7c34!2sRajesh%20Jain%20Academy!5e0!3m2!1sen!2sin!4v1677298913908!5m2!1sen!2sin"
            width="600"
            height="450"
            style="border:0;"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe> */}
        </div>
      </React.Fragment>
    );
  }
}
export default ContactUsPage;
