import React, { Component } from "react";
import "../App.css";
import DropDownAdminManage from "./drop-down-admin-manage";
import DropDownAdminReport from "./drop-down-admin-report";
import DropDownAdminControl from "./drop-down-admin-control";

import DropDownAdminUtilities from "./drop-down-admin-utilities";
import ActionMessage from "./action-message";
class NavBarAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      loginTitle: "Login",
    };
  }
  handleAdminManageClick = (event) => {
    this.props.onAdminManageClick(event);
  };
  handleAdminControlClick = (event) => {
    this.props.onAdminControlClick(event);
  };
  handleAdminReportClick = (event) => {
    this.props.onAdminReportClick(event);
  };
  handleAdminUtilityClick = (event) => {
    this.props.onAdminUtilityClick(event);
  };
  handleAdminManageProducts = () => {
    this.props.onAdminManageProducts();
  };
  handleSearchTextChange = (event) => {
    this.props.onSearchTextChange(event);
  };
  handleAddListToggleClick = (event) => {
    event.preventDefault(); // Not required
    this.props.onAddListToggleClick();
  };
  handleLogoutClick = () => {
    this.props.onLogoutClick();
  };
  handleMasterListClick = () => {
    this.props.onMasterListClick();
  };
  componentDidMount = () => {};
  render() {
    let { adminManageList } = this.props;
    let { adminControlList } = this.props;
    let { adminReportList } = this.props;
    let { adminUtilityList } = this.props;
    let { adminType } = this.props;
    let { message } = this.props;
    let { menu } = this.props;
    let { validAdmin } = this.props;
    // let  validAdmin  = true;
    let { shopUrl } = this.props;
    // let { flagFirstTimeLogin } = this.props;
    let flagFirstTimeLogin = 1;
    return (
      <div className="container-fluid container-md fixed-top bg-mycyan p-0">
        <div className="row column justify-content-center align-items-center">
          {(!validAdmin || (validAdmin && flagFirstTimeLogin == 0)) && (
            <div className="col-5 col-md-3 mx-auto column p-2">
              <div>
                <img
                  src="/images/rjac_logo.jpg"
                  className="img-fluid"
                  // className="img-responsive"
                />
                <div className="text-center text-white">
                  <b>Mob.:</b> 94220 35020
                </div>
              </div>
            </div>
          )}
          {validAdmin && flagFirstTimeLogin == 1 && (
            <React.Fragment>
              <div className="col-10 text-center thick-text bigger-text column p-0">
                {shopUrl}
              </div>
              <div className="col-3 text-center thick-text bigger-text column p-0">
                <DropDownAdminManage
                  adminManageList={adminManageList}
                  onAdminManageClick={this.handleAdminManageClick}
                  title={"Manage"}
                  shortTitle={"Manage"}
                  adminType={adminType}
                />
              </div>
              <div className="col-3 text-center thick-text bigger-text column p-0">
                <DropDownAdminControl
                  adminControlList={adminControlList}
                  onAdminControlClick={this.handleAdminControlClick}
                  title={"Control"}
                  shortTitle={"Control"}
                  adminType={adminType}
                />
              </div>
              <div className="col-3 text-center thick-text bigger-text column p-0">
                <DropDownAdminReport
                  adminReportList={adminReportList}
                  onAdminReportClick={this.handleAdminReportClick}
                  title={"Utilities"}
                  shortTitle={"Utilities"}
                  adminType={adminType}
                />
              </div>
              <div className="col-2 text-center column p-0 pb-1 pr-1">
                <button className="btn-mycyan" onClick={this.handleLogoutClick}>
                  Logout
                </button>
              </div>
            </React.Fragment>
          )}
        </div>
        {/* a row ends */}
        {/* a row starts */}
        <div className="row  justify-content-center ">
          <div className="col-11">
            {message && (
              <div className="cart-message text-center text-danger bg-white column">
                {/* <span className="thick-text">{productName}</span> */}
                {/* <span>{message}</span> */}
                <ActionMessage
                  // productName={productName}
                  message={message}
                />
              </div>
            )}
          </div>
        </div>
        {/* a row ends */}
        {/* a row starts */}
        {menu && (
          <div className="row column bg-white justify-content-center p-1 ">
            <div className="col-12 column text-center p-0">
              <button
                className="btn-mycyan bigger-text thick-text"
                onClick={this.handleAddListToggleClick}
              >
                {menu}
              </button>
            </div>
          </div>
        )}
        {/* a row ends */}
      </div>
    );
  }
}
export default NavBarAdmin;
