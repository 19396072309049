import React, { useEffect, useState } from "react";
import axios from "axios";
import { BeatLoader } from "react-spinners";

function AdminSetting(props) {
  let emptySetting;
  let [setting, setSetting] = useState({
    tempPassword: "",
    admissionBatch: "",
  });
  let [errorSetting, setErrorSetting] = useState({
    tempPassword: { message: "", mxLen: 6, mnLen: 6, onlyDigits: true },
    admissionBatch: { message: "" },
  });
  let [flagLoading, setFlagLoading] = useState(false);
  let [error, setError] = useState("");
  let [flagFormInvalid, setFlagFormInvalid] = useState(false);
  function handleChange(event) {
    let name = event.target.name;
    let userInput;
    let inputType = event.target.type;
    if (inputType == "checkbox") {
      // for checkbox, no checking of validations.
      userInput = event.target.checked;
      let value;
      if (userInput) {
        value = 1;
      } else {
        value = 0;
      }
      setSetting({ ...setting, [name]: value });
      return;
    }
    setSetting({ ...setting, [name]: event.target.value });
    isValid(event);
  }
  function handleBlur(event) {
    isValid(event);
  }
  function isValid(event) {
    let i,
      flag,
      name,
      value,
      requiredMessage = " is required",
      type,
      message = "",
      mnLen,
      mxLen,
      onlyDigits,
      allDigits,
      noSymbols;
    name = event.target.name;
    type = event.target.type;
    if (type === "text" || type === "textarea" || type === "password") {
      // check if 'required', also check min no. of characters
      value = event.target.value.trim();
      mnLen = errorSetting[`${name}`].mnLen;
      mxLen = errorSetting[`${name}`].mxLen;
      onlyDigits = errorSetting[`${name}`].onlyDigits;
      allDigits = errorSetting[`${name}`].allDigits;
      noSymbols = errorSetting[`${name}`].noSymbols;
      if (value.length === 0) {
        message = name + requiredMessage;
      } else if (value.length < mnLen) {
        if (!onlyDigits) message = "Min " + mnLen + " characters required";
        else message = "Min " + mnLen + " digits required";
      } //else
      else if (value.length > mxLen) {
        if (!onlyDigits) message = "Maximum " + mxLen + " characters allowed";
        else message = "Maximum " + mxLen + " digits allowed";
      } //else
      else if (onlyDigits) {
        for (let i = value.length - 1; i >= 0; i--) {
          const d = value.charCodeAt(i);
          if (d < 48 || d > 57) {
            message = "Enter only digits";
            break;
          } //if
        } //for
      } //else if... onlyDigits
      else if (noSymbols && !allDigits) {
        let d,
          count = 0;
        for (i = value.length - 1, flag = false; i >= 0; i--) {
          d = value.charCodeAt(i);
          if (d >= 48 && d <= 57) {
            count++; // digits are being counted
          } //if
          if (
            (d >= 97 && d <= 122) ||
            (d >= 65 && d <= 90) ||
            (d >= 48 && d <= 57) ||
            value.charAt(i) == "_"
          ) {
            continue;
          } //if
          else {
            // symbol is there, may be space
            flag = true;
            break;
          }
        } //for
        if (flag == true) {
          let q = String.fromCharCode(d);
          if (d == 32) {
            message = "space is not allowed";
          } else
            message =
              "Symbols other than underscore (_) are not allowed, you have used " +
              q;
        } else {
          if (count == value.length) {
            message = "Only digits are not allowed, use some alphabets also";
          }
        }
      } //else if... noSymbols
      else {
        message = "";
      }
    } //if... text || textarea
    else if (event.target.type === "select-one") {
      value = event.target.selectedIndex;
      if (value == 0) {
        message = name + requiredMessage;
      } else {
        message = "";
      }
    } //else if
    else if (type === "email") {
      value = event.target.value.trim();
      if (/\S+@\S+\.\S+/.test(value) == 0) {
        message = "valid email-id " + requiredMessage;
      } else {
        message = "";
      }
    } //else if
    else {
      message = "";
    }
    let errSetting = { ...errorSetting };
    errorSetting[`${name}`].message = message;
    setErrorSetting(errSetting);
    checkAllErrors();
  }
  function handleFocus(event) {
    checkAllErrors();
  }
  function checkAllErrors() {
    for (let field in errorSetting) {
      if (errorSetting[field].message !== "") {
        setFlagFormInvalid(true);
        return;
      } //if
    } //for
    setFlagFormInvalid(false);
  }
  useEffect(() => {
    window.scroll(0, 0);
    emptySetting = setting;
    getSettingFromDatabase();
  }, []);
  function getSettingFromDatabase() {
    setFlagLoading(true);
    axios
      .get(window.routerPrefix + "/setting/settings")
      .then((res) => {
        let setting = res.data[0]; // Only one row of setting
        setFlagLoading(false);
        setSetting(setting);
      })
      .catch((err) => {
        setError(err);
        setFlagLoading(false);
      });
  }
  function handleSubmit(event) {
    event.preventDefault();
    setFlagLoading(true);
    //EDIT / PUT
    axios
      .put(window.routerPrefix + "/setting/update", setting)
      .then((res) => {
        setFlagLoading(false);
        let message = "Setting updated successfully.";
        props.onCrudAction(message, "", "");
      })
      .catch((err) => {
        setFlagLoading(false);
        setError(err);
      });
  }
  let { action } = props;
  let { adminType } = props;
  let { accessLevel } = props;
  if (flagLoading) {
    return (
      <div className="div text-center container-content-page">
        <BeatLoader size={16} color={"blue"} flagLoading />
      </div>
    );
  }
  return (
    <>
      <div className="container-fluid container-md container-content-page">
        <div className="form-heading column col-12">Setting</div>
        <form
          // className="container container-add-form text-dark bg-warning p-4"
          className="text-dark bg-mycyan p-4"
          onSubmit={handleSubmit}
        >
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Temp Password</label>
            </div>
            <div className="col-7 column px-0">
              <input
                type="text"
                className="form-control"
                name="tempPassword"
                value={setting.tempPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                placeholder="Temp Password"
              />
            </div>
            <div className="offset-5">
              {errorSetting.tempPassword.message ? (
                <span className="error-text">
                  {errorSetting.tempPassword.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Admission Batch</label>
            </div>
            <div className="col-7 px-0">
              <input
                type="text"
                className="form-control"
                name="admissionBatch"
                value={setting.admissionBatch}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                placeholder="Admission Batch"
              />
            </div>
            <div className="offset-5">
              {errorSetting.admissionBatch.message ? (
                <span className="error-text">
                  {errorSetting.admissionBatch.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}

          {/* row starts */}
          {adminType >= accessLevel && (
            <div className="form-group row offset-5 text-center mb-0">
              <button
                className="btn-mycyan"
                type="submit"
                disabled={flagFormInvalid}
              >
                {action}
              </button>
            </div>
          )}
        </form>
      </div>
    </>
  );
}
export default AdminSetting;
