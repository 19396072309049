import React from "react";
import { Link } from "react-router-dom";
import AdminSubjectListSingle from "./admin-subject-list-single";

function AdminSubjectList(props) {
  function handleEditSubject(subject) {
    props.onEditSubject(subject);
  }
  function handleShowChapters(subject) {
    props.onShowChapters(subject);
  }
  function handleCrudAction(message, action) {
    props.onCrudAction(message, action);
  }
  function handleDeleteSubject(subject) {
    props.onDeleteSubject(subject);
  }
  function handleSearchTextBoxKeyUp(event) {
    props.onSearchTextBoxKeyUp(event);
  }
  function handleSearchTextChange(event) {
    props.onSearchTextChange(event);
  }
  function handleHeaderClick(event) {
    props.onHeaderClick(event);
  }
  function handleSectionNumberClick(event) {
    props.onSectionNumberClick(event);
  }
  let { filteredSubjects } = props;
  let { searchText } = props;
  let { currentSize } = props;
  let { accessLevel } = props;
  let { adminType } = props;

  
  let contentNosubjects = <div className="text-center">No subjects !</div>;
  return (
    <>
      {filteredSubjects.length == 0 && (
        <div className="text-center">No subjects !</div>
      )}
      <div className="form-heading  col-12">
        LIST OF subjects ({filteredSubjects.length})
      </div>
      {/* row starts */}
      {filteredSubjects.length != 0 && (
        <>
          <div className="row justify-content-center myborder">
            <div className="col-6 my-2 ">
              <input
                type="search"
                className="container-fluid form-control"
                value={searchText}
                onKeyUp={handleSearchTextBoxKeyUp}
                onChange={handleSearchTextChange} // This is contolled by parent
                placeholder="Search subjects"
                id=""
              />
            </div>
          </div>
          {/* row ends */}
          <div className="row p-0 justify-content-center ">
            <div className="col-2 col-md-1"></div>
            <div className="col-8 col-md-4">
              <Link to="#" onClick={handleHeaderClick} id="name">
                Name
              </Link>
            </div>
            <div className="col-2">&nbsp;</div>
          </div>
          {filteredSubjects.map((subject, index) => (
            <AdminSubjectListSingle
              key={index}
              index={index}
              subject={subject}
              onDeleteSubject={handleDeleteSubject}
              onEditSubject={handleEditSubject}
              onCrudAction={handleCrudAction}
              currentSize={currentSize}
              accessLevel={accessLevel}
              adminType={adminType}
            />
          ))}
        </>
      )}
    </>
  );
}
export default AdminSubjectList;
