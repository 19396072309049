import React, { Component } from "react";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import axios from "axios";
class AdminAccessListSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      response: {},
      flagLoading: false,
    };
  }
  handleEditAccess = (access) => {
    this.props.onEditAccess(access);
  };

  handleDeleteAccess = (access) => {
    // Now call for delete api
    let { shopUrl } = this.props;
    this.setState({
      flagLoading: true,
      // action: "LIST", // Back to list view
    });
    axios
      .delete(window.routerPrefix + "/access/delete/" + access.accessId)
      .then((res) => {
        // let message = access.name + " deleted successfully";
        this.setState({
          response: res.data,
          flagLoading: false,
          // action: "LIST", // Back to list view
        });
        this.props.onDeleteAccess(access);
      })
      .catch((err) => {
        this.setState({
          flagLoading: false,
          // action: "LIST", // Back to list view
        });
        console.log(err);
      });
  };

  render() {
    let { error } = this.state;
    let { access } = this.props;
    let { flagLoading } = this.state;

    let { accessLevel } = this.props;
    let { adminType } = this.props;

    let content = (
      <>
        <tr className="myborder-top">
          <td>{access.subject}</td>
          <td>{access.admissionType}</td>
          <td>{access.chapterLimit}</td>
          <td>
            <div className="row justify-content-around">
              <div className="col-4">
                {adminType >= accessLevel && (
                  <Link to="#" onClick={() => this.handleEditAccess(access)}>
                    {/* Edit */}
                    <i className="fas fa-edit admin-icons"></i>
                  </Link>
                )}
              </div>
              {!flagLoading && (
                <div className="col-4">
                  <Link
                    to="#"
                    onClick={() => {
                      if (
                        window.confirm(
                          "You really want to delete Access -" +
                            access.name +
                            " ?"
                        )
                      )
                        this.handleDeleteAccess(access);
                    }}
                  >
                    <i className="far fa-trash-alt admin-icons"></i>
                  </Link>
                </div>
              )}
              {flagLoading && (
                <div className="col-4">
                  <BeatLoader size={8} color={"blue"} flagLoading />
                </div>
              )}
            </div>
          </td>
        </tr>
      </>
    );
    if (error) {
      return <div>Error retriving Accesss </div>;
    } else {
      return content;
    } //else
  }
}
export default AdminAccessListSingle;
