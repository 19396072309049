import React, { Component } from "react";
import "./css/login.css";
class AdminLoginFirstForm extends Component {
  errorAdmin = {
    password_old: { message: "", onlyDigits: false },
    password_new: { message: "", mxLen: 10, mnLen: 8, onlyDigits: false },
    password_new1: { message: "", onlyDigits: false },
  };
  constructor(props) {
    super(props);
    this.state = {
      admin: {
        password_old: "",
        password_new: "",
        password_new1: "",
      },
      errorAdmin: this.errorAdmin,
      flagFormInvalid: false,
      flagWhatsappChecked: false,
    };
  }
  handleChange = (event) => {
    let { errorAdmin } = this.state;
    let name = event.target.name;
    let message;
    let value = event.target.value;
    //check of max. length
    let mxLen = errorAdmin[`${name}`].mxLen;
    let onlyDigits = errorAdmin[`${name}`].onlyDigits;
    if (value.length > mxLen) {
      value = value.slice(0, mxLen);
      if (!onlyDigits) message = "Maximum " + mxLen + " characters allowed";
      else message = "Maximum " + mxLen + " digits allowed";
    } //if...
    else {
      message = "";
    }
    errorAdmin[`${name}`].message = message;
    this.setState({
      errorAdmin: errorAdmin,
      admin: { ...this.state.admin, [name]: value },
    });
    this.checkAllErrors(errorAdmin);
  };
  handleBlur = (event) => {
    let {admin}=this.state;
    let name,
      value,
      requiredMessage = " is required",
      type,
      message = "",
      mnLen,
      onlyDigits;
    let { errorAdmin } = this.state;
    name = event.target.name;
    type = event.target.type;
    
    if (type === "text" || type === "textarea" || type === "password") {
      // check if 'required', also check min no. of characters
      value = event.target.value.trim();
      mnLen = errorAdmin[`${name}`].mnLen;
      onlyDigits = errorAdmin[`${name}`].onlyDigits;
      if (value.length === 0) {
        // message = name + requiredMessage;  // commented 18.07.2020
        message = requiredMessage; // use this one instead 18.07.2020
      } else if (value.length < mnLen) {
        if (!onlyDigits) message = "Min " + mnLen + " characters required";
        else message = "Min " + mnLen + " digits required";
      } //else
      else if (onlyDigits) {
        for (let i = value.length - 1; i >= 0; i--) {
          const d = value.charCodeAt(i);
          if (d < 48 || d > 57) {
            message = "Enter only digits";
            break;
          } //if
        } //for
      } //else if... onlyDigits
      else if (name === "password_new1" && errorAdmin["password_new"].message === "") {
        // check if both passwords are same or not
        if(value!==admin["password_new"])
        {
          message="The 2 passwords do not match";
        }       
      }
      else {
        message = "";
      }
    } //if... text || textarea
    errorAdmin[`${name}`].message = message;
    this.setState({ errorAdmin: errorAdmin });
    this.checkAllErrors(errorAdmin);
  };
  handleFocus = (event) => {
    let name = event.target.name;
    let errorAdmin = this.state.errorAdmin;
    errorAdmin[`${name}`].message = "";
    this.setState({ errorAdmin: errorAdmin });
    this.checkAllErrors(errorAdmin);
  };
  checkAllErrors = (errorAdmin) => {
    // let { errorProduct } = this.state;
    let flagFormInvalid = false;
    for (let field in errorAdmin) {
      if (errorAdmin[field].message !== "") {
        // error is there
        flagFormInvalid = true;
        break;
      } //if
    } //for
    this.setState({ flagFormInvalid: flagFormInvalid });
  };
  handleUserLogIn = () => {
    this.props.onUserLogIn();
  };
  handleAdminLogIn = () => {
    this.props.onAdminLogIn();
    // change content in the menubars in the navbar
  };
  handleLoginFirstFormSubmit = (event) => {
    event.preventDefault();
    let data = this.state.admin; //this.state
    this.props.onLoginFirstFormSubmit(data);
  };
  render() {
    let { errorAdmin } = this.state;
    let { admin } = this.state;
    let { flagFormInvalid } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid container-login-form p-0">
          <div className="form-heading column col-12">Admin Login</div>
          <form
            // className="container container-add-form text-dark bg-warning p-4"
            className="text-dark bg-mycyan p-4"
            onSubmit={this.handleLoginFirstFormSubmit}
          >
            {/* row starts */}
            <div className="form-group row column align-items-center">
              <div className="col-5 text-right">
                <label>Old Password</label>
              </div>
              <div className="col-7 px-0">
                <input
                  type="password"
                  className="form-control"
                  name="password_old"
                  value={admin.password_old}
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  onFocus={this.handleFocus}
                  placeholder="Enter Password"
                  required
                />
              </div>
              <div className="offset-5">
                {errorAdmin.password_old.message ? (
                  <span className="error-text">
                    {errorAdmin.password_old.message}
                  </span>
                ) : null}
              </div>
            </div>
            {/* row ends */}
            {/* row starts */}
            <div className="form-group row column align-items-center">
              <div className="col-5 text-right">
                <label>New Password</label>
              </div>
              <div className="col-7 px-0">
                <input
                  type="password"
                  className="form-control"
                  name="password_new"
                  value={admin.password_new}
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  onFocus={this.handleFocus}
                  placeholder="Enter New Password"
                  required
                />
              </div>
              <div className="offset-5">
                {errorAdmin.password_new ? (
                  <span className="error-text">
                    {errorAdmin.password_new.message}
                  </span>
                ) : null}
              </div>
            </div>
            {/* row ends */}
            {/* row starts */}
            <div className="form-group row column align-items-center">
              <div className="col-5 text-right">
                <label>New Password</label>
              </div>
              <div className="col-7 px-0">
                <input
                  type="password"
                  className="form-control"
                  name="password_new1"
                  value={admin.password_new1}
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  onFocus={this.handleFocus}
                  placeholder="Enter New Password again"
                  required
                />
              </div>
              <div className="offset-5">
                {errorAdmin.password_new1.message ? (
                  <span className="error-text">
                    {errorAdmin.password_new1.message}
                  </span>
                ) : null}
              </div>
            </div>
            {/* row ends */}
            {/* row starts */}
            <div className="form-group row offset-5 text-center mb-0">
              <button
                className="btn-mycyan"
                type="submit"
                disabled={flagFormInvalid}
              >
                submit
              </button>
            </div>
            {/* <input type="hidden" name="productId" value={product.productId} /> */}
          </form>
        </div>
      </React.Fragment>
    );
  }
}
export default AdminLoginFirstForm;
