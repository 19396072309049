import React, { Component } from "react";
import NavBtnChapter from "./nav-btn-chapter";

import "../App.css";

class NavBarChapters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      loginTitle: "Login",
    };
  }
  handleChapterClick = (chapter) => {
    this.props.onChapterClick(chapter);
  };
  handleChapterHeaderClick = () => {
    this.props.onChapterHeaderClick();
  };
  componentDidMount = () => {
    // this.props.onChapterHeaderClick();
  };
  render() {
    let { chapterList } = this.props;
    let { flagLoadingChapters } = this.props;
    let { selectedChapterId } = this.props;
    let { selectedChapterName } = this.props;
    let { selectedChapterNumber } = this.props;

    let { message } = this.props;
    return (
      <React.Fragment>
        {!flagLoadingChapters && (
          <div className="dropdown">
            <div
              className="btn dropdown-toggle"
              // type="button"
              data-toggle="dropdown"
              aria-expanded="false"
              onClick={this.handleChapterHeaderClick}
            >
              {selectedChapterId == -1
                ? "Select one of the " +
                  (flagLoadingChapters ? " - " : chapterList.length) +
                  " chapters"
                : selectedChapterNumber + ". " + selectedChapterName}
            </div>

            <div
              className={
                " dropdown-menu p-0 " +
                (message ? "dropdown-menu-noborder " : "")
              }
            >
              {chapterList.map((chapter, index) => (
                <NavBtnChapter
                  chapter={chapter}
                  index={index}
                  className="dropdown-item"
                  key={index}
                  message={message}
                  onChapterClick={this.handleChapterClick}
                  flagLoadingChapters={flagLoadingChapters}
                />
              ))}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
export default NavBarChapters;
