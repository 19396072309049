export class ProgramDB {
  constructor(programs) {
    this.programs = programs;
  }
  allPrograms() {
    return this.programs;
  }
  searchByProgramId(id) {
    for (let i = 0; i < this.programs.length; i++) {
      if (this.programs[i].programId == id) {
        return this.programs[i];
      }
    }
    return null; // not found
  }
  filterByName(query) {
    let filteredPrograms = [];
    for (let i = 0; i < this.programs.length; i++) {
      if (this.programs[i].description.toLowerCase().includes(query.toLowerCase())) {
        filteredPrograms.push(this.programs[i]);
      }
    } //for
    return filteredPrograms;
  }
}
export default ProgramDB;
