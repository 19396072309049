import React, { Component } from "react";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import axios from "axios";
class AdminStudentListSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      response: {},
      flagLoading: false,
    };
  }
  handleEditStudent = (student) => {
    this.props.onEditStudent(student);
  };

  handleDeleteStudent = (student) => {
    // Now call for delete api
    this.setState({
      flagLoading: true,
      // action: "LIST", // Back to list view
    });
    axios
      .delete(window.routerPrefix + "/student/delete/" + student.studentId)
      .then((res) => {
        // let message = student.name + " deleted successfully";
        this.setState({
          response: res.data,
          flagLoading: false,
          // action: "LIST", // Back to list view
        });
        this.props.onDeleteStudent(student);
      })
      .catch((err) => {
        this.setState({
          flagLoading: false,
          // action: "LIST", // Back to list view
        });
        console.log(err);
      });
  };
  render() {
    let { error } = this.state;
    let { student } = this.props;
    let { index } = this.props;
    let { flagLoading } = this.state;
    let { accessLevel } = this.props;
    let { adminType } = this.props;
    let content = (
      <React.Fragment>
        <tr className="myborder-top">
          <td>{index + 1}</td>
          <td>{student.fullName}</td>
          <td>{student.emailId}</td>
          <td>
            <div className="row">
              <div className="col-4">
                {adminType >= accessLevel && (
                  <Link to="#" onClick={() => this.handleEditStudent(student)}>
                    {/* Edit */}
                    <i className="fas fa-edit admin-icons"></i>
                  </Link>
                )}
              </div>
              {!flagLoading && adminType >= accessLevel && (
                <div className="col-4">
                  <Link
                    to="#"
                    onClick={() => {
                      if (
                        window.confirm(
                          "You really want to delete the Student - " +
                            student.fullName +
                            " ?"
                        )
                      )
                        this.handleDeleteStudent(student);
                    }}
                  >
                    {/* Delete */}
                    <i className="far fa-trash-alt admin-icons"></i>
                  </Link>
                </div>
              )}

              {flagLoading && (
                <div className="col-4">
                  <BeatLoader size={8} color={"blue"} flagLoading />
                </div>
              )}
            </div>
          </td>
        </tr>
      </React.Fragment>
    );
    if (error) {
      return <div>Error retriving Students </div>;
    } else {
      return content;
    } //else
  }
}
export default AdminStudentListSingle;
