import React, { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import axios from "axios";

function StudentDemoRequest(props) {
  let emptyEnquiredStudent;
  let [enquiredStudent, setEnquiredStudent] = useState({
    firstName: "",
    middleName: "",
    surName: "",
    whatsappNumber: "",
    studying: "",
    studyingYear: "",
    studyingBranch: "",
    college: "",
  });
  let [errorEnquiredStudent, setErrorEnquiredStudent] = useState({
    firstName: { message: "" },
    middleName: { message: "" },
    surName: { message: "" },
    whatsappNumber: { message: "", mxLen: 10, mnLen: 10, onlyDigits: true },
    studying: { message: "" },
    studyingYear: { message: "" },
    studyingBranch: { message: "" },
    college: { message: "", mxLen: 50, mnLen: 4 },
  });
  let [flagLoading, setFlagLoading] = useState(false);
  let [flagSession, setFlagSession] = useState(false);
  let [error, setError] = useState("");
  let [message, setMessage] = useState("");
  let [flagFormInvalid, setFlagFormInvalid] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);
    init();
  }, []);
  function init() {
    emptyEnquiredStudent = enquiredStudent;
    setEnquiredStudent(emptyEnquiredStudent);
    setFlagSession(true);
    axios
      .get(window.routerPrefix + "/enquiredStudent/welcome")
      .then((res) => {
        setFlagSession(false);
      })
      .catch((err) => {
        setFlagSession(false);
      });
  }
  function handleChange(event) {
    props.onSetMessage("");
    setMessage("");
    let name = event.target.name;
    let userInput;
    let inputType = event.target.type;
    if (inputType == "checkbox") {
      // for checkbox, no checking of validations.
      userInput = event.target.checked;
      let value;
      if (userInput) {
        value = 1;
      } else {
        value = 0;
      }
      setEnquiredStudent({ ...enquiredStudent, [name]: value });
      return;
    }
    setEnquiredStudent({ ...enquiredStudent, [name]: event.target.value });
    isValid(event);
  }
  function handleBlur(event) {
    isValid(event);
  }
  function isValid(event) {
    let i,
      flag,
      name,
      value,
      requiredMessage = " is required",
      type,
      message = "",
      mnLen,
      mxLen,
      onlyDigits,
      allDigits,
      noSymbols;
    name = event.target.name;
    type = event.target.type;
    if (type === "text" || type === "textarea" || type === "password") {
      // check if 'required', also check min no. of characters
      value = event.target.value.trim();
      mnLen = errorEnquiredStudent[`${name}`].mnLen;
      mxLen = errorEnquiredStudent[`${name}`].mxLen;
      onlyDigits = errorEnquiredStudent[`${name}`].onlyDigits;
      allDigits = errorEnquiredStudent[`${name}`].allDigits;
      noSymbols = errorEnquiredStudent[`${name}`].noSymbols;
      console.log(name + " " + type);
      if (value.length === 0) {
        message = name + requiredMessage;
      } else if (value.length < mnLen) {
        if (!onlyDigits) message = "Min " + mnLen + " characters required";
        else message = "Min " + mnLen + " digits required";
      } //else
      else if (value.length > mxLen) {
        if (!onlyDigits) message = "Maximum " + mxLen + " characters allowed";
        else message = "Maximum " + mxLen + " digits allowed";
      } //else
      else if (onlyDigits) {
        for (let i = value.length - 1; i >= 0; i--) {
          const d = value.charCodeAt(i);
          if (d < 48 || d > 57) {
            message = "Enter only digits";
            break;
          } //if
        } //for
      } //else if... onlyDigits
      else if (noSymbols && !allDigits) {
        let d,
          count = 0;
        for (i = value.length - 1, flag = false; i >= 0; i--) {
          d = value.charCodeAt(i);
          if (d >= 48 && d <= 57) {
            count++; // digits are being counted
          } //if
          if (
            (d >= 97 && d <= 122) ||
            (d >= 65 && d <= 90) ||
            (d >= 48 && d <= 57) ||
            value.charAt(i) == "_"
          ) {
            continue;
          } //if
          else {
            // symbol is there, may be space
            flag = true;
            break;
          }
        } //for
        if (flag == true) {
          let q = String.fromCharCode(d);
          if (d == 32) {
            message = "space is not allowed";
          } else
            message =
              "Symbols other than underscore (_) are not allowed, you have used " +
              q;
        } else {
          if (count == value.length) {
            message = "Only digits are not allowed, use some alphabets also";
          }
        }
      } //else if... noSymbols
      else {
        message = "";
      }
    } //if... text || textarea
    // else if (event.target.type === "select-one") {
    //   value = event.target.selectedIndex;
    //   if (value == 0) {
    //     message = name + requiredMessage;
    //   } else {
    //     message = "";
    //   }
    // } //else if
    else if (type === "email") {
      value = event.target.value.trim();
      if (/\S+@\S+\.\S+/.test(value) == 0) {
        message = "valid email-id " + requiredMessage;
      } else {
        message = "";
      }
    } //else if
    else {
      message = "";
    }
    let errEnquiredStudent = { ...errorEnquiredStudent };
    errEnquiredStudent[`${name}`].message = message;
    setErrorEnquiredStudent(errEnquiredStudent);
    checkAllErrors();
  }
  function handleFocus(event) {
    checkAllErrors();
  }
  function checkAllErrors() {
    for (let field in errorEnquiredStudent) {
      if (errorEnquiredStudent[field].message !== "") {
        setFlagFormInvalid(true);
        return;
      } //if
    } //for
    setFlagFormInvalid(false);
  }
  function checkFormInvalidity() {
    let errEnquiredStudent = { ...errorEnquiredStudent };
    let flag = false;
    for (let field in enquiredStudent) {
      if (enquiredStudent[field] == "") {
        errEnquiredStudent[field].message = "Required...";
        setErrorEnquiredStudent(errEnquiredStudent);
        flag = true;
      } //if
    } //for
    if (flag == false) {
      // setFlagFormInvalid(false);
      return false;
    } else {
      // setFlagFormInvalid(true);
      return true;
    }
  }
  function handleSelectStudyingChange(event) {
    let studying = event.target.value;
    setEnquiredStudent({
      ...enquiredStudent,
      studying: studying,
    });
    let errEnquiredStudent = { ...errorEnquiredStudent };
    errEnquiredStudent["studying"].message = "";
    setErrorEnquiredStudent(errEnquiredStudent);
  }
  function handleSelectStudyingYearChange(event) {
    let studyingYear = event.target.value;
    setEnquiredStudent({
      ...enquiredStudent,
      studyingYear: studyingYear,
    });
    let errEnquiredStudent = { ...errorEnquiredStudent };
    errEnquiredStudent["studyingYear"].message = "";
    setErrorEnquiredStudent(errEnquiredStudent);
  }
  function handleSelectStudyingBranchChange(event) {
    let studyingBranch = event.target.value;
    setEnquiredStudent({
      ...enquiredStudent,
      studyingBranch: studyingBranch,
    });
    let errEnquiredStudent = { ...errorEnquiredStudent };
    errEnquiredStudent["studyingBranch"].message = "";
    setErrorEnquiredStudent(errEnquiredStudent);
  }
  function handleIsReadySelection(value) {
    setEnquiredStudent({ ...enquiredStudent, isReady: value });
  }
  function onFormSubmit() {
    if (checkFormInvalidity()) {
      return;
    }
    setFlagLoading(true);
    axios
      .post(
        window.routerPrefix + "/enquiredStudent/addEnquiredStudent",
        enquiredStudent,
        {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
          },
        }
      )
      .then((res) => {
        setFlagLoading(false);
        let response = res.data;
        if (response == -1) {
          setMessage("Something went wrong...Try again");
          props.onSetMessage("Something went wrong...Try again");
          return;
        }
        if (response == 0) {
          setMessage("This WhatsApp Number is already registered.");
          props.onSetMessage("This WhatsApp Number is already registered.");
          return;
        }
        // open whatsApp to submit the request
        let fullName =
          enquiredStudent.firstName +
          " " +
          enquiredStudent.middleName +
          " " +
          enquiredStudent.surName;
        let details = `\nName: ${enquiredStudent.firstName} ${enquiredStudent.middleName} ${enquiredStudent.surName}
        Studying: ${enquiredStudent.studying}
        Year: ${enquiredStudent.studyingYear}
        Branch: ${enquiredStudent.studyingBranch}
        College: ${enquiredStudent.college}
        `;
        details = encodeURIComponent(details);
        let whatsAppMessage = `Hello Sir, I want to attend Demo class. Following are my details.
        ${details}.`;
        let whatsappNumberSir = "9422035020";

        window.location = `https://wa.me/${whatsappNumberSir}?text=${whatsAppMessage}`; //2023
        // onFormSubmit.studentId = res.data; // id of the added student is returned as response
      })
      .catch((err) => {
        setError(err);
        setFlagLoading(false);
      });
  }
  function handleSubmit(event) {
    event.preventDefault();
    window.scrollTo(0, 0); // moving scrollbar at the top
    onFormSubmit();
  }
  // let { subjectList } = props;
  // let { selectedSubject } = props;
  // let { action } = props;
  let studyingList = ["Engineering", "Diploma", "BCS", "BCA", "MCA", "Other"];
  let optionsStudiyng = studyingList.map((studying, index) => (
    <option value={studying} key={index} id={index}>
      {studying}
    </option>
  ));
  let studyingYearList = ["First", "Second", "Third", "Forth", "Other"];
  let optionsStudyingYear = studyingYearList.map((studyingYear, index) => (
    <option value={studyingYear} key={index} id={index}>
      {studyingYear}
    </option>
  ));
  let studyingBranchList = ["EnTC", "Comp", "IT", "Other", "NA"];
  let optionsStudyingBranch = studyingBranchList.map(
    (studyingBranch, index) => (
      <option value={studyingBranch} key={index} id={index}>
        {studyingBranch}
      </option>
    )
  );
  return (
    <>
      {flagLoading && (
        <div className="form-group row offset-5 text-center mb-0">
          <BeatLoader size={16} color={"blue"} flagLoading />
        </div>
      )}
      {flagLoading && <div className="text-loading">Wait... Your WhatsApp is being opened up..</div>}

      {!flagLoading && (
        <div className="container">
          <div className="row div-reference-page text-center">
            <div className="col-12 text-thick">
              Fillup the form to attend the demo class.
            </div>
          </div>

          <form className="text-dark bg-mycyan p-4" onSubmit={handleSubmit}>
            {/* row starts */}
            <div className="form-group row  align-items-center">
              <div className="col-5 text-right">
                <label>First Name</label>
              </div>
              <div className="col-7  px-0">
                <input
                  type="text"
                  className="form-control"
                  name="firstName"
                  value={enquiredStudent.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  placeholder="First Name"
                />
              </div>
              <div className="offset-5">
                {errorEnquiredStudent.firstName.message ? (
                  <span className="error-text">
                    {errorEnquiredStudent.firstName.message}
                  </span>
                ) : null}
              </div>
            </div>
            {/* row ends */}
            {/* row starts */}
            <div className="form-group row  align-items-center">
              <div className="col-5 text-right">
                <label>Middle Name</label>
              </div>
              <div className="col-7  px-0">
                <input
                  type="text"
                  className="form-control"
                  name="middleName"
                  value={enquiredStudent.middleName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  placeholder="Middle Name"
                />
              </div>
              <div className="offset-5">
                {errorEnquiredStudent.middleName.message ? (
                  <span className="error-text">
                    {errorEnquiredStudent.middleName.message}
                  </span>
                ) : null}
              </div>
            </div>
            {/* row ends */}
            {/* row starts */}
            <div className="form-group row  align-items-center">
              <div className="col-5 text-right">
                <label>Surname</label>
              </div>
              <div className="col-7  px-0">
                <input
                  type="text"
                  className="form-control"
                  name="surName"
                  value={enquiredStudent.surName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  placeholder="Surname"
                />
              </div>
              <div className="offset-5">
                {errorEnquiredStudent.surName.message ? (
                  <span className="error-text">
                    {errorEnquiredStudent.surName.message}
                  </span>
                ) : null}
              </div>
            </div>
            {/* row ends */}
            {/* row starts */}
            <div className="form-group row  align-items-center">
              <div className="col-5 text-right">
                <label>WhatsApp Number</label>
              </div>
              <div className="col-7  px-0">
                <input
                  type="text"
                  className="form-control"
                  name="whatsappNumber"
                  value={enquiredStudent.whatsappNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  placeholder="Whatsapp Number"
                />
              </div>
              <div className="offset-5">
                {errorEnquiredStudent.whatsappNumber.message ? (
                  <span className="error-text">
                    {errorEnquiredStudent.whatsappNumber.message}
                  </span>
                ) : null}
              </div>
            </div>
            {/* row ends */}
            {/* row starts */}
            <div className="form-group row  align-items-center">
              <div className="col-5 text-right">
                <label>Select Stream</label>
              </div>
              <div className="col-7 px-0">
                <select
                  className="form-control"
                  name="studying"
                  value={enquiredStudent.studying}
                  onChange={handleSelectStudyingChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                >
                  <option>Studying</option>
                  {optionsStudiyng}
                </select>
              </div>
              <div className="offset-5">
                {errorEnquiredStudent.studying.message ? (
                  <span className="error-text">
                    {errorEnquiredStudent.studying.message}
                  </span>
                ) : null}
              </div>
            </div>
            {/* row ends */}
            {/* row starts */}
            <div className="form-group row align-items-center">
              <div className="col-5 text-right">
                <label>Studying Year</label>
              </div>
              <div className="col-7 px-0">
                <select
                  className="form-control"
                  name="studyingYear"
                  value={enquiredStudent.studyingYear}
                  onChange={handleSelectStudyingYearChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                >
                  <option> Select Year </option>
                  {optionsStudyingYear}
                </select>
              </div>
              <div className="offset-5">
                {errorEnquiredStudent.studyingYear.message ? (
                  <span className="error-text">
                    {errorEnquiredStudent.studyingYear.message}
                  </span>
                ) : null}
              </div>
            </div>
            {/* row ends */}
            {/* row starts */}
            {/* row starts */}
            <div className="form-group row  align-items-center">
              <div className="col-5 text-right">
                <label>Branch</label>
              </div>
              <div className="col-7 px-0">
                <select
                  className="form-control"
                  name="studyingBranch"
                  value={enquiredStudent.studyingBranch}
                  onChange={handleSelectStudyingBranchChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                >
                  <option> Select Branch </option>
                  {optionsStudyingBranch}
                </select>
              </div>
              <div className="offset-5">
                {errorEnquiredStudent.studyingBranch.message ? (
                  <span className="error-text">
                    {errorEnquiredStudent.studyingBranch.message}
                  </span>
                ) : null}
              </div>
            </div>
            {/* row ends */}
            {/* row starts */}
            <div className="form-group row  align-items-center">
              <div className="col-5 text-right">
                <label>College</label>
              </div>
              <div className="col-7  px-0">
                <input
                  type="text"
                  className="form-control"
                  name="college"
                  value={enquiredStudent.college}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  placeholder="College Name"
                />
              </div>
              <div className="offset-5">
                {errorEnquiredStudent.college.message ? (
                  <span className="error-text">
                    {errorEnquiredStudent.college.message}
                  </span>
                ) : null}
              </div>
            </div>
            {/* row ends */}
            {!flagLoading && (
              <div className="form-group row offset-5 text-center mb-0">
                <button
                  className="btn-mycyan"
                  type="submit"
                  disabled={flagFormInvalid}
                >
                  Submit
                </button>
              </div>
            )}
            {flagSession && (
              <div className="form-group row offset-5 text-center mb-0">
                <BeatLoader size={16} color={"blue"} flagSession />
              </div>
            )}
            <input
              type="hidden"
              name="enquiredStudentId"
              value={enquiredStudent.enquiredStudentId}
            />
            {message && <div className="text-error text-center">{message}</div>}
          </form>
          <div>
            After submitting the form, WhatsApp will be opened-up. In WhatsApp,
            press enter button.{" "}
          </div>
        </div>
      )}
    </>
  );
}
export default StudentDemoRequest;
