export class ChapterDB {
  constructor(chapters) {
    this.chapters = chapters;
  }
  allChapters() {
    return this.chapters;
  }
  searchByChapterId(id) {
    for (let i = 0; i < this.chapters.length; i++) {
      if (this.chapters[i].chapterId == id) {
        return this.chapters[i];
      }
    }
    return null; // not found
  }
  filterByName(query) {
    let filteredChapters = [];
    for (let i = 0; i < this.chapters.length; i++) {
      if (this.chapters[i].name.toLowerCase().includes(query.toLowerCase())) {
        filteredChapters.push(this.chapters[i]);
      }
    } //for
    return filteredChapters;
  }
}
export default ChapterDB;
