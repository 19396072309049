import React, { useEffect, useState } from "react";
import axios from "axios";
import "./css/login.css";
import { BeatLoader } from "react-spinners";
import { WhatsappShareButton, WhatsappIcon } from "react-share";
import { Link } from "react-router-dom";
function ProfilePage(props) {
  let [message, setMessage] = useState("");
  let [error, setError] = useState("");
  let [referredByStudentName, setReferredByStudentName] = useState("");
  let [flagLoadingProfileDetails, setFlagLoadingProfileDetails] =
    useState(false);
  let [flagYourReferralsClicked, setFlagYourReferralsClicked] = useState(false);
  let [flagLoadingreferralList, setFlagLoadingreferralList] = useState(false);
  let [courseList, setCourseList] = useState([]);
  let [admissionList, setAdmissionList] = useState([]);
  let [referralList, setReferralList] = useState([]);

  /* constructor(props) {
    super(props);
    .state = {
      admissionList: [],
      courseList: [],
      referralList: [],
      flagLoadingProfileDetails: false,
      flagYourReferralsClicked: false,
    };
  }*/
  function hideMessage() {
    window.setTimeout(() => {
      setMessage("");
    }, 5000);
  }
  useEffect(() => {
    window.scroll(0, 0);
    componentDidMount();
  }, []);
  function componentDidMount() {
    let { student } = props;
    setFlagLoadingProfileDetails(true);
    // get admission details of the student
    axios
      .all([
        axios.get(
          window.routerPrefix +
            "/admission/admissionsByStudent/" +
            student.studentId
        ),
        axios.get(window.routerPrefix + "/course/courses/"),
        // axios.get(
        //   window.routerPrefix +
        //     "/referral/referralByReferredStudentEmailId/" +
        //     student.emailId
        // ),
      ])
      .then(
        axios.spread((res1, res2) => {
          let admissionList = res1.data;
          let courseList = res2.data;
          // let referredByStudentName = res3.data;
          let referredByStudentName = student.referredBy;
          let updatedAdmissionList = [...admissionList];
          if (admissionList.length != 0) {
            for (let i = 0; i < admissionList.length; i++) {
              for (let j = 0; j < courseList.length; j++) {
                if (admissionList[i].courseId == courseList[j].courseId) {
                  updatedAdmissionList[i].courseName = courseList[j].name;
                  break;
                } //if
              } //inner for
              // Also update admission Date
              let dt = new Date(updatedAdmissionList[i].dateOfAdmission);
              let dd = pad(dt.getDate(), 2);
              let mm = pad(dt.getMonth() + 1, 2);
              let yy = dt.getFullYear();
              updatedAdmissionList[i].dateOfAdmission =
                dd + "/" + mm + "/" + yy;
            } //outer for
          } //if
          // Add course Information to each batch (from courseId)
          setCourseList(courseList);
          setAdmissionList(updatedAdmissionList);
          setReferredByStudentName(referredByStudentName);
          setFlagLoadingProfileDetails(false);
          // setState({
          // courseList: courseList,
          // admissionList: updatedAdmissionList,
          // referredByStudentName: referredByStudentName,
          // flagLoadingProfileDetails: false,
          // });
        })
      )
      .catch((err) => {
        console.log(err);
        setFlagLoadingProfileDetails(false);
        setError(err);
      });
    hideMessage();
  }
  function pad(num, size) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }
  //""
  function handleYourReferralClick() {
    // from database, from referral tables get all entries for which referredByStudentId is present.
    setFlagYourReferralsClicked(true);
    setFlagLoadingreferralList(true);
    let { student } = props;
    axios
      .get(
        window.routerPrefix +
          "/referral/referralsByReferredByStudentId/" +
          student.studentId
      )
      .then((res) => {
        let referralList = res.data;
        setFlagLoadingreferralList(false);
        setReferralList(referralList);
      })
      .catch((err) => {
        setMessage("Something went wrong");
        setError(err);
        setFlagLoadingreferralList(false);
        hideMessage();
      });
  }
  function handleCopyToClipboard() {
    let link = "https://RajeshJainAcademy.com/reference/" + student.studentId;
    navigator.clipboard.writeText(link);
  }
  let { student } = props;
  let { sessionInfo } = props;
  let offerMessage = `Refer "Coding and OOP Bootcamp"  
    to your 2 friends and attend "Web Technology Bootcamp" worth Rs. 699/-
    at 199/- only.
    Share the referral-link with your friends.    
    `;
  if (flagLoadingProfileDetails) {
    return (
      <div className="text-center mt-5">
        <BeatLoader size={16} color={"blue"} flagLoadingProfileDetails />
      </div>
    );
  }
  return (
    <>
      <div className="container-profile bg-lightcolor">
        <div className="container-profile-personal text-center ">
          <div className="text-center text-bigger text-thick">
            Personal Details:
          </div>

          <div className="row no-gutters">
            <div className="col-5 col-md-6 text-right text-darkcolor ">
              Name:
            </div>
            <div className="text-left col-7 col-md-6">
              &nbsp;{student.fullName}
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-5 col-md-6 text-right text-darkcolor">
              Email-id:
            </div>
            <div className="text-left col-7 col-md-6">
              &nbsp;{student.emailId}
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-5 col-md-6 text-right text-darkcolor">
              WhatsApp No.:
            </div>
            <div className="text-left col-7 col-md-6">
              &nbsp;{student.whatsappNumber}
            </div>
          </div>
        </div>
        <div className=" text-center ">
          <img
            src={
              window.routerPrefix +
              "/files/downloadStudentPhoto/" +
              student.photoName
            }
            alt="No Photo"
            className="img-fluid student-photo"
            // onClick={this.handleLogoClick}
          />
        </div>
        <div className="container-profile-admission text-center ">
          <div className="text-center text-bigger text-thick">
            Admission Details:
          </div>
          <div className="row no-gutters">
            <div className="col-5 col-md-6 text-right text-darkcolor">
              Referred By:
            </div>
            <div className="text-left col-7 col-md-6 ">
              &nbsp;
              {referredByStudentName ? referredByStudentName : "None"}
            </div>
          </div>
          <div className="container container-profile-admission text-center ">
            {admissionList.length != 0 && (
              <div>
                {admissionList.map((admission, index) => (
                  <div className="row no-gutters" key={index}>
                    <div className="col-5 col-md-6 text-right text-darkcolor">
                      Course:
                    </div>
                    <div className="text-left col-7 col-md-6 ">
                      &nbsp;{admission.courseName}
                    </div>
                    <div className="col-5 col-md-6 text-right text-darkcolor">
                      Joining Date:
                    </div>
                    <div className="text-left col-7 col-md-6">
                      &nbsp;{admission.dateOfAdmission}
                    </div>
                    <div className="col-5 col-md-6 text-right text-darkcolor">
                      Course Fees:
                    </div>
                    <div className="text-left col-7 col-md-6">
                      &nbsp;Rs. {admission.courseFees}/-
                    </div>
                    {admission.discount != 0 && (
                      <div className="col-5 col-md-6 text-right text-darkcolor">
                        Discount:
                      </div>
                    )}
                    {admission.discount != 0 && (
                      <div className="text-left col-7 col-md-6">
                        &nbsp;Rs. {admission.discount}/-
                      </div>
                    )}
                    <div className="col-5 col-md-6 text-right text-darkcolor">
                      Fees Paid:
                    </div>
                    <div className="text-left col-7 col-md-6 ">
                      &nbsp;Rs. {admission.feesPaid}/-
                    </div>
                    <div className="col-5 col-md-6 text-right text-darkcolor">
                      Fees remaining:
                    </div>
                    <div className="text-left col-7 col-md-6">
                      &nbsp;Rs.{" "}
                      {admission.courseFees -
                        admission.feesPaid -
                        admission.discount}
                      /-
                    </div>
                    {index < admissionList.length - 1 && (
                      <div className="col-12 text-center">---------------</div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
          {admissionList.length == 0 && (
            <div>
              <div className="row ">
                <div className="col-12 text-center">No course joined yet.</div>
              </div>
            </div>
          )}
        </div>
        <div className=" text-center container-profile-offers ">
          <div className="text-center text-bigger text-thick">Offers:</div>
          <div className="text-center " style={{ whiteSpace: "pre-wrap" }}>
            {student.eligibleForPromoCode == "yes" ? offerMessage : "No Offers"}
            {/* {student.eligibleForPromoCode == "yes" && (
              <a
                href={
                  "https://RajeshJainAcademy.com/reference/" + student.studentId
                }
              >
                {"https://RajeshJainAcademy.com/reference/" + student.studentId}
              </a>
            )} */}
            {student.eligibleForPromoCode == "yes" && (
              <div>
                Share the link using WhatsApp: &nbsp;
                <WhatsappShareButton
                  title={
                    "Use this link to get discount of Rs. 200/- in 'Coding and OOP Bootcamp' at Rajesh Jain Academy."
                  }
                  url={
                    // "https://rajeshjainacademy.com/reference/inauguralOffer_4ed0659d-615e-4b14-bd40-b61fac8f3351"
                    "https://rajeshjainacademy.com/reference/" +
                    student.studentId
                    // "http://localhost:3000/reference/inauguralOffer_"+student.studentId//4ed0659d-615e-4b14-bd40-b61fac8f3351
                  }
                >
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
              </div>
            )}
            {student.eligibleForPromoCode == "yes" && (
              <div>
                OR{" "}
                <Link to="#" onClick={handleCopyToClipboard}>
                  Copy{" "}
                </Link>{" "}
                the link.
              </div>
            )}
          </div>
        </div>
        {student.eligibleForPromoCode == "yes" && (
          <div className="container container-profile-referrals">
            <div className="text-center" onClick={handleYourReferralClick}>
              <button className="btn-darkcolor">{"Your referrals>"} </button>
            </div>
            <div>
              {flagLoadingreferralList && (
                <div className="text-center mt-5">
                  <BeatLoader
                    size={16}
                    color={"blue"}
                    flagLoadingProfileDetails
                  />
                </div>
              )}
            </div>
            {flagYourReferralsClicked &&
              !flagLoadingreferralList &&
              referralList.length != 0 && (
                <div className="container-referral-details ">
                  {referralList.map((referral, index) => (
                    <div className="text-left" key={index}>
                      {index + 1}. {referral.referredEmailId}:{" "}
                      {referral.referralStatus}
                    </div>
                  ))}
                </div>
              )}
            {flagYourReferralsClicked &&
              !flagLoadingreferralList &&
              referralList.length == 0 && (
                <div>
                  <div className="text-center"> No referrals by you yet...</div>
                  <div className="text-center">
                    {" "}
                    (Here, You get information, whether your referred friend has
                    submitted his/her email-id and whether he/she has joined any
                    of our courses)
                  </div>
                </div>
              )}
          </div>
        )}
      </div>
    </>
  );
}
export default ProfilePage;
