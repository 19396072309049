import React, { Component } from "react";
import OfflineNavBar from "./offline-navbar";
import TopBar from "./top-bar";
import OfflineHomePage from "./offline-home-page";
import OfflineContentPage from "./offline-content-page";
import CoursesPage from "./courses-page";
import AboutUsPage from "./about-us-page";
import ContactUsPage from "./contact-us-page";
import { BeatLoader } from "react-spinners";
import axios from "axios";

class OfflineHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "homePage",
      message: "",
      subjectList: [],
      student: null,
      student1: null,
      student2: null,
      selectedSubjectId: -1,
      selectedSubjectFilePrefix: "",
      selectedSubjectName: "",
      filePrefixWOExtension: "",
      selectedSubjectAvailableFor: "",
      selectedSubjectIncludesAssignments: 1,
      // info whether admitted for subject or Demo or not at all
      // added on 11.01.2023
      visitorInfo: {
        status: "Registered",
        admissionStatus: "Admitted",
        chapterLimit: 10,
      },
      flagLoggedIn: false,
      flagStartLearning: false,
      flagLoadingChapters: false,
      flagLoggingOut: false,
      maxStudentLimit: 1,
      type: "",
      loggedinStudents: 0,
      loggedIn: "",
      emailId1: "",
      emailId2: "",
      studentNumber: 0,
      videoDuration: 0,
      windowWidth: "", // Trying to make the website responsive 14012023
      goAheadClicked: false,
      currentPage: "homePage",
      previousPage: "homePage",
      sessionInfo: null,
      flagCheckSession: false,
      selectedOption: "",
    };
  }
  handleCoursesClick = () => {
    this.setState({
      currentPage: "coursesPage",
      flagStartLearning: false,
    });
    this.getSubjectListFromDatabase();
  };
  handleStartLearningClick = (learningSubject) => {
    this.setState({
      flagStartLearning: true,
      learningSubject: learningSubject,
    });

    this.getSubjectListFromDatabase();
  };
  handleCancelClick = () => {
    this.setState({
      message: "",
    });
  };
  hideMessage = () => {
    window.setTimeout(() => {
      this.setState({
        message: "",
      });
    }, 5000);
  };
  handleUserNameClick = () => {
    if (this.state.student1.emailId == "temp@gmail.com") {
      return;
    }
    this.setState({
      currentPage: "profilePage",
      flagLoadingProfileDetails: true,
    });
  };

  getSubjectListFromDatabase = () => {
    this.setState({
      flagLoadingSubjects: true,
    });
    axios
      .get(window.routerPrefix + "/subject/subjects")
      .then((res) => {
        let subjectList = res.data;
        if (!subjectList) {
          this.setState({
            subjectList: [],
            message: "Something went wrong, Reload",
            flagLoadingSubjects: false,
          });
          this.hideMessage();
        } else {
          // Add FlagActive to each subject
          for (let i = 0; i < subjectList.length; i++) {
            subjectList[i].flagActive = false;
          } //for
          this.setState({
            subjectList: subjectList,
            flagLoadingSubjects: false,
          });
          // Is the guest clicked to start learning first chapter
          if (this.state.flagStartLearning) {
            for (let i = 0; i < subjectList.length; i++) {
              if (subjectList[i].name.startsWith(this.state.learningSubject)) {
                this.handleSubjectClick(subjectList[i]);
                break;
              }
            }
          }
        }
      })
      .catch((err) => {
        this.setState({
          error: err,
          message: "Something went wrong",
          flagLoadingSubjects: false,
        }); // error is set
        this.hideMessage();
      });
  };
  handleLogoClick = () => {
    this.setState({
      currentPage: "homePage",
      message: "",
      selectedSubjectId: "",
    });
  };
  getCookie = (key) => {
    var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
  };
  updateDimensions = () => {
    let windowWidth = "";
    if (window.innerWidth > 768) {
      windowWidth = "desktop";
    } else {
      windowWidth = "mobile";
    }
    this.setState({
      windowWidth: windowWidth,
    });
  };

  componentDidMount = () => {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
    // User might have logged-in, check local storage first
    this.setState({
      flagCheckSession: true,
    });
    // Added on 09.04.2023. For using the website in the class
    if (window.hosting == "offline") {
      //go for security check
      let code = this.getCookie("ccc");
      if (code) {
        // some code is there in cookie, check it
        axios
          .get(window.routerPrefix + "/student/hello")
          .then((res) => {
            if (res.data == 1) {
              // yes authorized
              this.setState({
                flagCheckSession: false,
              });
            } else {
              this.setState({
                flagCheckSession: false,
                visitorInfo: {
                  ...this.state.visitorInfo,
                  status: "UnauthorizedPerson",
                  message: "Something REALLY went wrong",
                },
              });
              return;
            }
          })
          .catch((err) => {
            this.setState({
              error: err,
              message: "Something went wrong",
              flagCheckSession: false,
            }); // error is set
          });
      } //if //..........
      else {
        // no code, that means this is unauthorized client in the classroom
        this.setState({
          message: "Something went wrong",
          flagCheckSession: false,
          visitorInfo: {
            ...this.state.visitorInfo,
            status: "UnauthorizedPerson",
          },
        }); // error is set
        this.hideMessage();
        return;
      }
    } else if (window.hosting == "internet") {
      this.setState({
        message: "Something went wrong",
        flagCheckSession: false,
        visitorInfo: {
          ...this.state.visitorInfo,
          status: "UnauthorizedPerson",
        },
      }); // error is set
      this.hideMessage();
      return;
    }
  };

  handleResetFlagStartLearning = () => {
    this.setState({
      flagStartLearning: false,
    });
  };
  handleSubjectClick = (subject) => {
    let subjectId = subject.subjectId;
    let filePrefix = subject.filePrefix;
    this.setState({
      selectedSubjectId: subject.subjectId,
    });
    // Active button color should be changed 28.10.2022
    let subjectList = [...this.state.subjectList];
    for (let i = 0; i < subjectList.length; i++) {
      if (subject.flagActive == false) {
        subjectList[i].flagActive = false;
        if (subjectList[i].subjectId === subject.subjectId) {
          subjectList[i].flagActive = true;
        }
      } //if
      else {
        subjectList[i].flagActive = false;
      }
    } //for
    this.setState({
      subjectList: subjectList,
    });
    this.setState({
      selectedSubjectName: subject.name,
      selectedSubjectFilePrefix: filePrefix,
      selectedSubjectAvailableFor: subject.availableFor,
      selectedSubjectIncludesAssignments: subject.includesAssignments,
      selectedSubjectFees: subject.fees,
      message: "",
    });
    this.setState({
      currentPage: "contentPage",
    });
  };

  // This gives info about access alloted to a student
  getAccessInfoFromDatabase = (studentId) => {
    this.setState({
      flagLoadingAccess: true,
    });
    axios
      .get(window.routerPrefix + "/student/accessInfo/" + studentId)
      .then((res) => {
        this.setState({
          sessionInfo: res.data,
        });
        this.setState({
          flagLoadingAccess: false,
        });
      })
      .catch((err) => {
        this.setState({
          error: err,
          message: "Something went wrong",
          flagLoadingAccess: false,
        }); // error is set
      });
    this.hideMessage();
  };
  handleOtherCoursesClick = () => {
    this.setState({
      currentPage: "coursesPage",
    });
  };
  handleChange = (value) => {
    console.log(`Option selected:`, value);
  };
  render() {
    let { status } = this.state;
    let { loggedIn } = this.state;
    let { message } = this.state;
    let { student1 } = this.state;
    let { student2 } = this.state;
    let { subjectList } = this.state;
    let { programList } = this.state;
    let { studentNumber } = this.state;
    let { loggedinStudents } = this.state;
    let { flagLoadingChapterContent } = this.state;
    let { flagStartLearning } = this.state;

    let { selectedSubjectFilePrefix } = this.state;
    let { filePrefixWOExtension } = this.state;
    let { selectedSubjectName } = this.state;
    let { selectedSubjectAvailableFor } = this.state;
    let { selectedSubjectIncludesAssignments } = this.state;
    let { selectedSubjectFees } = this.state;

    let { selectedSubjectId } = this.state;
    let { flagLoadingLogin } = this.state;
    let { currentPage } = this.state;
    let { flagLoadingSubjects } = this.state;
    let { windowWidth } = this.state;
    let { videoDuration } = this.state;
    let { visitorInfo } = this.state;
    let { sessionInfo } = this.state;
    let { flagLoadingStudentData } = this.state;
    let { flagCheckSession } = this.state;

    let { selectedOption } = this.state;
    let { flagLoggingOut } = this.state;

    let activeStudent;
    if (studentNumber == 1) {
      activeStudent = student1;
    } else if (studentNumber == 2) {
      activeStudent = student2;
    }
    if (flagCheckSession) {
      return (
        <div className="text-center mt-5">
          <BeatLoader size={16} color={"blue"} flagLoading />
        </div>
      );
    }
    if (this.state.visitorInfo.status == "UnauthorizedPerson") {
      return (
        <div className="text-center mt-5">
          <BeatLoader size={16} color={"black"} flagLoading />
        </div>
      );
    }
    /*-----------------------NavBar--------------------*/
    return (
      <>
        {currentPage == "homePage" && (
          <TopBar
            // onExploreClick={this.handleExploreClick}
            onLogoClick={this.handleLogoClick}
            status={status}
            student={student1}
          />
        )}
        <OfflineNavBar
          onLoginClick={this.handleLoginClick}
          onCoursesClick={this.handleCoursesClick}
          onLogoClick={this.handleLogoClick}
          onUserNameClick={this.handleUserNameClick}
          onContactUsClick={this.handleContactUsClick}
          flagLoadingLogin={flagLoadingLogin}
          flagLoggingOut={flagLoggingOut}
          status={status}
          message={message}
          student1={student1}
          loggedinStudents={loggedinStudents}
          loggedIn={loggedIn}
          windowWidth={windowWidth}
          flagLoadingStudentData={flagLoadingStudentData}
          currentPage={currentPage}
        />
        {/* ~~~~~~~~~~~~~~ Home-page (On site loading)  ~~~~~~~~~~~~~~*/}

        {currentPage == "homePage" && (
          <OfflineHomePage
            onStartLearningClick={this.handleStartLearningClick}
            status={status}
            student={student1}
            flagLoggingOut={flagLoggingOut}
          />
        )}
        {/* ~~~~~~~~~~~~~~ Courses-page (On explore button click)  ~~~~~~~~~~~~~~*/}
        {currentPage == "coursesPage" && (
          <CoursesPage
            subjectList={subjectList}
            flagLoadingSubjects={flagLoadingSubjects}
            // onExploreClick={this.handleExploreClick}
            onSubjectClick={this.handleSubjectClick}
          />
        )}
        {currentPage == "aboutUsPage" && <AboutUsPage />}
        {currentPage == "contactUsPage" && <ContactUsPage />}

        {currentPage == "contentPage" && (
          <OfflineContentPage
            subjectList={subjectList}
            onSubjectClick={this.handleSubjectClick}
            onOtherCoursesClick={this.handleOtherCoursesClick}
            resetFlagStartLearning={this.handleResetFlagStartLearning}
            windowWidth={windowWidth}
            selectedSubjectId={selectedSubjectId}
            flagLoadingChapterContent={flagLoadingChapterContent}
            message={message}
            selectedSubjectFilePrefix={selectedSubjectFilePrefix}
            selectedSubjectFees={selectedSubjectFees}
            selectedSubjectName={selectedSubjectName}
            selectedSubjectAvailableFor={selectedSubjectAvailableFor}
            selectedSubjectIncludesAssignments={
              selectedSubjectIncludesAssignments
            }
            filePrefixWOExtension={filePrefixWOExtension}
            programList={programList}
            student={student1}
            visitorInfo={visitorInfo}
            flagStartLearning={flagStartLearning}
          />
        )}
        {/* ~~~~~~~~~~~~~~ Container below Navbar Ends ~~~~~~~~~~~~~~*/}
      </>
      // { content; }
    );
  }
}
export default OfflineHome;
