import React, { Component } from "react";
import NavBar from "./navbar";
import HomePage from "./home-page";
import ContentPageTeaching from "./content-page-teaching";
import CoursesPage from "./courses-page";
import LoginPage from "./login-page";
import { BeatLoader } from "react-spinners";
import axios from "axios";

class Remote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "homePage",
      message: "",
      subjectList: [],
      student: null,
      student1: null,
      student2: null,
      selectedSubjectId: -1,
      selectedSubjectFilePrefix: "",
      selectedSubjectName: "",
      filePrefixWOExtension: "",
      selectedSubjectAvailableFor: "",
      selectedSubjectIncludesAssignments: 1,
      // info whether admitted for subject or Demo or not at all
      // added on 11.01.2023
      visitorInfo: {
        status: "Guest",
        admissionStatus: "Guest",
        chapterLimit: 0,
      },
      flagLoggedIn: false,
      flagStartLearning: false,
      flagLoadingChapters: false,
      maxStudentLimit: 1,
      type: "",
      loggedinStudents: 0,
      loggedIn: "",
      emailId1: "",
      emailId2: "",
      studentNumber: 0,
      videoDuration: 0,
      windowWidth: "", // Trying to make the website responsive 14012023
      goAheadClicked: false,
      currentPage: "homePage",
      previousPage: "homePage",
      sessionInfo: null,
      flagCheckSession: false,
      selectedOption: "",
      presentationFile: "",
    };
  }
  handleCoursesClick = () => {
    this.setState({
      currentPage: "coursesPage",
      flagStartLearning: false,
    });
    this.getSubjectListFromDatabase();
  };
  handleStartLearningClick = (learningSubject) => {
    this.setState({
      flagStartLearning: true,
      learningSubject: learningSubject,
    });

    this.getSubjectListFromDatabase();
  };
  handleCancelClick = () => {
    this.setState({
      message: "",
    });
  };
  handleLoginClick = () => {
    if (this.state.currentPage == "loginPage") {
      // don't click to login button again and again
      return;
    }
    this.setState({
      currentPage: "loginPage",
      previousPage: this.state.currentPage, // after login, come back to same page
      message: "",
    });
  };
  handleStudent1InfoClick = () => {
    this.setState({
      message: "",
      studentNumber: 1,
    });
  };
  handleStudent2InfoClick = () => {
    this.setState({
      message: "",
      studentNumber: 2,
    });
  };
  hideMessage = () => {
    window.setTimeout(() => {
      this.setState({
        message: "",
      });
    }, 5000);
  };
  handleLoginCancelClick = () => {
    this.setState({
      currentPage: this.state.previousPage,
    });
  };
  // axios      .get(window.routerPrefix + requestUrl)
  handleFacebookLoginSubmit = (emailId) => {
    this.setState({
      flagLoadingLogin: true,
    });
    axios
      .get(window.routerPrefix + "/student/studentByGoogle/" + emailId)
      .then((res1) => {
        let data = res1.data;
        if (data.emailId == "none") {
          this.setState({
            message: "You need to confirm email-id with FaceBook",
          });
          this.hideMessage();
        } else if (data.emailId == "notregistered") {
          this.setState({
            // message: emailId + " is not a registered account",
            message: "Not a registered account",
          });
          this.hideMessage();
        } else {
          this.setState({
            student1: data,
            loggedinStudents: 1,
            loggedIn: "first",
            status: "success",
            message: "Logged in Successfully!",
            currentPage: this.state.previousPage,
          });
          this.hideMessage();
          this.setState({
            visitorInfo: {
              ...this.state.visitorInfo,
              status: "Registered",
              logIn: "Facebook",
            },
          });
        }
        this.setState({
          flagLoadingLogin: false,
        });
      })
      .catch((err) => {
        this.setState({
          error: err,
          flagLoadingLogin: false,
          message: "Something went wrong",
        }); // error is set
        this.hideMessage();
      });
  };
  handleLoginFormSubmit = (data) => {
    let { loggedinStudents } = this.state;
    if (loggedinStudents == 0) {
      data.studentNumber = 1;
    } else if (loggedinStudents == 1) {
      data.studentNumber = 2;
    }
    this.setState({
      flagLoadingLogin: true,
    });
    axios
      .post(window.routerPrefix + "/student/check", data, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          // "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        },
      })
      .then((res) => {
        this.setState({
          flagLoadingLogin: false,
        });
        let r = res.data; // this.props.onLoginFormSubmit(res.data, data.emailId);
        if (r == 0) {
          // Invalid email-id
          this.setState({
            message: "Invalid Email-id",
          });
          this.hideMessage();
        } else if (r == 1) {
          this.setState({
            message: "Invalid Password",
          });
          this.hideMessage();
        } else if (r == 3) {
          this.setState({
            message: "Please Login using Facebook only!",
          });
          this.hideMessage();
        } else if (r == 4) {
          this.setState({
            message: "Only one login is allowed for registered email-id!",
          });
          this.hideMessage();
        } else if (r == 2) {
          // Valid login
          if (this.state.maxStudentLimit == 1) {
            // This is only student
            this.setState({
              message: "Logged in Successfully!",
            });
            this.hideMessage();
            // write down in local-storage
            this.markLoginSuccess(data.emailId);
          } else if (
            this.state.maxStudentLimit == 2 &&
            this.state.loggedinStudents == 0
          ) {
            // This is first student
            this.getStudentFromDatabase(1); // get data of first student
            this.setState({
              loggedinStudents: 1,
              status: "firstStudentLoggedIn",
              loggedIn: "first",
            });
          } else if (
            this.state.maxStudentLimit == 2 &&
            this.state.loggedinStudents == 1
          ) {
            this.setState({
              status: "success",
              loggedinStudents: 2,
              loggedIn: "both",
            });
            this.getStudentFromDatabase(2); // Student Number 2
            this.getSubjectListFromDatabase();
          }
        }
      })
      .catch((err) => {
        this.setState({
          flagLoadingLogin: false,
          message: "Something went wrong",
        });
        this.hideMessage();
      });
  };
  markLoginSuccess = (emailId) => {
    // Write down in local storage emailid of the student
    window.localStorage.setItem("emailId", emailId);
    this.setState({
      loggedinStudents: 1,
      loggedIn: "first",
    });
    this.setState({
      visitorInfo: {
        ...this.state.visitorInfo,
        status: "Registered",
        logIn: "email",
      },
    });
    this.getStudentFromDatabase(emailId); // get data of first student
  };
  getStudentFromDatabase = (emailId) => {
    let studentNo = 1;
    // let emailId;
    this.setState({
      flagLoadingStudentData: true,
    });
    axios
      .get(window.routerPrefix + "/student/studentByEmailId/" + emailId)
      .then((res1) => {
        let data = res1.data;
        this.setState({
          flagLoadingStudentData: false,
        });
        if (studentNo == 1) {
          this.setState({
            student1: data,
            status: "success",
            // Which page to be shown after login
            // currentPage: this.state.previousPage,
            currentPage: "homePage",
          });
          if (data.status == "Registered") {
            // Only one student allowed and he is logged in
            this.getAccessInfoFromDatabase(data.studentId); //13012023
          }
        } else if (studentNo == 2) {
          this.setState({
            student2: data,
          });
        }
      })
      .catch((err) => {
        this.setState({
          error: err,
          message: "Something went wrong",
          flagLoadingStudentData: false,
        }); // error is set
      });
    this.hideMessage();
  };
  handleUserNameClick = () => {
    if (this.state.student1.emailId == "temp@gmail.com") {
      return;
    }
    this.setState({
      currentPage: "profilePage",
      flagLoadingProfileDetails: true,
    });
  };
  handleLogoutClick = () => {
    // different cases
    let { status } = this.state;
    let { student1 } = this.state;
    let { student2 } = this.state;
    let { maxStudentLimit } = this.state;
    let { studentNumber } = this.state;
    let studentName;
    let studentId;

    if (maxStudentLimit == 2) {
      // 2 students allowed, so need to check who logged out
      if (status == "firstStudentLoggedIn") {
        //out of 2 only first had logged in, and now he/she  logging out
        status = "noLogIn";
        studentId = student1.studentId;
        studentName = student1.fullName;
        this.setState({
          student1: null,
          loggedIn: "",
          loggedinStudents: 0,
        });
      } else if (status == "secondStudentLoggedIn") {
        //out of 2 only second  had logged in, and now he/she  logging out
        status = "noLogIn";
        studentId = student2.studentId;
        studentName = student2.fullName;
        this.setState({
          student2: null,
          loggedIn: "",
          loggedinStudents: 0,
        });
      } else if (status == "success") {
        //both have logged in. Who is logging out?
        if (studentNumber == 1) {
          // first student wants to log out
          status = "secondStudentLoggedIn";
          studentId = student1.studentId;
          studentName = student1.fullName;
          this.setState({
            student1: null,
            loggedIn: "second",
            loggedinStudents: 1,
          });
        } else {
          // second student wants to log out
          status = "firstStudentLoggedIn";
          studentId = student2.studentId;
          studentName = student2.fullName;
          this.setState({
            student2: null,
            loggedIn: "first",
            loggedinStudents: 1,
          });
        }
      }
    } else if (maxStudentLimit == 1) {
      // 1 student allowed, so only he/she logged out

      status = "noLogIn";
      studentId = student1.studentId;
      studentName = student1.fullName;
      this.setState({
        student1: null,
        loggedIn: "",
        loggedinStudents: 0,
      });
    }
    // at backend, remove entry from LoginRecord Table
    this.handleStudentLogout(studentId, studentNumber, studentName);
    // selected subject, chapter, menu should be unselected
    let subjectList = [...this.state.subjectList];
    for (let i = 0; i < subjectList.length; i++) {
      subjectList[i].flagActive = false;
    } //for
    this.setState({
      subjectList: subjectList,
    });
    this.setState({
      status: status,
      currentPage: "homePage",
      visitorInfo: {
        ...this.state.visitorInfo,
        admissionStatus: "Guest",
        chapterLimit: 0,
      },
      sessionInfo: null,
    });
  };
  handleStudentLogout = (studentId, studentNumber, studentName) => {
    // send to back-end this info
    let data = {
      studentId: studentId,
      studentNumber: studentNumber,
      fullName: studentName,
    };
    axios
      .post(window.routerPrefix + "/student/logout", data, {
        headers: {
          "Accept-Language": "en-US,en;q=0.8",
        },
      })
      .then((res) => {
        // this.setState({
        //   status: "homePage",
        // });
      })
      .catch((err) => {
        this.setState({
          error: err,
          message: "Something went wrong",
        });
      });
    this.hideMessage();
  };
  getSubjectListFromDatabase = () => {
    this.setState({
      flagLoadingSubjects: true,
    });
    axios
      .get(window.routerPrefix + "/subject/subjects")
      .then((res) => {
        let subjectList = res.data;
        if (!subjectList) {
          this.setState({
            subjectList: [],
            message: "Something went wrong, Reload",
            flagLoadingSubjects: false,
          });
          this.hideMessage();
        } else {
          // Add FlagActive to each subject
          for (let i = 0; i < subjectList.length; i++) {
            subjectList[i].flagActive = false;
          } //for
          this.setState({
            subjectList: subjectList,
            flagLoadingSubjects: false,
          });
          // Is the guest clicked to start learning first chapter
          if (this.state.flagStartLearning) {
            for (let i = 0; i < subjectList.length; i++) {
              if (subjectList[i].name.startsWith(this.state.learningSubject)) {
                this.handleSubjectClick(subjectList[i]);
                break;
              }
            }
          }
        }
      })
      .catch((err) => {
        this.setState({
          error: err,
          message: "Something went wrong",
          flagLoadingSubjects: false,
        }); // error is set
        this.hideMessage();
      });
  };
  handleLogoClick = () => {
    this.setState({
      currentPage: "homePage",
      message: "",
      selectedSubjectId: "",
    });
  };
  handleAboutUsClick = () => {
    this.setState({
      currentPage: "aboutUsPage",
      message: "",
      selectedSubjectId: "",
    });
  };
  handleContactUsClick = () => {
    this.setState({
      currentPage: "contactUsPage",
      message: "",
      selectedSubjectId: "",
    });
  };
  getCookie = (key) => {
    var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
  };
  updateDimensions = () => {
    let windowWidth = "";
    if (window.innerWidth > 768) {
      windowWidth = "desktop";
    } else {
      windowWidth = "mobile";
    }
    this.setState({
      windowWidth: windowWidth,
    });
  };
  handleGetReviews = () => {
    let placeId = "ChIJYTVcyk6VwjsRNHytE8FsLBc";
    let apiKey = "AIzaSyB0BbTqiojTu21x3bqJJ1sJGWtm_Y_k0n0";
    axios
      .get(
        `https://maps.googleapis.com/maps/api/place/details/json?placeid=${placeId}&key=${apiKey}`
      )
      //   'https://maps.googleapis.com/maps/api/place/details/json?place_id=ChIJN1t_tDeuEmsRUsoyG83frY4&fields=name%2Crating%2Cformatted_phone_number&key=YOUR_API_KEY'
      .then((res) => {
        let data = res.data;
        console.log(data);
      })
      .catch((err) => {
        this.setState({
          error: err,
          message: "Something went wrong",
          flagCheckSession: false,
        }); // error is set
      });
  };
  componentDidMount = () => {
    let eventSource = new EventSource(
      "http://localhost:8083/api/files/presentation"
      // "api/files/presentation"
    );
    eventSource.addEventListener("Presentation", (event) => {
      let presentationFile = event.data;
      this.setState({
        presentationFile: presentationFile,
      });
    });
    eventSource.onerror = (event) => {
      if (event.target.readyState === EventSource.CLOSED) {
        console.log("SSE closed (" + event.target.readyState + ")");
      }
      eventSource.close();
    };

    eventSource.onopen = () => {
      console.log("connection opened");
    };
  };
  
  handleResetFlagStartLearning = () => {
    this.setState({
      flagStartLearning: false,
    });
  };
  handleSubjectClick = (subject) => {
    let subjectId = subject.subjectId;
    let filePrefix = subject.filePrefix;
    // if (subjectId == this.state.selectedSubjectId) {
    //   return;
    // }
    this.setState({
      selectedSubjectId: subject.subjectId,
    });
    // Active button color should be changed 28.10.2022
    let subjectList = [...this.state.subjectList];
    for (let i = 0; i < subjectList.length; i++) {
      if (subject.flagActive == false) {
        subjectList[i].flagActive = false;
        if (subjectList[i].subjectId === subject.subjectId) {
          subjectList[i].flagActive = true;
        }
      } //if
      else {
        subjectList[i].flagActive = false;
      }
    } //for
    this.setState({
      subjectList: subjectList,
    });
    this.setState({
      selectedSubjectName: subject.name,
      selectedSubjectFilePrefix: filePrefix,
      selectedSubjectAvailableFor: subject.availableFor,
      selectedSubjectIncludesAssignments: subject.includesAssignments,
      selectedSubjectFees: subject.fees,
      message: "",
    });
    this.setState({
      currentPage: "contentPage",
    });
  };
  updateVisitorInfo = (filePrefix) => {
    let sessionInfo = this.state.sessionInfo;
    // We got the sessionInfo
    // From that get idea whether for this particular subject
    // student is admitted or allowed only Demo
    // if admitted then chapterLimit
    let flag = false;
    let i;
    for (i = 0; i < sessionInfo.filePrefixAllowed.length; i++) {
      if (sessionInfo.filePrefixAllowed[i] == filePrefix) {
        flag = true;
        break;
      }
    } //for
    if (flag == false) {
      // For this particular subject, the registered student is guest
      this.setState({
        visitorInfo: {
          ...this.state.visitorInfo,
          admissionStatus: "Guest",
          chapterLimit: 0,
        },
      });
      // visitorInfo = { admissionStatus: "Guest", chapterLimit: 0 };
    } else {
      // Student is  admitted for this subject
      this.setState({
        visitorInfo: {
          ...this.state.visitorInfo,
          admissionStatus: sessionInfo.admissionStatus[i],
          admissionType: sessionInfo.admissionType[i],
          chapterLimit: sessionInfo.chapterLimit[i],
        },
      });
    }
  };
  // This gives info about access alloted to a student
  getAccessInfoFromDatabase = (studentId) => {
    this.setState({
      flagLoadingAccess: true,
    });
    axios
      .get(window.routerPrefix + "/student/accessInfo/" + studentId)
      .then((res1) => {
        this.setState({
          sessionInfo: res1.data,
        });

        this.setState({
          flagLoadingAccess: false,
        });
      })
      .catch((err) => {
        this.setState({
          error: err,
          message: "Something went wrong",
          flagLoadingAccess: false,
        }); // error is set
      });
    this.hideMessage();
  };
  handleOtherCoursesClick = () => {
    this.setState({
      currentPage: "coursesPage",
    });
  };
  handleChange = (value) => {
    console.log(`Option selected:`, value);
  };

  render() {
    let { status } = this.state;
    let { loggedIn } = this.state;
    let { message } = this.state;
    let { student1 } = this.state;
    let { student2 } = this.state;
    let { subjectList } = this.state;
    let { programList } = this.state;
    let { studentNumber } = this.state;
    let { loggedinStudents } = this.state;
    let { flagLoadingChapterContent } = this.state;
    let { flagStartLearning } = this.state;

    let { selectedSubjectFilePrefix } = this.state;
    let { filePrefixWOExtension } = this.state;
    let { selectedSubjectName } = this.state;
    let { selectedSubjectAvailableFor } = this.state;
    let { selectedSubjectIncludesAssignments } = this.state;
    let { selectedSubjectFees } = this.state;

    let { selectedSubjectId } = this.state;
    let { flagLoadingLogin } = this.state;
    let { currentPage } = this.state;
    let { flagLoadingSubjects } = this.state;
    let { windowWidth } = this.state;
    let { videoDuration } = this.state;
    let { visitorInfo } = this.state;
    let { sessionInfo } = this.state;
    let { flagLoadingStudentData } = this.state;
    let { flagCheckSession } = this.state;
    let { presentationFile } = this.state;

    let { selectedOption } = this.state;

    let activeStudent;
    if (studentNumber == 1) {
      activeStudent = student1;
    } else if (studentNumber == 2) {
      activeStudent = student2;
    }
    if (flagCheckSession) {
      return (
        <div className="text-center mt-5">
          <BeatLoader size={16} color={"blue"} flagLoading />
        </div>
      );
    }
    /*-----------------------NavBar--------------------*/
    return (
      <>
        <NavBar
          onLoginClick={this.handleLoginClick}
          onCoursesClick={this.handleCoursesClick}
          onAboutUsClick={this.handleAboutUsClick}
          onStudent1InfoClick={this.handleStudent1InfoClick}
          onStudent2InfoClick={this.handleStudent2InfoClick}
          onFacebookLoginSubmit={this.handleFacebookLoginSubmit}
          onStudentLogout={this.handleStudentLogout}
          onLogoutClick={this.handleLogoutClick}
          onLogoClick={this.handleLogoClick}
          onUserNameClick={this.handleUserNameClick}
          onContactUsClick={this.handleContactUsClick}
          flagLoadingLogin={flagLoadingLogin}
          status={status}
          message={message}
          student1={student1}
          loggedinStudents={loggedinStudents}
          loggedIn={loggedIn}
          windowWidth={windowWidth}
          flagLoadingStudentData={flagLoadingStudentData}
          currentPage={currentPage}
        />
        {/* ~~~~~~~~~~~~~~ Home-page (On site loading)  ~~~~~~~~~~~~~~*/}

        {currentPage == "homePage" && (
          <HomePage
            // onExploreClick={this.handleExploreClick}
            onStartLearningClick={this.handleStartLearningClick}
            status={status}
            student={student1}
            presentationFile={presentationFile}
          />
        )}
        {/* ~~~~~~~~~~~~~~ Courses-page (On explore button click)  ~~~~~~~~~~~~~~*/}
        {currentPage == "coursesPage" && (
          <CoursesPage
            subjectList={subjectList}
            flagLoadingSubjects={flagLoadingSubjects}
            // onExploreClick={this.handleExploreClick}
            onSubjectClick={this.handleSubjectClick}
          />
        )}
        {currentPage == "contentPage" && (
          <ContentPageTeaching
            subjectList={subjectList}
            // onExploreClick={this.handleExploreClick}
            onSubjectClick={this.handleSubjectClick}
            onOtherCoursesClick={this.handleOtherCoursesClick}
            resetFlagStartLearning={this.handleResetFlagStartLearning}
            onPresentationImageClick={this.handlePresentationImageClick}
            windowWidth={windowWidth}
            selectedSubjectId={selectedSubjectId}
            flagLoadingChapterContent={flagLoadingChapterContent}
            message={message}
            selectedSubjectFilePrefix={selectedSubjectFilePrefix}
            selectedSubjectFees={selectedSubjectFees}
            selectedSubjectName={selectedSubjectName}
            selectedSubjectAvailableFor={selectedSubjectAvailableFor}
            selectedSubjectIncludesAssignments={
              selectedSubjectIncludesAssignments
            }
            filePrefixWOExtension={filePrefixWOExtension}
            programList={programList}
            videoDuration={videoDuration}
            student={student1}
            visitorInfo={visitorInfo}
            flagStartLearning={flagStartLearning}
          />
        )}
        {currentPage == "loginPage" && (
          <LoginPage
            subjectList={subjectList}
            onFacebookLoginSubmit={this.handleFacebookLoginSubmit}
            onLoginFormSubmit={this.handleLoginFormSubmit}
            onLoginCancelClick={this.handleLoginCancelClick}
            flagLoadingLogin={flagLoadingLogin}
          />
        )}
        {/* ~~~~~~~~~~~~~~ Container below Navbar Ends ~~~~~~~~~~~~~~*/}
      </>
      // { content; }
    );
  }
}
export default Remote;
