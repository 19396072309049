import React, { Component } from "react";
import "../App.css";
import { Link } from "react-router-dom";

import { BeatLoader } from "react-spinners";
class OfflineNavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      loginTitle: "Login",
    };
  }
  handleFacebookLoginClick = (data) => {
    console.log(data);
  };
  handleLogoClick = () => {
    this.props.onLogoClick();
  };
  handleHomeClick = () => {
    this.props.onLogoClick();
  };
  handleStudent1InfoClick = () => {
    this.props.onStudent1InfoClick();
  };
  handleStudent2InfoClick = () => {
    this.props.onStudent2InfoClick();
  };
  handleLoginOrLogoutClick = () => {
    let { status } = this.props;

    if (status == "success") {
      this.props.onLogoutClick();
    } else {
      this.props.onLoginClick();
    }
  };
  handleAboutUsClick = () => {
    this.props.onAboutUsClick();
  };
  handleLoginClick = () => {};
  getCookie = (key) => {
    var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
  };
  // Following code is for Google Authentication
  // commented as no longer required
  handleCallbackResponse = (response) => {
    // var userObject = jwt_decode(response.credential);
    // get the google emailid of the user
    // this.props.onGoogleLoginSubmit(userObject.email);
  };
  responseFacebook = (response) => {
    console.log(response);
    this.props.onFacebookLoginSubmit(response.email);
  };
  showUserOrAboutUs = () => {
    let { status } = this.props;
    let { student1 } = this.props;
    let { flagLoadingStudentData } = this.props;
    if (flagLoadingStudentData) {
      return "";
    } else {
      if (status == "success") {
        if (student1.emailId == "temp@gmail.com") {
          return "Welcome Guest";
        }
        return student1.fullName.substring(0, student1.fullName.indexOf(" "));
      } else {
        return "About Us";
      }
    }
  };
  handleUserNameOrAboutUsClick = () => {
    return ;
  };
  handleCoursesClick = () => {
    this.props.onCoursesClick();
  };
  
  render() {
    let { message } = this.props;
    let { flagCheckSession } = this.props;
    let { currentPage } = this.props;
    let { flagLoggingOut } = this.props;

    return (
      <React.Fragment>
        {/* <!-- Navbar Start --> */}
        <div className="container-fluid ">
            <div className="row border-top px-xl-5 pt-2 align-items-center justify-content-between">
            <div className="col-3  logo-desktop">
              {currentPage != "homePage" ? (
                <Link to="#">
                  <img
                    src="images/rjac_logo.jpg"
                    alt="No logo"
                    className="img-fluid homepage-small-logo"
                    onClick={this.handleLogoClick}
                  />
                </Link>
              ) : null}
            </div>

            <div className="col-3 text-center  logo-mobile">
              {
                <Link to="#">
                  <img
                    src="images/logo_rjac_title.jpg"
                    alt="No logo"
                    className="img-fluid homepage-small-logo"
                    onClick={this.handleLogoClick}
                  />
                </Link>
              }
            </div>
            {!flagLoggingOut && (
              <div className="col-9  ">
                <nav className="navbar navbar-expand bg-light navbar-light py-3 py-lg-0 px-0">
                  <div
                    className="collapse navbar-collapse justify-content-between"
                    id="navbarCollapse"
                  >
                    <div className="navbar-nav py-0  container-navitems">
                      <button
                        href="#"
                        // className="nav-item nav-link"
                        className="button-section-menu bg-darkcolor p-2"
                        onClick={this.handleCoursesClick}
                      >
                        Courses
                      </button>
                      &nbsp;
                      {!flagLoggingOut && (
                        <button
                          to="#"
                          className="button-section-menu bg-darkcolor p-2"
                          onClick={this.handleUserNameOrAboutUsClick}
                        >
                          {this.showUserOrAboutUs()}
                        </button>
                      )}
                      &nbsp;
                      
                    </div>
                  </div>
                </nav>
              </div>
            )}
            {flagLoggingOut && (
              <div className="col-9  ">
                <BeatLoader size={16} color={"blue"} flagLoading />
              </div>
            )}
          </div>
        </div>
        {/* <!-- Navbar End --> */}
        <div className="container  container-navbar ">
          {/* row ends */}
          {/* <div className=" text-danger text-center">{message}</div> */}
          {message && <div className=" text-danger text-center">{message}</div>}
        </div>
        {/* container ends */}
      </React.Fragment>
    );
  }
}
export default OfflineNavBar;
