import "./App.css";
import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { withRouter } from "react-router";
import Home from "./components/home";
import AdminActivity from "./components/admin-activity";
import AdmissionStatus from "./components/admission-status";
import ReferredRegistration from "./components/referred-registration";
import Certificate from "./components/certificate";
import OfflineSecurity from "./components/offline-security";
import DsaBatch from "./components/dsa-batch";
import Downloadtc from "./components/download-tc";
import DownloadDevc from "./components/download-devc";
import StudentJoinClass from "./components/student-join-class";
import Remote from "./components/remote";
import Presentation from "./components/presentation";
import OfflineHome from "./components/offline-home";
import JavaClassDetails from "./components/java-class-details";
import CppClassDetails from "./components/cpp-class-details";
import PythonClassDetails from "./components/python-class-details";

window.routerPrefix = "api"; //production
// window.hosting = "offline"; //
window.internetInClass = "yes"; //
window.hosting = "internet"; // online
// window.routerPrefix = ""; //development
// This global variable added for production purpose
class App extends Component {
  handleAdminLogIn = () => {
    this.setState({
      userName: "Admin",
      userType: "Admin",
    });
  };

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/offlineclass" component={OfflineHome} />
          <Route path="/aaxpj" component={AdminActivity} />
          <Route path="/reference" component={ReferredRegistration} />
          <Route path="/certificates" component={Certificate} />
          <Route path="/status" component={AdmissionStatus} />
          <Route path="/offline" component={OfflineSecurity} />
          <Route path="/joinus" component={StudentJoinClass} />
          {/* <Route path="/registration" component={StudentJoinClass} /> */}
          <Route path="/abbadabbazabba" component={DsaBatch} />
          <Route path="/presentation" component={Presentation} />
          <Route path="/remote" component={Remote} />
          <Route path="/downloadtc" component={Downloadtc} />
          <Route path="/downloaddevc" component={DownloadDevc} />
          <Route path="/java" component={JavaClassDetails} />
          <Route path="/python" component={PythonClassDetails} />
          <Route path="/cpp" component={CppClassDetails} />
        </Switch>
      </BrowserRouter>
    );
  }
} //class
export default withRouter(App);
