import React, { Component } from "react";
import AdminBatchList from "./admin-batch-list";
import AdminBatchAdd from "./admin-batch-add";
import { BatchDB } from "../BatchDB";
import { BeatLoader } from "react-spinners";
import axios from "axios";

class AdminBatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      response: {},
      batch: null,
      filteredBatches: [],
      batchList: [],
      batchdb: {},
      flagLoading: false,
      searchText: "",
      sortedField: "",
      sortedDirection: false,
      sectionNumber: 1,
      courseList: [],
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  getBatchListFromDatabase = () => {
    this.setState({
      flagLoading: true,
    });
    axios
      .all([axios.get(window.routerPrefix + "/batch/batches")])
      .then(
        axios.spread((res1) => {
          let batchList = res1.data;
          let updatedBatches = [...batchList];

          batchList = updatedBatches;
          this.setState({
            batchList: batchList,
            filteredBatches: batchList,
            batchdb: new BatchDB(batchList),
            flagLoading: false,
          });
        })
      )
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  };
  componentDidMount() {
    this.setState({
      flagLoading: true,
    });
    axios
      .all([
        axios.get(window.routerPrefix + "/batch/batches/"),
        axios.get(window.routerPrefix + "/course/courses/"),
      ])
      .then(
        axios.spread((res1, res2) => {
          let batchList = res1.data;
          let courseList = res2.data;
          // Add course Information to each batch (from courseId)
          let updatedBatches = [...batchList];
          batchList.map((batch, index) => {
            for (let i = 0; i < courseList.length; i++) {
              if (batch.courseId === courseList[i].courseId) {
                updatedBatches[index].course = courseList[i].name;
                break;
              }
            }
          }); //map
          batchList = updatedBatches;
          this.setState({
            batchList: batchList,
            courseList: courseList,
            batchdb: new BatchDB(batchList),
            filteredBatches: batchList,
            flagLoading: false,
          });
        })
      )
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  }
  handleSearchTextBoxKeyUp = (event) => {
    let searchText = event.target.value;
    this.setState({ searchText: searchText });
    this.performSearchOperation(searchText);
  };
  handleSearchTextChange = (event) => {
    let searchText = event.target.value;
    this.setState({ searchText: searchText });
    this.performSearchOperation(searchText);
  };
  performSearchOperation = (searchText) => {
    let query = searchText.trim();
    let searchedBatches = [];
    searchedBatches = this.state.batchdb.filterByName(query);
    this.setState({
      filteredBatches: searchedBatches,
    });
  };
  onFormSubmit(data) {
    let { action } = this.props;
    this.setState({
      flagLoading: true,
    });
    if (action === "UPDATE") {
      //EDIT / PUT
      axios
        .put(window.routerPrefix + "/batch/update", data)
        .then((res) => {
          this.updateBatchList(data);
          this.setState({
            response: res.data,
            flagLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            flagLoading: false,
          });
        });
    } else if (action === "ADD") {
      axios
        .post(window.routerPrefix + "/batch/addBatch", data, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
          },
        })
        .then((res) => {
          this.setState({
            flagLoading: false,
            response: res.data,
          });
          let batchId = res.data.batchId; // id of the added batch is returned as response
          data.batchId = batchId;
          this.addToBatchList(data);
          // Add subjects and access (yes/no) to accessTable
          // For that get list of subjects of the course of this batch
          // from coursebundle table
          let courseId = data.courseId;
          // this.addAccessEntries(courseId, batchId);

          let accessList = [];
        })
        .catch((err) => {
          console.log("error!");
          this.setState({
            flagLoading: false,
          });
        });
    } //else
  }
  addAccessEntries = (courseId, batchId) => {
    this.setState({
      flagLoading: true,
    });
    axios
      .all([
        axios.get(
          window.routerPrefix + "/coursebundle/coursebundles/" + courseId
        ),
      ])
      .then(
        axios.spread((res1) => {
          let batchSubjectList = res1.data;

          for (let i = 0; i < batchSubjectList.length; i++) {
            batchSubjectList[i].allow = "yes";
            delete batchSubjectList[i].coursebundleId;
            batchSubjectList[i].batchId = batchId;
          } //for
          // post this data to access table
          axios
            .post(
              window.routerPrefix + "/access/addAccesses",
              batchSubjectList,
              {
                headers: {
                  accept: "application/json",
                  "Accept-Language": "en-US,en;q=0.8",
                },
              }
            )
            .then((res) => {
              this.setState({
                flagLoading: false,
                response: res.data,
              });
            })
            .catch((err) => {
              console.log("error!");
              this.setState({
                flagLoading: false,
              });
            });

          this.setState({
            flagLoading: false,
          });
        })
      )
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  };

  handleEditBatch = (batch) => {
    this.setState({
      batch: batch,
      // searchText: "",
    });
    this.props.onEditClick();
  };
  // handleShowChapters = (batch) => {
  //   this.props.onShowChapters(batch);
  // };
  addToBatchList = (batch) => {
    let batchList = [...this.state.batchList];
    batchList.unshift(batch);
    let filteredBatches = [...this.state.filteredBatches];
    filteredBatches.unshift(batch);
    this.setState({
      filteredBatches: filteredBatches,
      batchList: batchList,
    });
    let message = "The batch '" + batch.name + "' added successfully.";

    this.props.onCrudAction(message, "ADD");
  };
  updateBatchList = (batch) => {
    let batchList = [...this.state.batchList];
    for (let i = 0; i < batchList.length; i++) {
      if (batchList[i].batchId == batch.batchId) {
        //batchList[i] = batch;
        batchList.splice(i, 1); // remove 1 element from position i
        // and insert at the beginning as we want latest modified at the top
        batchList.unshift(batch);
      }
    } //for
    // remove from filteredchapters also
    let filteredBatches = [...this.state.filteredBatches];
    for (let i = 0; i < filteredBatches.length; i++) {
      if (filteredBatches[i].batchId == batch.batchId) {
        // filteredBatches[i] = batch;
        filteredBatches.splice(i, 1); // remove 1 element from position i
        // and insert at the beginning as we want latest modified at the top
        filteredBatches.unshift(batch);
      }
    } //for
    //sorting

    this.setState({
      //   chapterdb: new ChapterDB(chapterList),
      filteredBatches: filteredBatches,
      batchList: batchList,
      // searchText: "",
    });
    let message = "The batch '" + batch.fullName + "' updated successfully.";
    this.props.onCrudAction(message, "LIST", "Add a Batch");
  };

  handleDeleteBatch = (batch) => {
    let batchList = [...this.state.batchList];

    for (let i = 0; i < batchList.length; i++) {
      if (batchList[i].batchId == batch.batchId) {
        batchList.splice(i, 1); // remove 1 element from position i
      }
    } //for
    // remove from filteredbatches also
    let filteredBatches = [...this.state.filteredBatches];
    for (let i = 0; i < filteredBatches.length; i++) {
      if (filteredBatches[i].batchId == batch.batchId) {
        filteredBatches.splice(i, 1); // remove 1 element from position i
      }
    } //for

    this.setState({
      batchdb: new BatchDB(batchList),
      filteredBatches: filteredBatches,
      batchList: batchList,
    });
    let message = "The batch '" + batch.name + "' deleted successfully.";
    this.props.onCrudAction(message, "LIST");
  };
  handleDuplicateBatch = (batch, howMany) => {
    this.setState({
      flagLoading: true,
    });
    axios
      .post(window.routerPrefix + "/batches/" + "/" + howMany, batch, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
        },
      })
      .then((res) => {
        let message = batch.name + " duplicated " + howMany;
        if (howMany == 1) message += " time.";
        else message += " times.";
        this.props.onCrudAction(message, "LIST"); // Add again
        this.getBatchListFromDatabase();
        this.setState({
          flagLoading: false,
          response: res.data,
        });
      })
      .catch((err) => {
        console.log("error!");
        this.setState({
          flagLoading: false,
        });
      });
  };
  handleHeaderClick = (event) => {
    let field = event.target.id;

    let direction = false;
    if (field === this.state.sortedField) {
      // same button clicked twice
      direction = !this.state.direction;
    } else {
      // different field
      direction = false;
    }
    this.setState({ direction: direction });
    let { filteredBatches } = this.state;
    if (direction == false) {
      //in ascending order
      filteredBatches = filteredBatches.sort((a, b) => {
        if (a[field] > b[field]) {
          return 1;
        }
        if (a[field] < b[field]) {
          return -1;
        }
        return 0;
      });
    } else {
      //in descending order
      filteredBatches = filteredBatches.sort((a, b) => {
        if (a[field] < b[field]) {
          return 1;
        }
        if (a[field] > b[field]) {
          return -1;
        }
        return 0;
      });
    }
    this.setState({ filteredBatches: filteredBatches, sortedField: field });
  };
  handleSectionNumberClick = (event) => {
    this.setState({ sectionNumber: event.target.id });
  };
  render() {
    let { flagLoading } = this.state;
    let { action } = this.props;
    let { batch } = this.state;
    let { entityType } = this.state;
    let { courseList } = this.state;

    let content;
    if (flagLoading) {
      content = (
        <div className="text-center">
          <BeatLoader size={16} color={"blue"} flagLoading />
        </div>
      );
    } else if (action === "LIST") {
      // First show list
      content = (
        <AdminBatchList
          onEditBatch={this.handleEditBatch}
          onDeleteBatch={this.handleDeleteBatch}
          filteredBatches={this.state.filteredBatches}
          searchText={this.state.searchText}
          onSearchTextChange={this.handleSearchTextChange}
          onSearchTextBoxKeyUp={this.handleSearchTextBoxKeyUp}
          onHeaderClick={this.handleHeaderClick}
          onSectionNumberClick={this.handleSectionNumberClick}
          sectionNumber={this.state.sectionNumber}
          onDuplicateBatch={this.handleDuplicateBatch}
        />
      );
    } else if (action === "ADD" || action === "UPDATE") {
      // After pressing Add Button, show only form and not the list
      content = (
        <div>
          {/* content={" "} */}
          <AdminBatchAdd
            onFormSubmit={this.onFormSubmit}
            batch={batch}
            action={action}
            entityType={entityType}
            courseList={courseList}
          />
        </div>
      );
    } else if (action === "DELETE") {
      content = <div>Delete action in Progress...</div>;
    }
    return (
      <div className="container-fluid container-md container-content-page p-0">
        {content}
      </div>
    );
  }
}
export default AdminBatch;
