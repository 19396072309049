import React, { useState } from "react";
import { BeatLoader } from "react-spinners";
import axios from "axios";
import LazyLoad from "react-lazyload";
function StudentImage(props) {
  let [flagLoad, setFlagLoad] = useState(false);
  let [error, setError] = useState("");
  let [previewImage, setPreviewImage] = useState(false);
  let [flagImageChange, setFlagImageChange] = useState(false);
  let [flagImageUploaded, setFlagImageUploaded] = useState(false);
  let [message, setMessage] = useState("");
  let [file, setFile] = useState("");
  let { student } = props;
  function handleChangeImageClick() {
    setFlagImageChange(true);
  }
  function handleCancelScreenShotClick() {
    props.onCancelScreenShotClick(true);
  }

  function handleUploadImageClick() {
    const formData = new FormData();
    
    formData.append("imageFile", file);
    setFlagLoad(true);
    // return student1.fullName.substring(0, student1.fullName.indexOf(" "));
    axios
      .post(
        window.routerPrefix + "/files/uploadStudentPhoto/" + student.emailId,
        formData,
        {
          headers: {
            accept: "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data != "####") {
          //successfully uploaded
          let imageName = res.data;
          setFlagImageChange(false);
          setFlagImageUploaded(false);
          setPreviewImage(false);
        }
        setFlagLoad(false);
        props.onUploadStudentImage();
      })
      .catch((err) => {
        setError(err);
        setFlagLoad(false);
      });
  }
  function handleCancelChangeImageClick() {
    setMessage("");
    setFile("");
    setPreviewImage("");
    setFlagImageChange(false);
  }
  function fileChangedHandler(event) {
    let file = event.target.files[0];
    if (!file) {
      setMessage("");
      return;
    }
    let fileType = file.type.substring(0, file.type.indexOf("/"));
    if (fileType != "image") {
      setMessage("Invalid file...");
      setFile("");
    }
    //  else if (file.size > 5000) {
    //   setState({ message: "File-size should be below 5kb" });
    // }
    else {
      setMessage("");
      let previewImage = URL.createObjectURL(file);
      setFile(file);
      setPreviewImage(previewImage);
    }
  }
  return (
    <div className="container-fluid container-md">
      {!flagLoad && (
        <div className="row justify-content-center">
          {flagImageChange && previewImage && !message && (
            <div className="col-12 text-center mb-3">
              <LazyLoad>
                <img className="div-student-image" src={previewImage} alt="" />
              </LazyLoad>
            </div>
          )}

          {!flagImageChange && !previewImage && !message && (
            <div className="col-12 text-center mb-4">
              Dear { student.fullName} 
            </div>
          )}
          {!flagImageChange && !previewImage && !message && (
            <div className="col-12 text-center mb-4">
              Please upload your one photo as identity-proof
            </div>
          )}
          {!flagImageChange && (
            <div className="col-12 text-center ">
              <button
                className="button-section-menu bg-darkcolor p-2"
                // className={"btn-my" + colorScheme }
                onClick={handleChangeImageClick}
              >
                Upload photo
              </button>
              &nbsp;&nbsp;&nbsp;
              {/* <button
                className="button-section-menu bg-darkcolor p-2"
                onClick={handleCancelScreenShotClick}
              >
                Cancel
              </button> */}
            </div>
          )}
          {flagImageChange && !previewImage && (
            <div className="col-12 text-center ">
              <input type="file" name="file" onChange={fileChangedHandler} />
            </div>
          )}
          {flagImageChange && (
            <div className="col-12 text-center mb-2">
              <button
                className="button-section-menu bg-darkcolor p-2"
                onClick={handleCancelChangeImageClick}
              >
                Cancel
              </button>
            </div>
          )}
          {flagImageChange && message && (
            <div className="col-12 text-center text-red">{message}</div>
          )}

          {flagImageChange &&
            previewImage &&
            !message &&
            !flagImageUploaded && (
              <div className="col-12 text-center ">
                <button
                  className="button-section-menu bg-darkcolor p-2"
                  onClick={handleUploadImageClick}
                >
                  Submit photo
                </button>
              </div>
            )}
        </div>
      )}
      {error && <div>Error retriving photo </div>}
      {flagLoad && (
        <div className="text-center">
          <BeatLoader size={16} color={"blue"} flagLoading />
        </div>
      )}
    </div>
  );
}
export default StudentImage;
