import React from "react";

class AdminAdmAdd extends React.Component {
  emptyAdmin = {
    adminId: "",
    name: "",
    emailId: "",
    role: "",
    whatsappNumber: "",
    password: "",
  };
  errorAdmin = {
    name: { message: "", mxLen: 40, mnLen: 5, onlyDigits: false },
    emailId: { message: "", mxLen: 50, mnLen: 7, onlyDigits: false },
    role: { message: "" },
    whatsappNumber: { message: "", mxLen: 10, mnLen: 10, onlyDigits: true },
    password: { message: "", mxLen: 40, mnLen: 5, onlyDigits: false },
  };
  constructor(props) {
    super(props);
    let admin;
    let flagFormInvalid;
    let { action } = this.props;
    if (action === "ADD") {
      flagFormInvalid = true;
      admin = this.emptyAdmin;
    } else if (action === "UPDATE") {
      // in edit mode, keep the update button enabled at the beginning
      flagFormInvalid = false;
      admin = this.props.admin;
    }
    this.state = {
      admin: admin,
      errorAdmin: this.errorAdmin,
      flagFormInvalid: flagFormInvalid,
    };
  }

  handleChange = (event) => {
    let { errorAdmin } = this.state;
    let name = event.target.name;
    let message;
    let userInput;
    const isCheckbox = event.target.type === "checkbox";
    if (isCheckbox) {
      // for checkbox, no checking of validations.
      userInput = event.target.checked;
      this.setState({
        admin: { ...this.state.admin, [name]: userInput },
      });
      return;
    }
    let value = event.target.value;
    //check of max. length
    let mxLen = errorAdmin[`${name}`].mxLen;
    let onlyDigits = errorAdmin[`${name}`].onlyDigits;
    if (value.length > mxLen) {
      value = value.slice(0, mxLen + 1);
      if (!onlyDigits) message = "Maximum " + mxLen + " characters allowed";
      else message = "Maximum " + mxLen + " digits allowed";
    } //if...
    else {
      message = "";
    }
    errorAdmin[`${name}`].message = message;
    this.setState({
      errorAdmin: errorAdmin,
      admin: { ...this.state.admin, [name]: value },
    });
    this.checkAllErrors(errorAdmin);
  };
  handleBlur = (event) => {
    let name,
      value,
      requiredMessage = " is required",
      type,
      message = "",
      mnLen,
      onlyDigits;
    let { errorAdmin } = this.state;
    name = event.target.name;
    type = event.target.type;
    if (type === "text" || type === "textarea") {
      // check if 'required', also check min no. of characters
      value = event.target.value.trim();
      mnLen = errorAdmin[`${name}`].mnLen;
      onlyDigits = errorAdmin[`${name}`].onlyDigits;
      if (value.length === 0) {
        message = name + requiredMessage;
      } else if (value.length < mnLen) {
        if (!onlyDigits) message = "Min " + mnLen + " characters required";
        else message = "Min " + mnLen + " digits required";
      } //else
      else if (onlyDigits) {
        for (let i = value.length - 1; i >= 0; i--) {
          const d = value.charCodeAt(i);
          if (d < 48 || d > 57) {
            message = "Enter only digits";
            break;
          } //if
        } //for
      } //else if... onlyDigits
      else {
        message = "";
      }
    } //if... text || textarea
    else if (event.target.type === "select-one") {
      value = event.target.selectedIndex;

      if (value === 0) {
        // message = name + requiredMessage;
        // Currently no checking of category and brand
        message = "";
      } else {
        message = "";
      }
    } //else if
    errorAdmin[`${name}`].message = message;
    this.setState({ errorAdmin: errorAdmin });
    this.checkAllErrors(errorAdmin);
  };
  handleFocus = (event) => {
    let name = event.target.name;
    let errorAdmin = this.state.errorAdmin;
    errorAdmin[`${name}`].message = "";
    this.setState({ errorAdmin: errorAdmin });
    this.checkAllErrors(errorAdmin);
  };
  checkAllErrors = (errorAdmin) => {
    // let { errorAdmin } = this.state;
    let flagFormInvalid = false;
    for (let field in errorAdmin) {
      if (errorAdmin[field].message !== "") {
        // error is there
        flagFormInvalid = true;
        break;
      } //if
    } //for
    this.setState({ flagFormInvalid: flagFormInvalid });
  };
  handleSelectRoleChange = (event) => {
    let role = event.target.value;
    this.setState({
      admin: {
        ...this.state.admin,
        role: role,
      },
    });
  };
  handleUpload = (event) => {
    this.setState({
      imageName: event.target.files[0],
    });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    window.scrollTo(0, 0); // moving scrollbar at the top
    this.props.onFormSubmit(this.state.admin); //this.state
  };
  render() {
    let { admin } = this.state;
    let { errorAdmin } = this.state;
    let { flagFormInvalid } = this.state;
    let { action } = this.props;
    let roleList = ["Head", "Owner", "Teacher", "Instructor"];

    let optionsRole = roleList.map((role, index) => (
      <option value={role} key={index}>
        {role}
      </option>
    ));

    let heading;
    if (action === "ADD") {
      heading = "Add a Admin";
    } else if (action === "UPDATE") {
      heading = "Update the Admin";
    }
    return (
      <React.Fragment>
        <div className="form-heading column col-12">{heading}</div>
        <form
          // className="container container-add-form text-dark bg-warning p-4"
          className="text-dark bg-mycyan p-4"
          onSubmit={this.handleSubmit}
        >
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Admin Name</label>
            </div>
            <div className="col-7 column px-0">
              <input
                type="text"
                className="form-control"
                name="name"
                value={admin.name}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                placeholder="Admin Name"
                required
              />
            </div>
            <div className="offset-5">
              {errorAdmin.name.message ? (
                <span className="error-text">{errorAdmin.name.message}</span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Email-id</label>
            </div>
            <div className="col-7 column px-0">
              <input
                type="email"
                className="form-control"
                name="emailId"
                value={admin.emailId}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                placeholder="Email-id"
                required
              />
            </div>
            <div className="offset-5">
              {errorAdmin.emailId.message ? (
                <span className="error-text">{errorAdmin.emailId.message}</span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center px-2">
            <div className="col-5 text-right">
              <label>WhatsApp No.</label>
            </div>
            <div className="col-7 px-0">
              <input
                type="text"
                className="form-control"
                name="whatsappNumber"
                value={admin.whatsappNumber}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                placeholder="WhatsApp No."
              />
            </div>
            <div className="offset-5">
              {errorAdmin.whatsappNumber.message ? (
                <span className="error-text">
                  {errorAdmin.whatsappNumber.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Role</label>
            </div>
            <div className="col-7 px-0">
              <select
                className="form-control"
                name="role"
                value={admin.role}
                onChange={this.handleSelectRoleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
              >
                <option> Select Role </option>
                {optionsRole}
              </select>
            </div>
            <div className="offset-5">
              {errorAdmin.role.message ? (
                <span className="error-text">{errorAdmin.role.message}</span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>New Password</label>
            </div>
            <div className="col-7 px-0">
              <input
                type="password"
                className="form-control"
                name="password"
                value={admin.password}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                placeholder="Enter Password"
                required
              />
            </div>
            <div className="offset-5">
              {errorAdmin.password ? (
                <span className="error-text">
                  {errorAdmin.password.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row offset-5 text-center mb-0">
            <button
              className="btn-mycyan"
              type="submit"
              disabled={flagFormInvalid}
            >
              {action}
            </button>
          </div>
          <input type="hidden" name="adminId" value={admin.adminId} />
        </form>
      </React.Fragment>
    );
  }
}
export default AdminAdmAdd;
