import React, { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import axios from "axios";

function StudentEmailCheck(props) {
  // let joiningStudent;
  let [joiningStudent, setJoiningStudent] = useState({
    // whatsAppNumber: "",
    emailId: "",
  });
  let [errorJoiningStudent, setErrorJoiningStudent] = useState({
    emailId: { message: "" },
  });
  let [flagLoading, setFlagLoading] = useState(false);
  let [error, setError] = useState("");
  let [message, setMessage] = useState("");
  let [flagFormInvalid, setFlagFormInvalid] = useState(false);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  // function handleChange(event) {
  //   props.onSetMessage("");
  //   setMessage("");
  //   setWhatsAppNumber(event.target.value);
  //   isValid(event);
  // }
  function handleChange(event) {
    props.onSetMessage("");
    setMessage("");
    let name = event.target.name;
    let userInput;
    let inputType = event.target.type;

    if (inputType == "checkbox") {
      // for checkbox, no checking of validations.
      userInput = event.target.checked;
      let value;
      if (userInput) {
        value = 1;
      } else {
        value = 0;
      }
      setJoiningStudent({ ...joiningStudent, [name]: value });
      return;
    }
    setJoiningStudent({ ...joiningStudent, [name]: event.target.value });
    isValid(event);
  }
  function handleBlur(event) {
    isValid(event);
  }
  function isValid(event) {
    let i,
      flag,
      name,
      value,
      requiredMessage = " is required",
      type,
      message = "",
      mnLen,
      mxLen,
      onlyDigits,
      allDigits,
      noSymbols;
    name = event.target.name;
    type = event.target.type;

    if (type === "text" || type === "textarea" || type === "password") {
      // check if 'required', also check min no. of characters
      value = event.target.value;
      mnLen = errorJoiningStudent[`${name}`].mnLen;
      mxLen = errorJoiningStudent[`${name}`].mxLen;
      onlyDigits = errorJoiningStudent[`${name}`].onlyDigits;
      allDigits = errorJoiningStudent[`${name}`].allDigits;
      noSymbols = errorJoiningStudent[`${name}`].noSymbols;
      if (value.length === 0) {
        message = name + requiredMessage;
      } else if (value.length < mnLen) {
        if (!onlyDigits) message = "Min " + mnLen + " characters required";
        else message = "Min " + mnLen + " digits required";
      } //else
      else if (value.length > mxLen) {
        if (!onlyDigits) message = "Maximum " + mxLen + " characters allowed";
        else message = "Maximum " + mxLen + " digits allowed";
      } //else
      else if (onlyDigits) {
        for (let i = value.length - 1; i >= 0; i--) {
          const d = value.charCodeAt(i);
          if (d < 48 || d > 57) {
            message = "Enter only digits";
            break;
          } //if
        } //for
      } //else if... onlyDigits
      else if (noSymbols && !allDigits) {
        let d,
          count = 0;
        for (i = value.length - 1, flag = false; i >= 0; i--) {
          d = value.charCodeAt(i);
          if (d >= 48 && d <= 57) {
            count++; // digits are being counted
          } //if
          if (
            (d >= 97 && d <= 122) ||
            (d >= 65 && d <= 90) ||
            (d >= 48 && d <= 57) ||
            value.charAt(i) == "_"
          ) {
            continue;
          } //if
          else {
            // symbol is there, may be space
            flag = true;
            break;
          }
        } //for
        if (flag == true) {
          let q = String.fromCharCode(d);
          if (d == 32) {
            message = "space is not allowed";
          } else
            message =
              "Symbols other than underscore (_) are not allowed, you have used " +
              q;
        } else {
          if (count == value.length) {
            message = "Only digits are not allowed, use some alphabets also";
          }
        }
      } //else if... noSymbols
      else {
        message = "";
      }
    } //if... text || textarea
    else if (event.target.type === "select-one") {
      value = event.target.selectedIndex;

      if (value == 0) {
        message = name + requiredMessage;
      } else {
        message = "";
      }
    } //else if
    else if (type === "email") {
      value = event.target.value.trim();
      if (/\S+@\S+\.\S+/.test(value) == 0) {
        message = "valid email-id " + requiredMessage;
      } else {
        message = "";
      }
    } //else if
    else {
      message = "";
    }
    let errJoiningStudent = { ...errorJoiningStudent };
    errJoiningStudent[`${name}`].message = message;
    setErrorJoiningStudent(errJoiningStudent);
    checkAllErrors();
  }
  function handleBlur(event) {
    isValid(event);
  }

  function handleFocus(event) {
    checkAllErrors();
  }
  function checkAllErrors() {
    if (message) {
      setFlagFormInvalid(true);
      return;
    } //if
    setFlagFormInvalid(false);
  }
  function checkFormInvalidity() {
    // if error messages are there, form is invalid
    for (let field in joiningStudent) {
      if (errorJoiningStudent[field].message != "") {
        setFlagFormInvalid(true);
        return true;
      } //if
    } //for
    let errJoiningStudent = { ...errorJoiningStudent };
    let flag = false;
    // Now check for required fields, (mainly added for select components)
    for (let field in joiningStudent) {
      if (joiningStudent[field] == "") {
        errJoiningStudent[field].message = "Required...";
        flag = true;
      } //if
    } //for
    setErrorJoiningStudent(errJoiningStudent);
    if (flag == false) {
      setFlagFormInvalid(false);
      return false;
    } else {
      setFlagFormInvalid(true);
      return true;
    }
  }
  function onFormSubmit() {
    if (checkFormInvalidity()) {
      return;
    }
    setFlagLoading(true);
    axios
      .get(
        window.routerPrefix +
          "/enquiredStudent/enquiredStudentByEmailId/" +
          joiningStudent.emailId
      )
      .then((res) => {
        setFlagLoading(false);
        if (!res.data) {
          props.onSetMessage("This email-id is not registered with us.");
          setJoiningStudent({ ...joiningStudent, emailId: "" });
          return;
        } else {
          props.onSetValidEnquiredStudent(true);
          props.onSetJoiningStudentData(res.data);
        }
        // open whatsApp to submit the request
      })
      .catch((err) => {
        setError(err);
        setFlagLoading(false);
      });
  }
  function handleSubmit(event) {
    event.preventDefault();
    window.scrollTo(0, 0); // moving scrollbar at the top
    onFormSubmit();
  }
  return (
    <>
      {/* <div className="container-fluid container-md">
        <div className="row div-reference-page text-center">
          <div className="col-12 text-thick mt-4">
            Enter the WhatsApp Number used for attending the demo class.
          </div>
        </div>
      </div> */}
      <div className="row div-reference-page text-center">
        <div className="col-12 text-thick">
          Registration is required to join a course/bootcamp.
        </div>
        <div className="col-12 ">
          If registration is done, enter your registered email-id.
        </div>
      </div>
      <form className="text-dark bg-mycyan p-4" onSubmit={handleSubmit}>
        {/* row starts */}
        <div className="form-group row justify-content-center align-items-center">
          <div className="col-5 text-right">
            <label>Email-id:</label>
          </div>
          <div className="col-7 column px-0">
            <input
              type="email"
              className="form-control"
              name="emailId"
              value={joiningStudent.emailId}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholder="Registered email-id"
              required
            />
          </div>
          <div className="offset-5">
            {errorJoiningStudent.emailId.message ? (
              <span className="error-text">
                {errorJoiningStudent.emailId.message}
              </span>
            ) : null}
          </div>
        </div>
        {/* {message && (
          <div className="text-error text-center mb-2">{message}</div>
        )} */}
        {/* row ends */}
        {!flagLoading && (
          <div className="form-group row offset-5 text-center mb-0">
            <button
              className="btn-mycyan"
              type="submit"
              // disabled={flagFormInvalid}
            >
              Submit
            </button>
          </div>
        )}
        {flagLoading && (
          <div className="form-group row offset-5 text-center mb-0">
            <BeatLoader size={16} color={"blue"} flagLoading />
          </div>
        )}
      </form>
    </>
  );
}
export default StudentEmailCheck;
