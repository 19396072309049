import React, { useEffect, useState } from "react";
import AdminPaymentList from "./admin-payment-list";
import AdminPaymentAdd from "./admin-payment-add";
import { PaymentDB } from "../PaymentDB";
import { BeatLoader } from "react-spinners";
import axios from "axios";

function AdminPayment(props) {
  let [flagLoading, setFlagLoading] = useState(false);
  let [error, setError] = useState("");
  let [searchText, setSearchText] = useState("");
  let [direction, setDirection] = useState("");
  let [sortedField, setSortedField] = useState("");
  let [sectionNumber, setSectionNumber] = useState(1);
  let [filteredPayments, setFilteredPayments] = useState([]);
  let [paymentList, setPaymentList] = useState([]);
  let [paymentdb, setPaymentdb] = useState({});
  let [payment, setPayment] = useState(null);
  useEffect(() => {
    init();
  }, []);
  function init() {
    setFlagLoading(true);
    axios
      .all([
        axios.get(window.routerPrefix + "/payment/payments/"),
        axios.get(window.routerPrefix + "/student/students/"),
        axios.get(window.routerPrefix + "/course/courses/"),
      ])
      .then(
        axios.spread((res1, res2, res3) => {
          let paymentList = res1.data;
          let studentList = res2.data;
          let courseList = res3.data;
          let updatedPayments = [...paymentList];
          paymentList.map((payment, index) => {
            // get student  (string) from studentId
            for (let i = 0; i < studentList.length; i++) {
              if (payment.studentId === studentList[i].studentId) {
                updatedPayments[index].name = studentList[i].fullName;
                break;
              }
            }
            // get course  (string) from courseId
            for (let i = 0; i < courseList.length; i++) {
              if (payment.courseId === courseList[i].courseId) {
                updatedPayments[index].courseName = courseList[i].name;
                break;
              }
            } //for
          }); //map
          paymentList = updatedPayments;
          setFlagLoading(false);
          setFilteredPayments(paymentList);
          setPaymentList(paymentList);
          setPaymentdb(new PaymentDB(paymentList));
        })
      )
      .catch((err) => {
        setError(err);
        setFlagLoading(false);
      });
  }
  function handleSearchTextBoxKeyUp(event) {
    let searchText = event.target.value;
    setSearchText(searchText);
    performSearchOperation(searchText);
  }
  function handleSearchTextChange(event) {
    let searchText = event.target.value;
    setSearchText(searchText);
    performSearchOperation(searchText);
  }
  function performSearchOperation(searchText) {
    let query = searchText.trim();
    let searchedPayments = [];
    searchedPayments = paymentdb.filterByName(query);
    setFilteredPayments(searchedPayments);
  }

  function onFormSubmit(payment) {
    let { action } = props;
    setFlagLoading(true);
    if (action === "UPDATE") {
      //EDIT / PUT
      axios
        .put(window.routerPrefix + "/payment/update", payment)
        .then((res) => {
          if (res.data) {
            //success
            updatePaymentList(payment);
          } else {
            //error
            //something went wrong
          }
          setFlagLoading(false);
        })
        .catch((err) => {
          setError(err);
          setFlagLoading(false);
        });
    } else if (action === "ADD") {
      axios
        .post(window.routerPrefix + "/payment/addPayment", payment, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
          },
        })
        .then((res) => {
          setFlagLoading(false);
          payment.paymentId = res.data.paymentId; // id of the added payment is returned as response
          addToPaymentList(payment);
        })
        .catch((err) => {
          setError(err);
          setFlagLoading(false);
        });
    } //else
  }
  function handleEditPayment(payment) {
    setPayment(payment);
    // setState({
    //   payment: payment,
    // });
    props.onEditClick();
  }
  // handleShowChapters = (payment) => {
  //   props.onShowChapters(payment);
  // };
  function addToPaymentList(payment) {
    let sList = [...paymentList];
    sList.unshift(payment);
    let fPayments = [...filteredPayments];
    fPayments.unshift(payment);
    setFilteredPayments(fPayments);
    setPaymentList(sList);
    let message = "The payment '" + payment.name + "' added successfully.";
    props.onCrudAction(message, "ADD");
  }
  function updatePaymentList(payment) {
    let sList = [...paymentList];
    for (let i = 0; i < sList.length; i++) {
      if (sList[i].paymentId == payment.paymentId) {
        sList.splice(i, 1); // remove 1 element from position i
        // and insert at the beginning as we want latest modified at the top
        sList.unshift(payment);
      }
    } //for
    // remove from filteredchapters also
    let fPayments = [...filteredPayments];
    for (let i = 0; i < fPayments.length; i++) {
      if (fPayments[i].paymentId == payment.paymentId) {
        fPayments.splice(i, 1); // remove 1 element from position i
        // and insert at the beginning as we want latest modified at the top
        fPayments.unshift(payment);
      }
    } //for
    //sorting
    setPaymentList(sList);
    setFilteredPayments(fPayments);
    let message = "The payment '" + payment.name + "' updated successfully.";
    props.onCrudAction(message, "LIST", "Add a Payment");
  }

  function handleDeletePayment(payment) {
    let sList = [...paymentList];
    for (let i = 0; i < sList.length; i++) {
      if (sList[i].paymentId == payment.paymentId) {
        sList.splice(i, 1); // remove 1 element from position i
      }
    } //for
    // remove from filteredpayments also
    let fPayments = [...filteredPayments];
    for (let i = 0; i < fPayments.length; i++) {
      if (fPayments[i].paymentId == payment.paymentId) {
        fPayments.splice(i, 1); // remove 1 element from position i
      }
    } //for
    setPaymentList(sList);
    setFilteredPayments(fPayments);
    setPaymentdb(new PaymentDB(sList));
    let message = "The payment '" + payment.name + "' deleted successfully.";
    props.onCrudAction(message, "LIST");
  }
  function handleHeaderClick(event) {
    let field = event.target.id;
    let d = false;
    if (field === sortedField) {
      // same button clicked twice
      d = !direction;
    } else {
      // different field
      d = false;
    }
    setDirection(d);
    if (d == false) {
      //in ascending order
      filteredPayments = filteredPayments.sort((a, b) => {
        if (a[field] > b[field]) {
          return 1;
        }
        if (a[field] < b[field]) {
          return -1;
        }
        return 0;
      });
    } else {
      //in descending order
      filteredPayments = filteredPayments.sort((a, b) => {
        if (a[field] < b[field]) {
          return 1;
        }
        if (a[field] > b[field]) {
          return -1;
        }
        return 0;
      });
    }
    setFilteredPayments(filteredPayments);
    setSortedField(field);
  }
  function handleSectionNumberClick(event) {
    setSectionNumber(event.target.id);
  }
  let { action } = props;
  let { accessLevel } = props;
  let { adminType } = props;

  let content;
  return (
    <div className="container-fluid container-md container-content-page p-0">
      {flagLoading && (
        <div className="text-center">
          <BeatLoader size={16} color={"blue"} flagLoading />
        </div>
      )}
      {!flagLoading && action === "LIST" && (
        <AdminPaymentList
          onEditPayment={handleEditPayment}
          onDeletePayment={handleDeletePayment}
          filteredPayments={filteredPayments}
          searchText={searchText}
          onSearchTextChange={handleSearchTextChange}
          onSearchTextBoxKeyUp={handleSearchTextBoxKeyUp}
          onHeaderClick={handleHeaderClick}
          onSectionNumberClick={handleSectionNumberClick}
          sectionNumber={sectionNumber}
          accessLevel={accessLevel}
          adminType={adminType}
          recordSize={paymentList.length}
        />
      )}
      {!flagLoading && (action === "ADD" || action === "UPDATE") && (
        <div>
          {/* content={" "} */}
          <AdminPaymentAdd
            onFormSubmit={onFormSubmit}
            payment={payment}
            action={action}
          />
        </div>
      )}
      {action === "DELETE" && <div>Delete action in Progress...</div>}
    </div>
  );
}
export default AdminPayment;
