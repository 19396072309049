import React, { useEffect, useState } from "react";
function Presentation(props) {
  let [presentationFile, setPresentationFile] = useState("");
  useEffect(() => {
    window.scroll(0, 0);
    init();
  }, []);
  function init() {
    let eventSource = new EventSource(
      "http://localhost:8083/api/files/presentation" // online
      // "https://192.168.0.80:8443/api/files/presentation" // offline
      //   "/api/files/presentation"
    );
    eventSource.addEventListener("Presentation", (event) => {
      // let presentationFile = event.data;
      setPresentationFile(event.data);
      // this.setState({
      //   presentationFile: presentationFile,
      // });
    });
    eventSource.onerror = (event) => {
      if (event.target.readyState === EventSource.CLOSED) {
        console.log("SSE closed (" + event.target.readyState + ")");
      }
      eventSource.close();
    };

    eventSource.onopen = () => {
      console.log("connection opened");
    };
  }
  return (
    <div className="container-presentation ">
      <div className="row align-items-center justify-content-center">
        <h6 className="col-12 text-center">
          Rajesh Jain Academy - Enter Confidently in the world of I.T.
        </h6>
        <div className="col-12 text-center">
          <img
            className="image-fluid"
            src={
              window.routerPrefix +
              "/files/downloadTeachingImageFile/" +
              presentationFile
            }
          />
        </div>
      </div>
    </div>
  );
}
export default Presentation;
