import React, { Component } from "react";
import { Link } from "react-router-dom";

class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flagDownnLoadingPdf: false,
      message: "",
    };
  }
  handleContactNowClick = () => {
    let whatsappNumber = "919422035020";
    let whatsAppMessage =
      "Hi! I am interested to join the course of 'Programming using 3 languages'";
    window.location = `https://wa.me/${whatsappNumber}?text=${whatsAppMessage}`;
  };
  handleLogoClick = () => {
    this.props.onLogoClick();
  };
  render() {
    let { student } = this.props;

    return (
      <React.Fragment>
        {/* <!-- Topbar Start --> */}
        <div className="container-fluid d-none d-lg-block">
          <div className="row align-items-center py-4 px-xl-5">
            <div className="col-lg-3">
              <Link to="#" className="text-decoration-none">
                {/* <!-- <h1 class="m-0"><span class="text-primary">E</span>COURSES</h1> --> */}
                <img
                  src="images/rjac_logo.jpg"
                  alt="No logo"
                  className="img-fluid homepage-small-logo"
                  onClick={this.handleLogoClick}
                />
              </Link>
            </div>
            <div className="col-lg-3 text-right">
              <div className="d-inline-flex align-items-center">
                <i className="fa fa-2x fa-map-marker-alt text-primarycolor mr-3"></i>
                <div className="text-left">
                  <h6 className="font-weight-semi-bold mb-1">Our Location</h6>
                  <small>Sinhagad College Campus, Pune-41, India</small>
                </div>
              </div>
            </div>
            <div className="col-lg-3 text-right">
              <div className="d-inline-flex align-items-center">
                <i className="fa fa-2x fa-envelope text-primarycolor mr-3"></i>
                <div className="text-left">
                  <h6 className="font-weight-semi-bold mb-1">Email Us</h6>
                  <small>admin@rajeshjainacademy.com</small>
                </div>
              </div>
            </div>
            <div className="col-lg-3 text-right">
              <div className="d-inline-flex align-items-center">
                <i className="fa fa-2x fa-phone text-primarycolor mr-3"></i>
                <div className="text-left">
                  <h6 className="font-weight-semi-bold mb-1">Call Us</h6>
                  <small>9422035020</small>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Topbar End --> */}
      </React.Fragment>
    );
  }
}
export default TopBar;
