import React from "react";
import { BeatLoader } from "react-spinners";

class AdminCoursebundleAdd extends React.Component {
  emptyCoursebundle = {
    coursebundleId: "",
    subject: "",
    subjectId: -1,
  };
  errorCoursebundle = {
    subject: { message: "" },
    subjectId: { message: "" },
  };
  constructor(props) {
    super(props);
    let coursebundle;
    let flagFormInvalid;
    let { action } = this.props;
    if (action === "ADD") {
      flagFormInvalid = true;
      this.emptyCoursebundle.course = this.props.selectedCourse;
      // this.emptyCoursebundle.subjectId = this.props.subjectIdToRetain;
      this.emptyCoursebundle.courseId = this.props.selectedCourseId;
      coursebundle = this.emptyCoursebundle;
    } else if (action === "UPDATE") {
      // in edit mode, keep the update button enabled at the beginning
      flagFormInvalid = false;
      coursebundle = this.props.coursebundle;
    }
    this.state = {
      coursebundle: coursebundle,
      errorCoursebundle: this.errorCoursebundle,
      flagFormInvalid: flagFormInvalid,
    };
  }

  handleChange = (event) => {
    let { errorCoursebundle } = this.state;
    let name = event.target.name;
    let message;
    let userInput;
    const isCheckbox = event.target.type === "checkbox";
    if (isCheckbox) {
      // for checkbox, no checking of validations.
      userInput = event.target.checked;
      this.setState({
        coursebundle: { ...this.state.coursebundle, [name]: userInput },
      });
      return;
    }
    let value = event.target.value;
    //check of max. length
    let mxLen = errorCoursebundle[`${name}`].mxLen;
    let onlyDigits = errorCoursebundle[`${name}`].onlyDigits;
    if (value.length > mxLen) {
      value = value.slice(0, mxLen + 1);
      if (!onlyDigits) message = "Maximum " + mxLen + " characters allowed";
      else message = "Maximum " + mxLen + " digits allowed";
    } //if...
    else {
      message = "";
    }
    errorCoursebundle[`${name}`].message = message;
    this.setState({
      errorCoursebundle: errorCoursebundle,
      coursebundle: { ...this.state.coursebundle, [name]: value },
    });
    this.checkAllErrors(errorCoursebundle);
  };
  handleBlur = (event) => {
    let name,
      value,
      requiredMessage = " is required",
      type,
      message = "",
      mnLen,
      onlyDigits;
    let { errorCoursebundle } = this.state;
    name = event.target.name;
    type = event.target.type;
    if (type === "text" || type === "textarea") {
      // check if 'required', also check min no. of characters
      value = event.target.value.trim();
      mnLen = errorCoursebundle[`${name}`].mnLen;
      onlyDigits = errorCoursebundle[`${name}`].onlyDigits;
      if (value.length === 0) {
        message = name + requiredMessage;
      } else if (value.length < mnLen) {
        if (!onlyDigits) message = "Min " + mnLen + " characters required";
        else message = "Min " + mnLen + " digits required";
      } //else
      else if (onlyDigits) {
        for (let i = value.length - 1; i >= 0; i--) {
          const d = value.charCodeAt(i);
          if (d < 48 || d > 57) {
            message = "Enter only digits";
            break;
          } //if
        } //for
      } //else if... onlyDigits
      else {
        message = "";
      }
    } //if... text || textarea
    else if (event.target.type === "select-one") {
      value = event.target.selectedIndex;

      if (value === 0) {
        // message = name + requiredMessage;
        // Currently no checking of category and brand
        message = "";
      } else {
        message = "";
      }
    } //else if
    errorCoursebundle[`${name}`].message = message;
    this.setState({ errorCoursebundle: errorCoursebundle });
    this.checkAllErrors(errorCoursebundle);
  };
  handleFocus = (event) => {
    let name = event.target.name;
    let errorCoursebundle = this.state.errorCoursebundle;
    errorCoursebundle[`${name}`].message = "";
    this.setState({ errorCoursebundle: errorCoursebundle });
    this.checkAllErrors(errorCoursebundle);
  };
  checkAllErrors = (errorCoursebundle) => {
    // let { errorCoursebundle } = this.state;
    let flagFormInvalid = false;
    for (let field in errorCoursebundle) {
      if (errorCoursebundle[field].message !== "") {
        // error is there
        flagFormInvalid = true;
        break;
      } //if
    } //for
    this.setState({ flagFormInvalid: flagFormInvalid });
  };
  handleRatingClick = (value) => {
    this.setState({
      coursebundle: { ...this.state.coursebundle, rating: value },
    });
  };
  handleUpload = (event) => {
    this.setState({
      imageName: event.target.files[0],
    });
  };
  handleSelectSubjectChange = (event) => {
    let index = event.target.selectedIndex; // get selected index, instad of selected value
    var optionElement = event.target.childNodes[index];
    var selectedSubjectId = optionElement.getAttribute("id");
    let subject = event.target.value;
    let subjectId = selectedSubjectId;
    this.setState({
      coursebundle: {
        ...this.state.coursebundle,
        subject: subject,
        subjectId: subjectId,
      },
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    window.scrollTo(0, 0); // moving scrollbar at the top
    this.props.onFormSubmit(this.state.coursebundle); //this.state
  };
  render() {
    let { subjectList } = this.props;
    let { coursebundle } = this.state;
    let { errorCoursebundle } = this.state;
    let { flagFormInvalid } = this.state;
    let { selectedCourse } = this.props;
    let { action } = this.props;

    let optionsSubject = subjectList.map((subject, index) => (
      <option value={subject.name} key={index} id={subject.subjectId}>
        {subject.name}
      </option>
    ));
    let heading;
    if (action === "ADD") {
      heading = "Add a Coursebundle (" + selectedCourse + ")";
    } else if (action === "UPDATE") {
      heading = "Update the Coursebundle";
    }
    return (
      <React.Fragment>
        <div className="form-heading column col-12">{heading}</div>
        <form className="text-dark bg-mycyan p-4" onSubmit={this.handleSubmit}>
          {/* row starts */}
          {/* row ends */}
                    {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Subject</label>
            </div>
            <div className="col-7 px-0">
              <select
                className="form-control"
                name="subject"
                value={coursebundle.subject}
                onChange={this.handleSelectSubjectChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
              >
                <option> Select Subject </option>
                {optionsSubject}
              </select>
            </div>
            <div className="offset-5">
              {errorCoursebundle.subject.message ? (
                <span className="error-text">
                  {errorCoursebundle.subject.message}
                </span>
              ) : null}
            </div>
          </div>
                    {/* row ends */}
          {/* row starts */}
          <div className="form-group row offset-5 text-center mb-0">
            <button
              className="btn-mycyan"
              type="submit"
              disabled={flagFormInvalid}
            >
              {action}
            </button>
          </div>
          <input
            type="hidden"
            name="coursebundleId"
            value={coursebundle.coursebundleId}
          />
        </form>
      </React.Fragment>
    );
  }
}
export default AdminCoursebundleAdd;
