import React from "react";
import { FaStar } from "react-icons/fa";

class AdminBatchAdd extends React.Component {
  emptyBatch = {
    batchId: "",
    name: "",
    type: "",
    status: "",
    course: "",
    courseId: -1,
  };
  errorBatch = {
    name: { message: "", mxLen: 40, mnLen: 3, onlyDigits: false },
    type: { message: "" },
    status: { message: "" },
    course: { message: "" },
  };
  constructor(props) {
    super(props);
    let batch;
    let flagFormInvalid;
    let { action } = this.props;
    if (action === "ADD") {
      flagFormInvalid = true;
      batch = this.emptyBatch;
    } else if (action === "UPDATE") {
      // in edit mode, keep the update button enabled at the beginning
      flagFormInvalid = false;
      batch = this.props.batch;
    }
    this.state = {
      batch: batch,
      errorBatch: this.errorBatch,
      flagFormInvalid: flagFormInvalid,
    };
  }
  handleChange = (event) => {
    let { errorBatch } = this.state;
    let name = event.target.name;
    let message;
    let userInput;
    const isCheckbox = event.target.type === "checkbox";
    if (isCheckbox) {
      // for checkbox, no checking of validations.
      userInput = event.target.checked;
      this.setState({
        batch: { ...this.state.batch, [name]: userInput },
      });
      return;
    }
    let value = event.target.value;
    //check of max. length
    let mxLen = errorBatch[`${name}`].mxLen;
    let onlyDigits = errorBatch[`${name}`].onlyDigits;
    if (value.length > mxLen) {
      value = value.slice(0, mxLen);
      if (!onlyDigits) message = "Maximum " + mxLen + " characters allowed";
      else message = "Maximum " + mxLen + " digits allowed";
    } //if...
    else {
      message = "";
    }
    errorBatch[`${name}`].message = message;
    this.setState({
      errorBatch: errorBatch,
      batch: { ...this.state.batch, [name]: value },
    });
    this.checkAllErrors(errorBatch);
  };
  handleBlur = (event) => {
    let name,
      value,
      requiredMessage = " is required",
      type,
      message = "",
      mnLen,
      onlyDigits;
    let { errorBatch } = this.state;
    name = event.target.name;
    type = event.target.type;

    if (type === "text" || type === "textarea") {
      // check if 'required', also check min no. of characters
      value = event.target.value.trim();
      mnLen = errorBatch[`${name}`].mnLen;
      onlyDigits = errorBatch[`${name}`].onlyDigits;
      if (value.length === 0) {
        message = name + requiredMessage;
      } else if (value.length < mnLen) {
        if (!onlyDigits) message = "Min " + mnLen + " characters required";
        else message = "Min " + mnLen + " digits required";
      } //else
      else if (onlyDigits) {
        for (let i = value.length - 1; i >= 0; i--) {
          const d = value.charCodeAt(i);
          if (d < 48 || d > 57) {
            message = "Enter only digits";
            break;
          } //if
        } //for
      } //else if... onlyDigits
      else {
        message = "";
      }
    } //if... text || textarea
    else if (event.target.type === "select-one") {
      value = event.target.selectedIndex;

      if (value === 0) {
        // message = name + requiredMessage;
        // Currently no checking of category and brand
        message = "";
      } else {
        message = "";
      }
    } //else if
    errorBatch[`${name}`].message = message;
    this.setState({ errorBatch: errorBatch });
    this.checkAllErrors(errorBatch);
  };
  handleFocus = (event) => {
    let name = event.target.name;
    let errorBatch = this.state.errorBatch;
    errorBatch[`${name}`].message = "";
    this.setState({ errorBatch: errorBatch });
    this.checkAllErrors(errorBatch);
  };
  checkAllErrors = (errorBatch) => {
    // let { errorBatch } = this.state;
    let flagFormInvalid = false;
    for (let field in errorBatch) {
      if (errorBatch[field].message !== "") {
        // error is there
        flagFormInvalid = true;
        break;
      } //if
    } //for
    this.setState({ flagFormInvalid: flagFormInvalid });
  };
  handleUpload = (event) => {
    this.setState({
      imageName: event.target.files[0],
    });
  };
  handleSelectTypeChange = (event) => {
    let index = event.target.selectedIndex; // get selected index, instad of selected value
    let type = event.target.value;
    this.setState({
      batch: {
        ...this.state.batch,
        type: type,
      },
    });
  };
  handleSelectStatusChange = (event) => {
    let index = event.target.selectedIndex; // get selected index, instad of selected value
    let status = event.target.value;
    this.setState({
      batch: {
        ...this.state.batch,
        status: status,
      },
    });
  };
  handleSelectCourseChange = (event) => {
    let index = event.target.selectedIndex; // get selected index, instad of selected value
    var optionElement = event.target.childNodes[index];
    var selectedCourseId = optionElement.getAttribute("id");
    let course = event.target.value;
    let courseId = selectedCourseId;
    this.setState({
      batch: {
        ...this.state.batch,
        course: course,
        courseId: courseId,
      },
    });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    window.scrollTo(0, 0); // moving scrollbar at the top
    this.props.onFormSubmit(this.state.batch); //this.state
  };
  render() {
    let { batch } = this.state;
    let { errorBatch } = this.state;
    let { flagFormInvalid } = this.state;
    let { action } = this.props;
    let { courseList } = this.props;
    let heading;
    let typeList = ["Offline + Online", "Offline", "Online"];
    let statusList = ["Not Started","Running", "Active","Closed", "On Hold"];
    // prepare options
    let optionsTypes = typeList.map((type, index) => (
      <option value={type} key={index}>
        {type}
      </option>
    ));
    // prepare options
    let optionsStatus = statusList.map((status, index) => (
      <option value={status} key={index}>
        {status}
      </option>
    ));
    // prepare options
    let optionsCourses = courseList.map((course, index) => (
      <option value={course.name} key={index} id={course.courseId}>
        {course.name}
      </option>
    ));

    if (action === "ADD") {
      heading = "Add a Batch";
    } else if (action === "UPDATE") {
      heading = "Update the Batch";
    }
    return (
      <React.Fragment>
        <div className="form-heading column col-12">{heading}</div>
        <form
          // className="container container-add-form text-dark bg-warning p-4"
          className="text-dark bg-mycyan p-4"
          onSubmit={this.handleSubmit}
        >
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Batch Name</label>
            </div>
            <div className="col-7 column px-0">
              <input
                type="text"
                className="form-control"
                name="name"
                value={batch.name}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                placeholder="Enter Batch Name"
                required
              />
            </div>
            <div className="offset-5">
              {errorBatch.name.message ? (
                <span className="error-text">{errorBatch.name.message}</span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Batch-Type</label>
            </div>
            <div className="col-7 px-0">
              <select
                className="form-control"
                name="type"
                value={batch.type}
                onChange={this.handleSelectTypeChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
              >
                <option> Select </option>
                {optionsTypes}
              </select>
            </div>
            <div className="offset-5">
              {errorBatch.type.message ? (
                <span className="error-text">{errorBatch.type.message}</span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Course</label>
            </div>
            <div className="col-7 px-0">
              <select
                className="form-control"
                name="course"
                value={batch.course}
                onChange={this.handleSelectCourseChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
              >
                <option> Select </option>
                {optionsCourses}
              </select>
            </div>
            <div className="offset-5">
              {errorBatch.course.message ? (
                <span className="error-text">{errorBatch.course.message}</span>
              ) : null}
            </div>
          </div>
          {/* row ends */}

          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Batch-Status</label>
            </div>
            <div className="col-7 px-0">
              <select
                className="form-control"
                name="status"
                value={batch.status}
                onChange={this.handleSelectStatusChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
              >
                <option> Select </option>
                {optionsStatus}
              </select>
            </div>
            <div className="offset-5">
              {errorBatch.status.message ? (
                <span className="error-text">{errorBatch.status.message}</span>
              ) : null}
            </div>
          </div>
          {/* row ends */}

          {/* row starts */}
          <div className="form-group row offset-5 text-center mb-0">
            <button
              className="btn-mycyan"
              type="submit"
              disabled={flagFormInvalid}
            >
              {action}
            </button>
          </div>
          <input type="hidden" name="batchId" value={batch.batchId} />
        </form>
      </React.Fragment>
    );
  }
}
export default AdminBatchAdd;
