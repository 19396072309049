import React from "react";

class AdminStudentAdd extends React.Component {
  emptyStudent = {
    studentId: "",
    fullName: "",
    emailId: "",
    whatsappNumber: "",
    studying: "",
    password: "",
    status: "",
    otherInfo: "",
    eligibleForPromoCode:"",
    allowLoginUsing: "",
  };
  errorStudent = {
    fullName: { message: "", mxLen: 40, mnLen: 5, onlyDigits: false },
    emailId: { message: "", mxLen: 50, mnLen: 7, onlyDigits: false },
    whatsappNumber: { message: "", mxLen: 10, mnLen: 10, onlyDigits: true },
    studying: { message: "" },
    status: { message: "" },
    allowLoginUsing: { message: "" },
    eligibleForPromoCode:{ message: "" },
    otherInfo: { message: "", mxLen: 60, mnLen: 10, onlyDigits: false },
  };
  constructor(props) {
    super(props);
    let student;

    let flagFormInvalid;
    let { action } = this.props;
    let { entityType } = this.props;
    if (action === "ADD" && entityType === "") {
      flagFormInvalid = true;
      student = this.emptyStudent;
    } else if (action === "ADD" && entityType === "inValid") {
      flagFormInvalid = false;
      student = this.props.student;
    } else if (action === "UPDATE") {
      // in edit mode, keep the update button enabled at the beginning
      flagFormInvalid = false;
      student = this.props.student;
    }
    this.state = {
      student: student,
      errorStudent: this.errorStudent,
      flagFormInvalid: flagFormInvalid,
    };
  }
  handleChange = (event) => {
    let { errorStudent } = this.state;
    let name = event.target.name;
    let message;
    let userInput;
    const isCheckbox = event.target.type === "checkbox";
    if (isCheckbox) {
      // for checkbox, no checking of validations.
      userInput = event.target.checked;
      this.setState({
        student: { ...this.state.student, [name]: userInput },
      });
      return;
    }
    let value = event.target.value;
    //check of max. length
    let mxLen = errorStudent[`${name}`].mxLen;
    let onlyDigits = errorStudent[`${name}`].onlyDigits;
    if (value.length > mxLen) {
      value = value.slice(0, mxLen);
      if (!onlyDigits) message = "Maximum " + mxLen + " characters allowed";
      else message = "Maximum " + mxLen + " digits allowed";
    } //if...
    else {
      message = "";
    }
    errorStudent[`${name}`].message = message;
    this.setState({
      errorStudent: errorStudent,
      student: { ...this.state.student, [name]: value },
    });
    this.checkAllErrors(errorStudent);
  };
  handleBlur = (event) => {
    let name,
      value,
      requiredMessage = " is required",
      type,
      message = "",
      mnLen,
      onlyDigits;
    let { errorStudent } = this.state;
    name = event.target.name;
    type = event.target.type;

    if (type === "text" || type === "textarea") {
      // check if 'required', also check min no. of characters
      value = event.target.value.trim();
      mnLen = errorStudent[`${name}`].mnLen;
      onlyDigits = errorStudent[`${name}`].onlyDigits;
      if (value.length === 0) {
        message = name + requiredMessage;
      } else if (value.length < mnLen) {
        if (!onlyDigits) message = "Min " + mnLen + " characters required";
        else message = "Min " + mnLen + " digits required";
      } //else
      else if (onlyDigits) {
        for (let i = value.length - 1; i >= 0; i--) {
          const d = value.charCodeAt(i);
          if (d < 48 || d > 57) {
            message = "Enter only digits";
            break;
          } //if
        } //for
      } //else if... onlyDigits
      else {
        message = "";
      }
    } //if... text || textarea
    else if (event.target.type === "select-one") {
      value = event.target.selectedIndex;

      if (value === 0) {
        // message = name + requiredMessage;
        // Currently no checking of category and brand
        message = "";
      } else {
        message = "";
      }
    } //else if
    errorStudent[`${name}`].message = message;
    this.setState({ errorStudent: errorStudent });
    this.checkAllErrors(errorStudent);
  };
  handleFocus = (event) => {
    let name = event.target.name;
    let errorStudent = this.state.errorStudent;
    errorStudent[`${name}`].message = "";
    this.setState({ errorStudent: errorStudent });
    this.checkAllErrors(errorStudent);
  };
  checkAllErrors = (errorStudent) => {
    // let { errorStudent } = this.state;
    let flagFormInvalid = false;
    for (let field in errorStudent) {
      if (errorStudent[field].message !== "") {
        // error is there
        flagFormInvalid = true;
        break;
      } //if
    } //for
    this.setState({ flagFormInvalid: flagFormInvalid });
  };
  handleUpload = (event) => {
    this.setState({
      imageName: event.target.files[0],
    });
  };
  handleSelectStudyingChange = (event) => {
    let studying = event.target.value;
    this.setState({
      student: {
        ...this.state.student,
        studying: studying,
      },
    });
  };
  handleSelectStatusChange = (event) => {
    let status = event.target.value;
    this.setState({
      student: {
        ...this.state.student,
        status: status,
      },
    });
  };
  handleSelectAllowLoginUsingChange = (event) => {
    let allowLoginUsing = event.target.value;
    this.setState({
      student: {
        ...this.state.student,
        allowLoginUsing: allowLoginUsing,
      },
    });
  };
  handleSelectEligibleForPromoCodeChange = (event) => {
    let eligibleForPromoCode = event.target.value;
    this.setState({
      student: {
        ...this.state.student,
        eligibleForPromoCode: eligibleForPromoCode,
      },
    });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    window.scrollTo(0, 0); // moving scrollbar at the top
    this.props.onFormSubmit(this.state.student); //this.state
  };
  render() {
    let { student } = this.state;
    let { errorStudent } = this.state;
    let { flagFormInvalid } = this.state;
    let { action } = this.props;
    let heading;
    // prepare options
    let studyingList = [
      "Engineering",
      "Diploma",
      "MCS",
      "BCS",
      "MCA",
      "BCA",
      "Other",
    ];
    // prepare options
    let statusList = ["Demo", "Registered", "Barred"];

    let optionsStudying = studyingList.map((studying, index) => (
      <option value={studying} key={index}>
        {studying}
      </option>
    ));
    let optionsStatus = statusList.map((status, index) => (
      <option value={status} key={index}>
        {status}
      </option>
    ));
    // AllowLoginUsing
    let allowLoginUsingList = ["both", "facebook", "email"];
    let eligibleForPromoCodeList=["yes","no"];
    let optionsAllowLoginUsing = allowLoginUsingList.map(
      (allowLoginUsing, index) => (
        <option value={allowLoginUsing} key={index}>
          {allowLoginUsing}
        </option>
      )
    );
    let optionsEligibleForPromoCode = eligibleForPromoCodeList.map(
      (eligibleForPromoCode, index) => (
        <option value={eligibleForPromoCode} key={index}>
          {eligibleForPromoCode}
        </option>
      )
    );

    if (action === "ADD") {
      heading = "Add a Student";
    } else if (action === "UPDATE") {
      heading = "Update the Student";
    }
    return (
      <React.Fragment>
        <div className="form-heading column col-12">{heading}</div>
        <form
          // className="container container-add-form text-dark bg-warning p-4"
          className="text-dark bg-mycyan p-4"
          onSubmit={this.handleSubmit}
        >
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Student Name</label>
            </div>
            <div className="col-7 column px-0">
              <input
                type="text"
                className="form-control"
                name="fullName"
                value={student.fullName}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                placeholder="Enter Full Name"
                required
              />
            </div>
            <div className="offset-5">
              {errorStudent.fullName.message ? (
                <span className="error-text">
                  {errorStudent.fullName.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Email Id</label>
            </div>
            <div className="col-7 column px-0">
              <input
                type="text"
                className="form-control"
                name="emailId"
                value={student.emailId}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                placeholder="Enter Email-id"
                required
              />
            </div>
            <div className="offset-5">
              {errorStudent.emailId.message ? (
                <span className="error-text">
                  {errorStudent.emailId.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>WhatsApp Number</label>
            </div>
            <div className="col-7 px-0">
              <input
                type="text"
                className="form-control"
                name="whatsappNumber"
                value={student.whatsappNumber}
                onChange={this.handleChange}
                placeholder="Enter WhatsAppNumber"
              />
            </div>
            <div className="offset-5">
              {errorStudent.whatsappNumber.message ? (
                <span className="error-text">
                  {errorStudent.whatsappNumber.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Studying</label>
            </div>
            <div className="col-7 px-0">
              <select
                className="form-control"
                name="studying"
                value={student.studying}
                onChange={this.handleSelectStudyingChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
              >
                <option> Select </option>
                {optionsStudying}
              </select>
            </div>
            <div className="offset-5">
              {errorStudent.studying.message ? (
                <span className="error-text">
                  {errorStudent.studying.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Status</label>
            </div>
            <div className="col-7 px-0">
              <select
                className="form-control"
                name="status"
                value={student.status}
                onChange={this.handleSelectStatusChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
              >
                <option> Select </option>
                {optionsStatus}
              </select>
            </div>
            <div className="offset-5">
              {errorStudent.status.message ? (
                <span className="error-text">
                  {errorStudent.status.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Allow login using </label>
            </div>
            <div className="col-7 px-0">
              <select
                className="form-control"
                name="allowLoginUsing"
                value={student.allowLoginUsing}
                onChange={this.handleSelectAllowLoginUsingChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
              >
                <option> Select </option>
                {optionsAllowLoginUsing}
              </select>
            </div>
            <div className="offset-5">
              {errorStudent.allowLoginUsing.message ? (
                <span className="error-text">
                  {errorStudent.allowLoginUsing.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Eligible for Promo Code </label>
            </div>
            <div className="col-7 px-0">
              <select
                className="form-control"
                name="eligibleForPromoCode"
                value={student.eligibleForPromoCode}
                onChange={this.handleSelectEligibleForPromoCodeChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
              >
                <option> Select </option>
                {optionsEligibleForPromoCode}
              </select>
            </div>
            <div className="offset-5">
              {errorStudent.eligibleForPromoCode.message ? (
                <span className="error-text">
                  {errorStudent.eligibleForPromoCode.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}

          {/* row starts */}
          <div className="form-group row column align-items-center px-2">
            <div className="col-5 text-right">
              <label>Other Information</label>
            </div>
            <div className="col-7 px-0">
              <textarea
                type="text"
                rows="4"
                cols="70"
                className="form-control"
                name="otherInfo"
                value={student.otherInfo}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                placeholder="Write Year and Branch of study, if applicable"
              />
            </div>
            <div className="offset-5">
              {errorStudent.otherInfo.message ? (
                <span className="error-text">
                  {errorStudent.otherInfo.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row starts */}
          {/* row starts */}
          <div className="form-group row offset-5 text-center mb-0">
            <button
              className="btn-mycyan"
              type="submit"
              disabled={flagFormInvalid}
            >
              {action}
            </button>
          </div>
          <input type="hidden" name="studentId" value={student.studentId} />
        </form>
      </React.Fragment>
    );
  }
}
export default AdminStudentAdd;
