import React, { Component } from "react";
import { Link } from "react-router-dom";
import AdminVlengthListSingle from "./admin-vlength-list-single";
import axios from "axios";
import { BeatLoader } from "react-spinners";

class AdminVlengthList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      selectedSubjectId: "",
      response: {},
    };
  }
  handleEditChapter = (chapter) => {
    this.props.onEditChapter(chapter);
  };
  handleDuplicateChapter = (chapter, selection) => {
    this.props.onDuplicateChapter(chapter, selection);
  };
  handleDeleteChapter = (chapter) => {
    this.props.onDeleteChapter(chapter);
  };
  handleSearchTextBoxKeyUp = (event) => {
    this.props.onSearchTextBoxKeyUp(event);
  };
  handleSearchTextChange = (event) => {
    this.props.onSearchTextChange(event);
  };
  handleHeaderClick = (event) => {
    this.props.onHeaderClick(event);
  };
  handleSectionNumberClick = (event) => {
    this.props.onSectionNumberClick(event);
  };
  handleSelectSubjectChange = (event) => {
    let index = event.target.selectedIndex; // get selected index, instad of selected value
    var optionElement = event.target.childNodes[index];
    var selectedSubjectId = optionElement.getAttribute("id");
    let selectedSubject = event.target.value;
    this.props.onSelectSubjectChange(selectedSubject, selectedSubjectId);
  };
  handleChapterSelection = (event) => {
    this.setState({
      selectedChapterId: event.target.value,
    });
  };

  getVideoLength = () => {
    let { selectedChapterId } = this.state;
    let { selectedSubjectId } = this.props;
    this.setState({
      flagLoading: true,
    });
    // Query parameters used here
    axios
      .all([
        axios.get(
          window.routerPrefix +
            "/general/VideoLength/" +
            selectedChapterId +
            "?subjectId=" +
            selectedSubjectId
        ),
      ])
      .then(
        axios.spread((res1) => {
          this.setState({
            flagLoading: false,
          });
        })
      )
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  };

  render() {
    let { error } = this.state;
    let { selectedSubject } = this.props;
    let { filteredChapters } = this.props;
    let { subjectList } = this.props;
    let { searchText } = this.props;
    let { currentSize } = this.props;
    let { flagLoading } = this.state;

    let contentNochapters = <div className="text-center">No chapters !</div>;
    // prepare options
    let optionsSubject = subjectList.map((subject, index) => (
      <option value={subject.name} key={index} id={subject.subjectId}>
        {subject.name}
      </option>
    ));

    let content = (
      <React.Fragment>
        {/* First select the subject  */}
        <div className="row justify-content-center">
          <div className="col-6 ">
            <select
              className="form-control"
              name="selectedSubject"
              value={selectedSubject}
              onChange={this.handleSelectSubjectChange}
              onBlur={this.handleBlur}
              onFocus={this.handleFocus}
            >
              <option> Select Subject </option>
              {optionsSubject}
            </select>
          </div>
        </div>
        {selectedSubject && currentSize != 0 && (
          <div className="form-heading column col-12">
            LIST OF chapters ({filteredChapters.length})
          </div>
        )}
        {selectedSubject && currentSize == 0 && (
          <div className="form-heading column col-12 thick-red-text">
            No Chapters
          </div>
        )}
        {/* row starts */}
        {selectedSubject && currentSize != 0 && (
          <div className="row justify-content-center column">
            <div className="col-6 my-2 column">
              <input
                type="search"
                className="container-fluid form-control"
                value={searchText}
                onKeyUp={this.handleSearchTextBoxKeyUp}
                onChange={this.handleSearchTextChange} // This is contolled by parent
                placeholder="Search chapters"
                id=""
              />
            </div>
          </div>
        )}
        {/* row ends */}
        {selectedSubject && filteredChapters.length != 0 && (
          <div className="row p-0 ">
            <div className="col-12 justify-content-center">
              <div className="table-responsive">
                <table className="table mx-auto w-auto table-sm table-hover table-borderless bigger-text">
                  <thead>
                    <tr>
                      <th>
                        {" "}
                        <Link
                          to="#"
                          onClick={this.handleHeaderClick}
                          id="chapterNumber"
                        >
                          Number
                        </Link>
                      </th>
                      <th>
                        <Link to="#" onClick={this.handleHeaderClick} id="name">
                          Name
                        </Link>
                      </th>
                    </tr>
                  </thead>
                  {filteredChapters.map((chapter, index) => (
                    <tbody key={index}>
                      <AdminVlengthListSingle
                        chapter={chapter}
                        index={index}
                        onChapterSelection={this.handleChapterSelection}
                        currentSize={currentSize}
                      />
                    </tbody>
                  ))}
                </table>
              </div>
              {!flagLoading && (
                <div className="form-group row offset-5 text-center mb-0">
                  <button className="btn-mycyan" onClick={this.getVideoLength}>
                    Get Video Length
                  </button>
                </div>
              )}
              {flagLoading && (
                <div className="text-center mt-5">
                  <BeatLoader size={16} color={"blue"} flagLoading />
                </div>
              )}
            </div>
          </div>
        )}
      </React.Fragment>
    );
    if (error) {
      return <div>Error retriving chapters </div>;
    } else if (filteredChapters.length >= 0) {
      return (
        // <div className="container-fluid bg-blue">
        // <div className="row">
        <div className="">{content}</div>
        // </div>
        // </div>
      );
    } //else
    else if (filteredChapters.length == 0) {
      return contentNochapters;
    } //else
  }
}
export default AdminVlengthList;
