import React, { Component } from "react";
import AdminCourseList from "./admin-course-list";
import AdminCourseAdd from "./admin-course-add";
import { CourseDB } from "../CourseDB";
import { BeatLoader } from "react-spinners";
import axios from "axios";

class AdminCourse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      response: {},
      course: null,
      filteredCourses: [],
      courseList: [],
      coursedb: {},
      flagLoading: false,
      searchText: "",
      sortedField: "",
      sortedDirection: false,
      sectionNumber: 1,
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  getCourseListFromDatabase = () => {
    this.setState({
      flagLoading: true,
    });
    axios
      .all([axios.get(window.routerPrefix + "/course/courses")])
      .then(
        axios.spread((res1) => {
          let courseList = res1.data;
          let updatedCourses = [...courseList];

          courseList = updatedCourses;
          this.setState({
            courseList: courseList,
            filteredCourses: courseList,
            coursedb: new CourseDB(courseList),
            flagLoading: false,
            // initFlag: true,
            /* Reason for using this initFlag: This parent has got ajax calls so,
during rendering of child error obtained, undefined props property,
so as log as initFlag is false, (i.e. data is not fetched), do not render
the parent ( so obviously child is also not rendered). 21.05.2020
https://stackoverflow.com/questions/54219364/react-function-call-after-render-and-before-childs-constructor
*/
          });
        })
      )
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  };
  componentDidMount() {
    this.setState({
      flagLoading: true,
    });
    axios
      .all([axios.get(window.routerPrefix + "/course/courses/")])
      .then(
        axios.spread((res1) => {
          let courseList = res1.data;
          let updatedCourses = [...courseList];
          courseList = updatedCourses;
          this.setState({
            courseList: courseList,
            coursedb: new CourseDB(courseList),
            filteredCourses: courseList,
            flagLoading: false,
          });
        })
      )
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  }
  handleSearchTextBoxKeyUp = (event) => {
    let searchText = event.target.value;
    this.setState({ searchText: searchText });
    this.performSearchOperation(searchText);
  };
  handleSearchTextChange = (event) => {
    let searchText = event.target.value;
    this.setState({ searchText: searchText });
    this.performSearchOperation(searchText);
  };
  performSearchOperation = (searchText) => {
    let query = searchText.trim();
    let searchedCourses = [];
    searchedCourses = this.state.coursedb.filterByName(query);
    this.setState({
      filteredCourses: searchedCourses,
    });
  };
  onFormSubmit(data) {
    let { action } = this.props;
    this.setState({
      flagLoading: true,
    });

    if (action === "UPDATE") {
      //EDIT / PUT
      axios
        .put(window.routerPrefix + "/course/update", data)
        .then((res) => {
          this.updateCourseList(data);
          this.setState({
            response: res.data,
            flagLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            flagLoading: false,
          });
          //   this.props.askToLogin();
        });
    } else if (action === "ADD") {
      axios
        .post(window.routerPrefix + "/course/addCourse", data, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
          },
        })
        .then((res) => {
          this.setState({
            flagLoading: false,
            response: res.data,
          });
          data.courseId = res.data.courseId; // id of the added course is returned as response
          this.setState({
            flagLoading: false,
          });
          this.addToCourseList(data);
        })
        .catch((err) => {
          console.log("error!");
          this.setState({
            flagLoading: false,
          });
        });
    } //else
  }
  handleEditCourse = (course) => {
    this.setState({
      course: course,
      // searchText: "",
    });
    this.props.onEditClick();
  };
  // handleShowChapters = (course) => {
  //   this.props.onShowChapters(course);
  // };
  addToCourseList = (course) => {
    let courseList = [...this.state.courseList];
    courseList.unshift(course);
    let filteredCourses = [...this.state.filteredCourses];
    filteredCourses.unshift(course);
    this.setState({
      filteredCourses: filteredCourses,
      courseList: courseList,
    });
    let message = "The course '" + course.name + "' added successfully.";
    this.props.onCrudAction(message, "ADD");
  };
  updateCourseList = (course) => {
    let courseList = [...this.state.courseList];
    for (let i = 0; i < courseList.length; i++) {
      if (courseList[i].courseId == course.courseId) {
        //courseList[i] = course;
        courseList.splice(i, 1); // remove 1 element from position i
        // and insert at the beginning as we want latest modified at the top
        courseList.unshift(course);
      }
    } //for
    // remove from filteredchapters also
    let filteredCourses = [...this.state.filteredCourses];
    for (let i = 0; i < filteredCourses.length; i++) {
      if (filteredCourses[i].courseId == course.courseId) {
        // filteredCourses[i] = course;
        filteredCourses.splice(i, 1); // remove 1 element from position i
        // and insert at the beginning as we want latest modified at the top
        filteredCourses.unshift(course);
      }
    } //for
    //sorting

    this.setState({
      //   chapterdb: new ChapterDB(chapterList),
      filteredCourses: filteredCourses,
      courseList: courseList,
      // searchText: "",
    });
    let message = "The course '" + course.name + "' updated successfully.";
    this.props.onCrudAction(message, "LIST", "Add a Course");
  };

  handleDeleteCourse = (course) => {
    let courseList = [...this.state.courseList];

    for (let i = 0; i < courseList.length; i++) {
      if (courseList[i].courseId == course.courseId) {
        courseList.splice(i, 1); // remove 1 element from position i
      }
    } //for
    // remove from filteredcourses also
    let filteredCourses = [...this.state.filteredCourses];
    for (let i = 0; i < filteredCourses.length; i++) {
      if (filteredCourses[i].courseId == course.courseId) {
        filteredCourses.splice(i, 1); // remove 1 element from position i
      }
    } //for

    this.setState({
      coursedb: new CourseDB(courseList),
      filteredCourses: filteredCourses,
      courseList: courseList,
    });
    let message = "The course '" + course.name + "' deleted successfully.";
    this.props.onCrudAction(message, "LIST");
  };
  handleDuplicateCourse = (course, howMany) => {
    this.setState({
      flagLoading: true,
    });
    axios
      .post(window.routerPrefix + "/courses/" + "/" + howMany, course, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
        },
      })
      .then((res) => {
        let message = course.name + " duplicated " + howMany;
        if (howMany == 1) message += " time.";
        else message += " times.";
        this.props.onCrudAction(message, "LIST"); // Add again
        this.getCourseListFromDatabase();
        this.setState({
          flagLoading: false,
          response: res.data,
        });
      })
      .catch((err) => {
        console.log("error!");
        this.setState({
          flagLoading: false,
        });
      });
  };
  handleHeaderClick = (event) => {
    let field = event.target.id;

    let direction = false;
    if (field === this.state.sortedField) {
      // same button clicked twice
      direction = !this.state.direction;
    } else {
      // different field
      direction = false;
    }
    this.setState({ direction: direction });
    let { filteredCourses } = this.state;
    if (direction == false) {
      //in ascending order
      filteredCourses = filteredCourses.sort((a, b) => {
        if (a[field] > b[field]) {
          return 1;
        }
        if (a[field] < b[field]) {
          return -1;
        }
        return 0;
      });
    } else {
      //in descending order
      filteredCourses = filteredCourses.sort((a, b) => {
        if (a[field] < b[field]) {
          return 1;
        }
        if (a[field] > b[field]) {
          return -1;
        }
        return 0;
      });
    }
    this.setState({ filteredCourses: filteredCourses, sortedField: field });
  };
  handleSectionNumberClick = (event) => {
    this.setState({ sectionNumber: event.target.id });
  };
  render() {
    let { flagLoading } = this.state;
    let { action } = this.props;
    let { course } = this.state;
    let { accessLevel } = this.props;
    let { adminType } = this.props;
    let content;
    if (flagLoading) {
      content = (
        <div className="text-center">
          <BeatLoader size={16} color={"blue"} flagLoading />
        </div>
      );
    } else if (action === "LIST") {
      // First show list
      content = (
        <AdminCourseList
          onEditCourse={this.handleEditCourse}
          onDeleteCourse={this.handleDeleteCourse}
          filteredCourses={this.state.filteredCourses}
          searchText={this.state.searchText}
          onSearchTextChange={this.handleSearchTextChange}
          onSearchTextBoxKeyUp={this.handleSearchTextBoxKeyUp}
          onHeaderClick={this.handleHeaderClick}
          onSectionNumberClick={this.handleSectionNumberClick}
          sectionNumber={this.state.sectionNumber}
          onDuplicateCourse={this.handleDuplicateCourse}
          accessLevel={accessLevel}
          adminType={adminType}
        />
      );
    } else if (action === "ADD" || action === "UPDATE") {
      // After pressing Add Button, show only form and not the list
      content = (
        <div>
          {/* content={" "} */}
          <AdminCourseAdd
            onFormSubmit={this.onFormSubmit}
            course={course}
            action={action}
          />
        </div>
      );
    } else if (action === "DELETE") {
      content = <div>Delete action in Progress...</div>;
    }
    return (
      <div className="container-fluid container-md container-content-page p-0">
        {content}
      </div>
    );
  }
}
export default AdminCourse;
