import React, { useEffect, useState } from "react";
function AdminPaymentAdd(props) {
  let emptyPayment;
  let [payment, setPayment] = useState({
    paymentId: "",
    studentId: "",
    name: "",
    courseId: "",
    courseName: "",
    amount: "",
    imageName: "",
    isVerified: "",
    isRefunded: 0,
    refundAmount: 0,
  });
  let [errorPayment, setErrorPayment] = useState({
    name: { message: "" },
    courseName: { message: "" },
    amount: { message: "", onlyDigits: true },
    imageName: { message: "" },
    studentId: { message: "" },
    courseId: { message: "" },
    refundAmount: { message: "", onlyDigits: true },
  });
  let [flagFormInvalid, setFlagFormInvalid] = useState(false);
  useEffect(() => {
    window.scroll(0, 0);
    init();
  }, []);
  function init() {
    emptyPayment = payment;
    let { action } = props;
    if (action === "ADD") {
      setFlagFormInvalid(true);
      setPayment(emptyPayment);
    } else if (action === "UPDATE") {
      // in edit mode, keep the update button enabled at the beginning
      setFlagFormInvalid(false);
      setPayment(props.payment);
    }
  }
  function handleChange(event) {
    let name = event.target.name;
    let userInput;
    let inputType = event.target.type;
    if (inputType == "checkbox") {
      // for checkbox, no checking of validations.
      userInput = event.target.checked;
      let value;
      if (userInput) {
        value = 1;
      } else {
        value = 0;
      }
      setPayment({ ...payment, [name]: value });
      return;
    }
    setPayment({ ...payment, [name]: event.target.value });
    isValid(event);
  }
  function handleBlur(event) {
    isValid(event);
  }
  function isValid(event) {
    let i,
      flag,
      name,
      value,
      requiredMessage = " is required",
      type,
      message = "",
      mnLen,
      mxLen,
      onlyDigits,
      allDigits,
      noSymbols;
    name = event.target.name;
    type = event.target.type;
    if (type === "text" || type === "textarea" || type === "password") {
      // check if 'required', also check min no. of characters
      value = event.target.value.trim();
      mnLen = errorPayment[`${name}`].mnLen;
      mxLen = errorPayment[`${name}`].mxLen;
      onlyDigits = errorPayment[`${name}`].onlyDigits;
      allDigits = errorPayment[`${name}`].allDigits;
      noSymbols = errorPayment[`${name}`].noSymbols;
      if (value.length === 0) {
        message = name + requiredMessage;
      } else if (value.length < mnLen) {
        if (!onlyDigits) message = "Min " + mnLen + " characters required";
        else message = "Min " + mnLen + " digits required";
      } //else
      else if (value.length > mxLen) {
        if (!onlyDigits) message = "Maximum " + mxLen + " characters allowed";
        else message = "Maximum " + mxLen + " digits allowed";
      } //else
      else if (onlyDigits) {
        for (let i = value.length - 1; i >= 0; i--) {
          const d = value.charCodeAt(i);
          if (d < 48 || d > 57) {
            message = "Enter only digits";
            break;
          } //if
        } //for
      } //else if... onlyDigits
      else if (noSymbols && !allDigits) {
        let d,
          count = 0;
        for (i = value.length - 1, flag = false; i >= 0; i--) {
          d = value.charCodeAt(i);
          if (d >= 48 && d <= 57) {
            count++; // digits are being counted
          } //if
          if (
            (d >= 97 && d <= 122) ||
            (d >= 65 && d <= 90) ||
            (d >= 48 && d <= 57) ||
            value.charAt(i) == "_"
          ) {
            continue;
          } //if
          else {
            // symbol is there, may be space
            flag = true;
            break;
          }
        } //for
        if (flag == true) {
          let q = String.fromCharCode(d);
          if (d == 32) {
            message = "space is not allowed";
          } else
            message =
              "Symbols other than underscore (_) are not allowed, you have used " +
              q;
        } else {
          if (count == value.length) {
            message = "Only digits are not allowed, use some alphabets also";
          }
        }
      } //else if... noSymbols
      else {
        message = "";
      }
    } //if... text || textarea
    else if (event.target.type === "select-one") {
      value = event.target.selectedIndex;
      if (value == 0) {
        message = name + requiredMessage;
      } else {
        message = "";
      }
    } //else if
    else if (type === "email") {
      value = event.target.value.trim();
      if (/\S+@\S+\.\S+/.test(value) == 0) {
        message = "valid email-id " + requiredMessage;
      } else {
        message = "";
      }
    } //else if
    else {
      message = "";
    }
    let errPayment = { ...errorPayment };
    errorPayment[`${name}`].message = message;
    setErrorPayment(errPayment);
    checkAllErrors();
  }
  function handleFocus(event) {
    checkAllErrors();
  }
  function checkAllErrors() {
    for (let field in errorPayment) {
      if (errorPayment[field].message !== "") {
        setFlagFormInvalid(true);
        return;
      } //if
    } //for
    setFlagFormInvalid(false);
  }
  function handleIsVerifiedSelection(value) {
    setPayment({ ...payment, isVerified: value });
  }
  function handleIsRefundedSelection(value) {
    setPayment({ ...payment, isRefunded: value });
  }
  function handleSubmit(event) {
    event.preventDefault();
    window.scrollTo(0, 0); // moving scrollbar at the top
    props.onFormSubmit(payment);
  }
  let { action } = props;
  let heading;
  if (action === "ADD") {
    heading = "Add a Payment";
  } else if (action === "UPDATE") {
    heading = "Update the Payment";
  }
  let availableForList = ["All", "Nobody", "Online+Offline"];
  // prepare options
  let optionsAvailableFor = availableForList.map((availableFor, index) => (
    <option value={availableFor} key={index}>
      {availableFor}
    </option>
  ));
  return (
    <>
      <div className="form-heading col-12">{heading}</div>
      <form
        // className="container container-add-form text-dark bg-warning p-4"
        className="text-dark bg-mycyan p-4"
        onSubmit={handleSubmit}
      >
        {/* row starts */}
        <div className="form-group row align-items-center">
          <div className="col-5 text-right">
            <label>Student Name</label>
          </div>
          <div className="col-7 px-0">
            <input
              type="text"
              className="form-control"
              name="name"
              value={payment.name}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholder="Student Name"
              required
            />
          </div>
          <div className="offset-5">
            {errorPayment.name.message ? (
              <span className="error-text">
                {errorPayment.name.message}
              </span>
            ) : null}
          </div>
        </div>
        {/* row ends */}
        {/* row starts */}
        <div className="form-group row align-items-center">
          <div className="col-5 text-right">
            <label>Course Selected</label>
          </div>
          <div className="col-7 px-0">
            <input
              type="text"
              className="form-control"
              name="courseName"
              value={payment.courseName}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholder="Course selected"
              readOnly
            />
          </div>
          <div className="offset-5">
            {errorPayment.courseName.message ? (
              <span className="error-text">
                {errorPayment.courseName.message}
              </span>
            ) : null}
          </div>
        </div>
        {/* row ends */}
        {/* row starts */}
        <div className="form-group row  align-items-center">
          <div className="col-5 text-right">
            <label>Amount</label>
          </div>
          <div className="col-7  px-0">
            <input
              type="text"
              className="form-control"
              name="amount"
              value={payment.amount}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholder="Amount"
              required
            />
          </div>
          <div className="offset-5">
            {errorPayment.amount.message ? (
              <span className="error-text">{errorPayment.amount.message}</span>
            ) : null}
          </div>
        </div>
        {/* row ends */}

        {/* row starts */}
        <div className="form-group row  align-items-center">
          <div className="col-5 text-right">
            <label>Is Verified?</label>
          </div>
          <div className="col-7  px-0">
            <input
              type="radio"
              className="form-control-inline"
              name="isVerified"
              value="0"
              onClick={() => handleIsVerifiedSelection("0")}
              onChange={() => handleIsVerifiedSelection("0")}
              checked={payment.isVerified == "0"}
            />
            &nbsp;No &nbsp;&nbsp;&nbsp;
            <input
              type="radio"
              className="form-control-inline"
              name="isVerified"
              value="1"
              onClick={() => handleIsVerifiedSelection("1")}
              onChange={() => handleIsVerifiedSelection("1")}
              checked={payment.isVerified == "1"}
            />
            &nbsp;Yes&nbsp;&nbsp;
          </div>
        </div>
        {/* row starts */}
        <div className="form-group row  align-items-center">
          <div className="col-5 text-right">
            <label>Is Refunded?</label>
          </div>
          <div className="col-7  px-0">
            <input
              type="radio"
              className="form-control-inline"
              name="isRefunded"
              value="0"
              onClick={() => handleIsRefundedSelection("0")}
              onChange={() => handleIsRefundedSelection("0")}
              checked={payment.isRefunded == "0"}
            />
            &nbsp;No &nbsp;&nbsp;&nbsp;
            <input
              type="radio"
              className="form-control-inline"
              name="isRefunded"
              value="1"
              onClick={() => handleIsRefundedSelection("1")}
              onChange={() => handleIsRefundedSelection("1")}
              checked={payment.isRefunded == "1"}
            />
            &nbsp;Yes&nbsp;&nbsp;
          </div>
        </div>
        {/* row starts */}
        <div className="form-group row  align-items-center">
          <div className="col-5 text-right">
            <label>Refund Amount</label>
          </div>
          <div className="col-7  px-0">
            <input
              type="text"
              className="form-control"
              name="refundAmount"
              value={payment.refundAmount}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholder="Refund Amount"
              required
            />
          </div>
          <div className="offset-5">
            {errorPayment.refundAmount.message ? (
              <span className="error-text">
                {errorPayment.refundAmount.message}
              </span>
            ) : null}
          </div>
        </div>
        {/* row ends */}
        {/* row starts */}
        <div className="form-group row offset-5 text-center mb-0">
          <button
            className="btn-mycyan"
            type="submit"
            disabled={flagFormInvalid}
          >
            {action}
          </button>
        </div>
        <input type="hidden" name="paymentId" value={payment.paymentId} />
      </form>
    </>
  );
}
export default AdminPaymentAdd;
